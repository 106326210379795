/*
 A component to handle the other options pop-up with the predefined options in the pop-up and handle the clicks.
 This pop-up will appear when selected "Other" from dropdown.
*/
import React from 'react';
import Dialog from 'material-ui/Dialog';
import Snackbar from 'material-ui/Snackbar';
import FlatButton from 'material-ui/FlatButton';
import { confirmable } from 'react-confirm';
import Theme from '../../Theme';
import Checkbox from 'material-ui/Checkbox';
import './Dialogs.css';
import log from '../../utils/logger';

let component: any;

type DisqualifiedWarningAlertComponentProps = {
  okLabel?: string;
  confirmation: any;
  cancel: (...args: any[]) => any;
  dismiss: (...args: any[]) => any;
  show: false & ((...args: any[]) => any);
  proceed: (...args: any[]) => any;
};

type DisqualifiedWarningAlertComponentState = {
  oldState?: any;
  show: boolean;
  open: boolean;
  checked: boolean;
  defaultOptions: any[];
};

class DisqualifiedWarningAlertComponent extends React.Component<
  DisqualifiedWarningAlertComponentProps,
  DisqualifiedWarningAlertComponentState
> {
  constructor(props: DisqualifiedWarningAlertComponentProps) {
    super(props);
    this.state = {
      show: true,
      open: false,
      checked: false,
      defaultOptions: [],
    };
    component = this;
  }
  /*
    closes pop-up when "X" is clicked
    */
  handleClose = () => {
    if (component.state.checked) {
      this.props.proceed();
      this.setState({ show: false });
    } else {
      this.setState({ open: true });
    }
  };

  /*
    Submit button method which passes the selected options to the parent transcription class.
  */
  handleData = () => {
    if (component.state.checked) {
      this.props.proceed();
      this.setState({ show: false });
    } else {
      this.setState({ open: true });
    }
  };

  updateCheck() {
    this.setState(oldState => {
      return {
        checked: !oldState.checked,
      };
    });
  }

  /*
  Check box methods which removes or adds the selected option into array
  */
  handleRequestClose = () => {
    // this.props.cancel();
    this.setState({ open: false });
  };

  handleCheck = (event: any) => {
    const target = event.target;
    const selectedName = target.id;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const checked = this.state.defaultOptions.slice();
    if (value === true) {
      checked.push(selectedName);
      this.state.defaultOptions.push(selectedName);
    } else {
      const index = checked.indexOf(selectedName);
      checked.splice(index, 1);
      this.state.defaultOptions.splice(index, 1);
    }
  };

  render() {
    const { okLabel = 'Submit', confirmation } = this.props;
    log.debug('Props are', this.props);
    const actions = [
      <FlatButton
        key={0}
        className="OK"
        label={okLabel}
        style={{ height: '35px', lineHeight: '30px', width: '200px' }}
        onClick={this.handleData}
      />,
    ];

    return (
      <Theme>
        <Dialog
          className="submitDialog"
          actions={actions}
          modal={true}
          open={this.state.show}
          contentClassName="customDialogBox"
          autoScrollBodyContent={true}
          actionsContainerStyle={{
            textAlign: 'center',
            padding: '10px 0px 40px 0px',
          }}
        >
          <div>
            <div className="popCloseBtn" />
            <div className="popUpReportIconSec">
              <svg
                className="icon"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
              </svg>
            </div>
            <div className="innerTextSec">{confirmation.message}</div>
          </div>
          <div className="MessageCheckBoxSec">
            <Checkbox
              className="checkBoxSec"
              checked={this.state.checked}
              onCheck={this.updateCheck.bind(this)}
            />
            <label>I have read and understand this message.</label>
          </div>
        </Dialog>
        <Snackbar
          bodyStyle={{
            color: 'red',
          }}
          contentStyle={{
            textAlign: 'center',
          }}
          open={this.state.open}
          message={'Please accept acknowledgement'}
          autoHideDuration={4000}
          onRequestClose={this.handleRequestClose}
        />
      </Theme>
    );
  }
}

export default confirmable(DisqualifiedWarningAlertComponent);
