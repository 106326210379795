/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Card, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import CustomTextField from '../../CustomTextField';
import Api from '../../../api/Api';
import Constants from '../../../utils/Constants';
import CircleProgressComponent from '../../CircleProgressComponent';
import $ from 'jquery';
import { singleChoiceAlertDialogComponent } from '../../../utils/DialogsUtils';
import log from '../../../utils/logger';

let component: any;

type ChangePasswordComponentProps = {
  history?: any;
  match?: Record<string, unknown>;
};

type ChangePasswordComponentState = {
  isLoading: boolean;
  currentPasswordError: string;
  newPasswordError: string;
  confirmNewPasswordError: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  error: string;
};

class ChangePasswordComponent extends Component<
  ChangePasswordComponentProps,
  ChangePasswordComponentState
> {
  constructor(props: ChangePasswordComponentProps) {
    super(props);
    component = this;
  }

  state = {
    isLoading: false,
    currentPasswordError: '',
    newPasswordError: '',
    confirmNewPasswordError: '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    error: '',
  };

  // componentDidMount() {}

  onChange(event: any) {
    //component.setState({email,})
    log.debug('id', event.target.id);
    log.debug('value', event.target.value);
    const value = event.target.value;
    if (event.target.id === 'current_password') {
      if (value.length >= 6) {
        component.setState({
          currentPassword: value,
          currentPasswordError: '',
        });
      } else {
        component.setState({
          currentPassword: value,
          currentPasswordError: 'Please enter current password',
        });
      }
    } else if (event.target.id === 'new_password') {
      if (value.length >= 6) {
        component.setState({ newPassword: value, newPasswordError: '' });
      } else {
        component.setState({
          newPassword: value,
          newPasswordError: 'Please enter new password',
        });
      }
    } else if (event.target.id === 'confirm_new_password') {
      if (value.length >= 6) {
        component.setState({
          confirmNewPassword: value,
          confirmNewPasswordError: '',
        });
      } else {
        component.setState({
          confirmNewPassword: value,
          confirmNewPasswordError: 'Please confirm new password',
        });
      }
    }
  }

  onSave() {
    log.debug(component.state);
    if (
      component.state.newPassword.length >= 6 &&
      component.state.currentPassword.length >= 6 &&
      component.state.confirmNewPassword.length >= 6
    ) {
      if (component.state.newPassword === component.state.confirmNewPassword) {
        const data = {
          user_id: component.props.match.params.id,
          current_password: component.state.currentPassword,
          new_password: component.state.newPassword,
        };
        component.setState({ isLoading: true });
        Api.changePassword(data)
          .then(response => {
            if (response && response.http_status !== undefined) {
              if (response.http_status === 200) {
                component.props.history.push('/dashboard/profile');
              } else {
                singleChoiceAlertDialogComponent(response.message).then(
                  // eslint-disable-next-line no-unused-vars
                  (params: any) => {
                    log.debug('Email Changed', 'entered in login');
                  },
                  () => 0,
                );
                component.setState({ isLoading: false });
              }
            } else {
              singleChoiceAlertDialogComponent(Constants.generic_message).then(
                // eslint-disable-next-line no-unused-vars
                (params: any) => {
                  log.debug('Email Changed', 'entered in login');
                },
                () => 0,
              );
              component.setState({ isLoading: false });
            }
          })
          .catch((error: any) => {
            singleChoiceAlertDialogComponent(Constants.generic_message).then(
              // eslint-disable-next-line no-unused-vars
              (params: any) => {
                log.debug('Email Changed', 'entered in login');
              },
              () => 0,
            );
            component.setState({ isLoading: false });
          });
      } else {
        singleChoiceAlertDialogComponent(
          'Password confirmation and Password must match',
        ).then(
          // eslint-disable-next-line no-unused-vars
          (params: any) => {
            log.debug('Email Changed', 'entered in login');
          },
          () => 0,
        );
      }
    } else {
      const currentPassword = $('#current_password').val()?.toLocaleString();
      const newPassword = $('#new_password').val()?.toLocaleString();
      const confirmNewPassword = $('#confirm_new_password')
        .val()
        ?.toLocaleString();
      if (currentPassword && currentPassword.length >= 6) {
        component.setState({ currentPasswordError: '' });
      } else {
        component.setState({
          currentPasswordError: 'Please enter current password',
        });
      }
      if (newPassword && newPassword.length >= 6) {
        component.setState({ newPasswordError: '' });
      } else {
        component.setState({ newPasswordError: 'Please enter new password' });
      }
      if (confirmNewPassword && confirmNewPassword.length >= 6) {
        component.setState({ confirmNewPasswordError: '' });
      } else {
        component.setState({
          confirmNewPasswordError: 'Please confirm new password',
        });
      }
    }
  }

  render() {
    return (
      <div>
        <Card className="ProfileEditBox">
          {this.state.error.length > 0 && <div>{this.state.error}</div>}

          <CardText>
            <div className="headingSec">Change Password</div>
            <div className="clear" />
            <div className="BoxFromSec">
              <div className="BoxDetailsRow">
                <CustomTextField
                  fullWidth={true}
                  id="current_password"
                  errorText={this.state.currentPasswordError}
                  floatingLabelText="Current Password"
                  value={this.state.currentPassword}
                  type="password"
                  className="customTextColor"
                  onChange={this.onChange}
                  floatingLabelFixed={true}
                />
                <div className="clear" />
              </div>
              <div className="BoxDetailsRow">
                <CustomTextField
                  fullWidth={true}
                  errorText={this.state.newPasswordError}
                  value={this.state.newPassword}
                  onChange={this.onChange}
                  id="new_password"
                  floatingLabelText="New Password"
                  floatingLabelFixed={true}
                  className="customTextColor"
                  type="password"
                />
                <div className="clear" />
              </div>
              <div className="BoxDetailsRow">
                <CustomTextField
                  fullWidth={true}
                  errorText={this.state.confirmNewPasswordError}
                  value={this.state.confirmNewPassword}
                  onChange={this.onChange}
                  id="confirm_new_password"
                  type="password"
                  floatingLabelText="Confirm New Password"
                  className="customTextColor"
                  floatingLabelFixed={true}
                />
                <div className="clear" />
              </div>
              <div className="BoxDetailsRow btnRow">
                <RaisedButton
                  style={
                    !this.state.isLoading
                      ? {
                          display: 'inline-block',
                        }
                      : {
                          display: 'none',
                        }
                  }
                  onClick={this.onSave}
                  label="Save"
                  className="TransparentBtn"
                  primary={true}
                />
                <button
                  style={
                    this.state.isLoading
                      ? {
                          display: 'inline-block',
                          textAlign: 'center',
                        }
                      : {
                          display: 'none',
                        }
                  }
                  className="saveLoadBtnSec"
                >
                  <CircleProgressComponent
                    color="#FFFFFF"
                    size={25}
                    thickness={2}
                  />
                </button>
              </div>

              <div className="clear" />
            </div>
          </CardText>
        </Card>
      </div>
    );
  }
}

export default ChangePasswordComponent;
