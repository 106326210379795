/* eslint-disable @typescript-eslint/naming-convention */
import Link from '@material-ui/core/Link';
import TextField from 'material-ui/TextField';
import { TextFieldProps } from 'material-ui';
import React from 'react';
import Constants from '../../../utils/Constants';
import '../SampleCalibrationComponent.css';
import { menuDetailsDialog } from '../../../utils/DialogsUtils';

interface AudioTranscriptionTextFieldProps extends TextFieldProps {
  history?: any;
  id?: string;
  value?: string;
  onChange?: (...args: any[]) => any;
  onKeyUp?: (...args: any[]) => any;
  brandDetails?: string;
}

// eslint-disable-next-line func-style
const AudioTranscriptionTextField: React.FC<AudioTranscriptionTextFieldProps> = (
  props: AudioTranscriptionTextFieldProps,
) => {
  function handleMenuClick(menu: any) {
    // event.preventDefault()
    // console.log("menu", props,menu)
    const data = { menu, props };
    menuDetailsDialog(data).then(
      () => {
        props.history.push({
          // menu,
          // props,
          pathname: '/dashboard/brand/menu',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => 0,
    );
  }

  // function onClickOpenTranscribeFAQs(faq) {
  //   props.history.push({
  //     title: faq,
  //     pathname: '/dashboard/faqs/transcribefaqs',
  //   });
  // }

  return (
    <div className="audioTextBoxSec">
      <div
        style={{
          border: '1px solid #bebeb9',
          borderRight: '0px',
          borderLeft: '0px',
        }}
      >
        <div className="view_menu_text_sec">
          <p className="audioTextBoxSecHeading"> {Constants.menuHint}</p>
          <Link
            className="view_menu_link"
            onClick={() => handleMenuClick(props.brandDetails)}
            rel="noreferrer"
          >
            View Menu
          </Link>
        </div>
      </div>

      <p className="audioTextBoxSecHeading">
        {Constants.audio_transcription_message}
      </p>
      <TextField
        className="audioTextBox"
        id={props.id}
        value={props.value}
        multiLine={true}
        autoFocus={true}
        hintStyle={{ top: '15px', left: '13px', bottom: 'inherit' }}
        rows={2}
        hintText="Type here..."
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
        fullWidth={true}
        underlineShow={false}
      />
    </div>
  );
};

export default AudioTranscriptionTextField;
