/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { Component, Dispatch, RefObject } from 'react';
import MicrophoneOn from 'material-ui/svg-icons/av/mic';
import MicrophoneOff from 'material-ui/svg-icons/av/stop';
import PlayImage from 'material-ui/svg-icons/av/play-arrow';
import PauseImage from 'material-ui/svg-icons/av/pause';
import { connect } from 'react-redux';
import { getScore, updateTutorialFlag } from '../../../actions';
// import { createHashHistory } from "history";
import { Card } from 'material-ui/Card';
import DoneImage from 'material-ui/svg-icons/action/done';
import CustomFloatingActionButton from '../../CustomFloatingActionButton';
// require ('./styles.scss');
import Api from '../../../api/Api';
import Constants from '../../../utils/Constants';
import '../SampleCalibrationComponent.css';
import CircleProgressComponent from '../../CircleProgressComponent';
// import ReactMediaRecorder from '../../video/ReactMediaRecorder';
import ScoreComponent from '../../ScoreComponent';
// import ReactMediaRecorderNew from "../../video/ReactMediaRecorderNew";
import { getBrowserName, isAudioApiTokenEmpty } from '../../../utils/AppUtils';
import ShowAcceptTutorialComponent from './ShowAcceptTutorialComponent';
import getBlobDuration from 'get-blob-duration';
import log from '../../../utils/logger';
// const history = createHashHistory();

let component: any;
let startVideoRecording: () => any;
let stopVideoRecording: () => any;
let hideShowCamera: (...args: any[]) => any;

type AudioRecorderComponentNewProps = {
  score: Record<string, unknown>;
  history?: any;
  getScore: (...args: any[]) => any;
  updateTutorialFlag: (...args: any[]) => any;
};

type AudioRecorderComponentNewState = {
  record: boolean;
  blobObject: null;
  isRecording: boolean;
  mode: number;
  statements: any[];
  currentSelectedStatementPosition: number;
  isLoading: boolean;
  errorMessage: string;
  customer_data: any[];
  granted: boolean;
  rejectedReason: string;
  recording: boolean;
  paused: boolean;
  isPlaying: boolean;
  url: string;
};

class AudioRecorderComponentNew extends Component<
  AudioRecorderComponentNewProps,
  AudioRecorderComponentNewState
> {
  sourceRef:
    | string
    | ((instance: HTMLSourceElement | null) => void)
    | RefObject<HTMLSourceElement>
    | null
    | undefined;
  videoRef:
    | string
    | ((instance: HTMLVideoElement | null) => void)
    | RefObject<HTMLVideoElement>
    | null
    | undefined;
  onPermissionGranted: ((...args: any[]) => any) | undefined;
  audio: any;

  constructor(props: AudioRecorderComponentNewProps) {
    super(props);
    this.state = {
      record: false,
      blobObject: null,
      isRecording: false,
      mode: Constants.AUDIO_NORMAL,
      statements: [],
      currentSelectedStatementPosition: 0,
      isLoading: true,
      errorMessage: '',
      customer_data: [],
      granted: false,
      rejectedReason: '',
      recording: false,
      paused: false,
      isPlaying: false,
      url: '',
    };
    component = this;
    this.videoRef = React.createRef();
    this.sourceRef = React.createRef();
    this.startPlaying = this.startPlaying.bind(this);
    this.stopListening = this.stopListening.bind(this);
  }

  componentDidMount() {
    if (this.audio) {
      this.audio.addEventListener('ended', (e: any) => {
        log.debug(e);
        this.stopListening();
      });
    }
    // const body = {
    //   customer_id: 0,
    //   campaign_id: 0,
    // };
    // if (!this.props.TestStatus.status) {
    //   this.props.history.push('/dashboard/collections/calibration')
    //   return
    // }
    this.getNextStatement();
    if (hideShowCamera !== null) {
      hideShowCamera(true);
    }
  }

  getNextStatement() {
    Api.getNextStatement()
      .then(response => {
        log.debug('statement data', response);
        if (response && response.http_status && response.http_status === 200) {
          log.debug('Entered', response.data);
          log.debug('TEXT', response.data[0].statement_text);
          log.debug('CUSTOMER', response.customer_metadata);
          component.setState({
            statements: response.data,
            isLoading: false,
            customer_data: response.customer_metadata,
          });
          //this.forceUpdate();
        } else if (
          response &&
          response.message &&
          response.http_status === 202
        ) {
          component.setState({
            isLoading: false,
            errorMessage: Constants.no_samples_message,
            statements: [],
            customer_data: [],
          });
        } else {
          component.setState({
            isLoading: false,
            statements: [],
            customer_data: [],
          });
        }
      })
      .catch(() => {
        component.setState({
          isLoading: false,
          statements: [],
          customer_data: [],
        });
      });
  }

  startRecording = () => {
    //component.stopListening();
    if (component.videoRef && component.videoRef.isPlaying) {
      component.stop();
    }
    //component.setState({isRecording:true})
    //component.setState({ record: true, isRecording: true, mode: Constants.AUDIO_RECORDING });
    //////////////////
    if (startVideoRecording) {
      log.debug('started video recording');
      startVideoRecording();
      // component.setState({blobObject:null,url:""})
      //this.setState({ record: true, isRecording: true, mode: Constants.AUDIO_RECORDING });
    } else {
      alert('not working');
      log.debug('started video recording null ');
    }
    if (hideShowCamera) {
      hideShowCamera(true);
    } else {
      //alert('dddsdsds')
    }
  };

  stopRecording = () => {
    this.setState({
      record: false,
      isRecording: false,
      mode: Constants.AUDIO_RECORDED,
    });
    if (stopVideoRecording) {
      log.debug('stopped video recording');
      stopVideoRecording();
    }
    if (hideShowCamera) {
      hideShowCamera(false);
    }
  };

  onStart = () => {
    this.setState({
      record: true,
      isRecording: true,
      mode: Constants.AUDIO_RECORDING,
      isPlaying: false,
      url: '',
    });
  };

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPermissionError(message: any) {
    alert(Constants.camera_permission_error);
    component.setState({ mode: Constants.AUDIO_NORMAL });
  }

  // onPermissionGranted() {
  //   //alert(message)
  //   // component.setState({mode:Constants.AUDIO_NORMAL})
  // }

  onStop = (url: string, blobObject: any) => {
    log.debug('On Stop', url);
    getBlobDuration(blobObject).then(
      duration => {
        log.debug('Duration', duration);
        component.setState({
          blobObject: blobObject,
          url: url,
          duration: duration,
        });
        // this.setState(this.state)
        if (component.sourceRef) {
          component.sourceRef.setAttribute('src', url);
        }
        if (component.videoRef) {
          component.videoRef.load();
        }
      },
      () => 0,
    );
  };

  startPlaying() {
    log.debug('Playing');
    log.debug(this.audio);
    // this
    //   .audio
    //   .play();
    this.setState({ mode: Constants.AUDIO_PLAYING, isPlaying: true });
    component.videoRef.play();
  }

  stopListening() {
    // this
    //   .audio
    //   .pause();
    this.setState({ mode: Constants.AUDIO_RECORDED, isPlaying: false });
    component.videoRef.pause();
  }

  onEnded() {
    component.stopListening();
  }

  async onAccept() {
    log.debug(component.state);
    const statement = component.state.statements[0];
    const customer_metadata = component.state.customer_data[0];
    log.debug('CUSTOMER META DATA', customer_metadata);
    try {
      component.videoRef.pause();
    } catch (error) {
      log.debug(error);
    }
    // eslint-disable-next-line no-console
    console.time('Video_uploading_started');
    if (isAudioApiTokenEmpty()) {
      component.getAudioAuthenticationToken();
    } else {
      component.setState({ mode: Constants.AUDIO_NORMAL, isLoading: true });
      await Api.fileUpload(
        component.state.blobObject,
        statement,
        customer_metadata,
        component.state.duration,
      ).then(async (response: any) => {
        log.debug('AUDIO', response);
        if (response) {
          log.debug(response.audioId);
          if (response.audioId) {
            //var statement = component.state.statements[component.state.currentSelectedStatementPosition]
            log.debug('Statement', statement);
            const requestBody = {
              customer_id: statement.customer_id,
              campaign_id: statement.campaign_id,
              statement_id: statement.statement_id,
              audio_id: response.audioId,
              intent_id: statement.intent_id,
              audio_duration: component.state.duration,
              browser_type: getBrowserName(),
            };
            await Api.postSampleDetails(requestBody).then((response: any) => {
              if (response && response.http_status === 200) {
                log.debug('SampleDetails', response);
                component.getNextStatement();
                component.props.getScore();
                // eslint-disable-next-line no-console
                console.timeEnd('Video_uploading_started');
              } else if (response && response.message) {
                component.setState({
                  isLoading: false,
                  statements: [],
                  errorMessage: response.message,
                  customer_data: [],
                });
              } else {
                component.setState({
                  isLoading: false,
                  statements: [],
                  customer_data: [],
                  errorMessage: Constants.generic_message,
                });
              }
            });
          } else if (response.status === 401) {
            log.debug('calling get token api');
            component.getAudioAuthenticationToken();
          }
        } else {
          component.setState({
            isLoading: false,
            statements: [],
            customer_data: [],
            errorMessage: Constants.generic_message,
          });
        }
      });
    }
  }

  getAudioAuthenticationToken() {
    Api.getAudioAuthenticationToken()
      .then(res => {
        log.debug(res);
        if (res && res.access_token) {
          localStorage.setItem(
            Constants.AUDIO_URL_SECRET_KEY,
            res.access_token,
          );
          component.onAccept();
        }
      })
      .catch((error: any) => {
        log.debug(error);
      });
  }

  render() {
    // const { isRecording } = this.state;
    log.debug(this.state);
    const divStyle = {
      padding: 20,
      display: this.state.statements.length === 0 ? 'none' : 'block',
    };
    // const granted = this.state.granted;
    // const rejectedReason = this.state.rejectedReason;
    // const recording = this.state.recording;
    // const paused = this.state.paused;
    // const recorderStyle = {
    //   display:
    //     this.state.mode === Constants.AUDIO_RECORDED ||
    //     this.state.mode === Constants.AUDIO_PLAYING
    //       ? 'none'
    //       : 'block',
    // };

    return (
      <div>
        <div className="SampCalibratBodySec">
          <Card style={{ position: 'relative' }} className="SampCalibratBoxSec">
            {this.state.isLoading && (
              <div
                style={{
                  position: 'absolute',
                  width: '75px',
                  textAlign: 'center',
                  top: '50%',
                  margin: '-20px 0px 0px -37px',
                  left: '50%',
                }}
              >
                <CircleProgressComponent />
              </div>
            )}
            {!this.state.isLoading && this.state.errorMessage.length > 0 && (
              <div
                style={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                  top: '50%',
                  margin: '-20px 0px 0px 20px',
                  left: '0%',
                  color: 'red',
                }}
              >
                {this.state.errorMessage}{' '}
              </div>
            )}
            {!this.state.isLoading && (
              <div style={divStyle}>
                <div
                  style={{
                    color: '#3F3F3F',
                    fontFamily: 'Lato',
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '21px',
                    textAlign: 'center',
                  }}
                >
                  {this.state.statements.length > 0 && (
                    <p>
                      {' '}
                      {this.state.statements[0].is_verbatim === 1
                        ? 'Click the microphone and read the following phrase exactly.'
                        : 'Order the following list as you normally would.'}{' '}
                    </p>
                  )}
                </div>
                <div
                  className="SampCaliBoxTextSec"
                  style={{
                    color: '#000000',
                    fontFamily: 'Lato',
                    fontWeight: 300,
                  }}
                >
                  {this.state.statements.length > 0 && (
                    <p> {this.state.statements[0].statement_text} </p>
                  )}
                </div>
                <div className="clear" />
                {(this.state.mode === Constants.AUDIO_RECORDED ||
                  this.state.mode === Constants.AUDIO_PLAYING) && (
                  <div
                    style={{
                      width: '100%',
                      margin: 'auto',
                      textAlign: 'center',
                    }}
                  >
                    <div className="videoBgSec">
                      <video
                        width="100%"
                        controls={false}
                        ref={this.videoRef}
                        onEnded={this.onEnded}
                      >
                        <source
                          ref={this.sourceRef}
                          src={this.state.url}
                          type="video/webm"
                        />
                      </video>{' '}
                    </div>
                  </div>
                )}
                {/* <ReactMediaRecorderNew
                  onError={this.onPermissionError}
                  onPermissionGranted={this.onPermissionGranted}
                  onStart={this.onStart}
                  onStop={this.onStop}
                  render={({ startRecord, stopRecord, showHideCamera }: any) => {
                    startVideoRecording = startRecord;
                    stopVideoRecording = stopRecord;
                    hideShowCamera = showHideCamera;
                  }}
                /> */}
                <br />
                <br />{' '}
                {this.state.mode === Constants.AUDIO_NORMAL && (
                  <div
                    style={{ display: 'inline-block', position: 'relative' }}
                    className="actionBtnsBox"
                  >
                    <CustomFloatingActionButton
                      icon={MicrophoneOn}
                      backgroundColor="#EC501E"
                      labelColor="#E43918"
                      onClick={
                        this.props.score.is_sample_collection
                          ? this.startRecording
                          : () => 0
                      }
                      label={'Start Recording'}
                    />
                  </div>
                )}
                {this.state.mode === Constants.AUDIO_RECORDING && (
                  <div
                    style={{ display: 'inline-block', position: 'relative' }}
                    className="actionBtnsBox"
                  >
                    <CustomFloatingActionButton
                      icon={MicrophoneOff}
                      backgroundColor="#EC501E"
                      onClick={this.stopRecording}
                      labelColor="#E43918"
                      label={'Stop'}
                    />
                  </div>
                )}
                {(this.state.mode === Constants.AUDIO_RECORDED ||
                  this.state.mode === Constants.AUDIO_PLAYING) && (
                  <div
                    style={{ display: 'inline-block', position: 'relative' }}
                    className="actionBtnsBox"
                  >
                    <CustomFloatingActionButton
                      backgroundColor="#EC501E"
                      labelColor="#E43918"
                      icon={MicrophoneOn}
                      onClick={this.startRecording}
                      label={'Record Again'}
                    />
                  </div>
                )}
                {this.state.mode === Constants.AUDIO_RECORDED && (
                  <div
                    style={{ display: 'inline-block', position: 'relative' }}
                    className="actionBtnsBox"
                  >
                    <CustomFloatingActionButton
                      onClick={this.startPlaying}
                      icon={PlayImage}
                      // label={'Listen'}
                    />
                  </div>
                )}
                {this.state.mode === Constants.AUDIO_PLAYING && (
                  <div
                    style={{ display: 'inline-block', position: 'relative' }}
                    className="actionBtnsBox"
                  >
                    <CustomFloatingActionButton
                      onClick={this.stopListening}
                      icon={PauseImage}
                      // label={'Pause'}
                    />
                  </div>
                )}
                {(this.state.mode === Constants.AUDIO_RECORDED ||
                  this.state.mode === Constants.AUDIO_PLAYING) && (
                  <div
                    style={{ display: 'inline-block', position: 'relative' }}
                    className="actionBtnsBox"
                  >
                    <CustomFloatingActionButton
                      icon={DoneImage}
                      onClick={this.onAccept}
                      label={'Accept'}
                    />
                  </div>
                )}
                <br />
              </div>
            )}
          </Card>
          {this.props.score && !this.props.score.is_sample_collection && (
            <ShowAcceptTutorialComponent
              updateFlag={this.props.updateTutorialFlag}
            />
          )}
          <ScoreComponent path={this.props.history.location.pathname} />
          <div className="clear" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return { score: state.score, testStatus: state.testStatus };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    getScore: () => {
      dispatch(getScore());
    },
    updateTutorialFlag: (tutorialFlag: boolean) => {
      dispatch(updateTutorialFlag(tutorialFlag));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioRecorderComponentNew);
