/*
 A component to handle the other options pop-up with the predefined options in the pop-up and handle the clicks.
 This pop-up will appear when selected "Other" from dropdown.
*/
import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { confirmable } from 'react-confirm';
// import CloseIconGray from '../../theme/images/ic_close_gray.png';
// import CloseIconWhite from '../../theme/images/ic_close_white.png';
import Theme from '../../Theme';
import './Dialogs.css';
import log from '../../utils/logger';

type ScoreInfoDialogComponentProps = {
  confirmation: any;
  cancel: (...args: any[]) => any;
  dismiss: (...args: any[]) => any;
  proceed: (...args: any[]) => any;
  show: false & ((...args: any[]) => any);
};

type ScoreInfoDialogComponentState = {
  show: boolean;
  defaultOptions: any[];
  darkMode: string | null;
};

class ScoreInfoDialogComponent extends React.Component<
  ScoreInfoDialogComponentProps,
  ScoreInfoDialogComponentState
> {
  constructor(props: ScoreInfoDialogComponentProps) {
    super(props);
    this.state = {
      show: true,
      defaultOptions: [],
      darkMode: localStorage.getItem('DARK_MODE'),
    };
  }

  /*
  closes pop-up when "X" is clicked
  */
  handleClose = () => {
    this.setState({ show: false });
    this.props.cancel();
  };

  /*
    Submit button method which passes the selected options to the parent transcription class.
  */
  handleData = () => {
    this.setState({ show: false });
    this.props.proceed();
  };

  /*
  Check box methods which removes or adds the selected option into array
  */
  handleCheck = (event: any) => {
    const target = event.target;
    const selectedName = target.id;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const checked = this.state.defaultOptions.slice();
    if (value === true) {
      checked.push(selectedName);
      this.state.defaultOptions.push(selectedName);
    } else {
      const index = checked.indexOf(selectedName);
      checked.splice(index, 1);
      this.state.defaultOptions.splice(index, 1);
    }
  };

  render() {
    const { confirmation } = this.props;
    const customContentStyle = {
      width: '450px',
      // maxWidth: 'none',
      maxWidth: '100%',
    };
    log.debug('Props are', this.props);
    const actions = [
      <FlatButton
        key={0}
        className="OK"
        label={
          this.props.confirmation.isOnlyAlert
            ? 'OK'
            : 'Set Up PayPal to Get Paid'
        }
        onClick={this.handleData}
      />,
    ];
    return (
      <Theme>
        <Dialog
          className="submitDialog"
          actions={actions}
          modal={false}
          open={this.state.show}
          bodyStyle={{ color: 'black' }}
          style={{ color: 'black' }}
          onRequestClose={this.handleClose}
          contentStyle={customContentStyle}
          autoScrollBodyContent={true}
          actionsContainerStyle={{
            textAlign: 'center',
            padding: '10px 0px 40px 0px',
          }}
          paperClassName="dialogClass"
        >
          <div style={{ color: '#4A4A4A', textAlign: 'center' }}>
            <div className="popCloseBtn">
              {/* <img
                src={
                  this.state.darkMode === 'true'
                    ? CloseIconWhite
                    : CloseIconGray
                }
                onClick={this.handleClose}
              /> */}
            </div>
            <div
              style={{ padding: '50px 0px', fontSize: '20px' }}
              className="innerTextSec"
            >
              {confirmation.message}
            </div>
          </div>
        </Dialog>
      </Theme>
    );
  }
}

export default confirmable(ScoreInfoDialogComponent);
