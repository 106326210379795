/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/naming-convention */
import darkBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import merge from 'lodash.merge';
import Colors from './Colors';
import React, { ReactNode } from 'react';
import { cyan500, grey300, white, fullBlack } from 'material-ui/styles/colors';

const muiTheme = {
  fontFamily: 'Lato, sans-serif',
  typography: {
    fontFamily: ['Lato, sans-serif'].join(','),
  },
  palette: {
    primary1Color: Colors.ThemeColor,
    textColor: '#4A4A4A',
    canvasColor: white,
    borderColor: grey300,
    pickerHeaderColor: cyan500,
    secondaryColor: Colors.ThemeColor,
    secondaryTextColor: Colors.ThemeColor,
    // clockCircleColor: "fade(darkBlack, 0.07)",
    shadowColor: fullBlack,
  },
  textField: {
    textColor: '#4A4A4A',
  },
  raisedButton: {
    buttonTextTransform: 'none',
    color: Colors.ThemeColor,
    textColor: '#ffffff',
    hoverColor: Colors.ThemeDarkColor,
    buttonFilterColor: 'red',
  },
  button: {
    // hoverColor: "green"
  },
  checkbox: {
    boxColor: 'lightGray',
    checkedColor: '#2AB55A',
  },
  datePicker: {},
  flatButton: {
    // buttonTextTransform: 'none',
    // buttonFilterColor: Colors.ThemeDarkColor,
    // color: Colors.ThemeColor,
    // textColor: '#ffffff',
    hoverColor: Colors.ThemeDarkColor,
    color: Colors.ThemeColor,
    buttonFilterColor: '#40718',
    disabledTextColor: 'green',
    textColor: '#ffffff',
    primaryTextColor: 'blue',
    secondaryTextColor: 'black',
  },
  dropDownMenu: {
    accentColor: Colors.ThemeColor,
    selectedItemTextColor: Colors.ThemeColor,
    primary1Color: Colors.ThemeColor,
    containerBackgroundColor: Colors.ThemeColor,
  },
};

const themes = {
  dark: {
    palette: {
      primary1Color: 'black',
      textColor: '#ffffff',
    },
  },
  light: {
    palette: {
      primary1Color: '#ffffff',
      textColor: 'black',
    },
  },
};

const initialState = {
  dark: false,
  theme: themes.light,
  toggle: () => 0,
};

const ThemeContext = React.createContext(initialState);

const useTheme = () => React.useContext(ThemeContext);

const ThemeProvider: React.FC = ({ children }: { children?: ReactNode }) => {
  const [dark, setDark] = React.useState(false); // Default theme is light

  // On mount, read the preferred theme from the persistence
  React.useEffect(() => {
    const isDark = localStorage.getItem('dark') === 'true';
    setDark(isDark);
  }, [dark]);

  // To toggle between dark and light modes
  function toggle() {
    const isDark = !dark;
    localStorage.setItem('dark', JSON.stringify(isDark));
    setDark(isDark);
    return isDark ? 1 : 0;
  }

  // const themeUpdate = dark ? themes.dark : themes.light;
  return (
    <ThemeContext.Provider value={{ theme, dark, toggle }}>
      {children}
    </ThemeContext.Provider>
  );
};

const theme = merge(darkBaseTheme, muiTheme);
// export default theme;

export { theme, ThemeProvider, useTheme };
