import React from 'react';
import WaveSurfer from 'wavesurfer.js';
import './WaveformNew.css';
import log from '../../utils/logger';

let component: any;

type WaveformNewProps = {
  src?: string;
  render: (...args: any[]) => any;
  onReady?: (...args: any[]) => any;
  finish: (...args: any[]) => any;
};

export default class WaveformNew extends React.Component<WaveformNewProps> {
  waveForm: any;
  waveSurfer: any;
  constructor(props: WaveformNewProps) {
    super(props);
    this.state = {};
    component = this;
    this.waveForm = React.createRef();
  }

  componentDidMount() {
    this.waveSurfer = WaveSurfer.create({
      container: this.waveForm.current,
      backend: 'MediaElement',
      waveColor: '#aed8e8',
      progressColor: '#488FAB',
      barHeight: 2,
      responsive: true,
      barWidth: 1,
      cursorColor: '#488fab',
      height: 54,
    });
    log.debug('waveform', WaveSurfer, this.waveSurfer);
    if (this.props.src) {
      this.waveSurfer.load(this.props.src);
    }
    this.waveSurfer.on('ready', () => {
      component.props.onReady();
      //component.wavesurfer.play();
    });
    this.waveSurfer.on('finish', () => {
      //component.wavesurfer.play();
    });
    this.waveSurfer.on('finish', () => {
      component.props.finish();
    });
  }

  // componentWillUnmount() {}

  play() {
    if (component.waveSurfer) {
      component.waveSurfer.play();
    }
  }

  pause() {
    if (component.waveSurfer) {
      component.waveSurfer.pause();
    }
  }

  slowerPlayBackRate(speed: any) {
    log.debug('wavesurfer', component.waveSurfer);
    log.debug('wavesurfer', speed);
    component.waveSurfer.setPlaybackRate(speed);
  }

  render() {
    return (
      <div className="waveform">
        <div className="wave" ref={this.waveForm} />
        {this.props.render({
          play: this.play,
          pause: this.pause,
          slowerPlayBackRate: this.slowerPlayBackRate,
        })}
        <div className="waveLine" />
      </div>
    );
  }
}
