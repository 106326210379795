/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import Constants from '../utils/Constants';
import log from '../utils/logger';

interface NotAuthenticationRouteProps extends RouteProps {
  // location: any,
  // context?: (...args: any[]) => any,
  // refs?: (...args: any[]) => any,
  // updater?: (...args: any[]) => any,
  path?: any;
  component?: any;
  exact?: any;
  history?: any;
}

// eslint-disable-next-line func-style
const NotAuthenticationRoute: React.FC<NotAuthenticationRouteProps> = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (props.location?.pathname !== undefined) {
        // eslint-disable-next-line no-undef
        gtag('config', Constants.GoogleTrackingID, {
          page_title: 'Tag That Data',
          page_path: props.location?.pathname,
        });
        log.debug(props.location?.pathname);
      }
      return <Component {...props} />;
    }}
  />
);

export default NotAuthenticationRoute;
