/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch } from 'redux';
import Api from '../api/Api';
import log from '../utils/logger';

/** redux action types */
export const UPDATE_SCORE = 'UPDATE_SCORE';
export const UPDATE_TEST_STATUS = 'UPDATE_TEST_STATUS';
export const GET_TEST_STATUS = 'GET_TEST_STATUS';
export const UPDATE_MASK_STATUS = 'UPDATE_MASK_STATUS';
export const GET_MASK_STATUS = 'GET_MASK_STATUS';
export const PAUSE_AUDIO = 'PAUSE_AUDIO';
export const DARK_THEME = 'DARK_THEME';
export const LOG_OUT = 'LOG_OUT';

// redux types and interfaces
export interface Actions {
  UPDATE_SCORE: string;
  UPDATE_TEST_STATUS: string;
  GET_TEST_STATUS: string;
  UPDATE_MASK_STATUS: string;
  GET_MASK_STATUS: string;
  PAUSE_AUDIO: string;
  DARK_THEME: string;
  LOG_OUT: string;
}
export interface UpdateScore {
  type: Actions['UPDATE_SCORE'];
  score: any;
}
export interface UpdateTestStatus {
  type: Actions['UPDATE_TEST_STATUS'];
  status: boolean;
}
export interface GetTestStatus {
  type: Actions['GET_TEST_STATUS'];
}
export interface UpdateMaskStatus {
  type: Actions['UPDATE_MASK_STATUS'];
  status: boolean;
}
export interface GetMaskStatus {
  type: Actions['GET_MASK_STATUS'];
}
export interface ToggleSwitcher {
  type: Actions['DARK_THEME'];
  darkTheme: any;
}
export interface PauseAudio {
  type: Actions['PAUSE_AUDIO'];
  paused: boolean;
}
export interface LogOut {
  type: Actions['LOG_OUT'];
  paused: boolean;
}

export function toggleSwitcher(darkTheme: any) {
  return {
    type: 'DARK_THEME',
    darkTheme,
  };
}

export function updateScore(score: any) {
  return {
    type: 'UPDATE_SCORE',
    score,
  };
}

export function updateTestStatus(status: boolean) {
  log.debug('ACTION', status);
  return {
    type: 'UPDATE_TEST_STATUS',
    status,
  };
}

export function getTestStatus() {
  return {
    type: 'GET_TEST_STATUS',
  };
}

export function updateMaskStatus(status: boolean) {
  return {
    type: 'UPDATE_MASK_STATUS',
    status,
  };
}

export function getMaskStatus() {
  return {
    type: 'GET_MASK_STATUS',
  };
}

export function pauseAudio(paused: boolean) {
  return {
    type: 'PAUSE_AUDIO',
    paused,
  };
}

export function logout() {
  return {
    type: 'LOG_OUT',
  };
}

export function getScore() {
  return (dispatch: Dispatch<any>) => {
    log.debug('get score');
    Api.getGTScore()
      .then((response: any) => {
        log.debug('data', response);
        if (response && response.http_status && response.http_status === 200) {
          log.debug('updated score');
          dispatch(updateScore(response));
          //dispatch(updateTestStatus(true))
        }
      })
      .catch((error: any) => {
        log.debug('loaded freights error', error);
        throw error;
      });
  };
}

export function updateTutorialFlag(tutorialFlag: any) {
  return (dispatch: Dispatch<any>) => {
    log.debug('get score');
    Api.updateUserTutorialFlag(tutorialFlag)
      .then((response: any) => {
        log.debug('Tutorial update Res', response);
        if (
          response !== undefined &&
          response.http_status !== undefined &&
          response.http_status === 200
        ) {
          log.debug('Tutorial updated');
          dispatch(getScore());
        }
      })
      .catch((error: any) => {
        log.debug('Tutorial updated error', error);
        throw error;
      });
  };
}
