import { AnyAction } from 'redux';
import { PauseAudio, PAUSE_AUDIO } from '../actions';
import log from '../utils/logger';

export interface PauseAudioState {
  paused: boolean;
}

const initialState: PauseAudioState = {
  paused: false,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function PauseAudio(state = initialState, action: PauseAudio | AnyAction) {
  if (action.type === PAUSE_AUDIO) {
    log.debug('PAUSE_AUDIO', { paused: action.paused });
    return Object.assign({}, { paused: action.paused });
  }
  return state;
}

export default PauseAudio;
