import { createLogger } from '@valyant/logger';
import packageJson from '../../package.json';
import Constants from './Constants';
import LoggerOptions from '@valyant/logger';

export default createLogger({
  appName: 'tag-that-data',
  appEnv: Constants.endPoint?.includes('prod')
    ? 'prod'
    : Constants.endPoint?.includes('dev')
    ? 'dev'
    : 'stage',
  level: Constants.logLevel as LoggerOptions.Level,
  base: {
    appVersion: packageJson.version,
    instanceId: Constants.LOGGED_USER,
  },
});
