import React, { Component } from 'react';
import { connect } from 'react-redux';
import Constants from '../utils/Constants';
import log from '../utils/logger';
// import { createHashHistory } from "history";
import {
  disqualifiedWarningAlertComponentConfirmDialogComponent,
  disqualifiedUserAlertComponentConfirmDialogComponent,
} from '../utils/DialogsUtils';
import { scoreInfoAlertDialogComponent } from '../utils/DialogsUtils';
import Apis from '../api/Api';

type ScoreComponentProps = {
  history?: any;
  path?: string;
  score?: any;
};

type ScoreComponentState = {
  rulesAck: string | null;
};

// const history = createHashHistory();
class ScoreComponent extends Component<
  ScoreComponentProps,
  ScoreComponentState
> {
  constructor(props: ScoreComponentProps) {
    super(props);
    this.state = {
      rulesAck: localStorage.getItem('RULES_ACK'),
    };
    this.onClickOpenTranscribeFAQs = this.onClickOpenTranscribeFAQs.bind(this);
  }

  componentDidMount() {
    //this.updateAppBarTitle(window.location.pathname);
    log.debug('Entered in is Disqualified', this);
    // if(this.props.score&&this.props.score.is_disqualified){
    //     log.debug("Entered in is Disqualified")
    //    this. onSetPaypalIdButtonClick()
    // }
    if (this.props.history === undefined) {
      this.props.history.goBack();
    }
  }

  onSetPaypalIdButtonClick() {
    const data = { isOnlyAlert: false, message: Constants.paypalAlert };
    scoreInfoAlertDialogComponent(data).then(
      () => {
        this.props.history.push('/dashboard/profile');
      },
      () => 0,
    );
  }

  onAlertButtonClick() {
    const data = {
      isOnlyAlert: true,
      message: Constants.alertForInvalidSamples,
    };
    scoreInfoAlertDialogComponent(data).then(
      () => {
        // history
        //     .push('/dashboard/profile')
      },
      () => 0,
    );
  }

  displayDisqualifiedWarningAlert() {
    const data = {
      isOnlyAlert: true,
      message: Constants.warningForDisqualifiedUser,
    };
    disqualifiedWarningAlertComponentConfirmDialogComponent(data).then(
      () => {
        localStorage.setItem(Constants.IS_DISQUALIFIED_WARN_SHOWN, 'true');
      },
      () => 0,
    );
  }

  displayDisqualiedUserAlert() {
    const data = {
      isOnlyAlert: true,
      message: Constants.infoForDisqualifiedUser,
    };
    disqualifiedUserAlertComponentConfirmDialogComponent(data).then(
      async () => {
        await Apis.logout();
        this.props.history.push('/login');
      },
      () => 0,
    );
  }

  onClickOpenTranscribeFAQs(faq: string) {
    log.debug('history', this.props, faq);
    this.setState({ rulesAck: 'true' });
    localStorage.setItem('RULES_ACK', 'true');
    if (this.props.path === '/dashboard/tagcharacteristics') {
      this.props.history.push({
        title: faq,
        pathname: '/dashboard/faqs/characteristicsfaqs',
      });
    } else {
      this.props.history.push({
        title: faq,
        pathname: '/dashboard/faqs/transcribefaqs',
      });
    }
  }

  render() {
    // if (this.props.score) {
    //     log.debug("score",this.props.score)
    //     if (this.props.score.is_disqualified === 'true') {
    //         this.displayDisqualiedUserAlert()
    //     } else if (this.props.score.is_notification === 'true') {
    //         if (localStorage.getItem(Constants.IS_DISQUALIFIED_WARN_SHOWN)=='false')
    //             this.displayDisqualifiedWarningAlert()
    //     }else{
    //         localStorage.setItem(Constants.IS_DISQUALIFIED_WARN_SHOWN, false);
    //     }
    // }
    let transAmt = this.props.score?.transcription_amt
      ? parseFloat(this.props.score.transcription_amt)
      : 0;
    const charAmt = this.props.score?.characteristic_amt
      ? parseFloat(this.props.score.characteristic_amt)
      : 0;
    const scoreAmt = (transAmt += charAmt);
    return (
      <React.Fragment>
        <div>
          {' '}
          {this.props.score && (
            <div className="SampCalibRightBoxSec">
              <div className="cardHeadSec">
                Current Earnings:{' '}
                {this.props.score.transcription_amt === undefined ||
                this.props.score.characteristic_amt === undefined
                  ? ''
                  : `$ ${scoreAmt.toFixed(2)}`}
                <div className="iconSec" onClick={this.onAlertButtonClick}>
                  <svg
                    className="icon"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                  </svg>
                </div>
              </div>
              {this.props.score.contribution_amt +
                this.props.score.transcription_amt >=
                5 &&
                (this.props.score.paypal_id === null ||
                  this.props.score.paypal_id.length === 0) && (
                  <div
                    onClick={this.onSetPaypalIdButtonClick}
                    className="cardSecondHeadSec"
                  >
                    Please set up Paypal to get paid
                    <div className="iconSec">
                      <svg
                        className="icon"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
                        <path d="M0-.25h24v24H0z" fill="none" />
                      </svg>
                    </div>
                  </div>
                )}

              <table className="scoreWrapper">
                <tbody>
                  <tr>
                    <th scope="row">Transcripts</th>
                    <td>{'$ ' + this.props.score.transcription_amt}</td>
                  </tr>
                  <tr style={{ borderRadius: '4px' }}>
                    <th scope="row">Characteristics</th>
                    <td>{'$ ' + this.props.score.characteristic_amt}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div className="SampCalibRightFAQSec">
            <p
              className={
                this.state.rulesAck === 'true'
                  ? 'audioFaqTitle'
                  : 'audioFaqTitleRed'
              }
            >
              Please read the rules before transcribing:
            </p>
            <div
              onClick={() =>
                this.onClickOpenTranscribeFAQs(
                  this.props.path === '/dashboard/tagcharacteristics'
                    ? Constants.CHARACTERISTICS_FAQ
                    : Constants.TRANSCRIBE_FAQ,
                )
              }
              className="audioFAQ"
            >
              <a>
                {this.props.path === '/dashboard/tagcharacteristics'
                  ? Constants.CHARACTERISTICS_FAQ
                  : Constants.TRANSCRIBE_FAQ}
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    score: state.score,
    testStatus: state.testStatus,
  };
}

export default connect(mapStateToProps)(ScoreComponent);
