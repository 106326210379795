import React, { Component } from 'react';
import CircleProgressComponent from '../CircleProgressComponent';
import Api from '../../api/Api';
import { Card } from 'material-ui/Card';
import log from '../../utils/logger';

let component: any;

type VerifyUserComponentProps = {
  match?: any;
  history?: any;
};

type VerifyUserComponentState = ((e: any) => void) & {
  isLoading: boolean;
  errorText: string;
};

class VerifyUserComponent extends Component<
  VerifyUserComponentProps,
  VerifyUserComponentState
> {
  state = {
    isLoading: true,
    errorText: '',
  };
  constructor(props: VerifyUserComponentProps) {
    super(props);
    component = this;
  }

  componentDidMount() {
    //var path= component.props.locaiton.query;
    const token = this.props.match.params.token;
    log.debug('Token', token);
    Api.verifyUser(token)
      .then((response: any) => {
        if (response && response.http_status) {
          if (response.http_status === 200) {
            component.props.history.push({
              pathname: '/login',
            });
          }
          //   } else if (response.http_status === 202) {
          //     // component.setState({isLoading:false,errorText:response.message})
          //     component.props.history.push({
          //       pathname: '/login',
          //     });
          // }
        } else {
          // component.setState({isLoading:false,errorText:Constants.generic_message})
          component.props.history.push({
            pathname: '/login',
          });
        }
      })
      .catch(e => {
        log.debug(e);
      });
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Card className="verifyPageCard">
          <div
            style={{
              position: 'absolute',
              width: '75px',
              textAlign: 'center',
              top: '50%',
              margin: '-20px 0px 0px -37px',
              left: '50%',
            }}
          >
            {' '}
            {this.state.isLoading ? <CircleProgressComponent /> : null}{' '}
          </div>
        </Card>
      </div>
    );
  }
}

export default VerifyUserComponent;
