/* eslint-disable func-style */
import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import Colors from '../theme/Colors';
type CircleProgressComponentProps = {
  size?: number;
  color?: string;
  thickness?: number;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const CircleProgressComponent: React.FC<CircleProgressComponentProps> = (
  props: CircleProgressComponentProps,
) => {
  return (
    <CircularProgress
      // className="circularProgressSec"
      size={props.size ? props.size : 40}
      color={props.color ? props.color : Colors.ThemeColor}
      thickness={props.thickness ? props.thickness : 4}
    />
  );
};

export default CircleProgressComponent;
