import { createConfirmation } from 'react-confirm';
import ScoreInfoDialogComponent from '../components/dialogs/ScoreInfoDialogComponent';
import MenuDetailsDialogComponent from '../components/dialogs/MenuDetailsDialogComponent';
import SingleChoiceAlertComponent from '../components/dialogs/SingleChoiceAlertComponent';
import AudioTranscribeOtherOptions from '../components/dialogs/AudioTranscribeOtherOptions';
import DisqualifiedWarningAlertComponent from '../components/dialogs/DisqualifiedWarningAlertComponent';
import DisqualifiedUserAlertComponent from '../components/dialogs/DisqualifiedUserAlertComponent';
import SingleChoiceWithoutAutoCloseAlertComponent from '../components/dialogs/SingleChoiceWithoutAutoCloseAlertComponent';
import BrowserCompatibilityAlertComponent from '../components/dialogs/BrowserCompatibilityAlertComponent';
import AudioQualityInfoAlertComponent from '../components/dialogs/AudioQualityInfoAlertComponent';

const scoreInfoDialogComponentConfirm = createConfirmation(
  ScoreInfoDialogComponent,
);
const menuDetailsDialogComponentConfirm = createConfirmation(
  MenuDetailsDialogComponent,
);
const singleChoiceAlertComponentConfirm = createConfirmation(
  SingleChoiceAlertComponent,
);
const audioTranscribeOtherOptionsConfirm = createConfirmation(
  AudioTranscribeOtherOptions,
);
const disqualifiedWarningAlertComponentConfirm = createConfirmation(
  DisqualifiedWarningAlertComponent,
);
const disqualifiedUserAlertComponentConfirm = createConfirmation(
  DisqualifiedUserAlertComponent,
);
const singleChoiceWithoutAutoCloseAlertComponentConfirm = createConfirmation(
  SingleChoiceWithoutAutoCloseAlertComponent,
);
const browserCompatibilityAlertComponentConfirm = createConfirmation(
  BrowserCompatibilityAlertComponent,
);
const audioQualityInfoAlertComponentConfirm = createConfirmation(
  AudioQualityInfoAlertComponent,
);

export function scoreInfoAlertDialogComponent(confirmation: any, options = {}) {
  return scoreInfoDialogComponentConfirm({ confirmation, ...options });
}
export function menuDetailsDialog(confirmation: any, options = {}) {
  return menuDetailsDialogComponentConfirm({ confirmation, ...options });
}
export function singleChoiceAlertDialogComponent(
  confirmation: any,
  options = {},
) {
  return singleChoiceAlertComponentConfirm({ confirmation, ...options });
}
export function audioTranscribeOtherOptionsDialogComponent(
  confirmation: any,
  options = {},
) {
  return audioTranscribeOtherOptionsConfirm({ confirmation, ...options });
}
export function disqualifiedWarningAlertComponentConfirmDialogComponent(
  confirmation: any,
  options = {},
) {
  return disqualifiedWarningAlertComponentConfirm({ confirmation, ...options });
}
export function disqualifiedUserAlertComponentConfirmDialogComponent(
  confirmation: any,
  options = {},
) {
  return disqualifiedUserAlertComponentConfirm({ confirmation, ...options });
}
export function singleChoiceWithoutAutoCloseAlertDialogComponent(
  confirmation: any,
  options = {},
) {
  return singleChoiceWithoutAutoCloseAlertComponentConfirm({
    confirmation,
    ...options,
  });
}
export function browserCompatabilityAlertConfirmDialogComponent(
  confirmation: any,
  options = {},
) {
  return browserCompatibilityAlertComponentConfirm({
    confirmation,
    ...options,
  });
}
export function audioQualityInfoAlertComponentConfirmDialogComponent(
  confirmation: any,
  options = {},
) {
  return audioQualityInfoAlertComponentConfirm({ confirmation, ...options });
}
