/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable func-style */
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import CreateIcon from '@material-ui/icons/Create';
import React, { useEffect, useState } from 'react';
import './EditProfileAvatarStyles.css';
import { DialogActions } from '@material-ui/core';
import Api from '../../../api/Api';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CircularProgressComponent from '../../../utils/CircularProgressComponent';
// import { CircularProgress } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Constants from '../../../utils/Constants';
import log from '../../../utils/logger';
import AWS from 'aws-sdk';
const S3_URL = 'https://tagthatdata-avatar-icons.s3.amazonaws.com/';

type SimpleDialogProps = {
  userResponse?: any;
  selectedValue: number;
  onClose: (...args: any[]) => any;
  open: boolean;
};

type EditProfileAvatarPopUpProps = {
  userResponse: any;
};

const SimpleDialog: React.FC<SimpleDialogProps> = (
  props: SimpleDialogProps,
) => {
  const { onClose, selectedValue, open } = props;
  const [state, setState] = React.useState(() => ({
    avatarArray: [],
    selectedAvatar: null,
    selectedKey: '',
    errorMessage: '',
    onLoading: false,
    avatarURL: '',
    userId: '',
  }));

  useEffect(() => {
    getAvatars();
  }, []);

  function getAvatars() {
    const bucketName = 'tagthatdata-avatar-icons';
    AWS.config.region = 'us-west-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:1deb759b-3add-4107-9740-9b7e5303c164',
    });
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName, Delimiter: '/', Prefix: 'avatars/' },
    });
    const params = {
      Bucket: bucketName,
      Delimiter: '/',
      Prefix: 'avatars/',
    };
    s3.listObjects(params, (error: any, data: any) => {
      log.debug('avatarValues', data);
      if (error) {
        log.debug(error);
      } else {
        const newAvatarArray = data.Contents.shift();
        log.debug('newArray', newAvatarArray, data.Contents);
        setState(previousState => ({
          ...previousState,
          avatarArray: data.Contents,
        }));
        // return (state.avatarArray = data.Contents);
      }
    });
  }

  const updatedUserWithAvatar = () => {
    log.debug('entered');
    Api.getUserProfile()
      .then((response: any) => {
        if (response && response.http_status) {
          // setState(previousState => ({ ...previousState, isLoading: true }));
          if (response.http_status === 200) {
            setState(previousState => ({
              ...previousState,
              userId: response.data.id,
              selectedAvatar: response.data.avatar_url,
            }));
          } else if (
            response.http_status === 202 ||
            response.http_status === 203
          ) {
            setState(previousState => ({
              ...previousState,
              // isLoading: false,
              error: response.message,
            }));
          }
        } else {
          setState(previousState => ({
            ...previousState,
            // isLoading: false,
            error: Constants.generic_message,
          }));
        }
        props.userResponse.avatar_url = state.selectedAvatar;
        onClose(state.selectedAvatar);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((error: any) => {
        log.debug('avatar error');
      });
  };

  const handleClose = () => {
    // onClose();
    onClose(selectedValue);
    setState(previousState => ({
      ...previousState,
      errorMessage: '',
      onLoading: false,
    }));
  };

  const handleListItemClick = (value: any, index: number) => {
    setState((previousState: any) => ({
      ...previousState,
      selectedAvatar: value,
      selectedKey: index,
    }));
  };

  const onSave = () => {
    log.debug(
      'MyselectedAvatar',
      state.selectedAvatar !== undefined,
      state.selectedAvatar !== null,
    );
    if (state.selectedAvatar) {
      const requestBody = {
        user_id: props.userResponse.id,
        avatar_url: state.selectedAvatar,
      };
      setState(previousState => ({
        ...previousState,
        onLoading: true,
        errorMessage: '',
      }));
      log.debug('requestBody', requestBody);
      Api.updateAvatarUserProfile(requestBody)
        .then((data: any) => {
          if (data && data.http_status) {
            if (data.http_status === 200) {
              setState(previousState => ({
                ...previousState,
                onLoading: false,
              }));
              updatedUserWithAvatar();
            } else if (data.http_status === 203) {
              setState(previousState => ({
                ...previousState,
                onLoading: false,
                errorMessage: data.message,
              }));
            } else {
              log.debug('status 202');
              setState(previousState => ({
                ...previousState,
                onLoading: false,
                errorMessage: data.message,
              }));
            }
          } else {
            log.debug('status 204');
            setState(previousState => ({
              ...previousState,
              errorMessage: 'status 204',
              onLoading: false,
            }));
          }
        })
        .catch(reason => {
          log.debug('Exception', reason);
          setState(previousState => ({
            ...previousState,
            errorMessage: reason,
            onLoading: false,
          }));
        });
    } else {
      log.debug('error');
      setState(previousState => ({
        ...previousState,
        errorMessage: 'Please Select your avatar profile',
      }));
    }
  };

  const preventDefault = (event: any) => event.preventDefault();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogActions className="avatarSaveWrapper">
        <DialogTitle id="simple-dialog-title" className="avatarTitle">
          Select your avatar
        </DialogTitle>

        <IconButton
          className="avatarSave"
          color="primary"
          aria-label="close"
          onClick={handleClose}
          // onClick={() => onClose()}
          component="span"
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <FormHelperText error={true} className="avatarError">
        {` ${state.errorMessage} `}
      </FormHelperText>

      <List className="listAvatarWrapper">
        {state.avatarArray.map((avatar: any, index: number) => {
          return (
            <ListItem
              onClick={() => handleListItemClick(S3_URL + avatar.Key, index)}
              // disableTouchRipple={false}
              key={index}
              className="listAvatar"
            >
              <Link href="#" onClick={preventDefault} className="myLink">
                <img src={S3_URL + avatar.Key} alt="avatar" />
              </Link>
            </ListItem>
          );
        })}
      </List>

      <DialogActions className="avatarSaveWrapper">
        <DialogTitle id="simple-dialog-title" className="avatarTitle" />

        <Button
          className="avatarSave"
          color="primary"
          onClick={onSave}
          variant="contained"
        >
          {state.onLoading ? '' : 'Save'}
          {state.onLoading && (
            <CircularProgressComponent
              color="inherit"
              thickness={2}
              size={24}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditProfileAvatarPopUp: React.FC<EditProfileAvatarPopUpProps> = (
  props: EditProfileAvatarPopUpProps,
) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    props.userResponse.avatar_url,
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: any) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const userAvatarLetter = props.userResponse?.name.charAt(0);
  log.debug('userId', props, userAvatarLetter);

  return (
    <div>
      <div className="profileAvatarWrapper">
        {props.userResponse?.avatar_url !== undefined &&
        props.userResponse?.avatar_url !== null ? (
          <div
            className="avatar"
            style={{ backgroundImage: `url(${selectedValue})` }}
          >
            {' '}
          </div>
        ) : (
          <div className="avatar">
            <div className="avatarName"> {userAvatarLetter} </div>
          </div>
        )}

        <CreateIcon onClick={handleClickOpen} className="profileEditIcon" />
      </div>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        // props={props}
        // userId={props.userResponse?.id}
        userResponse={props.userResponse}
        selectedValue={selectedValue}
        // updateAvatarUserProfile={props.updateAvatarUserProfile}
      />
    </div>
  );
};

export default EditProfileAvatarPopUp;
