import React, { Component } from 'react';
import $ from 'jquery';
import './ForgotPasswordComponent.css';
import Constants from '../../utils/Constants';
import Api from '../../api/Api';
import loginImg from '../../theme/images/login_img.png';
import CustomTextField from '../CustomTextField';
import CircleProgressComponent from '../CircleProgressComponent';
import logoNavbar from '../../theme/images/logo_navbar.png';
import { singleChoiceWithoutAutoCloseAlertDialogComponent } from '../../utils/DialogsUtils';
import log from '../../utils/logger';
import { isLoggedIn, validateEmail } from '../../utils/AppUtils';

let component: any;
let enterKeyHit = false;

type ForgotPasswordComponentProps = {
  history: any;
};

type ForgotPasswordComponentState = {
  loginDisabled: boolean;
  emailError: string;
  redirectToDashboard: boolean;
  open: boolean;
  loading: boolean;
  errorMessage: string;
  loginCardTop: string;
  showDashboard: boolean;
};

class ForgotPasswordComponent extends Component<
  ForgotPasswordComponentProps,
  ForgotPasswordComponentState
> {
  constructor(props: ForgotPasswordComponentProps) {
    super(props);
    component = this;
    this.state = {
      loginCardTop: '578px',
      open: false,
      showDashboard: false,
      loading: false,
      emailError: '',
      loginDisabled: true,
      redirectToDashboard: false,
      errorMessage: '',
    };
    this.onLogin = this.onLogin.bind(this);
    this.gotoSignUp = this.gotoSignUp.bind(this);
  }

  componentDidMount() {
    if (isLoggedIn()) {
      this.props.history.push('/dashboard');
    } else {
      // this.props.logout()
    }
  }

  onChange(event: any) {
    let isEmailValid = true;
    const id = event.target.id;
    const email = $('#email').val()?.toLocaleString();
    // log.debug("Login id" + event.target.id + " value" + event.target.value)

    if (id === 'email') {
      isEmailValid = false;
      if (email && email.length > 0 && validateEmail(email)) {
        isEmailValid = true;
      }
    }

    const mailError = isEmailValid ? '' : Constants.enter_valid_email;

    if (isEmailValid) {
      component.setState({ loginDisabled: false, emailError: mailError });
    } else {
      component.setState({ loginDisabled: true, emailError: mailError });
    }
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleKeyEvent(event: any) {
    // log.debug("EVENT", event)
    if (event.key === 'Enter' && !enterKeyHit) {
      component.onLogin(event);
      enterKeyHit = true;
    }
  }

  async onLogin(event: any) {
    event.preventDefault();
    const email = $('#email').val()?.toLocaleString();
    if (email) {
      if (email.length === 0 || !validateEmail(email)) {
        if (email.length === 0 || !validateEmail(email)) {
          this.setState({ emailError: Constants.enter_valid_email });
        }
      } else {
        this.setState({ loading: true });
        await Api.forgotPassword(email)
          .then((data: any) => {
            if (data && data.http_status) {
              log.debug('Login Response', data);
              if (data.http_status === 200) {
                this.setState({ redirectToDashboard: true, loading: false });
                //              log.debug("successfully logged in")
                singleChoiceWithoutAutoCloseAlertDialogComponent(
                  data.message,
                ).then(
                  () => {
                    component.props.history.push('/login');
                  },
                  () => 0,
                );
              } else if (data.http_status === 202 || data.http_status === 203) {
                this.setState({
                  open: true,
                  loading: false,
                  errorMessage: data.message,
                });
                // } else if (data.http_status === 203) {
                //   this.setState({
                //     open: true,
                //     loading: false,
                //     errorMessage: data.message,
                //   });
              } else {
                this.setState({
                  open: true,
                  loading: false,
                  errorMessage: Constants.generic_message,
                });
              }
            } else {
              this.setState({
                open: true,
                loading: false,
                errorMessage: Constants.generic_message,
              });
            }
          })
          .catch(reason => {
            log.debug(reason.message);
            this.setState({
              open: true,
              loading: false,
              errorMessage: Constants.generic_message,
            });
          });
      }
    }
  }

  gotoSignUp() {
    component.props.history.push('/signup');
  }

  gotoLogin() {
    component.props.history.push('/login');
    // component.showLoginSignUpDisableAlert(Constants.login_disable_message);
  }

  render() {
    return (
      <div className="newLoginScreen">
        <nav className="navbar">
          <a className="navbar-brand" href="/">
            <img src={logoNavbar} alt="Tag That Data navigation logo" />
          </a>
          <div className="topRightMenu">
            <ul className="navbar-nav" role="tablist">
              <li className="nav-item linkTextSec">Ready to try again?</li>
              <li className="nav-item">
                <a className="btn marginRight" onClick={this.gotoLogin}>
                  {Constants.login}
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="bodyContent">
          <div className="loginImgSec">
            <img src={loginImg} className="green-glow" alt="Login Page Image" />
          </div>
          <div className="loginFormSec">
            <form>
              <h1 className="ForgotHeadText">Forgot Password</h1>
              <h1 className="please-enter-your-re">
                Please enter your registered email below to receive your
                password reset instructions.
              </h1>

              <CustomTextField
                id="email"
                fullWidth={true}
                onKeyDown={this.handleKeyEvent}
                onChange={this.onChange}
                errorText={this.state.emailError}
                floatingLabelText={Constants.email}
                className="formControl"
              />

              <div className="LoginBtnRowSec">
                <button
                  className="btn"
                  // label={Constants.login}
                  style={
                    this.state.loading
                      ? {
                          display: 'none',
                        }
                      : {
                          display: 'inline-block',
                        }
                  }
                  onClick={this.onLogin}
                >
                  {' '}
                  Send{' '}
                </button>

                <button
                  style={
                    this.state.loading
                      ? {
                          display: 'inline-block',
                          textAlign: 'center',
                        }
                      : {
                          display: 'none',
                        }
                  }
                  className="btn loginLoading forgotLoading"
                >
                  <CircleProgressComponent
                    color="#FFFFFF"
                    size={25}
                    thickness={2}
                  />
                </button>
                <div className="msgSec">{this.state.errorMessage}</div>
              </div>
            </form>
          </div>
          <div className="clear" />
        </div>
        <div className="footerSec">
          Copyright © 2018 by <span>Tag That Data</span>. All content on this
          website is proprietary, copyrighted, and owned or licensed by{' '}
          <span>Tag That Data</span>. Unauthorized use of trademarks or content
          from this website is prohibited. All rights are reserved.
        </div>
      </div>
    );
  }
}

export default ForgotPasswordComponent;
