/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Card, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { singleChoiceAlertDialogComponent } from '../../../utils/DialogsUtils';
import { isValidAge, getYearsOfBirth } from '../../../utils/AppUtils';
import CustomTextField from '../../CustomTextField';
import Api from '../../../api/Api';
import Constants from '../../../utils/Constants';
import CircleProgressComponent from '../../CircleProgressComponent';
import $ from 'jquery';
import './ProfileComponent.css';
import log from '../../../utils/logger';

let component: any;

type CharacteristicEditComponentProps = {
  history?: any;
  match?: Record<string, unknown>;
};

type CharacteristicEditComponentState = {
  genderError: string;
  yearOfBirthError: string;
  languageOtherOptionError: string;
  languageError: string;
  response: any;
  isLoading: boolean;
  profileResponse: any;
  selected_year: number | null;
  state_id: number | null;
  gender_id: number | null;
  ethnicity_id: number | null;
  language_id: number | null;
  other_native_language: number | undefined;
  years: any[];
} & ((error: any) => void);

class CharacteristicEditComponent extends Component<
  CharacteristicEditComponentProps,
  CharacteristicEditComponentState
> {
  constructor(props: CharacteristicEditComponentProps) {
    super(props);
    component = this;
  }
  state = {
    years: getYearsOfBirth(),
    isLoading: true,
    profileResponse: {},
    isSubmitting: false,
    response: {
      languages: [],
    },
    state_id: null,
    gender_id: null,
    ethnicity_id: null,
    selected_year: new Date().getFullYear(),
    language_id: null,
    other_native_language: undefined,
    languageOtherOptionError: '',
    yearOfBirthError: '',
    genderError: '',
    languageError: '',
  };

  getYears() {
    const array = [];
    for (let i = 1900; i < new Date().getFullYear(); i++) {
      array.push(i);
    }
    return array;
  }

  onGenderChange(event: any, index: number, value: number) {
    log.debug(event.target.value);
    component.setState({ gender_id: value, genderError: '' });
    //   values[id]=value;
    // component.setState({values:values})
  }

  onEthnicityChange(event: any, index: number, value: number) {
    component.setState({ ethnicity_id: value, ethnicityError: '' });
  }

  onStateChange(event: any, index: number, value: number) {
    component.setState({ state_id: value, stateError: '' });
    //   values[id]=value;
    // component.setState({values:values})
  }

  onLanguageChange(event: any, index: number, value: number) {
    // component.setState({ language_id: value, languageError: "" })
    //if (index === 0) {
    component.setState({ language_id: value, languageError: '' });
    // } else {
    //     // if (value === component.state.response.languages[component.state.response.languages.length - 1].native_language_id) {
    //     //     component.setState({ language_id: value, languageError: "" })
    //     // }
    //     //else {
    //     singleChoiceAlertDialogComponent(Constants.language_selection_error).then((params) => {
    //         log.debug(Constants.language_selection_error)
    //     }, () => {
    //     });
    //     // }
    //     component.setState({ languageError: "" })
    // }
  }

  onYearChange(event: any, index: number, value: number) {
    component.setState({ selected_year: value, yearOfBirthError: '' });
  }

  onSave() {
    if (!isValidAge(component.state.selected_year)) {
      singleChoiceAlertDialogComponent(Constants.app_profile_age_error).then(
        // eslint-disable-next-line no-unused-vars
        (params: any) => {
          log.debug(Constants.app_profile_age_error);
        },
        () => 0,
      );
      return;
    }

    const otherNativeLanguageText = $('#native_language_code_id')
      .val()
      ?.toLocaleString();
    log.debug(component.state);

    if (
      component.state.response.languages.length &&
      component.state.response.languages[
        component.state.response.languages.length - 1
      ].native_language_id === component.state.language_id
    ) {
      if (otherNativeLanguageText?.length === 0) {
        component.setState({
          languageOtherOptionError: Constants.signUp_empty_field_error,
        });
        return;
      }
    }

    const data = {
      user_id: component.props.match.params.id,
      state: component.state.state_id,
      gender: component.state.gender_id,
      ethnicity: component.state.ethnicity_id,
      native_language: component.state.language_id,
      year_of_birth: component.state.selected_year,
      other_native_language: otherNativeLanguageText,
    };

    component.setState({ isSubmitting: true });

    Api.updateProfile(data)
      .then(response => {
        if (response && response.http_status) {
          if (response.http_status === 200) {
            component.props.history.push('/dashboard/profile');
            const token = JSON.parse(
              sessionStorage.getItem(Constants.LOGGED_USER) as string,
            ).authentication_token;
            const newData = response.user_data;
            newData.authentication_token = token;
            sessionStorage.setItem(
              Constants.LOGGED_USER,
              JSON.stringify(newData),
            );
          } else {
            singleChoiceAlertDialogComponent(response.message).then(
              // eslint-disable-next-line no-unused-vars
              (params: any) => {
                log.debug('Email Changed', 'entered in login');
              },
              () => 0,
            );
            component.setState({ isSubmitting: false });
          }
        } else {
          singleChoiceAlertDialogComponent(Constants.generic_message).then(
            // eslint-disable-next-line no-unused-vars
            (params: any) => {
              log.debug('Email Changed', 'entered in login');
            },
            () => 0,
          );
          component.setState({ isSubmitting: false });
        }
      })
      .catch((error: any) => {
        log.debug('Generic error in profile' + error);
        singleChoiceAlertDialogComponent(Constants.generic_message).then(
          // eslint-disable-next-line no-unused-vars
          (params: any) => {
            log.debug('Email Changed', 'entered in login');
          },
          () => 0,
        );
        component.setState({ isSubmitting: false });
      });
  }

  componentDidMount() {
    log.debug('Getting Profile Basic Data');
    log.debug(this.state);

    Api.getUserProfile()
      .then(response => {
        if (response && response.http_status) {
          if (response.http_status === 200) {
            //component.setState({ profileResponse: response, isLoading: false,
            //  email: response.data.email, name: response.data.name, address: response.data.mailing_address })
            Api.getSignUpDropDownList()
              .then(res => {
                if (res && res.http_status && res.http_status === 200) {
                  // log.debug(response)
                  component.setState({
                    response: res,
                    isLoading: false,
                    profileResponse: response,
                    selected_year: parseInt(response.data.year_of_birth),
                    state_id: parseInt(response.data.state_id),
                    gender_id: parseInt(response.data.gender_id),
                    ethnicity_id: parseInt(response.data.ethnicity_id),
                    language_id: parseInt(response.data.native_language_id),
                    other_native_language: response.data.other_native_language,
                  });
                  component.forceUpdate();
                }
              })
              .catch((error: any) => {
                log.debug(error);
              });
          } else if (
            response.http_status === 202 ||
            response.http_status === 203
          ) {
            component.setState({ isLoading: false, error: response.message });
          }
        } else {
          component.setState({
            isLoading: false,
            error: Constants.generic_message,
          });
        }
      })
      .catch((error: any) => {
        component.setState({
          isLoading: false,
          error: Constants.generic_message,
        });
      });
  }

  render() {
    return (
      <Card className="ProfileEditBox">
        {this.state.isLoading && (
          <div
            style={{
              position: 'absolute',
              width: '75px',
              textAlign: 'center',
              top: '50%',
              margin: '-20px 0px 0px -37px',
              left: '50%',
            }}
          >
            <CircleProgressComponent />
          </div>
        )}
        {Object.keys(component.state.response).length &&
          Object.keys(component.state.profileResponse).length && (
            <CardText>
              <div className="headingSec">Edit Characteristic Info</div>
              <div className="clear" />
              <div className="BoxFromSec">
                <div className="BoxDetailsRow">
                  <SelectField
                    fullWidth={true}
                    errorText={this.state.yearOfBirthError}
                    floatingLabelText={Constants.sign_up_year_of_birth}
                    value={this.state.selected_year}
                    onChange={this.onYearChange}
                    className="customSelectColor"
                  >
                    {this.state.years.map((year, index) => {
                      return (
                        <MenuItem key={index} value={year} primaryText={year} />
                      );
                    })}
                  </SelectField>

                  <div className="clear" />
                </div>

                <div className="BoxDetailsRow">
                  <SelectField
                    fullWidth={true}
                    errorText={this.state.languageError}
                    floatingLabelText={Constants.sign_up_native_language}
                    value={this.state.language_id}
                    onChange={this.onLanguageChange}
                    className="customSelectColor"
                  >
                    {component.state.response.languages.length &&
                      component.state.response.languages.map(
                        (language: any, index: number) => {
                          return (
                            <MenuItem
                              key={index}
                              value={language.native_language_id}
                              primaryText={language.native_language_name}
                            />
                          );
                        },
                      )}
                  </SelectField>
                  <div className="clear" />
                </div>
                {component.state.response.languages.length &&
                  component.state.response.languages[
                    component.state.response.languages.length - 1
                  ]?.native_language_id === this.state.language_id && (
                    <CustomTextField
                      hintText=""
                      id="native_language_code_id"
                      errorText={this.state.languageOtherOptionError}
                      type="text"
                      value={
                        this.state.other_native_language
                          ? this.state.other_native_language
                          : ''
                      }
                      fullWidth={true}
                      // onChange={this.onChange}
                      floatingLabelText={Constants.enter_your_native_language}
                      className=" CustomTextColor"
                    />
                  )}

                <div className="BoxDetailsRow btnRow">
                  <RaisedButton
                    style={
                      !this.state.isSubmitting
                        ? {
                            display: 'inline-block',
                          }
                        : {
                            display: 'none',
                          }
                    }
                    onClick={this.onSave}
                    label="Save"
                    className="TransparentBtn"
                    primary={true}
                  />
                  <button
                    style={
                      this.state.isSubmitting
                        ? {
                            display: 'inline-block',
                            textAlign: 'center',
                          }
                        : {
                            display: 'none',
                          }
                    }
                    className="saveLoadBtnSec"
                  >
                    <CircleProgressComponent
                      color="#FFFFFF"
                      size={25}
                      thickness={2}
                    />
                  </button>
                </div>

                <div className="clear" />
              </div>
            </CardText>
          )}
      </Card>
    );
  }
}

export default CharacteristicEditComponent;
