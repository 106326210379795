/*
 A component to handle the other options pop-up with the predefined options in the pop-up and handle the clicks.
 This pop-up will appear when selected "Other" from dropdown.
*/
import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { confirmable } from 'react-confirm';
import Theme from '../../Theme';
import './Dialogs.css';
import log from '../../utils/logger';

let component: any;

type SingleChoiceAlertComponentProps = {
  okLabel?: string;
  confirmation: string & ((...args: any[]) => any);
  cancel: (...args: any[]) => any;
  dismiss: (...args: any[]) => any;
  proceed: (...args: any[]) => any;
  show: false & ((...args: any[]) => any);
};

type SingleChoiceAlertComponentState = {
  show: boolean;
  defaultOptions: any[];
};

class SingleChoiceAlertComponent extends React.Component<
  SingleChoiceAlertComponentProps,
  SingleChoiceAlertComponentState
> {
  constructor(props: SingleChoiceAlertComponentProps) {
    super(props);
    this.state = {
      show: true,
      defaultOptions: [],
    };
    component = this;
  }

  /*
  closes pop-up when "X" is clicked
  */
  handleClose = () => {
    if (
      component.props.confirmation.isAutoClose === undefined ||
      component.props.confirmation.isAutoClose === null
    ) {
      this.props.cancel();
      this.setState({ show: false });
    }
  };

  /*
    Submit button method which passes the selected options to the parent transcription class.
  */
  handleData = () => {
    this.props.proceed();
    this.setState({ show: false });
  };

  /*
  Check box methods which removes or adds the selected option into array
  */
  handleCheck = (event: any) => {
    const target = event.target;
    const selectedName = target.id;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const checked = this.state.defaultOptions.slice();
    if (value === true) {
      checked.push(selectedName);
      this.state.defaultOptions.push(selectedName);
    } else {
      const index = checked.indexOf(selectedName);
      checked.splice(index, 1);
      this.state.defaultOptions.splice(index, 1);
    }
  };

  render() {
    const { okLabel = 'OK', confirmation } = this.props;
    const customContentStyle = {
      width: '450px',
      // maxWidth: 'none',
      maxWidth: '100%',
    };
    log.debug('Props are', this.props);
    const actions = [
      <FlatButton
        key={0}
        className="OK"
        style={{
          paddingLeft: '70px',
          paddingRight: '70px',
          height: '40px',
          fontSize: '20px',
          fontWeight: 300,
        }}
        label={okLabel}
        onClick={this.handleData}
      />,
    ];

    return (
      <Theme>
        <Dialog
          className="submitDialog"
          actions={actions}
          modal={false}
          open={this.state.show}
          // bodyStyle={{ color: 'black' }}
          // style={{ color: 'black' }}
          contentStyle={customContentStyle}
          autoScrollBodyContent={true}
          actionsContainerStyle={{
            textAlign: 'center',
            padding: '10px 0px 40px 0px',
          }}
          paperClassName="dialogClass"
        >
          <div
            style={{
              textAlign: 'center',
              padding: '20px',
              lineHeight: '30px',
              fontSize: '20px',
              fontWeight: 300,
            }}
            className="innerTextSec"
          >
            {confirmation}
          </div>
        </Dialog>
      </Theme>
    );
  }
}

export default confirmable(SingleChoiceAlertComponent);
