import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Button } from '@material-ui/core';
import { Card } from 'material-ui/Card';
import { Component, default as React } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import Api from '../../../api/Api';
import CircleProgressComponent from '../../../utils/CircularProgressComponent';
import './FaqsComponent.css';
import log from '../../../utils/logger';

type TranscribeCharacteristicFaqsComponentProps = {
  history?: any;
  location: Record<string, unknown>;
};

type TranscribeCharacteristicFaqsComponentState = {
  editorState: any;
  contentLoading: boolean;
  errorMessage: string;
  file: string;
  loading: boolean;
  data: any[] | null;
};

class TranscribeCharacteristicFaqsComponent extends Component<
  TranscribeCharacteristicFaqsComponentProps,
  TranscribeCharacteristicFaqsComponentState
> {
  constructor(props: TranscribeCharacteristicFaqsComponentProps) {
    super(props);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
    this.state = {
      editorState: EditorState.createEmpty(),
      contentLoading: false,
      errorMessage: '',
      file: '',
      loading: true,
      data: null,
    };
  }

  componentDidMount() {
    this.contentManagement();
    if (this.props.location.title === undefined) {
      this.props.history.push('/dashboard/transcriptions');
    }
  }

  contentManagement() {
    Api.getContentManagement()
      .then(data => {
        if (data && data.http_status) {
          this.setState({
            loading: true,
          });
          if (data.http_status === 200) {
            data.data.faq.map((item: any) => {
              const contentBlock = htmlToDraft(item.body_text);
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks,
              );
              if (this.props.location.title === item.title) {
                this.setState({
                  editorState: EditorState.createWithContent(contentState),
                  loading: false,
                });
              }
            });
            this.setState({
              data: data.data.faq,
              loading: false,
            });
          } else {
            this.setState({ loading: false, data: [] });
          }
        } else {
          this.setState({ loading: false, errorMessage: data.message });
        }
      })
      .catch(reason => {
        log.debug('Exception', reason);
        this.setState({ loading: false, errorMessage: reason });
      });
  }

  onEditorStateChange = (editorState: any) => {
    this.setState({
      editorState,
    });
  };

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async uploadImageCallBack(file: any) {
    // upload
  }

  render() {
    return (
      <React.Fragment>
        {' '}
        {this.state.loading ? (
          <CircleProgressComponent />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Card className="faqCard">
              <Editor
                toolbarHidden={true}
                editorState={this.state.editorState}
                wrapperClassName="wrapper-class-custom"
                editorClassName="editor-class-custom"
                toolbarClassName="toolbar-class-custom"
                readOnly={true}
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                  image: {
                    uploadCallback: this.uploadImageCallBack,
                    previewImage: true,
                    uploadEnabled: true,
                    alt: {
                      present: true,
                      mandatory: true,
                    },
                  },
                }}
              />
            </Card>
            <Button
              variant="contained"
              size="small"
              id="continueRules"
              // icon={AcceptAudio}
              className={'audioSubmitButton'}
              onClick={() => this.props.history.goBack()}
            >
              Continue
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default TranscribeCharacteristicFaqsComponent;
