import { createStyles } from '@material-ui/core';
// import Colors from "../../../themes/Colors";
// import { borderRadius } from 'react-select/lib/theme';

// eslint-disable-next-line func-style
const Styles = (theme: any) =>
  createStyles({
    addIcon: {
      '&:hover': {
        background: '#343434',
      },
      background: '#343434',
      color: '#ffffff',
      fontSize: '11px',
      height: '36px',
      marginLeft: '8px',
      width: '36px',
    },
    earningClass: {
      padding: '12px',
      backgroundColor: '#122834',
      '&:hover': {
        backgroundColor: '#122834',
      },
      cursor: 'default',
      borderRadius: '4px',
      color: '#ffffff',
      display: 'inline-block',
      marginTop: '-11px',
      float: 'right',
      textTransform: 'capitalize',
      fontFamily: 'Gotham, sans-serif !important',
      fontSize: '14px',
      boxShadow:
        '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    },
    rankClass: {
      padding: '12px',
      backgroundColor: '#dee8ef',
      '&:hover': {
        backgroundColor: '#dee8ef',
      },
      cursor: 'default',
      border: '1px solid #3e9fc7',
      borderRadius: '4px',
      color: '#3e9fc7',
      display: 'inline-block',
      marginTop: '-11px',
      float: 'right',
      textTransform: 'capitalize',
      fontFamily: 'Gotham, sans-serif !important',
      fontSize: '14px',
      boxShadow: 'none',
    },
    filterWrapper: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
    },
    paginationContainer: {
      minWidth: '450px',
      width: '500px',
      maxWidth: 'calc(100% - 450px)',
      margin: ' 0px auto 40px auto',
      marginTop: '20px',
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      borderRadius: '10px 10px 10px 10px !important',
      position: 'relative',
      minHeight: '300px',
      [theme.breakpoints.down('xs')]: {
        width: '300px',
      },
    },
    // tslint:disable-next-line:object-literal-sort-keys
    AeMainStyles: {
      margin: 'auto',
      maxWidth: '900px',
      minWidth: '400px',
      position: 'relative',
    },
    bodyButtonProgress: {
      color: '#454545',
      left: '50%',
      margin: '-15px 0px 0px -15px',
      position: 'absolute',
      top: '50%',
    },
    brandImg: {
      // borderRadius: '50%',
      background: '#efefef',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: '4px',
      height: '48px',
      width: '48px',
    },
    brandNameClass: {
      '&:hover': {
        opacity: 1,
      },
      borderRight: '1px solid rgba(0,0,0,0.1)',
      color: '#4444',
      fontSize: '14px',
      // textTransform: 'capitalize',
      opacity: 0.87,
      padding: '16px',
    },
    // brands
    brandNameContainer: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
    },
    brandStatusRedColor: {
      background: 'red',
      borderRadius: '50%',
      height: '16px',
      margin: 'auto',
      width: '16px',
    },
    // Locations
    Brunch: {
      padding: '16px 0',
    },
    cellDelete: {
      '&:hover': {
        opacity: 1,
      },
      borderRight: '1px solid rgba(0,0,0,0.1)',
      color: 'orange',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 600,
      minWidth: '92px',
      opacity: 0.87,
      padding: '16px',
    },
    cellEdit: {
      '&:hover': {
        opacity: 1,
      },
      borderRight: '1px solid rgba(0,0,0,0.1)',
      color: '#44454',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 600,
      minWidth: '92px',
      opacity: 0.87,
      padding: '16px',
      textAlign: 'center',
    },
    circledBrandImg: {
      height: '48px',
      // borderRadius: '50%',
      marginRight: '24px',
      width: '48px',
    },
    emailNameTag: {
      color: '#4444',
      fontSize: '14px',
      margin: '0 0 6px 0',
      textTransform: 'lowercase',
    },
    emptyText: {
      color: '#d4d4d4',
      left: '50%',
      margin: 'auto',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    hoursTag: {
      color: '#444',
      fontSize: '14px',
      margin: '0 0 6px 0',
      minWidth: '180px',
    },
    IconButtonProps: {
      backgroundColor: '#ffffff',
      borderRadius: '6px',
      padding: '2px',
      color: 'red',
    },
    loaderContainer: {
      minHeight: '200px',
    },
    leaderboardContainer: {
      maxHeight: '600px',
    },
    nameTag: {
      color: '#454545',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 14px 6px 0',
      maxWidth: '220px',
      minWidth: '160px',
    },
    paginationIconBtn: {
      color: '#454545',
      cursor: 'pointer',
    },
    paginationBGColor: {
      backgroundColor: '#DEE8EF',
      paddingRight: '22px',
    },
    reactivateColor: {
      color: '#44443',
    },
    actions: {
      color: '#44443',
    },
    root: {
      marginTop: theme.spacing(3),
      // position: 'relative',
      width: '100%',
    },
    row: {
      '&:nth-of-type(odd)': {},
    },
    select: {
      '&:focus': {
        backgroundColor: '#ffffff',
        borderRadius: '6px',
      },
      backgroundColor: '#ffffff',
      borderRadius: '6px',
    },
    selectIcon: {
      paddingRight: '3px',
      right: '-3px',
    },
    shiftsWrapper: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    statusRedColor: {
      background: 'red',
      borderRadius: '50%',
      height: '8px',
      marginLeft: '20px',
      width: '8px',
    },
    table: {
      // overflow: 'scroll',
      width: '100%',
    },
    // tableCellStyles: {
    //   borderRight: "1px solid rgba(0,0,0,0.1)",
    //   padding: "16px",
    //   borderBottomColor:"rgba(0,0,0,0.1)"
    // },
    tableWrapper: {
      // overflow: 'hidden',
      // maxHeight: '800px',
      width: '100%',
    },
  });

export default Styles;
