import React from 'react';
import { TablePagination } from '@material-ui/core';
import log from '../../utils/logger';

type CustomPaginationComponentProps = {
  classes?: Record<string, any>;
  count: number | null;
  rowsPerPage: number;
  page: number;
  onChangePage: (...args: any[]) => any;
  onChangeRowsPerPage: (...args: any[]) => any;
  classesIconBtn?: Record<string, any>;
};

type CustomPaginationComponentState = {
  temp: boolean;
};

// import Colors from '../../../themes/Colors';
// import Strings from '../../../utils/Strings';

class CustomPaginationComponent extends React.Component<
  CustomPaginationComponentProps,
  CustomPaginationComponentState
> {
  state = {
    temp: false,
  };

  //  createSortHandler = (property ) => (event ) => {
  //     log.debug(event)
  //     log.debug(property)
  //     // this.props.onRequestSort(event, property);
  // };

  labelDisplay(rowsPerPage: number, pageInfo: any) {
    // return
    log.debug('pageInfo', pageInfo);
    log.debug('rowsPerPage', rowsPerPage);
    return (
      <React.Fragment>
        {pageInfo.page + 1} of {Math.ceil(pageInfo.count / rowsPerPage)}
      </React.Fragment>
    );
  }

  render() {
    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        classes={this.props.classes}
        // classes={root:classes.paginationBGColor, selectIcon:classes.selectIcon}
        component="div"
        labelDisplayedRows={this.labelDisplay.bind(
          this,
          this.props.rowsPerPage,
        )}
        count={this.props.count ? this.props.count : 1}
        rowsPerPage={this.props.rowsPerPage}
        page={this.props.page}
        labelRowsPerPage="Rows Per Page"
        style={{
          color: '#444',
          opacity: 0.87,
        }}
        // backIconButtonProps={{ color: '#454545', cursor: 'pointer' }}
        backIconButtonProps={{
          classes: this.props.classesIconBtn,
        }}
        // nextIconButtonProps={{ color: '#454545', cursor: 'pointer' }}
        nextIconButtonProps={{
          classes: this.props.classesIconBtn,
        }}
        onChangePage={this.props.onChangePage}
        onChangeRowsPerPage={this.props.onChangeRowsPerPage}
      />
    );
  }
}

export default CustomPaginationComponent;
