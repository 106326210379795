import Constants from './Constants';
import { browserCompatabilityAlertConfirmDialogComponent } from './DialogsUtils';
import $ from 'jquery';
import { detect } from 'detect-browser';
// const { detect } = require("detect-browser");
import MobileDetect from 'mobile-detect';
// var MobileDetect = require("mobile-detect");
import log from './logger';

const md = new MobileDetect(window.navigator.userAgent);
const browser = detect();

export async function getAudioS3URL(audioDetails: any) {
  log.debug('get audio buffer', audioDetails);
  const audioUrl = Constants.audioEndPoint + '/' + audioDetails.audio_id;
  // var audioUrl = Constants.audioEndPoint + "/" + audioDetails.audio_id;
  log.debug('get audio buffer url', audioUrl);
  const response = await fetch(audioUrl, {
    method: 'GET',
    headers: {
      authorization:
        'Bearer ' + localStorage.getItem(Constants.AUDIO_URL_SECRET_KEY),
    },
  });
  if (response !== null) {
    if (!response.ok) {
      // throw Error(response.statusText);
      log.debug(response);
      log.debug('BlobURL', 'Not OK' + response.statusText);
      return response;
    }
    const data = await response.json();
    log.debug('BlobURL', 'OK' + data);
    return getAudioBufferData(data.url);
  }
}

export function getAudioBuffer(url: string, audioContext: AudioContext | null) {
  if (audioContext) {
    return fetch(url)
      .then((res: any) => res.arrayBuffer())
      .then(
        (audioData: any) =>
          new Promise((resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            audioContext.decodeAudioData(audioData, resolve, reject);
          }),
      );
  }
  throw Error('no audio context for buffer');
}

export function getAudioBufferData(url: string) {
  // log.debug("get audio buffer", audioDetails);
  // var audioUrl = Constants.audioEndPoint + "/" + audioDetails.audio_id;
  // var audioUrl = Constants.audioEndPoint + "/" + audioDetails.audio_id;
  log.debug('get audio buffer url', url);
  return fetch(url, {
    method: 'GET',
  }).then((response: any) => {
    if (!response.ok) {
      // throw Error(response.statusText);
      log.debug(response);
      log.debug('S3 URL', 'Not OK' + response.statusText);
      return response;
    }
    log.debug('S3 URL', 'OK' + response.statusText);
    // Read the response as json.
    return response;
  });
}

export function getBrowserName() {
  return browser.name;
}

export function checkBrowserCompatibility() {
  if (
    browser.name === 'chrome' ||
    browser.name === 'firefox' ||
    browser.name === 'opera'
  ) {
    if (isMobileDevice()) {
      if (md.os() !== null && md.os().toLowerCase() === 'androidos') {
        //do nothing
      } else {
        //alert(Constants.browser_compatibility_message)
        const data = {
          isOnlyAlert: true,
          message: Constants.browser_compatibility_message,
        };
        browserCompatabilityAlertConfirmDialogComponent(data).then(
          () => 0,
          () => 0,
        );
      }
    }
  } else {
    // var data = {
    //   isOnlyAlert: true,
    //   message: Constants.browser_compatibility_message,
    // };
    browserCompatabilityAlertConfirmDialogComponent('').then(
      () => 0,
      () => 0,
    );
  }
}

export function getYearsOfBirth() {
  const array = [];
  const fromYear = new Date().getFullYear();
  log.debug(fromYear.toString());
  for (let i = 1900; i <= fromYear; i++) {
    array.push(i);
  }
  log.debug(array);
  return array.sort((a, b) => b - a);
}

/**
 * Get window audio context
 */
export function getContext() {
  // window.AudioContext =
  //   window.AudioContext ||
  //   window.webkitAudioContext ||
  //   window.mozAudioContext ||
  //   window.oAudioContext;
  // const context = new AudioContext();
  // return context;
  return new AudioContext();
}

export function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isValidPhone(phone: string) {
  // const phoneNo = /^[0-9\s+]*$/;
  const phoneNoFormat = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (phone.match(phoneNoFormat)) {
    return true;
  }
  return false;
}

export function isValidPayPalId(id: string) {
  log.debug('PayPalIdValid', isValidPhone(id) || validateEmail(id));
  return isValidPhone(id) || validateEmail(id) ? true : false;
}

export function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
}

export function isValidAge(age: string) {
  const d = new Date();
  const n = d.getFullYear();
  if (n - parseInt(age) < 18) {
    return false;
  }
  return true;
}

export function isAudioApiTokenEmpty() {
  const key = localStorage.getItem(Constants.AUDIO_URL_SECRET_KEY);
  const value =
    key === null || key === undefined || key.length === 0 ? true : false;
  log.debug('AUTOPLAY', key);
  return value;
}

export function isLoopEnabled() {
  const key = localStorage.getItem(Constants.IS_AUDIO_LOOP_ENABLED);
  const value = !key ? 'false' : key;
  log.debug('LOOP', key, localStorage);
  return value;
}

export function isAutoPlayEnabled() {
  const key = localStorage.getItem(Constants.IS_AUDIO_AUTOPLAY_ENABLED);
  log.debug('AUTOPLAY', key);
  const returnValue = !key ? 'false' : key;
  log.debug('AUTOPLAY_return', returnValue);
  return returnValue;
}

export function updateAutoPlayAudio(isEnabled: string) {
  localStorage.setItem(Constants.IS_AUDIO_AUTOPLAY_ENABLED, isEnabled);
}

export async function getIPAddress() {
  await $.getJSON('https://jsonip.com?callback=?', data => {
    // console.error("IP ADDRESS",data)
    return data.ip;
  });
}

export function updateAudioLoop(isEnabled: string) {
  localStorage.setItem(Constants.IS_AUDIO_LOOP_ENABLED, isEnabled);
}

export function getPreviousAudioStatus() {
  const key = localStorage.getItem(Constants.PREVIOUS_AUDIO_STATUS);
  log.debug('PREVIOUS_AUDIO_STATUS', key);
  const returnValue =
    key !== 'undefined' && key !== undefined && key !== null
      ? JSON.parse(key)
      : false;
  log.debug('PREVIOUS_AUDIO_STATUS_return', returnValue);
  return returnValue;
}

export function updatePreviousAudioStatus(isEnabled: boolean) {
  localStorage.setItem(Constants.PREVIOUS_AUDIO_STATUS, isEnabled.toString());
}

export function replaceUnwantedWords(statement: string) {
  const data =
    'umm,ummm,uum,uumm,uummm,uummmm,uuum,uuumm,uuummm,ah,ahh,ahhh,ahhhh,aah,aahh,aahhh,aaah,aaahh,aaahhh,uh,uhh,uhhh,uuh,uuhh,uuhhh,uuuh,uuuhh,uuuhhh,eh,egh,mm,mmm,mmmm,hum,humm,hm,hmm,hmmm,er,err,errr,erm,ohh,ohhh,ooh,oohh,oohhh,oooh,ooohh,ooohhh,uhm,ugh,ughh,ugghh,huh,huhh,huhhh,mhm,mhmm,mhmmm,mmhmm,uhhm,uhhmm,arg,argh,arghm,hrm,hrmm,rhmm,heh,ha,haha,hahaha,har,rah';
  const pauseWords = data.split(',');
  const statementLower = statement.toLowerCase();
  // statement=statement.replaceAll(/  /," ")
  const statementReplaced = statementLower.replace(/\s\s+/g, ' ');
  const statementWords = statementReplaced.split(' ');
  // if (statementWords.length < 2)
  // return statement

  const statementSanitizedArr = new Array(statementWords.length);
  statementWords.forEach((statementWord: string, index: number) => {
    for (let i = 0; i < pauseWords.length; i++) {
      if (pauseWords[i] === statementWord) {
        log.debug('Words matched: ' + i + ': ' + pauseWords[i]);
        statementSanitizedArr[index] = 'um';
        break;
      }
    }
    if (!statementSanitizedArr[index]) {
      statementSanitizedArr[index] = statementWord;
    }
  });

  // eslint-disable-next-line no-console
  // console.time('ConvertedStrWhat');
  // statementWords.forEach((word, index) => {
  //   log.debug('Words index replaced: ' + index + ': ' + word);
  // });
  // statementWords = statementWords.filter(word => word.trim().length > 0);
  // statementReplaced = statementWords.join(' ');
  // log.debug('ConvertedStr: ' + statementReplaced);
  // // eslint-disable-next-line no-console
  // // console.timeEnd('ConvertedStrWhat');
  // return correctSpelling(statementReplaced);

  const statementSanitized = statementSanitizedArr.join(' ');
  log.debug('ConvertedStr: ' + statementSanitized);
  return correctSpelling(statementSanitized);
}

export function correctSpelling(statement: string) {
  // const data = [
  //   ['a', 'a a'],
  //   ['a chorizo', 'achorizo'],
  //   ['a kids', "akid's"],
  //   [
  //     'a la carte',
  //     'Ã¡ la carte',
  //     'Ã¡ la cart',
  //     'a la cart',
  //     'ala cart',
  //     'ala carte',
  //   ],
  //   ['a large', 'alarge'],
  //   ['a lot', 'alot'],
  //   ['a number', 'anumber'],
  //   ['about', 'abut', 'bout'],
  //   ['actually', 'actuallt'],
  //   ['alright', 'all right', 'allright', 'alrigt'],
  //   ["alright and we'll", "alright an we'll"],
  //   ['also just', 'alsojust'],
  //   ['and', 'ad', 'amd', 'amd'],
  //   ['and a', 'an da'],
  //   ['and salt', 'an salt'],
  //   ['and then', 'an then', 'and than'],
  //   ['another', 'a nother'],
  //   ['any', 'ant'],
  //   ['anymore', 'any more'],
  //   ['applesauce', 'apple sauce', 'apple sauce'],
  //   ['are', 're'],
  //   ['arnold', 'arnjold'],
  //   ['bacon', 'beacon', 'baco', 'bacn'],
  //   [
  //     'bambino',
  //     'banbino',
  //     'ambino',
  //     'babino',
  //     'bambido',
  //     'banbino',
  //     'bambio',
  //     'bamabino',
  //     'bamino',
  //     'bambiino',
  //     'bambi no',
  //   ],
  //   ['bambinos', 'bambinadas', 'bambinios', 'banbinos', 'baminos'],
  //   ['banana', 'bannana'],
  //   ['barbecue', 'bbq', 'barbeque', 'bar-b-q', 'barbq'],
  //   ['barbecues', 'bbqs', 'barbeques', 'bar-b-qs'],
  //   ['big', 'bid', 'vig'],
  //   ['big daddy', 'big daddys'],
  //   ['black bean', 'blackbean'],
  //   ['blackberry', 'black berry', 'blackberrie', 'black berrie'],
  //   ['bowl', 'bowel'],
  //   ['breakfast', 'breakfist'],
  //   ['buffalo', 'bufallo', 'buffallo'],
  //   ['burger', 'burgar', 'buger', 'burge'],
  //   ['burger with', 'burgerwith'],
  //   ['burgers', "burger's"],
  //   ['burrito', 'burito', 'buritto', 'burritto', 'burritp', 'burito'],
  //   ['burritos', 'burrittos', 'burritoes', "burrito's", 'buritos', 'burritoos'],
  //   ['butter cup', 'buttercup'],
  //   ['butterfinger', 'butter finger'],
  //   ['can', 'ca', 'van', 'cam', 'czn', 'ca', 'cann'],
  //   ['can i get a', 'can i a'],
  //   ['candy cane', 'candy can'],
  //   ['cane', 'cae'],
  //   ['caramel', 'carmel', 'carmle', 'carmel'],
  //   ['chalupa', 'chulupa'],
  //   ['cheddar', 'chedder', 'cheedar'],
  //   ['cheese', 'chesse', 'chese', 'cheeese', 'cheesee'],
  //   ['cheese fries', 'cheesefries'],
  //   ['cheese meal', 'cheesemeal'],
  //   [
  //     'cheeseburger',
  //     'cheese burger',
  //     'cheese burger',
  //     'cheesburger',
  //     'chesseburger',
  //   ],
  //   ['cheeseburger with', 'cheesburgerwith'],
  //   ['cheeseburgers', 'cheese burgers'],
  //   ['cheesecake', 'cheese cake'],
  //   ['chicken', 'chicen', 'chiken'],
  //   ['chile', 'chili', 'chilly', 'chill', 'chilie', 'chilli'],
  //   ['chile cheese', 'chilliecheese'],
  //   ['chili mac', 'chili mask'],
  //   [
  //     'chocolate',
  //     'choclate',
  //     'chocolat',
  //     'chocoate',
  //     'choclolate',
  //     'chaocolate',
  //     'cholate',
  //     'chocalate',
  //     'chocoloate',
  //   ],
  //   ['cholula', 'challoula'],
  //   [
  //     'chorizo',
  //     'churrizo',
  //     'churizo',
  //     'chirozo',
  //     'chorizio',
  //     'cherrizo',
  //     'chorrizo',
  //     'chereso',
  //     'trezo',
  //   ],
  //   ['chorizos', "chirizo's"],
  //   ['cinnamon', 'cinnomen', 'cinamon', 'cinnammon'],
  //   ['coast no', 'coastno'],
  //   ['coca cola', 'cocacola'],
  //   ['coffee', 'coffe'],
  //   ['combination', 'combonation'],
  //   ['combo', 'comboi'],
  //   ['combos', "combo's"],
  //   ['comes', "come's"],
  //   ['contamination is', "contamination's"],
  //   ['cookie dough', 'cookiedough'],
  //   ['cookies and', "cookies'n"],
  //   ['could', 'oculd'],
  //   ['countryside', 'country side'],
  //   ['coupon', 'cupon'],
  //   ['crispy', 'cripsy'],
  //   ['crunch', 'cruch'],
  //   ['custard', 'custord', 'custerd'],
  //   ['cut fries', 'cut fried'],
  //   ['deluxe', 'delux e', 'delux'],
  //   ['dessert', 'desert'],
  //   ['desserts', 'deserts'],
  //   ["didn't", 'didnt'],
  //   ['diet', 'duet', 'iet'],
  //   ['dipping', "dippin'", 'dippin', 'dripping', 'dippjng', "dipping'"],
  //   ['do', 'fo'],
  //   ['do a', 'doa'],
  //   ['doctor', 'dr', 'docter'],
  //   ['doctor pepper', 'doctorpepper', 'dr pepper'],
  //   ['dokie', 'dookie'],
  //   ['dollars', 'dolllars'],
  //   ["don't", 'dont'],
  //   ['double', 'dounle'],
  //   ['eggnog', 'eggnok', 'egg nog', 'egg nogg'],
  //   ['eight', 'eiught'],
  //   ['eleven', 'elven'],
  //   ["else's", 'elses'],
  //   ['em', 'em'],
  //   ["everything's", 'everythings'],
  //   ['extra', 'exttra'],
  //   ['february', 'febuary', 'feburary', 'fabruary'],
  //   ['fifteen', 'fifiteen'],
  //   ['flavor', 'flavour'],
  //   ['forty', 'fourty'],
  //   ['french', 'frech'],
  //   ['fried', 'friend'],
  //   ['fries', 'f ries', 'frys', 'fris', 'f ries', "fries'"],
  //   ['fry', 'frie', 'fri'],
  //   ['get', 'gat', 'gt'],
  //   ['get a', 'getva', 'geta'],
  //   ['give', 'gibe'],
  //   ['give me', 'gimme', 'gimmy'],
  //   ['going to', 'gunna'],
  //   ['going to be', 'gonnabe', 'going be'],
  //   ['gonna', 'gunna'],
  //   ['good', 'god', 'dood'],
  //   ['good time', 'goodtime'],
  //   ['good times', 'goodtimes'],
  //   ['great', 'gret'],
  //   ['green', 'gren'],
  //   ['guacamole', 'quacamole', 'guacamoli', 'quacomolie', 'guamole'],
  //   ['guys', 'guye'],
  //   ['hamburger', 'hambgurger'],
  //   ['happens', 'hapens'],
  //   ['have', 'ave', 'hav e', 'havee'],
  //   ['have uh', 'haveuhh'],
  //   ["haven't", 'havent'],
  //   ['hi', 'hie'],
  //   ['Hon', 'hun'],
  //   ['honey', 'hunny', 'huney'],
  //   ['how', 'hoew'],
  //   ['i', 'i i'],
  //   ['i get', 'i iget'],
  //   ['i just', 'ijust'],
  //   ['i know', 'iknow'],
  //   ['i think', 'i thank'],
  //   ["i'd", 'i d', "i'de", 'id'],
  //   ["i'll", 'i"ll', "i'lll", 'il', "i''ll", "i'l", 'ill'],
  //   ["i'll do", "i'lldo"],
  //   ["i'm", "i 'm", "i'n", 'im'],
  //   ["i'm going to", "i'mma"],
  //   ["i'ma", 'ima', "i'm'a", 'imma'],
  //   ["i've", 'ive'],
  //   ['ice cream', 'iced cream'],
  //   ['iced tea', 'icetea', 'ice tea'],
  //   ['in like', 'inlike'],
  //   ["isn't", 'isnt'],
  //   ["it'll", 'itll'],
  //   ['itself', 'its self'],
  //   ['jalapeno', 'jalape̱o', 'jalepeno'],
  //   ['jalapenos', 'ajalapenos', 'jalapinos', 'jalape̱os'],
  //   ['junior', 'jr'],
  //   ['just', 'jus'],
  //   ['just the', 'justthe'],
  //   ['ketchup', 'katchup', 'ketvhup'],
  //   ['kids', "kid's"],
  //   ['krystal', 'crystal'],
  //   ['large', 'largr', 'larhe'],
  //   ['leftover', 'left over'],
  //   [
  //     'lemonade',
  //     'lemonaide',
  //     'lemon aid',
  //     'lemonaid',
  //     'emonade',
  //     'lemonaide',
  //     'lemmonade',
  //     'lemonande',
  //     'lemoade',
  //   ],
  //   ['lemonades', 'lemonaides'],
  //   ['let', 'lat'],
  //   ['let me', 'lemme', 'lemme', 'letme', 'lemmy'],
  //   ['lettuce', 'letuce', 'lattuce', 'letus', 'lettus', 'lettice'],
  //   ['light', 'lite'],
  //   ['like', 'lke'],
  //   ['like a', 'lik ea'],
  //   ['maam', "ma'am", 'mam', "ma 'am"],
  //   ['mac and', "mac'n", 'mac n'],
  //   [
  //     'mac and cheese',
  //     "mac n' cheese",
  //     'mac n cheese',
  //     "mac 'n' cheese",
  //     'macncheese',
  //   ],
  //   [
  //     'macaroni and cheese',
  //     "macaroni n' cheese",
  //     'macaroni n cheese',
  //     "macaroni 'n' cheese",
  //   ],
  //   ['marshmallow', 'marshmellow', 'marshmello', 'marshmallo'],
  //   ['marshmallows', 'marshmellows', 'marshmellos', 'marshmallos'],
  //   [
  //     'mayonnaise',
  //     'mayonaise',
  //     'mayonase',
  //     'mayonesse',
  //     'mayonnaiss',
  //     'mayonise',
  //     'mayonaisse',
  //   ],
  //   ['me', 'met'],
  //   ['meat pies', 'meatpies'],
  //   ['medium', 'madium'],
  //   ['milkshake', 'milk shake'],
  //   ['milkshakes', 'milshakes'],
  //   ['mountain', 'moutain', 'moutain'],
  //   ['mustard', 'mustaurd', 'mustaurd'],
  //   ['natural', 'nautral', 'natrual'],
  //   ['ninety', 'ninty'],
  //   ['nope', 'nop'],
  //   ['number', 'numbet', 'numbe', 'nuber', 'nubmber'],
  //   ["o'clock", 'o clock'],
  //   ['of a', 'ofa'],
  //   ['oh', 'ohh'],
  //   ['ok', 'okay'],
  //   ['okay', 'oksy'],
  //   ['okie dokie', 'oki doki', 'okey dokey'],
  //   ['oktoberfest', 'october fest', 'october first', 'octoberfest'],
  //   ['on it', 'o it'],
  //   ['one', 'o ne'],
  //   ['ones', "one's"],
  //   ['onion', 'oninon', 'oniion'],
  //   ['onions', 'inions', 'on ions'],
  //   ['or tomatoes', 'of tomatoes'],
  //   ['oregon', 'orgon', 'orgeon', 'organ'],
  //   ['original', 'orgional'],
  //   ['ought', 'aught'],
  //   ['pack', 'oack'],
  //   ['palmer', 'pulmer'],
  //   ['pattie', 'paddy'],
  //   ['patties', 'paddys', 'pattys'],
  //   ['pawbender', 'powerblender', 'paw bender'],
  //   ['peanut', 'peaut', 'penut', 'peant'],
  //   ['peanut butter', 'penutbutter', 'peanutbutter'],
  //   ['pecans', 'peacans'],
  //   ['pepper', 'peppar', 'peper', 'epper'],
  //   ['pepper jack', 'pepperjack'],
  //   ['pepsi', 'peplsi', 'pepse'],
  //   ['pepsis', "pepsi's"],
  //   ['pickles', 'pickeles', 'pickels', 'pickes', 'plickles'],
  //   ['piece', 'peice'],
  //   ['plain', 'plaon'],
  //   ['please', 'pleasee', 'llease', 'pleae', 'plesse', 'pease'],
  //   ['please but', 'pleas ebut'],
  //   ['please have', 'pleas ehave'],
  //   ['please wild', 'pleasewild'],
  //   ['postmates', 'post mates'],
  //   ['potato', 'pototo', 'potatoe'],
  //   ['potatoes', 'potatos', 'potatos'],
  //   ['powerade', 'power aid'],
  //   ['pumpkin', 'pumkin', 'pumpki'],
  //   [
  //     'raspberry',
  //     'raspberrie',
  //     'razberry',
  //     'razberrie',
  //     'rasberry',
  //     'rasberrie',
  //   ],
  //   ['receive', 'recieve'],
  //   ['recommend', 'reconmend', 'reccomend'],
  //   ["reese's", "resse's", 'reeses'],
  //   ['regular', 'regualr', 'regualar'],
  //   ['root beer', 'rootbeer'],
  //   ['rootbeer', 'ruby'],
  //   [
  //     'sandwich',
  //     'sandwhich',
  //     'sancwich',
  //     'sandwitch',
  //     'sanwich',
  //     'sand witch',
  //     'sand which',
  //   ],
  //   ['sandwiches', 'sandwhiches'],
  //   ['sauce', 'sause', 'saice'],
  //   ['sausage', 'sassage'],
  //   ['second', 'secone'],
  //   ['separate', 'seperate'],
  //   ['separately', 'seperately'],
  //   ['sevens', "seven's"],
  //   ['seventy', 'saeventy'],
  //   ['shake', 'skake'],
  //   ["she's", 'shes'],
  //   ['sicilian', 'sicillian', 'sacillian'],
  //   ['sierra', 'siera', 'sirrea', 'siera', 'sierrra'],
  //   ['single', 'singe'],
  //   ['singles', 'sigles'],
  //   ['size', 'sise'],
  //   ['sodas', 'soadas'],
  //   ['some', 'som'],
  //   ['someone', 'so eone'],
  //   ['sounds', 'souds', "sound's"],
  //   ['southwest', 'south west'],
  //   ['southwestern', 'soutwestern', 'south western', 'southweater'],
  //   ['sparkling', 'sparking'],
  //   ['spoonbender', 'spoon bender', 'spoonbeder', 'spoobender', 'spoonbend'],
  //   ['spoonbenders', 'spoon benders', 'spoobenders'],
  //   ['stephanie', 'stephaine'],
  //   [
  //     'strawberry',
  //     'straberry',
  //     'strawberrie',
  //     'straberrie',
  //     'straw berry',
  //     'straw berrie',
  //     'strawbery',
  //     'strwaberry',
  //   ],
  //   ['sugar', 'suger', 'suagar'],
  //   ['sundae', 'sunday', 'sundays'],
  //   ['supposed', 'supose'],
  //   ['supposed to', 'sopposta'],
  //   ['swiss burger', 'swissburger'],
  //   ['tater', 'tator'],
  //   ['thank', 'thtank', 'thak', 'thanlk'],
  //   ['thank you', 'thankyou', 'thankyou'],
  //   ['thanks', 'othanks'],
  //   ['that', 'thatt', 'thst'],
  //   ["that'd", "that'ld"],
  //   ["that'll", "that'l", 'thall', "tha'll", 'thatl', 'thatll'],
  //   ["that'll do", "that'lldo"],
  //   ["that's", "thant's", 'that s', 'thats'],
  //   ["that's it", "that'sit", "that'd it"],
  //   ['the', 'thd', 'thew'],
  //   ['the drink', 'th edrink'],
  //   ['the sweet', 'thesweet'],
  //   ['then', 'hten', 'hen'],
  //   ['there', 'thwre'],
  //   ["there's", 'theres'],
  //   ["they'll", 'theyll'],
  //   ["they're", 'theyre'],
  //   ["they've", 'theyve'],
  //   ['threes', "three's"],
  //   ['till', 'til'],
  //   ['tomato', 'tomatoe', 'tomoato', 'tomaotoe'],
  //   ['tomatoes', 'tomatos', 'tomatos', 'tomoatoes'],
  //   ['too late', 'to late'],
  //   ['triple', 'tripple'],
  //   ['tuscan', 'tuscany', 'tuscon'],
  //   ['twelve', 'twelce'],
  //   ['twenty five', 'twentyfive'],
  //   ['uh', 'ugh'],
  //   ['unsweetened', 'un sweetened', 'unswetened'],
  //   ['vanilla', 'vanila'],
  //   ['wafer', 'waffer'],
  //   ['waffle', 'waffel'],
  //   ['want', 'wat', 'wan t', 'wan'],
  //   ['we get', 'weget'],
  //   ["we'll order", 'well order'],
  //   ["we're", "we'are"],
  //   ["we've", 'weve'],
  //   ['welcome', 'welcom', 'welcom'],
  //   ["weren't", 'werent'],
  //   ['west', 'wes', 'wast'],
  //   ['west coast', 'west coat', 'westcoast', 'west cost', 'west cost'],
  //   ['what', 'whst'],
  //   ["what'd", 'whatd'],
  //   ["what's", "what'is", 'whats'],
  //   ['whatever', 'what ever'],
  //   ["where's", 'wheres'],
  //   ['whichever', 'which ever'],
  //   ['wild', 'wlld'],
  //   ['wild fries', 'wild fires'],
  //   ['wild fry', 'wild fire'],
  //   ['wild wings', 'wildwings'],
  //   ['with', 'wit', 'wth', 'wih', 'witb'],
  //   ['without', 'with out', 'withought', 'withot'],
  //   ["won't", 'wont'],
  //   ["y'all", "ya'll", 'yall'],
  //   ['yeah', 'yea', 'ya', 'yah', 'yeh'],
  //   ["yeah i'll", "yeah'll"],
  //   ['yep', 'yup'],
  //   ['yes either is fine', 'yes neither is fine'],
  //   ['you', 'yoou', 'yu', 'youqq'],
  //   ["you'll", 'youll'],
  //   ["you're", 'youre', "you'are"],
  //   ["you've", 'youve'],
  // ];
  const data = [
    ['a', 'a a'],
    ['a chorizo', 'achorizo'],
    ['a kids', "akid's"],
    [
      'a la carte',
      'Ã¡ la carte',
      'Ã¡ la cart',
      'a la cart',
      'ala cart',
      'ala carte',
    ],
    ['a large', 'alarge'],
    ['a lot', 'alot'],
    ['a number', 'anumber'],
    ['about', 'abut', 'bout', "'bout"],
    ['actually', 'actuallt'],
    ['alright', 'all right', 'allright', 'alrigt'],
    ["alright and we'll", "alright an we'll"],
    ['also just', 'alsojust'],
    ['and', 'ad', 'amd', 'amd'],
    ['and a', 'an da'],
    ['and salt', 'an salt'],
    ['and then', 'an then', 'and than'],
    ['another', 'a nother'],
    ['any', 'ant'],
    ['anymore', 'any more'],
    ['applesauce', 'apple sauce', 'apple sauce'],
    ['are', 're'],
    ['arnold', 'arnjold'],
    ['bacon', 'beacon', 'baco', 'bacn'],
    [
      'baconzilla',
      'baconzila',
      'baconsilla',
      'baconsila',
      'bacon zilla',
      'bacoonzilla',
      'baconzoola',
    ],
    // [
    //   'bambino',
    //   'banbino',
    //   'ambino',
    //   'babino',
    //   'bambido',
    //   'banbino',
    //   'bambio',
    //   'bamabino',
    //   'bamino',
    //   'bambiino',
    //   'bambi no',
    // ],
    // ['bambinos', 'bambinadas', 'bambinios', 'banbinos', 'baminos'],
    ['banana', 'bannana'],
    ['barbecue', 'bbq', 'barbeque', 'bar-b-q', 'barbq'],
    ['barbecues', 'bbqs', 'barbeques', 'bar-b-qs'],
    ['big', 'bid', 'vig'],
    ['big daddy', 'big daddys'],
    ['black bean', 'blackbean'],
    ['blackberry', 'black berry', 'blackberrie', 'black berrie'],
    ['bowl', 'bowel'],
    ['breakfast', 'breakfist'],
    ['buffalo', 'bufallo', 'buffaloe', 'buffallo', 'bufalo'],
    [
      'buford',
      'buferd',
      'puferd',
      'puford',
      'bueford',
      'bueferd',
      'bufford',
      'buffard',
      'bufard',
      'beauford',
      'beaufford',
      'bouffard',
      'boufard',
    ],
    ['burger', 'burgar', 'buger', 'burge'],
    ['burger with', 'burgerwith'],
    ['burgers', "burger's"],
    ['burrito', 'burito', 'buritto', 'burritto', 'burritp', 'burito'],
    ['burritos', 'burrittos', 'burritoes', "burrito's", 'buritos', 'burritoos'],
    ['butter cup', 'buttercup'],
    ['butterfinger', 'butter finger'],
    ['can', 'ca', 'van', 'cam', 'czn', 'ca', 'cann'],
    ['can i get a', 'can i a'],
    ['candy cane', 'candy can'],
    ['cane', 'cae'],
    ['caramel', 'carmel', 'carmle', 'carmel'],
    ['chalupa', 'chulupa'],
    ['checkerburger', 'checker burger', 'Check her burger ', 'Check or burger'],
    ['checkerburgers', 'checker burgers'],
    ['cheddar', 'chedder', 'cheedar'],
    ['cheese', 'chesse', 'chese', 'cheeese', 'cheesee'],
    ['cheese fries', 'cheesefries'],
    ['cheese meal', 'cheesemeal'],
    [
      'cheeseburger',
      'cheese burger',
      'cheese burger',
      'cheesburger',
      'chesseburger',
    ],
    ['cheeseburger with', 'cheesburgerwith'],
    ['cheeseburgers', 'cheese burgers'],
    ['cheesecake', 'cheese cake'],
    ['chicken', 'chicen', 'chiken'],
    ['chile', 'chili', 'chilly', 'chill', 'chilie', 'chilli'],
    ['chile cheese', 'chilliecheese'],
    ['chili mac', 'chili mask'],
    [
      'chocolate',
      'choclate',
      'chocolat',
      'chocoate',
      'choclolate',
      'chaocolate',
      'cholate',
      'chocalate',
      'chocoloate',
    ],
    ['cholula', 'challoula'],
    [
      'chorizo',
      'churrizo',
      'churizo',
      'chirozo',
      'chorizio',
      'cherrizo',
      'chorrizo',
      'chereso',
      'trezo',
    ],
    ['chorizos', "chirizo's"],
    ['cinnamon', 'cinnomen', 'cinamon', 'cinnammon'],
    ['classic', 'clasic'],
    ['coast no', 'coastno'],
    ['coca cola', 'cocacola'],
    ['coffee', 'coffe'],
    ['combination', 'combonation'],
    ['combo', 'comboi'],
    ['combos', "combo's"],
    ['comes', "come's"],
    ['contamination is', "contamination's"],
    ['cookie dough', 'cookiedough'],
    ['cookies and', "cookies'n"],
    ['could', 'oculd'],
    ['countryside', 'country side'],
    ['coupon', 'cupon'],
    ['crispy', 'cripsy'],
    ['crunch', 'cruch', 'krunch'],
    ['cruncher', 'kruncher'],
    ['custard', 'custord', 'custerd'],
    ['cut fries', 'cut fried'],
    ['deluxe', 'delux e', 'delux'],
    ['dessert', 'desert'],
    ['desserts', 'deserts'],
    ["didn't", 'didnt'],
    ['diet', 'duet', 'iet'],
    ['dipping', "dippin'", 'dippin', 'dripping', 'dippjng', "dipping'"],
    ['do', 'fo'],
    ['do a', 'doa'],
    ['doctor', 'dr', 'docter'],
    ['doctor pepper', 'doctorpepper', 'dr pepper'],
    ['dokie', 'dookie'],
    ['dollars', 'dolllars'],
    ["don't", 'dont'],
    ['double', 'dounle'],
    ['eggnog', 'eggnok', 'egg nog', 'egg nogg'],
    ['eight', 'eiught'],
    ['eleven', 'elven'],
    ["else's", 'elses'],
    ['em', 'em'],
    ["everything's", 'everythings'],
    ['extra', 'exttra'],
    ['february', 'febuary', 'feburary', 'fabruary'],
    ['fifteen', 'fifiteen'],
    ['flavor', 'flavour'],
    ['forty', 'fourty'],
    ['french', 'frech'],
    ['fried', 'friend'],
    ['fries', 'f ries', 'frys', 'fris', 'f ries', "fries'"],
    ['fry', 'frie', 'fri'],
    ['funnel', 'funel', 'funnell', 'funell'],
    ['funnel cake', 'funnelcake'],
    ['get', 'gat', 'gt'],
    ['get a', 'getva', 'geta'],
    ['give', 'gibe'],
    ['give me', 'gimme', 'gimmy'],
    ['going to', 'gunna'],
    ['going to be', 'gonnabe', 'going be'],
    ['gonna', 'gunna'],
    ['good', 'god', 'dood'],
    ['good time', 'goodtime'],
    ['good times', 'goodtimes'],
    ['great', 'gret'],
    ['green', 'gren'],
    ['guacamole', 'quacamole', 'guacamoli', 'quacomolie', 'guamole'],
    ['guys', 'guye'],
    ['hamburger', 'hambgurger'],
    ['happens', 'hapens'],
    ['have', 'ave', 'hav e', 'havee'],
    ['have uh', 'haveuhh'],
    ["haven't", 'havent'],
    ['hi', 'hie'],
    [
      'hi c flashin',
      'high c flashin',
      'hi see flashin',
      'high sea flashin',
      "high sea flashin'",
      "hi see flashin'",
      "hi c flashin'",
      "high c flashin'",
      'hi c flashing',
      'high sea flashing',
    ],
    ['Hon', 'hun'],
    ['honey', 'hunny', 'huney'],
    ['how', 'hoew'],
    ['i', 'i i'],
    ['i get', 'i iget'],
    ['i just', 'ijust'],
    ['i know', 'iknow'],
    ['i think', 'i thank'],
    ["i'd", 'i d', "i'de", 'id'],
    ["i'll", 'i"ll', "i'lll", 'il', "i''ll", "i'l", 'ill'],
    ["i'll do", "i'lldo"],
    ["i'm", "i 'm", "i'n", 'im'],
    ["i'm going to", "i'mma"],
    ["i'ma", 'ima', "i'm'a", 'imma'],
    ["i've", 'ive'],
    ['ice cream', 'iced cream'],
    ['iced tea', 'icetea', 'ice tea'],
    ['in like', 'inlike'],
    ["isn't", 'isnt'],
    ["it'll", 'itll'],
    ['itself', 'its self'],
    ['jalapeno', 'jalape̱o', 'jalepeno'],
    ['jalapenos', 'ajalapenos', 'jalapinos', 'jalape̱os'],
    ['junior', 'jr'],
    ['just', 'jus'],
    ['just the', 'justthe'],
    ['ketchup', 'katchup', 'ketvhup'],
    ['kids', "kid's"],
    ['krystal', 'crystal'],
    ['large', 'largr', 'larhe'],
    ['leftover', 'left over'],
    [
      'lemonade',
      'lemonaide',
      'lemon aid',
      'lemonaid',
      'emonade',
      'lemonaide',
      'lemmonade',
      'lemonande',
      'lemoade',
    ],
    ['lemonades', 'lemonaides'],
    ['let', 'lat'],
    ['let me', 'lemme', 'lemme', 'letme', 'lemmy'],
    ['lettuce', 'letuce', 'lattuce', 'letus', 'lettus', 'lettice'],
    ['light', 'lite'],
    ['like', 'lke'],
    ['like a', 'lik ea'],
    ['maam', "ma'am", 'mam', "ma 'am"],
    ['mac and', "mac'n", 'mac n'],
    [
      'mac and cheese',
      "mac n' cheese",
      'mac n cheese',
      "mac 'n' cheese",
      'macncheese',
    ],
    [
      'macaroni and cheese',
      "macaroni n' cheese",
      'macaroni n cheese',
      "macaroni 'n' cheese",
    ],
    ['marshmallow', 'marshmellow', 'marshmello', 'marshmallo'],
    ['marshmallows', 'marshmellows', 'marshmellos', 'marshmallos'],
    [
      'marinara',
      'marinnara',
      'marinarra',
      'marinnarra',
      'marrinara',
      'marrinnara',
      'marrinnarra',
      'marrinarra',
      'maineara',
      'mairinera',
    ],
    [
      'mayonnaise',
      'mayonaise',
      'mayonase',
      'mayonesse',
      'mayonnaiss',
      'mayonise',
      'mayonaisse',
      'mayonaze',
    ],
    ['me', 'met'],
    ['meat pies', 'meatpies'],
    ['medium', 'madium'],
    ['mello yello', 'mellow yellow', 'mello yellow', 'mellow yello'],
    ['milkshake', 'milk shake'],
    ['milkshakes', 'milshakes'],
    ['mountain', 'moutain', 'moutain'],
    [
      'mozzarella',
      'mozarela',
      'mozzarela',
      'mozzarrela',
      'mozzarrella',
      'mozarrela',
      'mozarrella',
      'mozarella',
      'mozzorella',
      'mozorella',
      'mazzarella',
      'mazarella',
    ],
    ['monsterella', 'monztrella', 'monsterela'],
    ['mother cruncher', 'mothercruncher'],
    ['mustard', 'mustaurd', 'mustaurd'],
    ['natural', 'nautral', 'natrual'],
    ['ninety', 'ninty'],
    ['nope', 'nop'],
    ['number', 'numbet', 'numbe', 'nuber', 'nubmber'],
    ["o'clock", 'o clock'],
    ['of a', 'ofa'],
    ['oh', 'ohh'],
    ['ok', 'okay'],
    ['okay', 'oksy'],
    ['okie dokie', 'oki doki', 'okey dokey'],
    ['oktoberfest', 'october fest', 'october first', 'octoberfest'],
    ['on it', 'o it'],
    ['one', 'o ne'],
    ['ones', "one's"],
    ['onion', 'oninon', 'oniion'],
    ['onions', 'inions', 'on ions'],
    ['or tomatoes', 'of tomatoes'],
    ['oregon', 'orgon', 'orgeon', 'organ'],
    ['original', 'orgional'],
    ['ought', 'aught'],
    ['pack', 'oack'],
    ['palmer', 'pulmer'],
    ['pattie', 'paddy'],
    ['patties', 'paddys', 'pattys'],
    [
      'parmesan',
      'parmesian',
      'parmeshawn',
      'parmeshaun',
      'parmeson',
      'parmesion',
      'parmasen',
    ],
    // ['pawbender', 'powerblender', 'paw bender'],
    ['peanut', 'peaut', 'penut', 'peant'],
    ['peanut butter', 'penutbutter', 'peanutbutter'],
    ['pecans', 'peacans'],
    ['pepper', 'peppar', 'peper', 'epper'],
    ['pepper jack', 'pepperjack'],
    ['pepsi', 'peplsi', 'pepse'],
    ['pepsis', "pepsi's"],
    ['pickles', 'pickeles', 'pickels', 'pickes', 'plickles'],
    ['piece', 'peice'],
    ['plain', 'plaon'],
    ['please', 'pleasee', 'llease', 'pleae', 'plesse', 'pease'],
    ['please but', 'pleas ebut'],
    ['please have', 'pleas ehave'],
    ['please wild', 'pleasewild'],
    ['postmates', 'post mates'],
    ['potato', 'pototo', 'potatoe'],
    ['potatoes', 'potatos', 'potatos'],
    ['powerade', 'power aid'],
    ['pumpkin', 'pumkin', 'pumpki'],
    ['rallyburger', 'raly burger', 'rally burger', 'ralyburger'],
    [
      'raspberry',
      'raspberrie',
      'razberry',
      'razberrie',
      'rasberry',
      'rasberrie',
    ],
    ['receive', 'recieve'],
    ['recommend', 'reconmend', 'reccomend'],
    ["reese's", "resse's", 'reeses'],
    ['regular', 'regualr', 'regualar'],
    ['root beer', 'rootbeer'],
    ['rootbeer', 'ruby'],
    [
      'sandwich',
      'sandwhich',
      'sancwich',
      'sandwitch',
      'sanwich',
      'sand witch',
      'sand which',
    ],
    ['sandwiches', 'sandwhiches'],
    ['sauce', 'sause', 'saice'],
    ['sausage', 'sassage'],
    ['second', 'secone'],
    ['separate', 'seperate'],
    ['separately', 'seperately'],
    ['sevens', "seven's"],
    ['seventy', 'saeventy'],
    ['shake', 'skake'],
    ["she's", 'shes'],
    ['sicilian', 'sicillian', 'sacillian'],
    ['sierra', 'siera', 'sirrea', 'siera', 'sierrra'],
    ['single', 'singe'],
    ['singles', 'sigles'],
    ['size', 'sise'],
    ['slushie', 'slushy', 'slushey', 'slushee'],
    ['smoky', 'smokey', 'smokie', 'smokee'],
    ['sodas', 'soadas'],
    ['some', 'som'],
    ['someone', 'so eone'],
    ['sounds', 'souds', "sound's"],
    ['southwest', 'south west'],
    ['southwestern', 'soutwestern', 'south western', 'southweater'],
    ['sparkling', 'sparking'],
    ['spoonbender', 'spoon bender', 'spoonbeder', 'spoobender', 'spoonbend'],
    ['spoonbenders', 'spoon benders', 'spoobenders'],
    ['stephanie', 'stephaine'],
    [
      'strawberry',
      'straberry',
      'strawberrie',
      'straberrie',
      'straw berry',
      'straw berrie',
      'strawbery',
      'strwaberry',
    ],
    ['sugar', 'suger', 'suagar'],
    ['sundae', 'sunday', 'sundays'],
    ['supposed', 'supose'],
    ['supposed to', 'sopposta'],
    ['swiss burger', 'swissburger'],
    ['tater', 'tator'],
    ['thank', 'thtank', 'thak', 'thanlk'],
    ['thank you', 'thankyou', 'thankyou'],
    ['thanks', 'othanks'],
    ['that', 'thatt', 'thst'],
    ["that'd", "that'ld"],
    ["that'll", "that'l", 'thall', "tha'll", 'thatl', 'thatll'],
    ["that'll do", "that'lldo"],
    ["that's", "thant's", 'that s', 'thats'],
    ["that's it", "that'sit", "that'd it"],
    ['the', 'thd', 'thew'],
    ['the drink', 'th edrink'],
    ['the sweet', 'thesweet'],
    ['then', 'hten', 'hen'],
    ['there', 'thwre'],
    ["there's", 'theres'],
    ["they'll", 'theyll'],
    ["they're", 'theyre'],
    ["they've", 'theyve'],
    ['threes', "three's"],
    ['till', 'til'],
    ['tomato', 'tomatoe', 'tomoato', 'tomaotoe'],
    ['tomatoes', 'tomatos', 'tomatos', 'tomoatoes'],
    ['too late', 'to late'],
    ['triple', 'tripple'],
    ['tuscan', 'tuscany', 'tuscon'],
    ['twelve', 'twelce'],
    ['twenty five', 'twentyfive'],
    ['uh', 'ugh'],
    ['unsweetened', 'un sweetened', 'unswetened'],
    ['vanilla', 'vanila'],
    ['wafer', 'waffer'],
    ['waffle', 'waffel'],
    ['want', 'wat', 'wan t', 'wan'],
    ['we get', 'weget'],
    ["we'll order", 'well order'],
    ["we're", "we'are"],
    ["we've", 'weve'],
    ['welcome', 'welcom', 'welcom'],
    ["weren't", 'werent'],
    ['west', 'wes', 'wast'],
    ['west coast', 'west coat', 'westcoast', 'west cost', 'west cost'],
    ['what', 'whst'],
    ["what'd", 'whatd'],
    ["what's", "what'is", 'whats'],
    ['whatever', 'what ever'],
    ["where's", 'wheres'],
    ['whichever', 'which ever'],
    ['wild', 'wlld'],
    ['wild fries', 'wild fires'],
    ['wild fry', 'wild fire'],
    ['wild wings', 'wildwings'],
    ['with', 'wit', 'wth', 'wih', 'witb'],
    ['without', 'with out', 'withought', 'withot'],
    ["won't", 'wont'],
    ["y'all", "ya'll", 'yall'],
    ['yeah', 'yea', 'ya', 'yah', 'yeh'],
    ["yeah i'll", "yeah'll"],
    ['yep', 'yup'],
    ['yes either is fine', 'yes neither is fine'],
    ['you', 'yoou', 'yu', 'youqq'],
    ["you'll", 'youll'],
    ["you're", 'youre', "you'are"],
    ["you've", 'youve'],
  ];

  // var items = data.split(",")
  //statement = statement.toLowerCase()
  // statement=statement.replaceAll(/  /," ")
  let statementSanitized = statement.replace(/\s\s+/g, ' ');
  // var statementWords = statement.split(" ")
  // if (statementWords.length < 2)
  // return statement
  // const regex = '';
  // eslint-disable-next-line no-console
  // console.time('Spell Correction time');

  try {
    data.forEach(itemsArray => {
      // log.debug('ItemsArray', itemsArray);
      // statementWords.forEach(function (word, index, array) {
      // var items = itemsAray.split(",")
      itemsArray.forEach(item => {
        //if (word === item) {
        // log.debug("<br>Wprs: Matched: " + index + ":  " + word)
        // array[index] =itemsAray[0];
        const myRegExp = new RegExp(' ' + item + ' ', 'g');
        const myRegExp1 = new RegExp('^' + item + ' ', 'g');
        const myRegExp2 = new RegExp(' ' + item + '$', 'g');
        const myRegExp3 = new RegExp('^' + item + '$', 'g');
        // log.debug('Regex', regex);
        // log.debug('Regex_statement', statementSanitized);
        const replace = ' ' + itemsArray[0] + ' ';
        statementSanitized = statementSanitized.replace(myRegExp, replace);
        statementSanitized = statementSanitized.replace(myRegExp1, replace);
        statementSanitized = statementSanitized.replace(myRegExp2, replace);
        statementSanitized = statementSanitized.replace(myRegExp3, replace);
        //}
      });
      // })
    });
  } catch (error) {
    log.debug('error: ' + error);
  }

  // statementWords.forEach(function (word, index) {
  //   log.debug("<br>Wprs:" + index + ":  " + word)
  // })
  // statementWords = statementWords.filter(word => word.trim().length > 0);
  // statement = statementWords.join(" ")
  log.debug('After spell correction: ' + statementSanitized);
  // eslint-disable-next-line no-console
  // console.timeEnd('Spell Correction time');

  return statementSanitized;
}

export function isLoggedIn() {
  const isLoggedInStore = sessionStorage.getItem(Constants.LOGGED_IN);
  log.info('LoggedIn', isLoggedInStore);
  if (isLoggedInStore === 'true') {
    const sessionTime = sessionStorage.getItem(Constants.LOGGED_TIME);
    const currentTime = new Date().getTime();
    if (currentTime > Number(sessionTime)) {
      return false;
    }
    return true;
  }
  return false;
}
