import Constants from '../utils/Constants';
import { appHistory } from '../routes/RouterMain';
// import { createHashHistory } from 'history'
import {
  disqualifiedWarningAlertComponentConfirmDialogComponent,
  disqualifiedUserAlertComponentConfirmDialogComponent,
} from '../utils/DialogsUtils';
import Apis from '../api/Api';
// import RouterMain from "../routes/RouterMain";
// const  history = createHashHistory()
import log from '../utils/logger';
import { UpdateScore, UPDATE_SCORE } from '../actions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ScoreState {
  //TODO
}

const initialState: ScoreState | null = null;

// eslint-disable-next-line @typescript-eslint/naming-convention
function Score(state = initialState, action: UpdateScore) {
  switch (action.type) {
    case UPDATE_SCORE:
      log.debug('Latest score', String(action.score));
      //displayDisqualifiedWarningAlert()
      checkAlerts(action.score);
      return Object.assign({}, action.score);
    default:
      return state;
  }
}

function checkAlerts(score: any) {
  // displayDisqualifiedWarningAlert()
  log.debug('SCORE', score);
  if (score.is_deactivated) {
    displayDisqualifiedUserAlert(score);
  } else if (score.is_disqualified) {
    displayDisqualifiedUserAlert(score);
  } else if (score.is_notification) {
    log.debug(
      'SCORE_NOTIFY',
      localStorage.getItem(Constants.IS_DISQUALIFIED_WARN_SHOWN),
    );
    if (
      localStorage.getItem(Constants.IS_DISQUALIFIED_WARN_SHOWN) === 'false' ||
      localStorage.getItem(Constants.IS_DISQUALIFIED_WARN_SHOWN) ===
        undefined ||
      localStorage.getItem(Constants.IS_DISQUALIFIED_WARN_SHOWN) === null
    ) {
      displayDisqualifiedWarningAlert();
    }
  } else {
    localStorage.setItem(Constants.IS_DISQUALIFIED_WARN_SHOWN, 'false');
  }
}

function displayDisqualifiedWarningAlert() {
  // try {
  //     dispatch(pauseAudio(true))
  // } catch (error) {
  //     console.error("dispatch_error",error)
  // }

  const data = {
    isOnlyAlert: true,
    message: Constants.warningForDisqualifiedUser,
  };

  disqualifiedWarningAlertComponentConfirmDialogComponent(data).then(
    () => {
      localStorage.setItem(Constants.IS_DISQUALIFIED_WARN_SHOWN, 'true');
    },
    () => 0,
  );
}

function displayDisqualifiedUserAlert(score: any) {
  // dispatch(pauseAudio(true))

  const data = {
    isOnlyAlert: true,
    message:
      score.message.length > 0
        ? score.message
        : Constants.infoForDisqualifiedUser,
  };

  disqualifiedUserAlertComponentConfirmDialogComponent(data).then(
    async () => {
      await Apis.logout();
      appHistory.push('/login');
    },
    () => 0,
  );
}

export default Score;
