import React, { Component, Dispatch } from 'react';
// import { render } from 'react-dom';
// import { FloatingActionButton, MuiThemeProvider } from 'material-ui';
// import injectTapEventPlugin from 'react-tap-event-plugin';
import MicrophoneOn from 'material-ui/svg-icons/av/mic';
import MicrophoneOff from 'material-ui/svg-icons/av/stop';
import PlayImage from 'material-ui/svg-icons/av/play-arrow';
import PauseImage from 'material-ui/svg-icons/av/pause';
import {
  updateTestStatus,
  updateTutorialFlag,
  updateMaskStatus,
} from '../../../actions';
import { connect } from 'react-redux';
import { Card } from 'material-ui/Card';
import DoneImage from 'material-ui/svg-icons/action/done';
import CustomFloatingActionButton from '../../CustomFloatingActionButton';
// import { ReactMic, saveRecording } from 'react-mic';
// require ('./styles.scss');
import Constants from '../../../utils/Constants';
// import { createHashHistory } from 'history'
import '../SampleCalibrationComponent.css';
import ScoreComponent from '../../ScoreComponent';
// import ReactMediaRecorderNew from "../../video/ReactMediaRecorderNew";
// import { checkBrowserCompatibility } from '../../../utils/AppUtils';
import log from '../../../utils/logger';
// const history = createHashHistory()

let startVideoRecording: () => any;
let stopVideoRecording: () => any;
let hideShowCamera: (...args: any[]) => any;
let component: any;

type AudioRecorderSampleComponentProps = {
  score?: any;
  history?: any;
  updateMaskStatus: (...args: any[]) => any;
  updateTestStatus: (...args: any[]) => any;
};

type AudioRecorderSampleComponentState = {
  mode: number;
  isPlaying: boolean;
  record: boolean;
  blobObject: null;
  isRecording: boolean;
  url: string;
};

class AudioRecorderSampleComponent extends Component<
  AudioRecorderSampleComponentProps,
  AudioRecorderSampleComponentState
> {
  videoRef: React.RefObject<HTMLVideoElement>;
  sourceRef: React.RefObject<HTMLSourceElement>;
  constructor(props: AudioRecorderSampleComponentProps) {
    super(props);
    this.state = {
      record: false,
      blobObject: null,
      isRecording: false,
      mode: Constants.AUDIO_NORMAL,
      isPlaying: false,
      url: '',
    };

    this.videoRef = React.createRef();
    this.sourceRef = React.createRef();
    component = this;

    this.startPlaying = this.startPlaying.bind(this);
    this.stopListening = this.stopListening.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.onEnded = this.onEnded.bind(this);
  }

  componentDidMount() {
    // this
    //   .audio
    //   .addEventListener('ended', (e: any) =>  {
    //     this.stopListening();
    //   });
    //if (this.props.TestStatus.status) {
    this.props.history.push('/dashboard/collections/samplecollections');
    //}
    //checkBrowserCompatibility()
  }

  componentWillUnmount() {
    this.props.updateMaskStatus(false);
  }

  startRecording = () => {
    //this.stopListening();
    this.setState({ isPlaying: false });
    if (startVideoRecording) {
      log.debug('started video recording');
      startVideoRecording();
      //this.setState({ record: true, isRecording: true, mode: Constants.AUDIO_RECORDING });
    } else {
      log.debug('started video recording null ');
    }
    if (hideShowCamera) {
      hideShowCamera(true);
    }
  };

  // resetRecording = () => {
  //   alert('dd')
  //   log.debug("Reset video recording")
  //   this.stopListening();
  //   this.setState({ record: true, isRecording: true, mode: Constants.AUDIO_RECORDING });
  //   if (startVideoRecording) {
  //     startVideoRecording();
  //   }
  // }

  stopRecording = () => {
    this.setState({
      record: false,
      isRecording: false,
      mode: Constants.AUDIO_RECORDED,
    });
    if (stopVideoRecording) {
      log.debug('stopped video recording');
      stopVideoRecording();
    }
    if (hideShowCamera) {
      hideShowCamera(false);
    }
  };

  onStart = () => {
    component.setState({
      record: true,
      isRecording: true,
      mode: Constants.AUDIO_RECORDING,
    });
  };

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPermissionError(message: string) {
    alert(Constants.camera_permission_error);
    component.setState({ mode: Constants.AUDIO_NORMAL });
  }

  onPermissionGranted() {
    //alert(message)
    // component.setState({mode:Constants.AUDIO_NORMAL})
  }

  onStop = (url: string, blobObject: any) => {
    log.debug('On Stop', url);
    this.setState({ blobObject: blobObject.blobURL, url: url });
    if (component.sourceRef) {
      component.sourceRef.setAttribute('src', url);
    }
    if (component.videoRef) {
      component.videoRef.load();
      // component.videoRef.play()
    }
  };

  startPlaying() {
    log.debug('Playing');
    // log.debug(this.audio)
    // this
    //   .audio
    //   .play();
    this.setState({ mode: Constants.AUDIO_PLAYING, isPlaying: true });
    component.videoRef.play();
  }

  stopListening() {
    // this
    //   .audio
    //   .pause();
    this.setState({ mode: Constants.AUDIO_RECORDED, isPlaying: false });
    component.videoRef.pause();
  }

  onAccept() {
    this.stopListening();
    this.props.updateTestStatus(true);
    this.props.history.push('/dashboard/collections/samplecollections');
  }

  onEnded() {
    this.stopListening();
  }

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showHideCamera(show: boolean) {
    //TODO
  }

  getClassName() {
    // if(component.props.score!=null&&component.props.score!=undefined){
    //   if((!component.props.score.is_calibration&&component.state.mode==Constants.AUDIO_NORMAL)||(!component.props.score.is_confirm_calibration&&component.state.mode==Constants.AUDIO_RECORDED)){
    //     return "actionBtnsBox demoSampleCalibBoxSec startRecord"
    //    }else{
    //      return "actionBtnsBox startRecord"
    //    }
    // }else{
    //   return "actionBtnsBox startRecord"
    // }
    if (component.isNeedShowMask()) {
      return 'actionBtnsBox demoSampleCalibBoxSec startRecord';
    }
    return 'actionBtnsBox startRecord';
  }

  getAcceptClassName() {
    // if(component.props.score!=null&&component.props.score!=undefined){
    //   if((!component.props.score.is_calibration&&component.state.mode==Constants.AUDIO_NORMAL)||(!component.props.score.is_confirm_calibration&&component.state.mode==Constants.AUDIO_RECORDED)){
    //     return "actionBtnsBox demoSampleCalibBoxSec startRecord"
    //    }else{
    //      return "actionBtnsBox startRecord"
    //    }
    // }else{
    //   return "actionBtnsBox startRecord"
    // }
    if (component.isNeedShowMask()) {
      // component.props.updateMaskStatus(true)
      return 'actionBtnsBox demoSampleCalibBoxSec';
    }
    //component.props.updateMaskStatus(true)
    return 'actionBtnsBox';
  }

  updateMastStatusValue() {
    if (component.isNeedShowMask()) {
      component.props.updateMaskStatus(true);
    } else {
      component.props.updateMaskStatus(false);
    }
  }

  isNeedShowMask() {
    if (component.props.score) {
      if (
        (!component.props.score.is_calibration &&
          component.state.mode === Constants.AUDIO_NORMAL) ||
        (!component.props.score.is_confirm_calibration &&
          component.state.mode === Constants.AUDIO_RECORDED)
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  onTutorialAcceptButtonClick(flags: any) {
    component.props.updateTutorialFlag(flags);
  }

  render() {
    const { isPlaying } = this.state;
    log.debug('isPlaying: ', isPlaying);
    // const recorderStyle = {
    //   display:
    //     this.state.mode === Constants.AUDIO_RECORDED ||
    //     this.state.mode === Constants.AUDIO_PLAYING
    //       ? 'none'
    //       : 'block',
    // };

    return (
      <div className="SampCalibratBodySec">
        <Card className="SampCalibratBoxSec">
          <div
            style={{
              padding: 20,
            }}
          >
            <div className="SampCaliBoxTextSec">
              Test your camera so we can ensure suitable records.
            </div>
            <div className="clear" />
            {(this.state.mode === Constants.AUDIO_RECORDED ||
              this.state.mode === Constants.AUDIO_PLAYING) && (
              <div
                style={{ width: '100%', margin: 'auto', textAlign: 'center' }}
              >
                <div className="videoBgSec">
                  <video
                    width="100%"
                    controls={false}
                    ref={this.videoRef}
                    onEnded={this.onEnded}
                  >
                    <source
                      ref={this.sourceRef}
                      src={this.state.url}
                      type="video/webm"
                    />
                  </video>
                </div>
              </div>
            )}
            {/* <ReactMediaRecorderNew
              onError={this.onPermissionError}
              style={{ margin: "30px auto 0" }}
              onPermissionGranted={this.onPermissionGranted}
              onStart={this.onStart}
              onStop={this.onStop}
              render={({ startRecord, stopRecord, showHideCamera }): any => {
                startVideoRecording = startRecord;
                stopVideoRecording = stopRecord;
                hideShowCamera = showHideCamera;
              }}
            /> */}

            <br />
            <br />

            <div className="btnContBoxSec">
              {this.state.mode === Constants.AUDIO_NORMAL && (
                <div
                  style={{ display: 'inline-block', position: 'relative' }}
                  className={this.getClassName()}
                >
                  <CustomFloatingActionButton
                    icon={MicrophoneOn}
                    backgroundColor="#EC501E"
                    labelColor="#E43918"
                    mode={Constants.AUDIO_NORMAL}
                    updateTutorialFlag={this.onTutorialAcceptButtonClick}
                    showMask={this.isNeedShowMask()}
                    tutorialText={Constants.tutorial_calibration_message}
                    onClick={
                      !this.isNeedShowMask() ? this.startRecording : () => 0
                    }
                    label={'Start Recording'}
                  />
                </div>
              )}
              {this.state.mode === Constants.AUDIO_RECORDING && (
                <div
                  style={{ display: 'inline-block', position: 'relative' }}
                  className="actionBtnsBox"
                >
                  {' '}
                  <CustomFloatingActionButton
                    icon={MicrophoneOff}
                    labelColor="#E43918"
                    backgroundColor="#EC501E"
                    onClick={this.stopRecording}
                    label={'Stop'}
                  />
                </div>
              )}
              {(this.state.mode === Constants.AUDIO_RECORDED ||
                this.state.mode === Constants.AUDIO_PLAYING) && (
                <div
                  style={{ display: 'inline-block', position: 'relative' }}
                  className="actionBtnsBox"
                >
                  {' '}
                  <CustomFloatingActionButton
                    backgroundColor="#EC501E"
                    labelColor="#E43918"
                    icon={MicrophoneOn}
                    onClick={this.startRecording}
                    label={'Record Again'}
                  />
                </div>
              )}

              {this.state.mode === Constants.AUDIO_RECORDED && (
                <div
                  style={{ display: 'inline-block', position: 'relative' }}
                  className="actionBtnsBox"
                >
                  <CustomFloatingActionButton
                    onClick={this.startPlaying}
                    icon={PlayImage}
                    // label={'Listen'}
                  />
                </div>
              )}
              {this.state.mode === Constants.AUDIO_PLAYING && (
                <div
                  style={{ display: 'inline-block', position: 'relative' }}
                  className="actionBtnsBox"
                >
                  <CustomFloatingActionButton
                    onClick={this.stopListening}
                    icon={PauseImage}
                    // label={'Pause'}
                  />
                </div>
              )}

              {(this.state.mode === Constants.AUDIO_RECORDED ||
                this.state.mode === Constants.AUDIO_PLAYING) && (
                <div
                  style={{ display: 'inline-block', position: 'relative' }}
                  className={this.getAcceptClassName()}
                >
                  <CustomFloatingActionButton
                    mode={Constants.AUDIO_RECORDED}
                    updateTutorialFlag={this.onTutorialAcceptButtonClick}
                    showMask={this.isNeedShowMask()}
                    tutorialText={
                      Constants.tutorial_calibration_confirm_message
                    }
                    icon={DoneImage}
                    onClick={!this.isNeedShowMask() ? this.onAccept : () => 0}
                    label={'Accept'}
                  />
                </div>
              )}
              {this.isNeedShowMask() && <div className="demoPageMaskBgSec" />}
            </div>

            <br />
          </div>
        </Card>

        <ScoreComponent />

        <div className="clear" />
        {this.updateMastStatusValue()}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return { testStatus: state.testStatus, score: state.score };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    updateTestStatus: () => {
      dispatch(updateTestStatus(true));
    },
    updateTutorialFlag: (tutorialFlag: boolean) => {
      dispatch(updateTutorialFlag(tutorialFlag));
    },
    updateMaskStatus: (status: any) => {
      dispatch(updateMaskStatus(status));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioRecorderSampleComponent);
