import {
  GET_MASK_STATUS,
  UPDATE_MASK_STATUS,
  GetMaskStatus,
  UpdateMaskStatus,
} from '../actions';
import log from '../utils/logger';

export interface MaskStatusState {
  status: boolean;
}

const initialState: MaskStatusState = {
  status: false,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function MaskStatus(
  state = initialState,
  action: UpdateMaskStatus & GetMaskStatus,
) {
  if (action.type === UPDATE_MASK_STATUS) {
    log.debug('ACTION_REDUX', { status: action.status });
    return Object.assign({}, { status: action.status });
  } else if (action.type === GET_MASK_STATUS) {
    return state;
  }
  return state;
}

export default MaskStatus;
