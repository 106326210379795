/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React from 'react';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Api from '../../../api/Api';
import CustomPaginationComponent from '../../table/CustomPaginationComponent';
import PaginationFilterToolbarComponent from '../../table/PaginationFilterToolbarComponent';
import PaginationHeaderComponent from '../../table/PaginationHeaderComponent';
import Styles from '../../table/PaginationStyles';
import categoryFilterOptions from './PaymentHistoryFilterPopUp';
import CircularProgressComponent from '../../../utils/CircularProgressComponent';
import log from '../../../utils/logger';

type PaginationComponentProps = {
  classes: Record<string, string | undefined>;
};

type PaginationComponentState = {
  data: any[];
  errorMessage: string;
  filter: {
    total_earned_from: string;
    total_earned_to: string;
    last_payment_from: string;
    last_payment_to: string;
  };
  loading: boolean;
  metaData: any;
  order: string;
  orderBy: string;
  page: number;
  pageNumber: number;
  pageSize: number;
  rowsPerPage: number;
  totalEarnings: number;
};

const initialFilter = {
  total_earned_from: '',
  total_earned_to: '',
  last_payment_from: '',
  last_payment_to: '',
};

class PaginationComponent extends React.Component<
  PaginationComponentProps,
  PaginationComponentState
> {
  state = {
    data: [],
    errorMessage: '',
    filter: initialFilter,
    loading: true,
    metaData: {
      total_records: null,
    },
    order: 'asc',
    orderBy: 'payment',
    page: 0,
    pageNumber: 1,
    pageSize: 10,
    rowsPerPage: 10,
    totalEarnings: 0,
  };
  constructor(props: PaginationComponentProps) {
    super(props);
  }

  rows = [
    {
      id: 'payment',
      sort: false,
      numeric: false,
      disablePadding: true,
      label: 'Payment Date',
    },
    {
      id: 'amount',
      sort: false,
      numeric: true,
      disablePadding: false,
      label: 'Amount',
    },
  ];

  handleRequestSort = (event: any, property: string) => {
    log.debug(event);
    log.debug(property);
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy }, () => {
      //   this.getUsers()
    });
  };

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleClick = (event: any, id: any) => {
    // const { selected } = this.state;
    // const selectedIndex = selected.indexOf(event.target, 2);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, id);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1),
    //   );
    // }
  };

  componentDidMount() {
    log.debug('component did mount');
    this.paymentHistoryList();
  }

  paymentHistoryList() {
    const params = this.createRequest();
    log.debug('Params', params);
    Api.paymentHistory(params)
      .then((data: any) => {
        if (data && data.http_status) {
          if (data.http_status === 200) {
            this.setState({
              data: data.data,
              loading: false,
              metaData: data.meta_data,
              totalEarnings: data.total_earnings.total_earned,
            });
          } else {
            log.debug('status 202');
            this.setState({ loading: false, data: [] });
          }
        } else {
          log.debug('status 204');
          this.setState({
            loading: false,
            errorMessage: data ? data.message : 'err',
          });
        }
      })
      .catch(reason => {
        log.debug('Exception', reason);
        this.setState({ loading: false, errorMessage: reason });
      });
  }

  openCategoryFilterPopUp = () => {
    log.debug('filter', this.props);
    const options = {
      filter: this.state.filter,
      message: '',
      title: '',
    };

    categoryFilterOptions(options)
      .then((result: any) => {
        log.debug(result);
        if (result) {
          this.setState({ filter: result }, () => {
            this.paymentHistoryList();
          });
        }
      })
      .catch((err: any) => {
        log.debug('categoryFilterOptions error: ', err);
      });
  };

  createRequest = () => {
    let request;
    log.debug('request', request);
    const filter = this.state.filter;
    if (filter === initialFilter) {
      request = {
        offset: this.state.rowsPerPage,
        order_by_field: this.state.orderBy,
        order_by_type: this.state.order,
        pageNo: this.state.page + 1,
      };
    } else {
      request = {
        offset: this.state.rowsPerPage,
        order_by_field: this.state.orderBy,
        order_by_type: this.state.order,
        pageNo: this.state.page + 1,
        total_earned_from: filter?.total_earned_from,
        total_earned_to: filter?.total_earned_to,
        last_payment_from: filter?.last_payment_from,
        last_payment_to: filter?.last_payment_to,
      };
    }
    return request;
  };

  handleChangePage = (event: any, page: number) => {
    this.setState({ page: page }, () => {
      log.debug('PAGE', this.state.page);
      this.paymentHistoryList();
    });
  };

  handleChangeRowsPerPage = (event: any) => {
    // tslint:disable-next-line:radix
    this.setState(
      { rowsPerPage: parseInt(event.target.value), page: 0 },
      () => {
        this.paymentHistoryList();
      },
    );
  };

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, rowsPerPage, page } = this.state;
    log.debug('State', this.state);
    return (
      <div className={classes.paginationContainer}>
        <div className={classes.filterWrapper}>
          <PaginationFilterToolbarComponent
            filter={this.openCategoryFilterPopUp}
          />
          <Button className={classes.earningClass} disableTouchRipple={true}>
            Total Payments: <b>${this.state.totalEarnings} </b>
          </Button>
        </div>
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <PaginationHeaderComponent
                headers={this.rows}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              {this.state.loading ||
              this.state.data === undefined ||
              this.state.data.length === 0 ? (
                <TableBody className={classes.loaderContainer}>
                  {this.state.data.length === 0 && !this.state.loading ? (
                    <TableRow>
                      <TableCell
                        style={{ padding: '60px', borderBottom: 'none' }}
                      >
                        <p className={classes.emptyText}>
                          {' '}
                          No Payments Found.{' '}
                        </p>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell>
                        <CircularProgressComponent
                          className={classes.bodyButtonProgress}
                          color="inherit"
                          thickness={2}
                          size={30}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <TableBody className="paymentHistoryBody">
                  <React.Fragment>
                    {this.state.data &&
                      this.state.data.map((user: any, index: number) => {
                        // const date = new Date(user.last_payout_date);
                        const amount =
                          user.total_earned === null ? 0 : user.total_earned;
                        return (
                          <TableRow
                            hover={true}
                            key={index}
                            className="tableRowBG"
                            // tslint:disable-next-line:jsx-no-lambda
                            onClick={event => this.handleClick(event, user.id)}
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell
                              style={{ minWidth: '160px' }}
                              className="tableCellStyles"
                              align="left"
                            >
                              {' '}
                              {new Date(
                                user.last_payout_date,
                              ).toLocaleDateString()}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: '160px' }}
                              className="tableCellStyles"
                              align="left"
                              scope="row"
                            >
                              {' '}
                              {'$ ' + amount}{' '}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </React.Fragment>
                </TableBody>
              )}
            </Table>
          </div>
          <CustomPaginationComponent
            classes={{
              selectIcon: classes.selectIcon,
              select: classes.select,
              root: classes.paginationBGColor,
            }}
            classesIconBtn={{
              root: classes.paginationIconBtn,
            }}
            // count={this.state.data.length}
            count={this.state.metaData.total_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withStyles(Styles)(PaginationComponent);
