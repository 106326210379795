import { createStyles } from '@material-ui/core';
import Colors from '../../../theme/Colors';

// eslint-disable-next-line func-style
const Styles = (theme: any) =>
  createStyles({
    turtleIcon: {
      // backgroundImage: 'url(' + turtleIcon + ')',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '40px',
      height: '40px',
      cursor: 'pointer',
      margin: '8px',
    },
    colorSwitchBase: {
      '& colorChecked': {
        color: Colors.ThemeDarkColor,
        '& + colorBar': {
          backgroundColor: Colors.ThemeDarkColor,
        },
      },
    },
    iOSSwitchBase: {
      '& iOSChecked': {
        color: theme.palette.common.white,
        '& + iOSBar': {
          backgroundColor: '#52d869',
        },
      },
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.sharp,
      }),
    },
    iOSChecked: {
      transform: 'translateX(15px)',
      '& + iOSBar': {
        opacity: 1,
        border: 'none',
      },
    },
    switchBox: {
      display: 'block',
    },
    iOSBar: {
      borderRadius: 13,
      width: 42,
      height: 26,
      marginTop: -13,
      marginLeft: -21,
      border: 'solid 1px',
      borderColor: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
      width: 24,
      height: 24,
    },
    iOSIconChecked: {
      boxShadow: theme.shadows[1],
    },
    audioWrapper: {
      display: 'flex',
      // justifyContent: "space-between",
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'start',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    },
    qaContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flexStart',
      marginTop: '24px',
    },
    qaContainerHeader: {
      marginRight: '24px',
      lineHeight: '48px',
      fontSize: '20px',
      color: '#265067',
    },
    root: {
      marginRight: '0',
    },
    autoPlayStyle: {
      margin: '0',
      flexDirection: 'column',
    },
  });

export default Styles;
