/* eslint-disable camelcase */
class Constants {
  //**************** production configuration **************
  // static endPoint = "https://prodapi.tagthatdata.com/api/" // production
  // eslint-disable-next-line no-undef
  static endPoint = process.env.APP_API_END_POINT; // production
  // static audioEndPoint = "https://adb.valyant.com/audio"//audio end point for production
  // eslint-disable-next-line no-undef
  static audioEndPoint = process.env.AUDIO_API_END_POINT; //audio end point for production
  // eslint-disable-next-line no-undef
  static logLevel = process.env.LOG_LEVEL ? process.env.LOG_LEVEL : 'silent'; // production
  //************ test configuration  ********************
  //  static endPoint = "https://stageapi.tagthatdata.com/api/" // test
  //  static audioEndPoint = "https://adb-test.valyant.com/audio"//audio end point for test or dev
  /*************** Dev configuration *************************/
  // static endPoint = "https://devapi.tagthatdata.com/api/"     // dev
  // static audioEndPoint = "https://adb-test.valyant.com/audio"//audio end point for test or dev
  //end points for apis
  static PRODUCTION_API_END_POINT = 'https://prodapi.tagthatdata.com/api/';
  static STAGE_API_END_POINT = 'https://stageapi.tagthatdata.com/api/';
  // static DEV_API_END_POINT="http://d8eeeccf.ngrok.io/api/"
  static DEV_API_END_POINT = 'https://devapi.tagthatdata.com/api/';
  //end points for adb valyant apis
  static PRODUCTION_AUDIO_API_END_POINT = 'https://adb.valyant.com/audio';
  static STAGE_AUDIO_API_END_POINT = 'https://adb-test.valyant.com/audio';
  static DEV_AUDIO_API_END_POINT = 'https://adb-test.valyant.com/audio';
  static htmlsPath = 'page/';
  static privacyPolicy = Constants.htmlsPath + 'privacy-policy.html'; //production
  static termsAndConditions = Constants.htmlsPath + 'terms-and-conditions.html'; //production
  static menuURL = Constants.htmlsPath + 'menu.html'; //production
  // methods
  static method_login = 'contributorLogin';
  static method_forgot_password = 'forgotPassword';
  static method_reset_password = 'resetPassword';
  static method_get_statements = 'getNextStatement';
  static method_post_audio_details = 'storeSample';
  static method_get_score = 'getScore';
  static method_get_GT_score = 'getGTScore';
  static method_activation_by_verification_code =
    'contributorActivationByVerificationCode';
  static method_resend_verification_code = 'resendVerificationCode';
  static method_get_characteristic_issues = 'getCharacteristicAudioIssues';
  static method_get_audio_id = 'getAudioID';
  static method_good_times_get_audio_id = 'getGTAudioID';
  static method_get_good_times_characteristic_audio_id =
    'getCorroboratedAudioID';
  static method_get_intents = 'getIntents';
  static method_get_audio_issues = 'getAudioIssues';
  static method_store_transcription = 'storeTranscription';
  static method_good_times_store_transcription = 'storeGTTranscription';
  static method_good_times_characteristic_store_transcription =
    'storeCharacteristicTagging';
  static method_get_list_of_drop_downs = 'getListOfDropdowns';
  static method_signup = 'signup';
  static method_get_user_profile = 'getUserProfile';
  static method_logout = 'logout';
  static method_verify_user = 'contributorActivation';
  static method_change_password = 'changePassword';
  static method_update_user_profile = 'updateUserProfile';
  static method_get_token = 'getToken';
  static method_hologram_location_list = 'getList';
  static method_updateUser_tutorial_flag = 'updateUserTutorialFlag';
  static method_view_orders = 'viewOrders';
  static method_payment_history = 'getPaymentHistory';
  static method_get_leader_board_data = 'getLeaderBoardData';
  static method_get_content = 'getContent';
  static method_get_menu_by_brand = 'getMenuByBrand';
  static method_update_profile_avatar = 'updateUserAvatar';
  //Sign Up
  static sign_up_state = 'State';
  static sign_up_email = 'Email';
  static sign_up_referral_code = 'Referral Code (Optional)';
  static enter_your_native_language = 'Enter Your Native Language';
  static enter_valid_paypal_id = 'Enter Your Valid Paypal Id';
  static sign_up_password = 'Password';
  static sign_up_confirm_password = 'Confirm Password';
  static sign_up_ethnicity = 'Ethnicity';
  static sign_up_year_of_birth = 'Year of Birth';
  static sign_up_phone_number_value = '+1';
  static sign_up_phone_number = 'Phone Number';
  static sign_up_native_language = 'Native Language';
  static sign_up_name = 'Name';
  static sign_up_gender = 'Gender';
  static sign_up_submit = 'Submit';
  static COUNTRY_CODES = [
    { name: 'Afghanistan', dial_code: '+93', code: 'AF' },
    { name: 'Aland Islands', dial_code: '+358', code: 'AX' },
    { name: 'Albania', dial_code: '+355', code: 'AL' },
    { name: 'Algeria', dial_code: '+213', code: 'DZ' },
    { name: 'AmericanSamoa', dial_code: '+1684', code: 'AS' },
    { name: 'Andorra', dial_code: '+376', code: 'AD' },
    { name: 'Angola', dial_code: '+244', code: 'AO' },
    { name: 'Anguilla', dial_code: '+1264', code: 'AI' },
    { name: 'Antarctica', dial_code: '+672', code: 'AQ' },
    { name: 'Antigua and Barbuda', dial_code: '+1268', code: 'AG' },
    { name: 'Argentina', dial_code: '+54', code: 'AR' },
    { name: 'Armenia', dial_code: '+374', code: 'AM' },
    { name: 'Aruba', dial_code: '+297', code: 'AW' },
    { name: 'Australia', dial_code: '+61', code: 'AU' },
    { name: 'Austria', dial_code: '+43', code: 'AT' },
    { name: 'Azerbaijan', dial_code: '+994', code: 'AZ' },
    { name: 'Bahamas', dial_code: '+1242', code: 'BS' },
    { name: 'Bahrain', dial_code: '+973', code: 'BH' },
    { name: 'Bangladesh', dial_code: '+880', code: 'BD' },
    { name: 'Barbados', dial_code: '+1246', code: 'BB' },
    { name: 'Belarus', dial_code: '+375', code: 'BY' },
    { name: 'Belgium', dial_code: '+32', code: 'BE' },
    { name: 'Belize', dial_code: '+501', code: 'BZ' },
    { name: 'Benin', dial_code: '+229', code: 'BJ' },
    { name: 'Bermuda', dial_code: '+1441', code: 'BM' },
    { name: 'Bhutan', dial_code: '+975', code: 'BT' },
    { name: 'Bolivia, Plurinational State of', dial_code: '+591', code: 'BO' },
    { name: 'Bosnia and Herzegovina', dial_code: '+387', code: 'BA' },
    { name: 'Botswana', dial_code: '+267', code: 'BW' },
    { name: 'Brazil', dial_code: '+55', code: 'BR' },
    { name: 'British Indian Ocean Territory', dial_code: '+246', code: 'IO' },
    { name: 'Brunei Darussalam', dial_code: '+673', code: 'BN' },
    { name: 'Bulgaria', dial_code: '+359', code: 'BG' },
    { name: 'Burkina Faso', dial_code: '+226', code: 'BF' },
    { name: 'Burundi', dial_code: '+257', code: 'BI' },
    { name: 'Cambodia', dial_code: '+855', code: 'KH' },
    { name: 'Cameroon', dial_code: '+237', code: 'CM' },
    { name: 'Canada', dial_code: '+1', code: 'CA' },
    { name: 'Cape Verde', dial_code: '+238', code: 'CV' },
    { name: 'Cayman Islands', dial_code: '+ 345', code: 'KY' },
    { name: 'Central African Republic', dial_code: '+236', code: 'CF' },
    { name: 'Chad', dial_code: '+235', code: 'TD' },
    { name: 'Chile', dial_code: '+56', code: 'CL' },
    { name: 'China', dial_code: '+86', code: 'CN' },
    { name: 'Christmas Island', dial_code: '+61', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', dial_code: '+61', code: 'CC' },
    { name: 'Colombia', dial_code: '+57', code: 'CO' },
    { name: 'Comoros', dial_code: '+269', code: 'KM' },
    { name: 'Congo', dial_code: '+242', code: 'CG' },
    {
      name: 'Congo, The Democratic Republic of the Congo',
      dial_code: '+243',
      code: 'CD',
    },
    { name: 'Cook Islands', dial_code: '+682', code: 'CK' },
    { name: 'Costa Rica', dial_code: '+506', code: 'CR' },
    { name: "Cote d'Ivoire", dial_code: '+225', code: 'CI' },
    { name: 'Croatia', dial_code: '+385', code: 'HR' },
    { name: 'Cuba', dial_code: '+53', code: 'CU' },
    { name: 'Cyprus', dial_code: '+357', code: 'CY' },
    { name: 'Czech Republic', dial_code: '+420', code: 'CZ' },
    { name: 'Denmark', dial_code: '+45', code: 'DK' },
    { name: 'Djibouti', dial_code: '+253', code: 'DJ' },
    { name: 'Dominica', dial_code: '+1767', code: 'DM' },
    { name: 'Dominican Republic', dial_code: '+1849', code: 'DO' },
    { name: 'Ecuador', dial_code: '+593', code: 'EC' },
    { name: 'Egypt', dial_code: '+20', code: 'EG' },
    { name: 'El Salvador', dial_code: '+503', code: 'SV' },
    { name: 'Equatorial Guinea', dial_code: '+240', code: 'GQ' },
    { name: 'Eritrea', dial_code: '+291', code: 'ER' },
    { name: 'Estonia', dial_code: '+372', code: 'EE' },
    { name: 'Ethiopia', dial_code: '+251', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', dial_code: '+500', code: 'FK' },
    { name: 'Faroe Islands', dial_code: '+298', code: 'FO' },
    { name: 'Fiji', dial_code: '+679', code: 'FJ' },
    { name: 'Finland', dial_code: '+358', code: 'FI' },
    { name: 'France', dial_code: '+33', code: 'FR' },
    { name: 'French Guiana', dial_code: '+594', code: 'GF' },
    { name: 'French Polynesia', dial_code: '+689', code: 'PF' },
    { name: 'Gabon', dial_code: '+241', code: 'GA' },
    { name: 'Gambia', dial_code: '+220', code: 'GM' },
    { name: 'Georgia', dial_code: '+995', code: 'GE' },
    { name: 'Germany', dial_code: '+49', code: 'DE' },
    { name: 'Ghana', dial_code: '+233', code: 'GH' },
    { name: 'Gibraltar', dial_code: '+350', code: 'GI' },
    { name: 'Greece', dial_code: '+30', code: 'GR' },
    { name: 'Greenland', dial_code: '+299', code: 'GL' },
    { name: 'Grenada', dial_code: '+1473', code: 'GD' },
    { name: 'Guadeloupe', dial_code: '+590', code: 'GP' },
    { name: 'Guam', dial_code: '+1671', code: 'GU' },
    { name: 'Guatemala', dial_code: '+502', code: 'GT' },
    { name: 'Guernsey', dial_code: '+44', code: 'GG' },
    { name: 'Guinea', dial_code: '+224', code: 'GN' },
    { name: 'Guinea-Bissau', dial_code: '+245', code: 'GW' },
    { name: 'Guyana', dial_code: '+595', code: 'GY' },
    { name: 'Haiti', dial_code: '+509', code: 'HT' },
    { name: 'Holy See (Vatican City State)', dial_code: '+379', code: 'VA' },
    { name: 'Honduras', dial_code: '+504', code: 'HN' },
    { name: 'Hong Kong', dial_code: '+852', code: 'HK' },
    { name: 'Hungary', dial_code: '+36', code: 'HU' },
    { name: 'Iceland', dial_code: '+354', code: 'IS' },
    { name: 'India', dial_code: '+91', code: 'IN' },
    { name: 'Indonesia', dial_code: '+62', code: 'ID' },
    {
      name: 'Iran, Islamic Republic of Persian Gulf',
      dial_code: '+98',
      code: 'IR',
    },
    { name: 'Iraq', dial_code: '+964', code: 'IQ' },
    { name: 'Ireland', dial_code: '+353', code: 'IE' },
    { name: 'Isle of Man', dial_code: '+44', code: 'IM' },
    { name: 'Israel', dial_code: '+972', code: 'IL' },
    { name: 'Italy', dial_code: '+39', code: 'IT' },
    { name: 'Jamaica', dial_code: '+1876', code: 'JM' },
    { name: 'Japan', dial_code: '+81', code: 'JP' },
    { name: 'Jersey', dial_code: '+44', code: 'JE' },
    { name: 'Jordan', dial_code: '+962', code: 'JO' },
    { name: 'Kazakhstan', dial_code: '+77', code: 'KZ' },
    { name: 'Kenya', dial_code: '+254', code: 'KE' },
    { name: 'Kiribati', dial_code: '+686', code: 'KI' },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: '+850',
      code: 'KP',
    },
    { name: 'Korea, Republic of South Korea', dial_code: '+82', code: 'KR' },
    { name: 'Kuwait', dial_code: '+965', code: 'KW' },
    { name: 'Kyrgyzstan', dial_code: '+996', code: 'KG' },
    { name: 'Laos', dial_code: '+856', code: 'LA' },
    { name: 'Latvia', dial_code: '+371', code: 'LV' },
    { name: 'Lebanon', dial_code: '+961', code: 'LB' },
    { name: 'Lesotho', dial_code: '+266', code: 'LS' },
    { name: 'Liberia', dial_code: '+231', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', dial_code: '+218', code: 'LY' },
    { name: 'Liechtenstein', dial_code: '+423', code: 'LI' },
    { name: 'Lithuania', dial_code: '+370', code: 'LT' },
    { name: 'Luxembourg', dial_code: '+352', code: 'LU' },
    { name: 'Macao', dial_code: '+853', code: 'MO' },
    { name: 'Macedonia', dial_code: '+389', code: 'MK' },
    { name: 'Madagascar', dial_code: '+261', code: 'MG' },
    { name: 'Malawi', dial_code: '+265', code: 'MW' },
    { name: 'Malaysia', dial_code: '+60', code: 'MY' },
    { name: 'Maldives', dial_code: '+960', code: 'MV' },
    { name: 'Mali', dial_code: '+223', code: 'ML' },
    { name: 'Malta', dial_code: '+356', code: 'MT' },
    { name: 'Marshall Islands', dial_code: '+692', code: 'MH' },
    { name: 'Martinique', dial_code: '+596', code: 'MQ' },
    { name: 'Mauritania', dial_code: '+222', code: 'MR' },
    { name: 'Mauritius', dial_code: '+230', code: 'MU' },
    { name: 'Mayotte', dial_code: '+262', code: 'YT' },
    { name: 'Mexico', dial_code: '+52', code: 'MX' },
    {
      name: 'Micronesia, Federated States of Micronesia',
      dial_code: '+691',
      code: 'FM',
    },
    { name: 'Moldova', dial_code: '+373', code: 'MD' },
    { name: 'Monaco', dial_code: '+377', code: 'MC' },
    { name: 'Mongolia', dial_code: '+976', code: 'MN' },
    { name: 'Montenegro', dial_code: '+382', code: 'ME' },
    { name: 'Montserrat', dial_code: '+1664', code: 'MS' },
    { name: 'Morocco', dial_code: '+212', code: 'MA' },
    { name: 'Mozambique', dial_code: '+258', code: 'MZ' },
    { name: 'Myanmar', dial_code: '+95', code: 'MM' },
    { name: 'Namibia', dial_code: '+264', code: 'NA' },
    { name: 'Nauru', dial_code: '+674', code: 'NR' },
    { name: 'Nepal', dial_code: '+977', code: 'NP' },
    { name: 'Netherlands', dial_code: '+31', code: 'NL' },
    { name: 'Netherlands Antilles', dial_code: '+599', code: 'AN' },
    { name: 'New Caledonia', dial_code: '+687', code: 'NC' },
    { name: 'New Zealand', dial_code: '+64', code: 'NZ' },
    { name: 'Nicaragua', dial_code: '+505', code: 'NI' },
    { name: 'Niger', dial_code: '+227', code: 'NE' },
    { name: 'Nigeria', dial_code: '+234', code: 'NG' },
    { name: 'Niue', dial_code: '+683', code: 'NU' },
    { name: 'Norfolk Island', dial_code: '+672', code: 'NF' },
    { name: 'Northern Mariana Islands', dial_code: '+1670', code: 'MP' },
    { name: 'Norway', dial_code: '+47', code: 'NO' },
    { name: 'Oman', dial_code: '+968', code: 'OM' },
    { name: 'Pakistan', dial_code: '+92', code: 'PK' },
    { name: 'Palau', dial_code: '+680', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', dial_code: '+970', code: 'PS' },
    { name: 'Panama', dial_code: '+507', code: 'PA' },
    { name: 'Papua New Guinea', dial_code: '+675', code: 'PG' },
    { name: 'Paraguay', dial_code: '+595', code: 'PY' },
    { name: 'Peru', dial_code: '+51', code: 'PE' },
    { name: 'Philippines', dial_code: '+63', code: 'PH' },
    { name: 'Pitcairn', dial_code: '+872', code: 'PN' },
    { name: 'Poland', dial_code: '+48', code: 'PL' },
    { name: 'Portugal', dial_code: '+351', code: 'PT' },
    { name: 'Puerto Rico', dial_code: '+1939', code: 'PR' },
    { name: 'Qatar', dial_code: '+974', code: 'QA' },
    { name: 'Romania', dial_code: '+40', code: 'RO' },
    { name: 'Russia', dial_code: '+7', code: 'RU' },
    { name: 'Rwanda', dial_code: '+250', code: 'RW' },
    { name: 'Reunion', dial_code: '+262', code: 'RE' },
    { name: 'Saint Barthelemy', dial_code: '+590', code: 'BL' },
    {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      dial_code: '+290',
      code: 'SH',
    },
    { name: 'Saint Kitts and Nevis', dial_code: '+1869', code: 'KN' },
    { name: 'Saint Lucia', dial_code: '+1758', code: 'LC' },
    { name: 'Saint Martin', dial_code: '+590', code: 'MF' },
    { name: 'Saint Pierre and Miquelon', dial_code: '+508', code: 'PM' },
    {
      name: 'Saint Vincent and the Grenadines',
      dial_code: '+1784',
      code: 'VC',
    },
    { name: 'Samoa', dial_code: '+685', code: 'WS' },
    { name: 'San Marino', dial_code: '+378', code: 'SM' },
    { name: 'Sao Tome and Principe', dial_code: '+239', code: 'ST' },
    { name: 'Saudi Arabia', dial_code: '+966', code: 'SA' },
    { name: 'Senegal', dial_code: '+221', code: 'SN' },
    { name: 'Serbia', dial_code: '+381', code: 'RS' },
    { name: 'Seychelles', dial_code: '+248', code: 'SC' },
    { name: 'Sierra Leone', dial_code: '+232', code: 'SL' },
    { name: 'Singapore', dial_code: '+65', code: 'SG' },
    { name: 'Slovakia', dial_code: '+421', code: 'SK' },
    { name: 'Slovenia', dial_code: '+386', code: 'SI' },
    { name: 'Solomon Islands', dial_code: '+677', code: 'SB' },
    { name: 'Somalia', dial_code: '+252', code: 'SO' },
    { name: 'South Africa', dial_code: '+27', code: 'ZA' },
    { name: 'South Sudan', dial_code: '+211', code: 'SS' },
    {
      name: 'South Georgia and the South Sandwich Islands',
      dial_code: '+500',
      code: 'GS',
    },
    { name: 'Spain', dial_code: '+34', code: 'ES' },
    { name: 'Sri Lanka', dial_code: '+94', code: 'LK' },
    { name: 'Sudan', dial_code: '+249', code: 'SD' },
    { name: 'Suriname', dial_code: '+597', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', dial_code: '+47', code: 'SJ' },
    { name: 'Swaziland', dial_code: '+268', code: 'SZ' },
    { name: 'Sweden', dial_code: '+46', code: 'SE' },
    { name: 'Switzerland', dial_code: '+41', code: 'CH' },
    { name: 'Syrian Arab Republic', dial_code: '+963', code: 'SY' },
    { name: 'Taiwan', dial_code: '+886', code: 'TW' },
    { name: 'Tajikistan', dial_code: '+992', code: 'TJ' },
    {
      name: 'Tanzania, United Republic of Tanzania',
      dial_code: '+255',
      code: 'TZ',
    },
    { name: 'Thailand', dial_code: '+66', code: 'TH' },
    { name: 'Timor-Leste', dial_code: '+670', code: 'TL' },
    { name: 'Togo', dial_code: '+228', code: 'TG' },
    { name: 'Tokelau', dial_code: '+690', code: 'TK' },
    { name: 'Tonga', dial_code: '+676', code: 'TO' },
    { name: 'Trinidad and Tobago', dial_code: '+1868', code: 'TT' },
    { name: 'Tunisia', dial_code: '+216', code: 'TN' },
    { name: 'Turkey', dial_code: '+90', code: 'TR' },
    { name: 'Turkmenistan', dial_code: '+993', code: 'TM' },
    { name: 'Turks and Caicos Islands', dial_code: '+1649', code: 'TC' },
    { name: 'Tuvalu', dial_code: '+688', code: 'TV' },
    { name: 'Uganda', dial_code: '+256', code: 'UG' },
    { name: 'Ukraine', dial_code: '+380', code: 'UA' },
    { name: 'United Arab Emirates', dial_code: '+971', code: 'AE' },
    { name: 'United Kingdom', dial_code: '+44', code: 'GB' },
    { name: 'United States', dial_code: '+1', code: 'US' },
    { name: 'Uruguay', dial_code: '+598', code: 'UY' },
    { name: 'Uzbekistan', dial_code: '+998', code: 'UZ' },
    { name: 'Vanuatu', dial_code: '+678', code: 'VU' },
    {
      name: 'Venezuela, Bolivarian Republic of Venezuela',
      dial_code: '+58',
      code: 'VE',
    },
    { name: 'Vietnam', dial_code: '+84', code: 'VN' },
    { name: 'Virgin Islands, British', dial_code: '+1284', code: 'VG' },
    { name: 'Virgin Islands, U.S.', dial_code: '+1340', code: 'VI' },
    { name: 'Wallis and Futuna', dial_code: '+681', code: 'WF' },
    { name: 'Yemen', dial_code: '+967', code: 'YE' },
    { name: 'Zambia', dial_code: '+260', code: 'ZM' },
    { name: 'Zimbabwe', dial_code: '+263', code: 'ZW' },
  ];
  //Reset Password
  static reset_pw_page_title = 'Reset Password';
  static reset_pw_password = 'New Password';
  static reset_pw_confirm_password = 'Confirm New Password';
  static reset_pw_confirm_password_match_error =
    'Your password and confirmation password do not match.';
  //Audio modes
  static AUDIO_RECORDING = 1;
  static AUDIO_RECORDED = 2;
  static AUDIO_PLAYING = 3;
  static AUDIO_NORMAL = 0;
  //error messages
  static generic_message =
    'Oops! Something went wrong. Please try again later.';
  //Strings
  static sample_collection = 'Sample Collection';
  static audio_transcription = 'Transcribe Audio';
  static demo_graphics = 'Tag Characteristics';
  static CHARACTERISTICS_FAQ = 'Tag Characteristics Rules';
  static sample_calibration = 'Sample Calibration';
  static profile = 'Profile';
  static FAQ = 'FAQ';
  static LEADER_BOARD = 'Leaderboard';
  static TRANSCRIBE_FAQ = 'Transcription Rules';
  static signup = 'Sign Up';
  static login = 'Log In';
  static email = 'Email';
  static verification_Code = 'Verification Code';
  static password = 'Password';
  static remember_me = 'Remember Me';
  static forgot_password = 'Forgot Password';
  static create_new_account = 'Create an Account';
  static enter_valid_email = 'Enter valid email';
  static enter_valid_verification_code = 'Enter valid verification code';
  static login_error_message = 'Email and Password do not match.';
  static enter_password_6_characters =
    'Password should be a minimum of 6 characters.';
  static enter_password = 'Enter valid password';
  static signUp_empty_field_error =
    'Please complete this field before submission.';
  static phone_number_error = 'Please enter valid phone number.';
  static age_error_message =
    'In order to register you must be over the age of 18.';
  static language_selection_error =
    'Thank you for attempting to register with Tag That Data. However, we are not currently taking any submissions for the language selected at this time.';
  static terms_conditions_error =
    'In order to register you must accept Terms and Conditions and Privacy Policy.';
  static app_profile_age_error = 'You must be over the age of 18.';
  static camera_permission_error =
    'Webcam / audio permissions are denied. Please enable the permissions.';
  static browser_compatibility_message =
    'This site works best with Chrome, Firefox, or Opera browsers on your computer or Android device.';
  static audio_transcription_message =
    'Play the audio and enter the exact words you hear in the audio.';
  static no_transcriptions_message =
    'There are currently no records available for transcription. Please check back soon!';
  static no_samples_message =
    'There are currently no statements available for recording. Please check back soon!';
  static tutorial_calibration_message =
    'Click here and say anything you want for this quick video and audio test.';
  static tutorial_calibration_confirm_message =
    "If the video and audio play successfully, click Accept. If you can't see the video or hear the audio, click Record Again.";
  static sample_collection_complete_screen =
    'Thanks for your help with our Sample Collection. We have reached our goals quicker than expected and as a result have paused audio collection at this time. We will restart in the near future and send out emails to those of you who would like to continue. In the interim, we still need a lot of support with Audio Transcriptions, so please navigate to that section and we appreciate your continued support.';
  // static no_registration_alert_message = "Thank you for your interest in Tag That Data. We are not currently accepting new users, but please check back soon!";
  // static no_login_alert_message = "Thank you for your interest in Tag That Data. We have reached our quota so we're temporarily pausing new contributions, but please check back soon!";
  // static audio_issues_info_message = "Check the box if the audio recording cannot be transcribed because it's entirely inaudible or because it's only noise.";
  static audio_issues_info_message =
    'Select "Can\'t Transcribe" if the speech is completely unintelligible and you can\'t understand any words in the audio. If there is at least one audible word, transcribe the clear portions and ignore the rest.';
  // static login_disable_message="I'm sorry, logins are temporarily disabled while we make some updates to the website. Please try again later."
  static audio_issue_offensive_audio =
    'Check this box if the audio contains offensive or inappropriate language, then click Submit. You are not required to enter a transcript for this audio.';
  static audio_issue_employee =
    'If one or more of the speakers sounds like an employee or robot taking the order, check the “Employee or Robot” checkbox and submit your entry. You don’t need to enter a transcript for this audio file.';
  static audio_issue_spanish =
    'Check this box if the speaker is speaking in Spanish or another language, then click Submit. You are not required to enter a transcript for this audio.';
  static audio_issue_unknown_language =
    "Check this box if the speaker is speaking a language you don't understand, then click Submit. You are not required to enter a transcript for this audio.";
  static audio_issue_characteristic_multiple =
    'Check this box if the audio contains multiple speakers, then click Submit. You are not required to enter characteristics for this audio.';
  static audio_issue_characteristic_noise_only =
    'Check this box if the audio contains noise only, then click Submit. You are not required to enter characteristics for this audio.';
  static characteristic_issue_employee =
    'Check this box if one or more of the speakers sounds like an employee or robot taking the order, then click Submit. You don’t need to answer the characteristics questions for this audio file';
  static audio_issue_Inquiry =
    'Check this box if the speaker is asking a question (e.g. "are there pickles on that burger? "), then click Submit. You are not required to enter characteristics for this audio.';
  static login_disable_message =
    "We've reached our first data collection milestone, so we're taking a quick break while we make an update to Tag That Data. We'll be back soon, and we'll send you an email when we're ready to start transcribing again. Thank you!";
  static signup_disable_message =
    "I'm sorry, sign ups are temporarily disabled while we make some updates to the website. Please try again later.";
  static address = 'Address';
  static logout = 'Log Out';
  static submit = 'Submit';
  static details = 'Details';
  static edit = 'Edit';
  static delete = 'Delete';
  static paypalAlert =
    "In order to receive payment for your work, please don't forget to add your PayPal email address.";
  static alertForInvalidSamples =
    'No payouts will be made for audio recordings or transcriptions that are incorrect. Deactivated accounts are not eligible for payout. All earnings shown are in USD.';
  static warningForDisqualifiedUser =
    'Some of your recent records were not successfully validated. Please ensure that your Recordings and Transcriptions are clear and accurate. Per the Agreement, no payouts will be made for incorrect records, and accounts will be deactivated after multiple infractions.';
  static infoForDisqualifiedUser =
    'Your account has been deactivated due to invalid transcriptions/contributions.';
  // static footer_text = "The material contained herein is confidential and the sole property of Tag That Data, LLC © 2018."
  static footer_text =
    'Copyright © 2018 by Tag That Data. All content on this website is proprietary, copyrighted, and owned or licensed by Tag That Data.';
  //storage keys
  static LOGGED_IN = 'COLLECTION_IS_LOGGED_IN';
  static LOGGED_EMAIL = 'COLLECTION_LOGGED_EMAIL';
  static AUTH_TOKEN = 'COLLECTION_AUTH_TOKEN';
  static LOGGED_USER = 'COLLECTION_LOGGED_USER';
  static IS_DISQUALIFIED_WARN_SHOWN = 'COLLECTION_IS_DISQUALIFIED_WARN_SHOWN';
  static AUDIO_URL_SECRET_KEY = 'COLLECTION_AUDIO_URL_SECRET_KEY';
  static LOGGED_TIME = 'COLLECTION_LOGGED_TIME';
  static LOGGED_IS_REMEMBER_LOGIN = 'COLLECTION_IS_REMEMBER_LOGIN';
  static IS_AUDIO_LOOP_ENABLED = 'AUDIO_LOOP_ENABLED';
  static IS_AUDIO_AUTOPLAY_ENABLED = 'AUDIO_AUTOPLAY_ENABLED';
  static PREVIOUS_AUDIO_STATUS = 'PREVIOUS_AUDIO_STATUS';
  //new transcriptions
  static FRUSTRATION_LEVELS = [0, 1, 2, 3, 4];
  static SPEAKERS = ['Native English Speaker', 'Non-Native English Speaker'];
  static GENDERS = ['Male', 'Female', 'Not Sure'];
  static AGE_RANGE_TYPES = [
    '18-25 years',
    '36-45 years',
    '46-55 years',
    '56-65 years',
    '66+ years',
  ];
  static AGE_RANGE = 'Age Range';
  static FRUSTRATION_LEVEL = 'Frustration Level';
  static SPEAKER = 'Speaker';
  static GENDER = 'Gender';
  static menuHint = 'Please review the applicable menu:';
  static supportText =
    'If you have any questions about functionality, payment, or need support, please email the Tag That Data team at:';
  // payment History
  static FILTER = 'Filter';
  static FROM = 'From';
  static TO = 'To';
  static LAST_PAYMENT_DATE = 'Last Payment Date';
  static TOTAL_EARNED_FROM = 'Total Earned From';
  static TOTAL_EARNED = 'Total Earned';
  static TOTAL_EARNED_TO = 'Total Earned To';
  // characteristics
  static QHead = 'Play the audio and enter the following:';
  static QPara = "If you aren't sure, just take your best guess";
  // google Tracking ID
  // static   GoogleTrackingID='UA-143629759-1'
  static GoogleTrackingID = 'UA-134188675-2';
}
export default Constants;
