const COLORS = {
  white: '#ffffff',
  black: '#000',
};

const LIGHT_MODE = {
  backgroundColor: COLORS.white,
  textColor: COLORS.black,
};

const DARK_MODE = {
  textColor: COLORS.white,
  backgroundColor: COLORS.black,
};

export { COLORS, LIGHT_MODE, DARK_MODE };
