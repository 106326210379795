import { createMuiTheme } from '@material-ui/core/styles';
import Colors from './Colors';

// For customizing the theme for the whole application
// eslint-disable-next-line @typescript-eslint/naming-convention
const CustomTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      label: {
        fontWeight: 700,
        textTransform: 'capitalize',
      },
      text: {
        // Name of the rule
        color: '#ffffff', // Some CSS
      },
    },
  },
  palette: {
    primary: {
      main: Colors.PRIMARY_COLOR,
    },
    secondary: {
      main: Colors.SECONDARY_COLOR,
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Lato', 'Roboto'].join(','),
    // useNextVariants: true,
  },
});

export default CustomTheme;
