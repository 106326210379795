/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Card, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import CustomTextField from '../../CustomTextField';
import Api from '../../../api/Api';
import Constants from '../../../utils/Constants';
import CircleProgressComponent from '../../CircleProgressComponent';
import { validateEmail } from '../../../utils/AppUtils';
import {
  singleChoiceAlertDialogComponent,
  singleChoiceWithoutAutoCloseAlertDialogComponent,
} from '../../../utils/DialogsUtils';
import $ from 'jquery';
import './ProfileComponent.css';
import log from '../../../utils/logger';

let component: any;

type ProfileBasicEditComponentProps = {
  history?: any;
};

type ProfileBasicEditComponentState = {
  isLoading: boolean;
  response: any;
  error: string;
  emailError: string;
  nameError: string;
  email: string;
  isSubmitting: boolean;
  name: string;
  address: string;
  isEmailChanged: boolean;
} & ((error: any) => void);

class ProfileBasicEditComponent extends Component<
  ProfileBasicEditComponentProps,
  ProfileBasicEditComponentState
> {
  constructor(props: ProfileBasicEditComponentProps) {
    super(props);
    component = this;
  }
  state = {
    isLoading: true,
    response: null,
    error: '',
    emailError: '',
    nameError: '',
    email: '',
    isSubmitting: false,
    name: '',
    address: '',
    isEmailChanged: false,
  };

  componentDidMount() {
    log.debug('Getting Profile Basic Data');
    Api.getUserProfile()
      .then((response: any) => {
        if (response && response.http_status) {
          if (response.http_status === 200) {
            component.setState({
              response: response,
              isLoading: false,
              email: response.data.email,
              name: response.data.name,
              address: response.data.mailing_address,
            });
          } else if (
            response.http_status === 202 ||
            response.http_status === 203
          ) {
            component.setState({ isLoading: false, error: response.message });
          }
        } else {
          component.setState({
            isLoading: false,
            error: Constants.generic_message,
          });
        }
      })
      .catch((error: any) => {
        component.setState({
          isLoading: false,
          error: Constants.generic_message,
        });
      });
  }

  onChange(event: any) {
    //component.setState({email,})
    log.debug('id', event.target.id);
    log.debug('value', event.target.value);
    const value = event.target.value;
    if (event.target.id === 'email') {
      if (validateEmail(value)) {
        component.setState({ email: value, emailError: '' });
      } else {
        component.setState({
          email: value,
          emailError: Constants.enter_valid_email,
        });
      }
    } else if (event.target.id === 'name') {
      if (value.length > 0) {
        component.setState({ name: value, nameError: '' });
      } else {
        component.setState({ name: value, nameError: 'Enter name' });
      }
    } else if (event.target.id === 'address') {
      component.setState({ address: value });
    }
  }

  onSave() {
    if (
      component.state.name.length > 0 &&
      validateEmail(component.state.email)
    ) {
      let data;
      let isEmailChanged = false;
      if (component.state.response.data.email === component.state.email) {
        data = {
          user_id: component.state.response.data.id,
          name: component.state.name,
          mailing_address: component.state.address,
        };
      } else {
        isEmailChanged = true;
        data = {
          user_id: component.state.response.data.id,
          name: component.state.name,
          mailing_address: component.state.address,
          email: component.state.email,
        };
      }
      log.debug('Email Changed', isEmailChanged);
      component.setState({ isSubmitting: true });
      Api.updateProfile(data)
        .then(async response => {
          if (response && response.http_status) {
            if (response.http_status === 200) {
              log.debug('Email Changed', isEmailChanged);
              if (isEmailChanged) {
                await Api.logout();
                localStorage.setItem(Constants.LOGGED_IN, 'false');
                localStorage.setItem(Constants.LOGGED_USER, '');
                localStorage.setItem(Constants.email, '');
                localStorage.setItem(Constants.LOGGED_TIME, '');
                component.props.history.push({ pathname: '/login' });
                singleChoiceWithoutAutoCloseAlertDialogComponent(
                  'Email updated successfully. Please verify your email to login',
                ).then(
                  (params: any) => {
                    log.debug('Email Changed', 'entered in login');
                  },
                  () => 0,
                );
              } else {
                log.debug('Email Changed', 'entered in profile');
                component.props.history.push({
                  pathname: '/dashboard/profile',
                });
              }
            } else {
              singleChoiceAlertDialogComponent(response.message).then(
                // eslint-disable-next-line no-unused-vars
                (params: any) => 0,
                () => 0,
              );
              component.setState({ isSubmitting: false });
            }
          } else {
            singleChoiceAlertDialogComponent(Constants.generic_message).then(
              // eslint-disable-next-line no-unused-vars
              (params: any) => 0,
              () => 0,
            );
          }
        })
        .catch((error: any) => {
          singleChoiceAlertDialogComponent(Constants.generic_message).then(
            // eslint-disable-next-line no-unused-vars
            (params: any) => 0,
            () => 0,
          );
        });
    } else {
      const email = $('#email').val()?.toLocaleString();
      const name = $('#name').val()?.toLocaleString();
      if (email && validateEmail(email)) {
        component.setState({ email: email, emailError: '' });
      } else {
        component.setState({
          email: email,
          emailError: Constants.enter_valid_email,
        });
      }
      if (name && name.length > 0) {
        component.setState({ name: name, nameError: '' });
      } else {
        component.setState({ name: name, nameError: 'Enter name' });
      }
    }
  }

  render() {
    return (
      <div>
        <Card className="ProfileEditBox ProfileEditInfoBox">
          {this.state.error.length > 0 && <div>{this.state.error}</div>}
          {this.state.isLoading && (
            <div
              style={{
                position: 'absolute',
                width: '75px',
                textAlign: 'center',
                top: '50%',
                margin: '-20px 0px 0px -37px',
                left: '50%',
              }}
            >
              <CircleProgressComponent />
            </div>
          )}
          {this.state.response && (
            <CardText>
              <div className="headingSec">Edit Basic Info</div>
              <div className="clear" />
              <div className="BoxFromSec">
                <div className="BoxDetailsRow">
                  <CustomTextField
                    fullWidth={true}
                    id="name"
                    errorText={this.state.nameError}
                    floatingLabelText="Name"
                    value={this.state.name}
                    onChange={this.onChange}
                    floatingLabelFixed={true}
                    className="customTextColor"
                  />
                  <div className="clear" />
                </div>
                <div className="BoxDetailsRow">
                  <CustomTextField
                    fullWidth={true}
                    errorText={this.state.emailError}
                    value={this.state.email}
                    onChange={this.onChange}
                    id="email"
                    floatingLabelText="Email"
                    floatingLabelFixed={true}
                    type="email"
                    className="customTextColor"
                  />
                  <div className="clear" />
                </div>

                <div className="BoxDetailsRow btnRow">
                  <RaisedButton
                    style={
                      !this.state.isSubmitting
                        ? {
                            display: 'inline-block',
                            textAlign: 'center',
                          }
                        : {
                            display: 'none',
                          }
                    }
                    onClick={this.onSave}
                    label="Save"
                    className="TransparentBtn"
                    primary={true}
                  />
                  <button
                    style={
                      this.state.isSubmitting
                        ? {
                            display: 'inline-block',
                            textAlign: 'center',
                          }
                        : {
                            display: 'none',
                          }
                    }
                    className="saveLoadBtnSec"
                  >
                    <CircleProgressComponent
                      color="#FFFFFF"
                      size={25}
                      thickness={2}
                    />
                  </button>
                </div>

                <div className="clear" />
              </div>
            </CardText>
          )}
        </Card>
      </div>
    );
  }
}

export default ProfileBasicEditComponent;
