/* eslint-disable camelcase */
import React, { Component, Dispatch } from 'react';
// import FlatButton from 'material-ui/FlatButton';
import Constants from '../../utils/Constants';
import logo_navbar from '../../theme/images/logo_navbar.png';
import audio_trans from '../../theme/images/audio_trans.png';
import pic_1 from '../../theme/images/pic_1.png';
import audio_rec from '../../theme/images/audio_rec.png';
import pic_2 from '../../theme/images/pic_2.png';
import '../../bootstrap/bootstrap.min.css';
import './all1.css';
import '../../bootstrap/bootstrap.min';
import './HomeComponent.css';
import { connect } from 'react-redux';
import { logout } from '../../actions';
import $ from 'jquery';
import { singleChoiceAlertDialogComponent } from '../../utils/DialogsUtils';
import log from '../../utils/logger';

let component: any;

// log.debug('isLoggedIn: ' + isRemebered) if (isRemebered == 'true') { return
// true; } else {     return false; }

// eslint-disable-next-line func-style
function isLoggedIn() {
  const isLoggedInStore = sessionStorage.getItem(Constants.LOGGED_IN);
  const isRememberLogin = sessionStorage.getItem(
    Constants.LOGGED_IS_REMEMBER_LOGIN,
  );
  if (isRememberLogin === 'true') {
    return true;
  }
  if (isLoggedInStore === 'true') {
    const sessionTime = sessionStorage.getItem(Constants.LOGGED_TIME);
    const currentTime = new Date().getTime();
    if (currentTime > Number(sessionTime)) {
      return false;
    }
    return true;
  }
  return false;
}

type HomeComponentProps = {
  history?: any;
};

class HomeComponent extends Component<HomeComponentProps> {
  // hash: string;
  // pathname: any;
  // hostname: string;

  constructor(props: HomeComponentProps) {
    super(props);
    component = this;
    // this.hash = '';
    // this.hostname = '';
    // this.pathname = '';
  }

  componentDidMount() {
    if (isLoggedIn()) {
      log.debug('LOGGED IN', 'True');
      this.props.history.push('/dashboard');
    } else {
      //this.props.logout()
      log.debug('LOGGED IN', 'False');
    }
    $('a[href*="#"]')
      .not('[href="#"]')
      .not('[href="#0"]')
      .click((event: any) => {
        event.preventDefault();
        // // On-page links
        // if (
        //   location.pathname.replace(/^\//, '') ===
        //     this.pathname.replace(/^\//, '') &&
        //   location.hostname === this.hostname
        // ) {
        //   // Figure out element to scroll to
        //   let $target = $(this.hash);
        //   $target = $target?.length
        //     ? $target
        //     : $('[name=' + this.hash.slice(1) + ']');
        //   // Does a scroll target exist?
        //   if ($target.length) {
        //     // Only prevent default if animation is actually gonna happen
        //     event.preventDefault();
        //  const divPosition = $target.offset();
        const divPosition = $(event?.target?.hash).offset();
        log.debug('Top Pixels', divPosition);
        const height = $('#top-navigation').height();
        if (divPosition && height) {
          $('html, body').scrollTop(divPosition.top - height - 10);
        }
        // }
        // }
      });
    //   $("#top-navigation").scrollspy();
  }

  navigateToLogin() {
    // component.showLoginSignUpDisableAlert(Constants.login_disable_message)
    // return
    component.props.history.push('/login');
  }

  navigateToSignUp() {
    // component.showLoginSignUpDisableAlert(Constants.signup_disable_message  )
    // return
    component.props.history.push('/signup');
  }

  showLoginSignUpDisableAlert(message: string) {
    singleChoiceAlertDialogComponent(message).then(
      // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (params: any) => {
        log.debug(this.props);
        // window.location = "https://tagthatdata.com"
      },
      () => 0,
    );
  }

  render() {
    return (
      <div className="landingPageSec">
        <nav id="top-navigation" className="navbar navbar-expand-lg sticky-top">
          <a className="navbar-brand" href="/">
            <img src={logo_navbar} alt="Tag That Data navigation logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#main-navbar"
            aria-controls="main-navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars f-2x" />
          </button>

          <div
            className="collapse navbar-collapse flex-row-reverse"
            id="main-navbar"
          >
            <ul className="navbar-nav" role="tablist">
              <li className="nav-item">
                <a className="nav-link" href="#how_it_works">
                  How It Works
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#payment">
                  Payment
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#get_started">
                  Get Started
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#use_of_data">
                  Use of Data
                </a>
              </li>
              <li className="nav-item">
                <a className="btn marginRight" onClick={this.navigateToLogin}>
                  {Constants.login}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="btn"
                  onClick={this.navigateToSignUp}
                  target="_blank"
                >
                  {Constants.signup}
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div id="landing">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <h1>
                  <span>
                    Earn $8.00 - $16.00 per hour working at your pace and from
                    any location you choose!
                  </span>
                </h1>

                <div className="green_line first_line" />
                <i className="fas fa-angle-double-down arrow-down" />
              </div>
            </div>
          </div>
        </div>

        <div id="how_it_works" className="mainContainer">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-md-5">
                <h1>
                  Tag That Data offers multiple ways for you to make money{' '}
                </h1>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="wrapper_transcription col-xl-4 col-lg-6 col-md-8">
                <div className="audio_transcription white_bg_box">
                  <img
                    src={audio_trans}
                    alt="Audio transcription icon, speakers"
                    className="float-left"
                  />
                  <h2>Audio Transcription</h2>

                  <div className="clear" />

                  <p className="body">
                    Listen to short audio files and then type out the text of
                    what was said. After typing out the text, you’ll submit the
                    audio file for review and then be presented with a new audio
                    file to transcribe next. The more files you transcribe, the
                    more money you make.
                  </p>
                </div>

                <img
                  src={pic_1}
                  id="how-it-works-bg-img"
                  className="green-glow"
                  alt="Lady writing"
                />
              </div>
            </div>
          </div>

          <div className="container-fluid ">
            <div className="row justify-content-center">
              <div className="wrapper_recording col-xl-4 col-lg-6 col-md-8">
                <div className="audio_recording white_bg_box">
                  <img
                    src={audio_rec}
                    alt="Audio recording Icon, microphone"
                    className="float-left"
                  />
                  <h2>Audio Recording</h2>

                  <div className="clear" />

                  <p className="body ">
                    Record yourself reading a short line of text. The recording
                    will be uploaded for review, and then you’ll see a new line
                    of text to record. Like the audio transcription, the more
                    audio files you create, the more money you make.{' '}
                  </p>
                </div>

                <img
                  src={pic_2}
                  id="how-it-works-bg-img-2"
                  className="green-glow"
                  alt="Lady listening"
                />
              </div>
            </div>

            <div className="clear" />
            <div className="separation-20" />

            <div className="stored_data col-lg-5">
              <h3>
                All audio recording data will be stored anonymously, and you’ll
                be paid for every audio recording transcribed or audio recording
                you generate.
              </h3>
            </div>
          </div>
        </div>

        <div id="payment" className="mainContainer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 left_payment">
                <div className="wrapper_payment_title">
                  <h1>
                    <span>
                      Transcribing and recording data is simple, easy, and fast.
                    </span>
                  </h1>
                </div>
              </div>

              <div className="col-md-6 right_payment bg_green">
                <h3>
                  Enjoy flexibility and freedom in your work. With Tag That
                  Data, you can earn money in your spare time and at your own
                  pace.{' '}
                </h3>

                <div className="wrapper_payment_bullets">
                  <div className="icon_payment_bullet">
                    <i className="fas fa-map-marker-alt" />
                  </div>
                  <div className="body_payment_bullet">
                    <p className="body_bigger">
                      Wherever you are, and whatever you’re doing, why not make
                      some money when you have a few extra moments?{' '}
                    </p>
                  </div>
                </div>

                <div className="wrapper_payment_bullets">
                  <div className="icon_payment_bullet">
                    <i className="fas fa-calendar-alt" />
                  </div>
                  <div className="body_payment_bullet">
                    <p className="body_bigger">
                      In order to keep the work as engaging as possible,{' '}
                      <span className="white">
                        Tag That Data makes weekly payments to all users
                      </span>
                      . Need money in a pinch, get paid in days!
                    </p>
                  </div>
                </div>

                <div className="wrapper_payment_bullets">
                  <div className="icon_payment_bullet">
                    <i className="fas fa-award" />
                  </div>
                  <div className="body_payment_bullet">
                    <p className="body_bigger">
                      Tag That Data also keeps things engaging by occasionally
                      offering temporary features for other types of data
                      tagging.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="get_started" className="mainContainer">
          <div className="container-fluid">
            <div className="row section-title">
              <div className="col-sm-12 text-center">
                <h1>Three easy steps for getting started:</h1>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="wrapper_steps col-xl-4 col-lg-6 col-md-8">
                <div className="white_bg_box">
                  <div className="text-center">
                    <p className="body_bigger">
                      If you’re thinking this might be interesting and you’d
                      like to give it a try, click here to sign up:
                    </p>
                    <div className="separation-20" />

                    <a
                      className="btn"
                      onClick={this.navigateToSignUp}
                      target="_blank"
                    >
                      {Constants.signup}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-6 col-md-8">
                <div className="three_steps bg_green">
                  <h3>There are three easy steps for getting paid:</h3>
                  <div className="wrapper_steps_bullets">
                    <div className="icon_steps_bullet">
                      <i className="fas fa-clipboard-list fa-2x" />
                    </div>
                    <div className="body_steps_bullet">
                      <p className="body_bigger">Create your free account.</p>
                    </div>
                  </div>
                  <div className="wrapper_steps_bullets">
                    <div className="icon_steps_bullet">
                      <i className="fas fa-volume-up fa-2x" />
                    </div>
                    <div className="body_steps_bullet">
                      <p className="body_bigger">Transcribe or record audio.</p>
                    </div>
                  </div>
                  <div className="wrapper_steps_bullets">
                    <div className="icon_steps_bullet">
                      <i className="fas fa-hand-holding-usd fa-2x" />
                    </div>
                    <div className="body_steps_bullet">
                      <p className="body_bigger">Get paid in days.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row that-simple justify-content-center">
              <div className="col-xl-4 col-lg-6 col-md-8">
                <div className="white_bg_box text-center">
                  <p className="body_bigger">
                    Yes, the process really is that simple.
                  </p>

                  <div className="separation-20" />

                  <div className="text-center">
                    <a
                      className="btn"
                      onClick={this.navigateToSignUp}
                      target="_blank"
                    >
                      {Constants.signup}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="use_of_data" className="mainContainer">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-md-6 left_use_of_data" />
              <div className="col-md-6 right_use_of_data">
                <h1>{"Here's how the data is used:"}</h1>
                <p className="body">
                  <span className="green-text">Tag That Data</span> uses audio
                  recordings and transcriptions to train machine learning
                  software for conversational artificial intelligence (AI)
                  products. Examples, but not current customers, include
                  products like Apple’s Siri™ or Amazon’s Alexa™. The data added
                  to the audio files helps companies train their software
                  programs to recognize their customer audio. The more accurate
                  the data is, the more it will help the conversational AI to
                  operate more accurately. For example, more accurate data means
                  better understanding a song you requested, movie you wanted to
                  see or food you wanted to order. The better the data, the
                  better the software can understand a customer’s requests.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="summary" className="mainContainer">
          <div className="container-fluid ">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h1>
                  If you’d like to earn $8.00 - $16.00 per hour working at your
                  pace and from any location you choose, sign up for your Tag
                  That Data account today!
                </h1>

                <div className="separation-20" />

                <div className="text-center">
                  <a
                    onClick={this.navigateToSignUp}
                    target="_blank"
                    className="btn btn-lg"
                  >
                    {Constants.signup}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return { score: state.score };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
