import React, { Component, CSSProperties, Dispatch } from 'react';
// import LazyLoadingComponent from "../../utils/LazyLoadingComponent";
import $ from 'jquery';
import './LoginComponent.css';
import Constants from '../../utils/Constants';
import Api from '../../api/Api';
import loginImg from '../../theme/images/login_img.png';
import CustomTextField from '../CustomTextField';
import Checkbox from 'material-ui/Checkbox';
import CircleProgressComponent from '../CircleProgressComponent';
import logoNavbar from '../../theme/images/logo_navbar.png';
import { connect } from 'react-redux';
import { logout } from '../../actions';
import { singleChoiceAlertDialogComponent } from '../../utils/DialogsUtils';
import log from '../../utils/logger';
import { isLoggedIn, validateEmail } from '../../utils/AppUtils';

let component: any;
let enterKeyHit = false;

type LoginComponentProps = {
  history?: any;
};

type LoginComponentState = {
  loginCardTop: string;
  open: boolean;
  password: string;
  showDashboard: boolean;
  loading: boolean;
  emailError: string;
  passwordError: string;
  loginDisabled: boolean;
  checked: boolean;
  redirectToDashboard: boolean;
  errorMessage: string;
} & ((oldState: any) => { checked: boolean });

class LoginComponent extends Component<
  LoginComponentProps,
  LoginComponentState
> {
  constructor(props: LoginComponentProps) {
    super(props);
    component = this;
    this.state = {
      loginCardTop: '578px',
      open: false,
      password: '',
      showDashboard: false,
      loading: false,
      emailError: '',
      passwordError: '',
      loginDisabled: true,
      checked:
        sessionStorage.getItem(Constants.LOGGED_IS_REMEMBER_LOGIN) === 'true'
          ? true
          : false,
      redirectToDashboard: false,
      errorMessage: '',
    };
    this.onLogin = this.onLogin.bind(this);
    this.onChecked = this.onChecked.bind(this);
    this.onForgotPassword = this.onForgotPassword.bind(this);
    this.gotoSignUp = this.gotoSignUp.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (isLoggedIn()) {
      // log.info(`isLoggedIn: true`);
      component.props.history.push('/dashboard');
    } else {
      // log.info(`isLoggedIn false`);
      //this.props.logout()
    }
  }

  // componentDidMount() {
  //   // for temporarily disable login
  //   component.props.history.push({
  //     pathname: "/home",
  //   });
  //   return;
  // }

  onChange(event: any) {
    let isEmailValid = true;
    let isPasswordValid = true;
    const id = event.target.id;
    const email = $('#email').val()?.toLocaleString();
    const pwd = $('#password').val()?.toLocaleString();
    // log.info(`Login id: ${event.target.id} value: ${event.target.value}`);

    if (id === 'email') {
      isEmailValid = false;
      if (email && email.length > 0 && validateEmail(email)) {
        isEmailValid = true;
      }
    }

    if (id === 'password') {
      isPasswordValid = false;
      if (pwd && pwd.length < 6) {
        if (pwd.length === 0) {
          component.setState({ passwordError: Constants.enter_password });
        } else {
          component.setState({
            passwordError: Constants.enter_password_6_characters,
          });
        }
      } else {
        component.setState({ passwordError: '' });
        isPasswordValid = true;
      }
    }

    const mailError = isEmailValid ? '' : Constants.enter_valid_email;
    const pwdError = isPasswordValid
      ? ''
      : pwd?.length === 0
      ? Constants.enter_password
      : Constants.enter_password_6_characters;

    if (isEmailValid && isPasswordValid) {
      component.setState({
        loginDisabled: false,
        passwordError: pwdError,
        emailError: mailError,
      });
    } else {
      component.setState({
        loginDisabled: true,
        passwordError: pwdError,
        emailError: mailError,
      });
    }
  }

  onChecked() {
    this.setState((oldState: any) => {
      return {
        checked: !oldState.checked,
      };
    });
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleKeyEvent(event: any) {
    // log.info("EVENT", event)
    if (event.key === 'Enter' && !enterKeyHit) {
      component.onLogin(event);
      enterKeyHit = true;
    }
  }

  async onLogin(event: any) {
    event.preventDefault();
    const email = $('#email').val()?.toLocaleString();
    const pwd = $('#password').val()?.toLocaleString();
    if (email && pwd) {
      if (email.length === 0 || !validateEmail(email) || pwd.length < 6) {
        if (email.length === 0 || !validateEmail(email)) {
          component.setState({ emailError: Constants.enter_valid_email });
        }
        if (pwd && pwd.length < 6) {
          if (pwd.length === 0) {
            component.setState({ passwordError: Constants.enter_password });
          } else {
            component.setState({
              passwordError: Constants.enter_password_6_characters,
            });
          }
        }
      } else {
        this.setState({ loading: true });
        await Api.doLogin(email, pwd)
          .then((data: any) => {
            if (data && data.http_status) {
              log.debug('Login Response', data);
              if (data.http_status === 200) {
                sessionStorage.setItem(Constants.LOGGED_IN, 'true');
                const milliseconds = new Date().getTime() + 24 * 60 * 60 * 1000;
                sessionStorage.setItem(
                  Constants.LOGGED_TIME,
                  milliseconds.toString(),
                );
                sessionStorage.setItem(
                  Constants.LOGGED_USER,
                  JSON.stringify(data),
                );
                localStorage.setItem(Constants.PREVIOUS_AUDIO_STATUS, 'false');
                if (this.state.checked) {
                  sessionStorage.setItem(
                    Constants.LOGGED_IS_REMEMBER_LOGIN,
                    'true',
                  );
                  sessionStorage.setItem(
                    Constants.LOGGED_EMAIL,
                    email ? email : '',
                  );
                } else {
                  sessionStorage.setItem(
                    Constants.LOGGED_IS_REMEMBER_LOGIN,
                    'false',
                  );
                  sessionStorage.setItem(Constants.LOGGED_EMAIL, '');
                }
                component.props.history.push(
                  '/dashboard/collections/calibration',
                );
              } else if (data.http_status === 202) {
                this.setState({
                  open: true,
                  loading: false,
                  errorMessage: data.message,
                });
              }
            } else {
              this.setState({
                open: true,
                loading: false,
                errorMessage: Constants.generic_message,
              });
            }
          })
          .catch(reason => {
            log.info(JSON.stringify(reason.message));
            this.setState({
              open: true,
              loading: false,
              errorMessage: Constants.generic_message,
            });
          });
      }
    }
  }

  onForgotPassword() {
    this.props.history.push('/forgot');
  }

  gotoSignUp() {
    this.props.history.push('/signup');
    // this.showLoginSignUpDisableAlert(Constants.signup_disable_message)
  }

  showLoginSignUpDisableAlert(message: string) {
    singleChoiceAlertDialogComponent(message).then(
      // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (params: any) => {
        log.debug(this.props);
        // window.location = "https://tagthatdata.com";
      },
      () => 0,
    );
  }

  render() {
    const errorStyle: CSSProperties = {
      textAlign: 'center',
    };

    return (
      <div className="newLoginScreen">
        <nav className="navbar">
          <a className="navbar-brand" href="/">
            <img src={logoNavbar} alt="Tag That Data navigation logo" />
          </a>
          <div className="topRightMenu">
            <ul className="navbar-nav" role="tablist">
              <li className="nav-item linkTextSec">Do you need an Account?</li>
              <li className="nav-item">
                <a className="btn marginRight" onClick={this.gotoSignUp}>
                  {Constants.signup}
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="bodyContent">
          <div className="loginImgSec">
            <img src={loginImg} className="green-glow" alt="Login Page Image" />
          </div>
          <div className="loginFormSec">
            <form>
              <h1 className="LoginHeadText">{Constants.login}</h1>

              <CustomTextField
                id="email"
                fullWidth={true}
                onKeyDown={this.handleKeyEvent}
                onChange={this.onChange}
                errorText={this.state.emailError}
                value={
                  sessionStorage.getItem(Constants.LOGGED_IS_REMEMBER_LOGIN) ===
                  'true'
                    ? sessionStorage.getItem(Constants.LOGGED_EMAIL)?.toString()
                    : ''
                }
                floatingLabelText={Constants.email}
                className="formControl"
                errorStyle={errorStyle}
              />
              <CustomTextField
                id="password"
                onChange={this.onChange}
                errorText={this.state.passwordError}
                onKeyDown={this.handleKeyEvent}
                floatingLabelText={Constants.password}
                type="password"
                className="formControl"
                errorStyle={errorStyle}
              />

              <div className="RememberMeText">
                <Checkbox
                  id="remember_me"
                  className="RememberMe"
                  checked={this.state.checked}
                  label={Constants.remember_me}
                  onCheck={this.onChecked.bind(this)}
                />
                <div
                  className="forgot-password"
                  onClick={this.onForgotPassword}
                >
                  {Constants.forgot_password}
                </div>
              </div>
              <div className="LoginBtnRowSec">
                <button
                  className="btn"
                  // label={Constants.login}
                  style={
                    this.state.loading
                      ? {
                          display: 'none',
                        }
                      : {
                          display: 'inline-block',
                        }
                  }
                  onClick={this.onLogin}
                >
                  {Constants.login}
                </button>

                <button
                  style={
                    this.state.loading
                      ? {
                          display: 'inline-block',
                          textAlign: 'center',
                        }
                      : {
                          display: 'none',
                        }
                  }
                  className="btn loginLoading"
                >
                  <CircleProgressComponent
                    color="#FFFFFF"
                    size={25}
                    thickness={2}
                  />
                </button>
                <div className="msgSec">{this.state.errorMessage}</div>
              </div>
            </form>
          </div>
          <div className="clear" />
        </div>
        <div className="footerSec">
          Copyright © 2018 by <span>Tag That Data</span>. All content on this
          website is proprietary, copyrighted, and owned or licensed by{' '}
          <span>Tag That Data</span>. Unauthorized use of trademarks or content
          from this website is prohibited. All rights are reserved.
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return { score: state.score };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
