import React, { Component } from 'react';
import $ from 'jquery';
import './ResetPasswordComponent.css';
import Constants from '../../utils/Constants';
import Api from '../../api/Api';
import loginImg from '../../theme/images/login_img.png';
import CustomTextField from '../CustomTextField';
import CircleProgressComponent from '../CircleProgressComponent';
import logoNavbar from '../../theme/images/logo_navbar.png';
import { singleChoiceWithoutAutoCloseAlertDialogComponent } from '../../utils/DialogsUtils';
import log from '../../utils/logger';
import { isLoggedIn } from '../../utils/AppUtils';

let component: any;
let enterKeyHit = false;

type ResetPasswordComponentProps = {
  match: Record<string, any>;
  history: any;
};

type ResetPasswordComponentState = {
  passwordError: string;
  confirmPasswordError: string;
  loginDisabled: boolean;
  loading: boolean;
  errorMessage: string;
  confirmPassword?: string;
  redirectToDashboard: boolean;
  open: boolean;
  loginCardTop: string;
  password: string;
  showDashboard: boolean;
};

class ResetPasswordComponent extends Component<
  ResetPasswordComponentProps,
  ResetPasswordComponentState
> {
  constructor(props: ResetPasswordComponentProps) {
    super(props);
    component = this;
    this.state = {
      loginCardTop: '578px',
      open: false,
      password: '',
      showDashboard: false,
      loading: false,
      passwordError: '',
      confirmPasswordError: '',
      loginDisabled: true,
      redirectToDashboard: false,
      errorMessage: '',
    };
    this.resetPassword = this.resetPassword.bind(this);
    this.gotoSignUp = this.gotoSignUp.bind(this);
  }

  componentDidMount() {
    // for temporarily disable login
    // component.props.history.push({
    //   pathname: '/home'
    // })
    // return
    if (isLoggedIn()) {
      this.props.history.push('/dashboard');
    }
  }

  onChange(event: any) {
    let isConfirmPasswordValid = true;
    let isPasswordValid = true;
    const id = event.target.id;
    const confirmPassword = $('#confirm_password').val()?.toLocaleString();
    const pwd = $('#password').val()?.toLocaleString();
    if (id === 'password') {
      isPasswordValid = false;
      if (pwd && pwd.length < 6) {
        if (pwd.length === 0) {
          component.setState({ passwordError: Constants.enter_password });
        } else {
          component.setState({
            passwordError: Constants.enter_password_6_characters,
          });
        }
      } else {
        component.setState({ passwordError: '' });
        isPasswordValid = true;
      }
    }
    if (id === 'confirm_password') {
      isConfirmPasswordValid = false;
      if (confirmPassword && confirmPassword.length < 6) {
        if (confirmPassword.length === 0) {
          component.setState({
            confirmPasswordError: Constants.enter_password,
          });
        } else {
          component.setState({
            confirmPasswordError: Constants.enter_password_6_characters,
          });
        }
      } else {
        component.setState({ passwordError: '' });
        isConfirmPasswordValid = true;
      }
    }

    const confirmPasswordError = isConfirmPasswordValid
      ? ''
      : confirmPassword?.length === 0
      ? Constants.enter_password
      : Constants.enter_password_6_characters;

    const pwdError = isPasswordValid
      ? ''
      : pwd?.length === 0
      ? Constants.enter_password
      : Constants.enter_password_6_characters;

    if (isConfirmPasswordValid && isPasswordValid) {
      component.setState({
        loginDisabled: false,
        passwordError: pwdError,
        confirmPasswordError: confirmPasswordError,
      });
    } else {
      component.setState({
        loginDisabled: true,
        passwordError: pwdError,
        confirmPasswordError: confirmPasswordError,
      });
    }
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleKeyEvent(event: any) {
    // log.debug("EVENT", event)
    if (event.key === 'Enter' && !enterKeyHit) {
      component.resetPassword(event);
      enterKeyHit = true;
    }
  }

  async resetPassword(event: any) {
    event.preventDefault();
    const confirmPassword = $('#confirm_password').val()?.toLocaleString();
    const pwd = $('#password').val()?.toLocaleString();
    if (pwd && confirmPassword) {
      if (
        confirmPassword.length < 6 ||
        pwd.length < 6 ||
        pwd !== confirmPassword
      ) {
        if (confirmPassword.length < 6 || pwd.length < 6) {
          if (confirmPassword.length < 6) {
            if (confirmPassword.length === 0) {
              component.setState({
                confirmPasswordError: Constants.enter_password,
              });
            } else {
              component.setState({
                confirmPasswordError: Constants.enter_password_6_characters,
              });
            }
          }
          if (pwd.length < 6) {
            if (pwd.length === 0) {
              component.setState({ passwordError: Constants.enter_password });
            } else {
              component.setState({
                passwordError: Constants.enter_password_6_characters,
              });
            }
          }
        } else {
          this.setState({
            loading: false,
            errorMessage: Constants.reset_pw_confirm_password_match_error,
          });
        }
      } else {
        this.setState({
          loading: true,
          errorMessage: '',
          passwordError: '',
          confirmPassword: '',
        });

        const token = this.props.match.params.token;
        log.debug('Token', token);
        await Api.resetPassword(pwd, token)
          .then((data: any) => {
            if (data !== undefined && data.http_status) {
              log.debug('Login Response', data);
              if (data.http_status === 200) {
                this.setState({ redirectToDashboard: true, loading: false });
                //
                log.debug('successfully logged in');
                //this.setState({ redirectToDashboard: true, loading: false })
                //              log.debug("successfully logged in")
                singleChoiceWithoutAutoCloseAlertDialogComponent(
                  data.message,
                ).then(
                  () => {
                    component.props.history.push('/login');
                  },
                  () => 0,
                );
              } else if (data.http_status === 202) {
                this.setState({
                  open: true,
                  loading: false,
                  errorMessage: data.message,
                });
              } else if (data.http_status === 203) {
                singleChoiceWithoutAutoCloseAlertDialogComponent(
                  data.message,
                ).then(
                  () => {
                    component.props.history.push('/login');
                  },
                  () => 0,
                );
              }
            } else {
              this.setState({
                open: true,
                loading: false,
                errorMessage: Constants.generic_message,
              });
            }
          })
          .catch(reason => {
            log.debug(reason.message);
            this.setState({
              open: true,
              loading: false,
              errorMessage: Constants.generic_message,
            });
          });
      }
    }
  }

  gotoSignUp() {
    component.props.history.push('/signup');
  }

  gotoLogin() {
    component.props.history.push('/login');
    // component.showLoginSignUpDisableAlert(Constants.login_disable_message);
  }

  render() {
    return (
      <div className="newLoginScreen">
        <nav className="navbar">
          <a className="navbar-brand" href="/">
            <img src={logoNavbar} alt="Tag That Data navigation logo" />
          </a>
          <div className="topRightMenu">
            <ul className="navbar-nav" role="tablist">
              <li className="nav-item linkTextSec">Ready to try again?</li>
              <li className="nav-item">
                <a className="btn marginRight" onClick={this.gotoLogin}>
                  {Constants.login}
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="bodyContent">
          <div className="loginImgSec">
            <img src={loginImg} className="green-glow" alt="Login Page Image" />
          </div>
          <div className="loginFormSec">
            <form>
              <h1 className="ResetHeadText">{Constants.reset_pw_page_title}</h1>

              <CustomTextField
                id="password"
                fullWidth={true}
                onKeyDown={this.handleKeyEvent}
                onChange={this.onChange}
                errorText={this.state.passwordError}
                type="password"
                floatingLabelText={Constants.reset_pw_password}
                className="formControl"
              />
              <CustomTextField
                id="confirm_password"
                onChange={this.onChange}
                errorText={this.state.confirmPasswordError}
                onKeyDown={this.handleKeyEvent}
                floatingLabelText={Constants.reset_pw_confirm_password}
                type="password"
                className="formControl"
              />

              <div className="LoginBtnRowSec">
                <button
                  className="btn"
                  // label={Constants.login}
                  style={
                    this.state.loading
                      ? {
                          display: 'none',
                        }
                      : {
                          display: 'inline-block',
                        }
                  }
                  onClick={this.resetPassword}
                >
                  {' '}
                  {Constants.reset_pw_page_title}{' '}
                </button>

                <button
                  style={
                    this.state.loading
                      ? {
                          display: 'inline-block',
                          textAlign: 'center',
                        }
                      : {
                          display: 'none',
                        }
                  }
                  className="btn loginLoading ResetLoading"
                >
                  <CircleProgressComponent
                    color="#FFFFFF"
                    size={25}
                    thickness={2}
                  />
                </button>
                <div className="msgSec">{this.state.errorMessage}</div>
              </div>
            </form>
          </div>
          <div className="clear" />
        </div>
        <div className="footerSec">
          Copyright © 2018 by <span>Tag That Data</span>. All content on this
          website is proprietary, copyrighted, and owned or licensed by{' '}
          <span>Tag That Data</span>. Unauthorized use of trademarks or content
          from this website is prohibited. All rights are reserved.
        </div>
      </div>
    );
  }
}

export default ResetPasswordComponent;
