/* eslint-disable @typescript-eslint/naming-convention */
import { Button, TableContainer } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import Api from '../../../api/Api';
import awardOne from '../../../theme/images/award_one.svg';
import awardThree from '../../../theme/images/award_three.svg';
import awardTwo from '../../../theme/images/award_two.svg';
// import CustomPaginationComponent from '../../table/CustomPaginationComponent';
import PaginationHeaderComponent from '../../table/PaginationHeaderComponent';
import Styles from '../../table/PaginationStyles';
// import categoryFilterOptions from "./PaymentHistoryFilterPopUp";
import './LeaderBoardStyles.css';
import CircularProgressComponent from '../../../utils/CircularProgressComponent';
// const moment = require('moment');
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import log from '../../../utils/logger';

TimeAgo.addLocale(en);

type LeaderBoardComponentProps = {
  classes: Record<string, string | undefined>;
};

type LeaderBoardComponentState = {
  data: any[];
  errorMessage: string;
  filter: undefined;
  loading: boolean;
  metaData: undefined;
  order: string;
  orderBy: string;
  page: number;
  pageNumber: number;
  pageSize: number;
  rowsPerPage: number;
  totalEarnings: number;
  userId: string;
  userData: any[];
  error: string;
  userRank: string;
  userAvatar: string;
  userName: string;
  selectedFilterValue: number;
  filterArray: string[];
} & ((reason: any) => void);

class LeaderBoardComponent extends React.Component<
  LeaderBoardComponentProps,
  LeaderBoardComponentState
> {
  state = {
    data: [],
    errorMessage: '',
    filter: undefined,
    loading: true,
    metaData: undefined,
    order: 'asc',
    orderBy: 'payment',
    page: 0,
    pageNumber: 1,
    pageSize: 10,
    rowsPerPage: 10,
    totalEarnings: 0,
    userId: '',
    userData: [],
    error: '',
    userRank: '',
    userAvatar: '',
    userName: '',
    selectedFilterValue: 1,
    filterArray: ['All Time', 'This Week', 'This Month'],
  };
  constructor(props: LeaderBoardComponentProps) {
    super(props);
  }

  rows = [
    {
      id: 'rank',
      sort: false,
      numeric: false,
      disablePadding: true,
      label: 'Rank',
    },
    {
      id: 'name',
      sort: false,
      numeric: true,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'points',
      sort: false,
      numeric: true,
      disablePadding: false,
      label: 'Points',
    },
    {
      id: 'member_since',
      sort: false,
      numeric: true,
      disablePadding: false,
      label: 'Member Since',
    },
  ];

  handleRequestSort = (event: any, property: string) => {
    log.debug(event);
    log.debug(property);
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy }, () => {
      //   this.getUsers()
    });
  };

  // handleClick = (event, id) => {
  //   const { selected } = this.state;
  //   const selectedIndex = selected.indexOf(event.target, 2);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  // };

  componentDidMount() {
    this.updatedUserProfile();
  }

  updatedUserProfile() {
    Api.getUserProfile()
      .then(data => {
        if (data && data.http_status) {
          if (data.http_status === 200) {
            this.setState({
              userData: data.data,
              loading: false,
              userId: data.data.id,
              userAvatar: data.data.avatar_url,
              userName: data.data.name,
            });
            const params = {
              // eslint-disable-next-line camelcase
              user_id: this.state.userId,
              timeFrameCondition: this.state.selectedFilterValue,
            };
            this.leaderBoardDataList(params);
            log.debug('status 200', this.state.userData, this.state.userId);
          } else {
            log.debug('status 202');
            this.setState({ loading: false, data: [] });
          }
        } else {
          log.debug('status 204');
          this.setState({ loading: false, errorMessage: data.message });
        }
      })
      .catch(reason => {
        log.debug('Exception', reason);
        this.setState({ loading: false, errorMessage: reason });
      });
  }

  leaderBoardDataList(params?: any) {
    log.debug('Params', params, this.state.userId);
    Api.getLeaderBoardData(params)
      .then(data => {
        log.debug(' leader Data', data, data !== undefined);
        this.setState({ loading: true });
        if (data && data.http_status) {
          log.debug('getLeaderBoardData', this.state.data, this.state.loading);
          if (data.http_status === 200) {
            this.setState({
              userRank: data.user_rank,
              data: data.leader_board_data,
              loading: false,
            });
            log.debug(
              'getLeaderBoardData',
              this.state.data,
              this.state.loading,
            );
          } else {
            log.debug('status 202');
            this.setState({ loading: false, data: [] });
          }
        } else {
          log.debug('status 204');
          this.setState({
            loading: false,
            errorMessage: data ? data.message : '',
          });
        }
      })
      .catch(reason => {
        log.debug('Exception', reason);
        this.setState({ loading: false, errorMessage: reason });
      });
  }

  onFilterValueChange = (event: any, data: any) => {
    this.setState({
      selectedFilterValue: data.props.value,
    });
    const params = {
      timeFrameCondition: data.props.value,
      // eslint-disable-next-line camelcase
      user_id: this.state.userId,
    };
    this.leaderBoardDataList(params);
    log.debug('filterOptions', params);
  };

  handleChangePage = (event: any, page: number) => {
    this.setState({ page: page }, () => {
      log.debug('PAGE', this.state.page);
      this.leaderBoardDataList();
    });
  };

  handleChangeRowsPerPage = (event: any) => {
    // tslint:disable-next-line:radix
    this.setState(
      { rowsPerPage: parseInt(event.target.value), page: 0 },
      () => {
        this.leaderBoardDataList();
      },
    );
  };

  render() {
    const { classes } = this.props;
    // const { data, order, orderBy, rowsPerPage, page } = this.state;
    const { data, order, orderBy } = this.state;
    log.debug('State', this.state);

    return (
      <div className={classes.paginationContainer}>
        <div className={classes.filterWrapper}>
          <Button className={classes.rankClass} disableTouchRipple={true}>
            {this.state.userAvatar ? (
              <img className="rankAvatar" src={this.state.userAvatar} />
            ) : (
              <div className="rankChar"> {this.state.userName.charAt(0)} </div>
            )}
            Your Rank : <b>#{this.state.userRank} </b>
          </Button>
          <FormControl className="selectTime" variant="outlined">
            <InputLabel
              className="selectTime"
              id="demo-simple-select-outlined-label"
            >
              Select
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.selectedFilterValue}
              onChange={this.onFilterValueChange}
              labelWidth={120}
              className="selectTime"
              color="primary"
            >
              {this.state.filterArray.map((label, index) => (
                <MenuItem key={index} value={index}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Paper
          className={classes.root}
          style={{
            maxHeight: '600px',
            // overflow: 'scroll',
            position: 'relative',
          }}
        >
          <div className={classes.tableWrapper}>
            <TableContainer className={classes.leaderboardContainer}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <PaginationHeaderComponent
                  headers={this.rows}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                />
                {this.state.loading ||
                !this.state.data ||
                this.state.data.length === 0 ? (
                  <TableBody className={classes.loaderContainer}>
                    <TableRow>
                      <TableCell
                        style={{
                          display: 'table-cell',
                          height: '100%',
                          width: '100%',
                          textAlign: 'center',
                          color: '#d4d4d4',
                        }}
                      >
                        {this.state.data.length === 0 && !this.state.loading ? (
                          <p> No Data Found.</p>
                        ) : (
                          <CircularProgressComponent
                            className={classes.bodyButtonProgress}
                            color="inherit"
                            thickness={2}
                            size={30}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {this.state.data &&
                      this.state.data.map((user: any, index: number) => {
                        const timeAgo = new TimeAgo('en-US');
                        const userFirstLetter = user.name.charAt(0);
                        // var realtiveTime = moment(user.signup_date).fromNow()
                        const realtiveTime = !user.signup_date
                          ? 'Invalid Date'
                          : timeAgo.format(new Date(user.signup_date));
                        return (
                          <TableRow
                            hover={true}
                            key={index}
                            className="tableRowBG"
                            // tslint:disable-next-line:jsx-no-lambda
                            // onClick={event => this.handleClick(event, user.id)}
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell
                              style={{ minWidth: '60px', textAlign: 'center' }}
                              className="tableCellStyles"
                              align="left"
                              scope="row"
                            >
                              {index === 0 ? (
                                <img src={awardOne} className="awardStyles" />
                              ) : index === 1 ? (
                                <img src={awardTwo} className="awardStyles" />
                              ) : index === 2 ? (
                                <img src={awardThree} className="awardStyles" />
                              ) : (
                                index + 1
                              )}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: '100px' }}
                              className="tableCellStyles"
                              align="left"
                              scope="row"
                            >
                              {user.avatar_url ? (
                                <img
                                  className="leaderAvatar"
                                  src={user.avatar_url}
                                />
                              ) : (
                                <div className="leaderavatarName">
                                  {' '}
                                  {userFirstLetter}{' '}
                                </div>
                              )}

                              <div className="userNameColor"> {user.name}</div>
                            </TableCell>
                            <TableCell
                              style={{ minWidth: '60px' }}
                              className="tableCellStyles"
                              align="left"
                              scope="row"
                            >
                              {user.points}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: '100px' }}
                              className="tableCellStyles"
                              align="left"
                              scope="row"
                            >
                              {realtiveTime}
                              <div className="relativeTime">
                                {!user.signup_date
                                  ? ''
                                  : new Date(
                                      user.signup_date,
                                    ).toLocaleDateString()}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withStyles(Styles)(LeaderBoardComponent);
