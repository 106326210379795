/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';
import { isValidAge, getYearsOfBirth } from '../../utils/AppUtils';
import Constants from '../../utils/Constants';
import Api from '../../api/Api';
import CircleProgressComponent from '../CircleProgressComponent';
import $ from 'jquery';
import { validateEmail } from '../../utils/AppUtils';
import CustomTextField from '../CustomTextField';
import './signup.css';
import { singleChoiceAlertDialogComponent } from '../../utils/DialogsUtils';
import logo_navbar from '../../theme/images/logo_navbar.png';
import signUp_img from '../../theme/images/signUp_img.png';
import Colors from '../../theme/Colors';
import log from '../../utils/logger';
import { isLoggedIn } from '../../utils/AppUtils';

let component: any;

type SignUpComponentProps = {
  history?: any;
};

type SignUpComponentState = {
  value: number;
  response: any;
  isLoading: boolean;
  data_of_birth: string;
  years: any[];
  name: string;
  isDoingSignUp: boolean;
  year_of_birth: string;
  state_id: string;
  language_id: string;
  gender_id: string;
  ethnicity_id: string;
  emailError: string;
  checked: boolean;
  passwordError: string;
  confirmPasswordError: string;
  password: string;
  stateError: string;
  genderError: string;
  ethnicityError: string;
  languageError: string;
  Error: string;
  yearOfBirthError: string;
  nameError: string;
  apiError: string;
  languageOtherOptionError: string;
  open: boolean;
  selected_country_code: string;
} & (() => void);

class SignUpComponent extends Component<
  SignUpComponentProps,
  SignUpComponentState
> {
  state = {
    value: 1,
    response: null,
    isLoading: true,
    data_of_birth: '',
    years: getYearsOfBirth(),
    name: '',
    isDoingSignUp: false,
    year_of_birth: '',
    state_id: '',
    language_id: '',
    gender_id: '',
    ethnicity_id: '',
    emailError: '',
    checked: false,
    passwordError: '',
    confirmPasswordError: '',
    phoneNumberError: '',
    password: '',
    stateError: '',
    genderError: '',
    ethnicityError: '',
    languageError: '',
    Error: '',
    yearOfBirthError: '',
    nameError: '',
    apiError: '',
    languageOtherOptionError: '',
    open: false,
    selected_country_code: '+1',
  };
  constructor(props: SignUpComponentProps) {
    super(props);
    component = this;
    this.gotoLogin = this.gotoLogin.bind(this);
  }

  doSignUp(event: any) {
    event.preventDefault();
    let isYearOfBirthEntered = true;
    let isNameEntered = true;
    let isPhoneNumberEntered = true;
    let isEmailEntered = true;
    let isPasswordEntered = true;
    let isConfirmPasswordEntered = true;
    let isLanguageEntered = true;
    let yearOfBirthError = '';
    let nameError = '';
    let passwordError = '';
    let emailError = '';
    let confirmPasswordError = '';
    const stateError = '';
    let languageOtherOptionError = '';
    const ethnicityError = '';
    const genderError = '';
    let languageError = '';
    let languageOtherOption = '';
    let phoneNumberError = '';
    const email = $('#email').val()?.toLocaleString();
    const pwd = $('#password').val()?.toLocaleString();
    const name = $('#name').val()?.toLocaleString();
    const phoneNumber = $('#phone_number').val()?.toLocaleString();
    const confirmPassword = $('#confirm_password').val()?.toLocaleString();
    const mailingAddress = $('#mailing_address').val()?.toLocaleString();
    const otherNativeLanguageText = $('#native_language_code_id')
      .val()
      ?.toLocaleString();
    if (name?.length === 0) {
      isNameEntered = false;
      nameError = Constants.signUp_empty_field_error;
    }
    if (
      phoneNumber?.length === 0 ||
      !component.isValidPhoneNumber(phoneNumber)
    ) {
      isPhoneNumberEntered = false;
      phoneNumberError = Constants.phone_number_error;
    }
    if (email?.length === 0 || (email && !validateEmail(email))) {
      isEmailEntered = false;
      if (email.length === 0) {
        emailError = Constants.signUp_empty_field_error;
      } else {
        emailError = 'Please enter valid email';
      }
    }
    if (pwd?.length === 0 || (pwd && pwd.length < 6)) {
      isPasswordEntered = false;
      if (pwd?.length === 0) {
        passwordError = Constants.signUp_empty_field_error;
      } else {
        passwordError = Constants.enter_password_6_characters;
      }
    }
    if (
      confirmPassword?.length === 0 ||
      (confirmPassword && confirmPassword.length < 6)
    ) {
      isConfirmPasswordEntered = false;
      if (confirmPassword?.length === 0) {
        confirmPasswordError = Constants.signUp_empty_field_error;
      } else {
        confirmPasswordError = Constants.enter_password_6_characters;
      }
    }
    if (component.state.language_id.length === 0) {
      isLanguageEntered = false;
      languageError = Constants.signUp_empty_field_error;
    } else {
      if (
        component.state.language_id ===
        component.state.response.languages[
          component.state.response.languages.length - 1
        ].native_language_id.toString()
      ) {
        if (otherNativeLanguageText && otherNativeLanguageText.length > 0) {
          languageOtherOption = otherNativeLanguageText;
        } else {
          languageOtherOptionError = Constants.signUp_empty_field_error;
          isLanguageEntered = false;
        }
      }
    }
    if (component.state.data_of_birth.length === 0) {
      isYearOfBirthEntered = false;
      yearOfBirthError = Constants.signUp_empty_field_error;
    }
    log.debug(isPhoneNumberEntered.toString());
    if (
      isNameEntered &&
      isEmailEntered &&
      isPasswordEntered &&
      isConfirmPasswordEntered &&
      isEmailEntered &&
      isYearOfBirthEntered &&
      isLanguageEntered &&
      isPhoneNumberEntered
    ) {
      log.debug('ConPassword', confirmPassword);
      if (pwd === confirmPassword) {
        if (!isValidAge(component.state.data_of_birth)) {
          singleChoiceAlertDialogComponent(Constants.age_error_message).then(
            () => {
              log.debug(Constants.age_error_message);
            },
            () => 0,
          );
          return;
        }
        if (!component.state.checked) {
          singleChoiceAlertDialogComponent(
            Constants.terms_conditions_error,
          ).then(
            () => {
              log.debug(Constants.age_error_message);
            },
            () => 0,
          );
          return;
        }
        // component.setState({ apiError: "", isDoingSignUp: true })
        const data = {
          name: name,
          year_of_birth: component.state.data_of_birth,
          mailing_address: mailingAddress,
          native_language: component.state.language_id,
          password: pwd,
          email: email,
          other_native_language: languageOtherOption,
          phone_number: phoneNumber,
          country_code: component.state.selected_country_code,
        };
        log.debug(data);
        component.setState({
          isDoingSignUp: true,
          emailError: '',
          passwordError: '',
          confirmPasswordError: '',
          stateError: '',
          genderError: '',
          ethnicityError: '',
          languageError: '',
          yearOfBirthError: '',
          nameError: '',
          phoneNumberError: '',
          apiError: '',
          languageOtherOptionError: '',
        });
        component.props.history.push({
          pathname: '/agreement',
          state: {
            data: data,
            stateData: component.state,
          },
        });
      } else {
        component.setState({
          apiError: 'Please confirm your password',
          open: true,
        });
      }
    } else {
      log.debug('Error Occurred');
      component.setState({
        nameError: nameError,
        emailError: emailError,
        passwordError: passwordError,
        confirmPasswordError: confirmPasswordError,
        yearOfBirthError: yearOfBirthError,
        phoneNumberError: phoneNumberError,
        stateError: stateError,
        ethnicityError: ethnicityError,
        languageError: languageError,
        genderError: genderError,
        languageOtherOptionError: languageOtherOptionError,
      });
    }
  }

  componentDidMount() {
    // // for temporarily disable signup
    // component.props.history.push({
    //   pathname: '/home'
    // })
    // return
    if (isLoggedIn()) {
      this.props.history.push('/dashboard');
      return;
    }
    if (component.props.history.location.state === undefined) {
      Api.getSignUpDropDownList()
        .then((response: any) => {
          if (
            response &&
            response.http_status &&
            response.http_status === 200
          ) {
            component.setState({ response: response, isLoading: false });
          }
        })
        .catch(() => 0);
    } else {
      const state = component.props.history.location.state.stateData;
      // state.apiError=""
      component.setState(state);
    }
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  onChange(event: any) {
    const value = event.target.value;
    const id = event.target.id;
    // log.debug("Login id" + event.target.id + " value" + event.target.value)
    if (id === 'email') {
      if (value.length > 0 && validateEmail(value)) {
        component.setState({ emailError: '' });
      } else {
        component.setState({
          emailError:
            value.length === 0
              ? Constants.signUp_empty_field_error
              : 'Please enter valid email',
        });
      }
      return;
    }
    if (id === 'name') {
      if (value.length > 0) {
        component.setState({ nameError: '' });
      } else {
        component.setState({ nameError: 'Please enter name' });
      }
    }
    if (id === 'phone_number') {
      if (component.isValidPhoneNumber(value)) {
        if (value.length > 0) {
          component.setState({ phoneNumberError: '' });
        } else {
          component.setState({
            phoneNumberError: Constants.phone_number_error,
          });
        }
      } else {
        component.setState({ phoneNumberError: Constants.phone_number_error });
      }
    }
    if (id === 'password') {
      if (value.length < 6) {
        if (value.length === 0) {
          component.setState({
            passwordError: Constants.signUp_empty_field_error,
          });
        } else {
          component.setState({
            passwordError: Constants.enter_password_6_characters,
          });
        }
      } else {
        component.setState({ passwordError: '' });
      }
    }
    if (id === 'confirm_password') {
      if (value.length < 6) {
        if (value.length === 0) {
          component.setState({
            confirmPasswordError: Constants.signUp_empty_field_error,
          });
        } else {
          component.setState({
            confirmPasswordError: Constants.enter_password_6_characters,
          });
        }
      } else {
        component.setState({ confirmPasswordError: '' });
      }
    }
  }

  onGenderChange(event: any, value: any) {
    log.debug(event.target.value);
    component.setState({ gender_id: value, genderError: '' });
  }

  onEthnicityChange(event: any, index: number, value: string) {
    component.setState({ ethnicity_id: value, ethnicityError: '' });
  }

  updateCheck() {
    this.setState((oldState: any) => {
      return {
        checked: !oldState.checked,
      };
    });
  }

  isValidPhoneNumber(text: string) {
    const phoneNo = /^[0-9\s]*$/;
    if (text.match(phoneNo)) {
      return true;
    }
    return false;
  }

  onStateChange(event: any, index: number, value: string) {
    component.setState({ state_id: value, stateError: '' });
  }

  onLanguageChange(event: any, index: number, value: string) {
    component.setState({ language_id: value, languageError: '' });
  }

  onYearChange(event: any, index: number, value: string) {
    component.setState({ data_of_birth: value, yearOfBirthError: '' });
  }

  onCountryCodeChange(event: any, index: number, value: string) {
    // console.error("Country_code", value)
    // console.error("Country_code", event)
    component.setState({ selected_country_code: value });
  }

  gotoLogin() {
    this.props.history.push('/login');
    // component.showLoginSignUpDisableAlert(Constants.login_disable_message)
  }

  showLoginSignUpDisableAlert(message: string) {
    singleChoiceAlertDialogComponent(message).then(
      // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (params: any) => {
        log.debug(this.props);
        // window.location = "https://tagthatdata.com";
      },
      () => 0,
    );
  }

  render() {
    log.debug(this.state);
    return (
      <div className="newSignUpScreen">
        <nav className="navbar">
          <a className="navbar-brand" href="/">
            <img src={logo_navbar} alt="Tag That Data navigation logo" />
          </a>

          <div className="topRightMenu">
            <ul className="navbar-nav" role="tablist">
              <li className="nav-item linkTextSec">Already have an account?</li>
              <li className="nav-item">
                <a className="btn marginRight" onClick={this.gotoLogin}>
                  {Constants.login}
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <div className="fixedImgSec">
          <div className="leftImgContent">
            <div className="signUpImgSec">
              <img
                src={signUp_img}
                className="green-glow"
                alt="Login Page Image"
              />
            </div>
          </div>
        </div>

        <div className="bodyContent">
          <div className="signUpFormSec">
            {this.state.isLoading && (
              <div
                style={{
                  position: 'absolute',
                  width: '75px',
                  textAlign: 'center',
                  top: '50%',
                  margin: '-20px 0px 0px -37px',
                  left: '50%',
                }}
              >
                <CircleProgressComponent color={Colors.ThemeTagThatDataColor} />
              </div>
            )}
            {!component.state.isLoading && component.state.response && (
              <div>
                <h1 className="SignUpHeadText">
                  Sign up, <span>create your profile</span>!
                </h1>
                <div className="formRowSec">
                  <CustomTextField
                    hintText=""
                    errorText={this.state.nameError}
                    fullWidth={true}
                    value={
                      this.props.history.location.state
                        ? this.props.history.location.state.data.name
                        : ''
                    }
                    id="name"
                    onChange={this.onChange}
                    floatingLabelText={Constants.sign_up_name}
                    className="formControl"
                  />
                </div>
                <div className="formRowSec">
                  <CustomTextField
                    hintText=""
                    id="email"
                    errorText={this.state.emailError}
                    type="email"
                    value={
                      this.props.history.location.state
                        ? this.props.history.location.state.data.email
                        : ''
                    }
                    fullWidth={true}
                    onChange={this.onChange}
                    floatingLabelText={Constants.sign_up_email}
                    className="formControl"
                  />
                </div>

                <div className="formRowSec phoneNumberContainer ">
                  <div className="selectBox">
                    <SelectField
                      className="phoneSelectBox"
                      // className="formControl"
                      autoWidth={true}
                      // errorText={this.state.yearOfBirthError}
                      // displayborder="false"
                      hintText={Constants.sign_up_phone_number_value}
                      // hintStyle={{ color: "#373A3C" }}
                      style={{ color: 'red' }}
                      value={this.state.selected_country_code}
                      // openImmediately={true}
                      onChange={this.onCountryCodeChange}
                    >
                      {Constants.COUNTRY_CODES.map((country, index) => {
                        return (
                          <MenuItem
                            key={index}
                            className="list-item"
                            value={country.dial_code}
                            label={country.dial_code}
                          >
                            {country.name}
                            {'\xa0\xa0'}
                            <b> {'(' + country.dial_code + ')'}</b>
                          </MenuItem>
                        );
                      })}
                    </SelectField>
                  </div>
                  <div className="phoneNumber">
                    <CustomTextField
                      hintText=""
                      errorText={this.state.phoneNumberError}
                      // autoWidth={true}
                      fullWidth={true}
                      type="text"
                      value={
                        this.props.history.location.state
                          ? this.props.history.location.state.data.phone_number
                          : ''
                      }
                      id="phone_number"
                      onChange={this.onChange}
                      floatingLabelText={Constants.sign_up_phone_number}
                      className="formControl"
                    />
                  </div>
                </div>
                <div
                  style={{
                    fontSize: '12px',
                    textAlign: 'left',
                    marginTop: '12px',
                    color: 'black',
                  }}
                >
                  {" We'll text a verification code to this phone number "}
                </div>
                <div className="formRowSec">
                  <CustomTextField
                    hintText=""
                    errorText={this.state.passwordError}
                    value={
                      this.props.history.location.state
                        ? this.props.history.location.state.data.password
                        : ''
                    }
                    id="password"
                    onChange={this.onChange}
                    type="password"
                    fullWidth={true}
                    floatingLabelText={Constants.password}
                    className="formControl"
                  />
                </div>
                <div className="formRowSec">
                  <CustomTextField
                    hintText=""
                    id="confirm_password"
                    errorText={this.state.confirmPasswordError}
                    onChange={this.onChange}
                    value={
                      this.props.history.location.state
                        ? this.props.history.location.state.data.password
                        : ''
                    }
                    type="password"
                    fullWidth={true}
                    floatingLabelText={Constants.sign_up_confirm_password}
                    className="formControl"
                  />
                </div>
                <div className="formRowSec selectBox">
                  <SelectField
                    fullWidth={true}
                    errorText={this.state.yearOfBirthError}
                    hintText={Constants.sign_up_year_of_birth}
                    className="formControlSelect"
                    style={{ color: 'red' }}
                    value={this.state.data_of_birth}
                    onChange={this.onYearChange}
                  >
                    {component.state.years.map(
                      (year: string, index: number) => {
                        return (
                          <MenuItem
                            key={index}
                            className="list-item"
                            value={year}
                            primaryText={year}
                          />
                        );
                      },
                    )}
                  </SelectField>
                </div>
                <div className="formRowSec selectBox">
                  <SelectField
                    fullWidth={true}
                    errorText={this.state.languageError}
                    hintText={Constants.sign_up_native_language}
                    className="formControlSelect"
                    value={this.state.language_id}
                    onChange={this.onLanguageChange}
                  >
                    {component.state.response.languages.map(
                      (language: any, index: number) => {
                        return (
                          <MenuItem
                            key={index}
                            className="list-item"
                            value={language.native_language_id}
                            primaryText={language.native_language_name}
                          />
                        );
                      },
                    )}
                  </SelectField>
                </div>
                {component.state.response.languages[
                  component.state.response.languages.length - 1
                ].native_language_id === this.state.language_id && (
                  <div className="formRowSec">
                    <CustomTextField
                      hintText=""
                      id="native_language_code_id"
                      errorText={this.state.languageOtherOptionError}
                      type="text"
                      value={
                        this.props.history.location.state
                          ? this.props.history.location.state.data
                              .other_native_language
                          : ''
                      }
                      fullWidth={true}
                      onChange={this.onChange}
                      // floatingLabelText={Constants.enter_your_native_language}
                      className="formControl"
                    />
                  </div>
                )}

                <div className="  ">
                  <div
                    className="TermsConCheckBoxSec"
                    style={{ padding: '20px 0px' }}
                  >
                    <Checkbox
                      className="checkBoxSec"
                      checked={this.state.checked}
                      onCheck={this.updateCheck.bind(this)}
                    />
                    <label className="TermsConLink">
                      {"I agree to Tag That Data's™ "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={Constants.termsAndConditions}
                      >
                        {' Terms and Conditions '}
                      </a>
                      {'and'}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={Constants.privacyPolicy}
                      >
                        {' Privacy Policy '}
                      </a>
                    </label>
                    <div className="clear" />
                  </div>
                </div>
                <div className="formRowSec">
                  <div className="ContriSignUpBtnSec">
                    {!this.state.isDoingSignUp && (
                      <button
                        // label={Constants.sign_up_submit}
                        className="btn"
                        style={{
                          display: 'inline-block',
                        }}
                        onClick={this.doSignUp}
                        // primary="true"
                      >
                        {Constants.signup}
                      </button>
                    )}
                    {this.state.isDoingSignUp && (
                      <button
                        style={{
                          display: 'inline-block',
                          textAlign: 'center',
                        }}
                        className="btn loginLoading"
                      >
                        <CircleProgressComponent
                          color="#FFFFFF"
                          size={25}
                          thickness={2}
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="msgSec">{this.state.apiError}</div>
          </div>
        </div>

        <div className="footerSec">
          Copyright © 2018 by <span>Tag That Data</span>. All content on this
          website is proprietary, copyrighted, and owned or licensed by{' '}
          <span>Tag That Data</span>. Unauthorized use of trademarks or content
          from this website is prohibited. All rights are reserved.
        </div>
      </div>
    );
  }
}

export default SignUpComponent;
