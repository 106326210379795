/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import Colors from '../theme/Colors';

type CircularProgressComponentProps = {
  className?: string;
  size?: number;
  color?: string;
  thickness?: number;
};

// eslint-disable-next-line func-style
const CircularProgressComponent: React.FC<CircularProgressComponentProps> = (
  props: CircularProgressComponentProps,
) => {
  return (
    <CircularProgress
      // className={props.className ? props.className : "circularProgressSec"}
      style={{
        width: '100%',
        marginBottom: '10px',
      }}
      size={props.size ? props.size : 40}
      color={props.color ? props.color : Colors.ThemeColor}
      thickness={props.thickness ? props.thickness : 4}
    />
  );
};

export default CircularProgressComponent;
