/* eslint-disable camelcase */
import React, { Component } from 'react';
import $ from 'jquery';
import './PhoneNumberVerificationComponent.css';
import Constants from '../../utils/Constants';
import Api from '../../api/Api';
import loginImg from '../../theme/images/login_img.png';
import CustomTextField from '../CustomTextField';
import CircleProgressComponent from '../CircleProgressComponent';
import logoNavbar from '../../theme/images/logo_navbar.png';
// import { singleChoiceAlertDialogComponent } from "../../utils/DialogsUtils";
import { singleChoiceWithoutAutoCloseAlertDialogComponent } from '../../utils/DialogsUtils';
import log from '../../utils/logger';
import { isLoggedIn } from '../../utils/AppUtils';

let component: any;
let enterKeyHit = false;

type PhoneNumberVerificationComponentProps = {
  history?: any;
};

type PhoneNumberVerificationComponentState = {
  loginDisabled: boolean;
  loading: boolean;
  errorMessage: string;
  redirectToDashboard: boolean;
  open: boolean;
  loginCardTop: string;
  verificationError: string;
  showDashboard: boolean;
};

class PhoneNumberVerificationComponent extends Component<
  PhoneNumberVerificationComponentProps,
  PhoneNumberVerificationComponentState
> {
  constructor(props: PhoneNumberVerificationComponentProps) {
    super(props);
    component = this;
    this.state = {
      loginCardTop: '578px',
      open: false,
      showDashboard: false,
      loading: false,
      verificationError: '',
      loginDisabled: true,
      redirectToDashboard: false,
      errorMessage: '',
    };
    this.submitVerificationCode = this.submitVerificationCode.bind(this);
    this.gotoLogin = this.gotoLogin.bind(this);
  }

  componentDidMount() {
    log.debug(this.props.history);
    if (isLoggedIn()) {
      this.props.history.push('/dashboard');
    }
    if (this.props.history.location.state === undefined) {
      this.props.history.push('/login');
    }
  }

  onChange(event: any) {
    let isVerification_code_valid = true;
    const id = event.target.id;
    const verificationCode = $('#verification_code').val()?.toLocaleString();

    // log.debug("Login id" + event.target.id + " value" + event.target.value)
    if (id === 'verification_code') {
      isVerification_code_valid = false;
      if (verificationCode && verificationCode.length > 5) {
        isVerification_code_valid = true;
      } else {
        isVerification_code_valid = false;
      }
    }

    const verificationCodeError = isVerification_code_valid
      ? ''
      : Constants.enter_valid_verification_code;

    if (isVerification_code_valid) {
      component.setState({
        loginDisabled: false,
        verificationError: verificationCodeError,
      });
    } else {
      component.setState({
        loginDisabled: true,
        verificationError: verificationCodeError,
      });
    }
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleKeyEvent(event: any) {
    // log.debug("EVENT", event)
    if (event.key === 'Enter' && !enterKeyHit) {
      component.submitVerificationCode(event);
      enterKeyHit = true;
    }
  }

  gotoLogin() {
    this.props.history.push('/login');
    // component.showLoginSignUpDisableAlert(Constants.login_disable_message);
  }

  async submitVerificationCode(event: any) {
    event.preventDefault();
    const verificationCode = $('#verification_code').val()?.toLocaleString();
    if (verificationCode) {
      if (verificationCode.length < 6) {
        this.setState({
          verificationError: Constants.enter_valid_verification_code,
        });
      } else {
        this.setState({ loading: true });
        await Api.activateAccountByVerificationCode(
          component.props.history.location.state.user_id,
          verificationCode,
        )
          .then((data: any) => {
            if (data && data.http_status) {
              log.debug('Login Response', data);
              if (data.http_status === 200) {
                this.setState({ redirectToDashboard: true, loading: false });
                //              log.debug("successfully logged in")
                singleChoiceWithoutAutoCloseAlertDialogComponent(
                  data.message,
                ).then(
                  () => {
                    component.props.history.push('/login');
                  },
                  () => 0,
                );
              } else if (data.http_status === 202 || data.http_status === 203) {
                this.setState({
                  open: true,
                  loading: false,
                  errorMessage: data.message,
                });
                // } else if (data.http_status === 203) {
                //   this.setState({
                //     open: true,
                //     loading: false,
                //     errorMessage: data.message,
                //   });
              } else {
                this.setState({
                  open: true,
                  loading: false,
                  errorMessage: Constants.generic_message,
                });
              }
            } else {
              this.setState({
                open: true,
                loading: false,
                errorMessage: Constants.generic_message,
              });
            }
          })
          .catch(reason => {
            log.debug(reason.message);
            this.setState({
              open: true,
              loading: false,
              errorMessage: Constants.generic_message,
            });
          });
      }
    }
  }

  async sentVerificationCode(event: any) {
    event.preventDefault();
    await Api.sendVerificationCode(
      component.props.history.location.state.user_id,
    )
      .then((data: any) => {
        if (data !== undefined && data.http_status) {
          log.debug('Login Response', data);
          if (data.http_status === 200) {
            singleChoiceWithoutAutoCloseAlertDialogComponent(data.message).then(
              () => 0,
              () => 0,
            );
          } else if (data.http_status === 202 || data.http_status === 203) {
            this.setState({
              open: true,
              loading: false,
              errorMessage: data.message,
            });
            // } else if (data.http_status === 203) {
            //   this.setState({
            //     open: true,
            //     loading: false,
            //     errorMessage: data.message,
            //   });
          } else {
            this.setState({
              open: true,
              loading: false,
              errorMessage: Constants.generic_message,
            });
          }
        } else {
          this.setState({
            open: true,
            loading: false,
            errorMessage: Constants.generic_message,
          });
        }
      })
      .catch(reason => {
        log.debug(reason.message);
        this.setState({
          open: true,
          loading: false,
          errorMessage: Constants.generic_message,
        });
      });
  }

  // showLoginSignUpDisableAlert(message: string) {
  //   singleChoiceAlertDialogComponent(message).then(
  //     // eslint-disable-next-line no-unused-vars
  //     (params: any) => {
  //       log.debug(this.props);
  //       // window.location = "https://tagthatdata.com"
  //     },
  //     () => 0
  //   );
  // }

  render() {
    return (
      <div className="newLoginScreen">
        <nav className="navbar">
          <a className="navbar-brand" href="/">
            <img src={logoNavbar} alt="Tag That Data navigation logo" />
          </a>
          <div className="topRightMenu">
            <ul className="navbar-nav" role="tablist">
              <li className="nav-item linkTextSec">Ready to try again?</li>
              <li className="nav-item">
                <a className="btn marginRight" onClick={this.gotoLogin}>
                  {Constants.login}
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="bodyContent">
          <div className="loginImgSec">
            <img src={loginImg} className="green-glow" alt="Login Page Image" />
          </div>
          <div className="loginFormSec">
            <form>
              <h1 className="PhoneHeadText">Verification</h1>
              <h1 className="please-enter-your-re">
                {
                  "We'll send a verification code to the phone number you entered. Please enter it here:"
                }
              </h1>

              <CustomTextField
                id="verification_code"
                fullWidth={true}
                onKeyDown={this.handleKeyEvent}
                onChange={this.onChange}
                errorText={this.state.verificationError}
                floatingLabelText={Constants.verification_Code}
                className="formControl"
              />

              <div className="LoginBtnRowSec">
                <button
                  className="btn"
                  style={
                    this.state.loading
                      ? {
                          display: 'none',
                        }
                      : {
                          display: 'inline-block',
                        }
                  }
                  onClick={this.submitVerificationCode}
                >
                  {' '}
                  Submit{' '}
                </button>

                <button
                  style={
                    this.state.loading
                      ? {
                          display: 'inline-block',
                          textAlign: 'center',
                        }
                      : {
                          display: 'none',
                        }
                  }
                  className="btn loginLoading forgotLoading"
                >
                  <CircleProgressComponent
                    color="#FFFFFF"
                    size={25}
                    thickness={2}
                  />
                </button>
                <div className="msgSec">{this.state.errorMessage}</div>
              </div>
              <h1 className="please-enter-your-re">
                {'Didn’t receive the verification code? Click here to '}
                <a target="blank" onClick={this.sentVerificationCode} href="#">
                  {' send again '}
                </a>{' '}
              </h1>
            </form>
          </div>
          <div className="clear" />
        </div>
        <div className="footerSec">
          Copyright © 2018 by <span>Tag That Data</span>. All content on this
          website is proprietary, copyrighted, and owned or licensed by{' '}
          <span>Tag That Data</span>. Unauthorized use of trademarks or content
          from this website is prohibited. All rights are reserved.
        </div>
      </div>
    );
  }
}

export default PhoneNumberVerificationComponent;
