/* eslint-disable @typescript-eslint/naming-convention */
// class Colors{
//     static ThemeColor="#488FAB"
//     static ThemeDarkColor="#316F88"
//     // static ThemeTextColor="#4A4A4A"
//     static ThemeTextColor="#333333"
//     static ThemeDeactivateColor = "#EC501E"
//     static ThemeActivateColor = "#488fab"
// }
// export default Colors
const Colors: Record<string, string> = {
  PRIMARY_COLOR: '#488FAB',
  SECONDARY_COLOR: '#265067',
  DARK_GRAY_COLOR: '#353C3E',
  LIGHT_GRAY_BG_COLOR: '#ebf0f4',
  DRAWER_TEXT_COLOR: 'rgba(255,255,255,0.76)',
  BRAND_TEXT_COLOR: '#265067',
  WHITE: '#FFFFFF',
  ORANGE: '#EC501E',
  RED: '#f44336',
  LOG_OUT_TEXT_COLOR: '#EC501E',
  TABLE_HEADER_FOOTER: '#DEE8EF',
  ADD_RESTAURANT_BG: '#FAFAFA',
  TABLE_TEXT_COLOR: '#4B4B4B',
  ACTIVE_STATUS_COLOR: '#488FAB',
  ThemeColor: '#488FAB',
  ThemeDarkColor: '#316F88',
  ThemeTextColor: '#333333',
  ThemeDeactivateColor: '#EC501E',
  ThemeActivateColor: '#488fab',
};

export default Colors;
