/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import TextField from 'material-ui/TextField';
import log from '../utils/logger';
import { TextFieldProps } from 'material-ui';

interface CustomTextFieldProps extends TextFieldProps {
  type?: string;
  id?: string;
  errorText?: string;
  floatingLabelText?: string;
  onKeyDown?: (...args: any[]) => any;
  min?: number;
  max?: number;
  onChange?: (...args: any[]) => any;
  value?: string;
  className?: string;
}

const CustomTextField: React.FC<CustomTextFieldProps> = (
  props: CustomTextFieldProps,
) => {
  //       id="password"   onChange={this.onChange}
  // errorText={this.state.passwordError}   floatingLabelText={Constants.password}
  //   type="password"   className="fieldRows"
  log.debug('TextFieldProps', props);
  return (
    <TextField
      type={props.type}
      autoComplete={props.id}
      id={props.id}
      errorText={props.errorText}
      // floatingLabelText={props.floatingLabelText}
      fullWidth={true}
      hintText={props.floatingLabelText}
      onKeyDown={props.onKeyDown}
      min={props.min}
      max={props.max}
      errorStyle={{ ...props.errorStyle, color: 'red', fontWeight: 'bold' }}
      onChange={props.onChange}
      // defaultValue={props.value}
      {...(props.value && { defaultValue: props.value })}
      className={props.className}
    />
  );
};

export default CustomTextField;
