/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { Component, Dispatch } from 'react';
import { Card, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
// import TextField from 'material-ui/TextField';
import CustomTextField from '../../CustomTextField';
import { connect } from 'react-redux';
import { getScore } from '../../../actions';
import Api from '../../../api/Api';
import Constants from '../../../utils/Constants';
import CircleProgressComponent from '../../CircleProgressComponent';
import { singleChoiceAlertDialogComponent } from '../../../utils/DialogsUtils';
import './ProfileComponent.css';
import { isValidPayPalId } from '../../../utils/AppUtils';
import log from '../../../utils/logger';

let component: any;
type ProfilePayPalEditComponentProps = {
  location?: Record<string, unknown>;
  history?: any;
  getScore?: (...args: any[]) => any;
};

type ProfilePayPalEditComponentState = {
  response: any;
  isLoading: boolean;
  error: string;
  isSubmitting: boolean;
  payPalId: string;
  payPalIdError: string;
  originalPayPalId: string;
} & ((error: any) => void);

class ProfilePayPalEditComponent extends Component<
  ProfilePayPalEditComponentProps,
  ProfilePayPalEditComponentState
> {
  constructor(props: ProfilePayPalEditComponentProps) {
    super(props);
    component = this;
  }
  state = {
    isLoading: true,
    response: null,
    error: '',
    isSubmitting: false,
    payPalId: '',
    payPalIdError: '',
    originalPayPalId: '',
  };

  componentDidMount() {
    log.debug('Getting Profile Basic Data');
    Api.getUserProfile()
      .then((response: any) => {
        if (response && response.http_status) {
          if (response.http_status === 200) {
            component.setState({
              response: response,
              isLoading: false,
              payPalId: response.data.paypal_id ? response.data.paypal_id : '',
              originalPayPaId: response.data.paypal_id
                ? response.data.paypal_id
                : '',
            });
          } else if (
            response.http_status === 202 ||
            response.http_status === 203
          ) {
            component.setState({ isLoading: false, error: response.message });
          }
        } else {
          component.setState({
            isLoading: false,
            error: Constants.generic_message,
          });
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((error: any) => {
        component.setState({
          isLoading: false,
          error: Constants.generic_message,
        });
      });
  }

  onChange(event: any) {
    //component.setState({email,})
    log.debug('id', event.target.id);
    log.debug('value', event.target.value);
    const value = event.target.value;
    if (event.target.id === 'paypal') {
      if (value.length > 0) {
        component.setState({ payPalId: value, emailError: '' });
      } else {
        component.setState({
          payPalId: value,
          emailError: 'Enter paypal email/phone number',
        });
      }
    }
  }

  onSave() {
    if (component.state.payPalId === component.state.originalPayPaId) {
      component.props.history.push('/dashboard/profile');
      return;
    }
    if (isValidPayPalId(component.state.payPalId)) {
      const data = {
        user_id: component.state.response.data.id,
        paypal_id: component.state.payPalId,
      };
      component.setState({ isSubmitting: true });
      Api.updateProfile(data)
        .then((response: any) => {
          if (response && response.http_status) {
            if (response.http_status === 200) {
              component.props.getScore();
              component.props.history.push('/dashboard/profile');
            } else if (
              response.http_status === 202 ||
              response.http_status === 203
            ) {
              singleChoiceAlertDialogComponent(response.message).then(
                // eslint-disable-next-line no-unused-vars
                (params: any) => {
                  log.debug('Update profile response 202', response);
                },
                () => 0,
              );
              component.setState({ isSubmitting: false });
            }
          } else {
            log.debug('Update profile response unknown', response);
            singleChoiceAlertDialogComponent(Constants.generic_message).then(
              // eslint-disable-next-line no-unused-vars
              (params: any) => 0,
              () => 0,
            );
            component.setState({ isSubmitting: false });
          }
        })
        .catch((error: any) => {
          log.debug('Exception', error);
          singleChoiceAlertDialogComponent(Constants.generic_message).then(
            // eslint-disable-next-line no-unused-vars
            (params: any) => 0,
            () => 0,
          );
          component.setState({ isSubmitting: false });
        });
    } else {
      component.setState({ payPalIdError: 'Enter paypal email/phone number' });
    }
  }

  render() {
    // log.debug("Demograph", this.props.location)
    // log.debug("Demograph", this.props)
    return (
      <div>
        <Card className="ProfileEditBox">
          {this.state.error.length > 0 && <div>{this.state.error}</div>}
          {this.state.isLoading && (
            <div
              style={{
                position: 'absolute',
                textAlign: 'center',
                top: '45%',
                margin: '-20px 0px 0px -37px',
                left: '50%',
              }}
            >
              <CircleProgressComponent />
            </div>
          )}
          {this.state.response !== null && !this.state.isLoading && (
            <CardText>
              <div className="headingSec">Paypal</div>
              <div className="clear" />
              <div className="BoxFromSec">
                <div className="BoxDetailsRow">
                  <CustomTextField
                    fullWidth={true}
                    id="paypal"
                    errorText={this.state.payPalIdError}
                    floatingLabelText="Paypal Email/Phone Number"
                    value={this.state.payPalId}
                    onChange={this.onChange}
                    className="customTextColor"
                    floatingLabelFixed={true}
                  />
                  <div className="clear" />
                </div>

                <div className="BoxDetailsRow btnRow">
                  <RaisedButton
                    style={
                      !this.state.isSubmitting
                        ? {
                            display: 'inline-block',
                          }
                        : {
                            display: 'none',
                          }
                    }
                    onClick={this.onSave}
                    label="Save"
                    className="TransparentBtn"
                    primary={true}
                  />
                  <button
                    style={
                      this.state.isSubmitting
                        ? {
                            display: 'inline-block',
                            textAlign: 'center',
                          }
                        : {
                            display: 'none',
                          }
                    }
                    className="saveLoadBtnSec"
                  >
                    <CircleProgressComponent
                      color="#FFFFFF"
                      size={25}
                      thickness={2}
                    />
                  </button>
                </div>

                <div className="clear" />
              </div>
            </CardText>
          )}
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return { score: state.score, testStatus: state.testStatus };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    getScore: () => {
      dispatch(getScore());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfilePayPalEditComponent);
