import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Card, CardText } from 'material-ui/Card';
import log from '../../../utils/logger';

type DarkModeToggleButtonProps = {
  darkMode?: string | null;
};

type DarkModeToggleButtonState = {
  darkMode: string | null;
};

class DarkModeToggleButton extends Component<
  DarkModeToggleButtonProps,
  DarkModeToggleButtonState
> {
  constructor(props: DarkModeToggleButtonProps) {
    super(props);
    this.state = {
      // checked:false,
      darkMode: localStorage.getItem('DARK_MODE'),
    };
    if (localStorage.getItem('DARK_MODE') === 'true') {
      document.body.classList.add('dark-mode');
    }
    this.handleModeChange = this.handleModeChange.bind(this);
  }

  handleModeChange() {
    log.debug('localStorage', localStorage, this.state.darkMode);
    if (!this.state.darkMode || this.state.darkMode === 'false') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
    this.setState({
      darkMode: this.state.darkMode === 'true' ? 'false' : 'true',
    });
    localStorage.setItem(
      'DARK_MODE',
      this.state.darkMode === 'true' ? 'false' : 'true',
      // !this.state.checked
    );
  }

  render() {
    return (
      <Card className="ProfilePageInfoBox card">
        <CardText>
          <div className="headingSec">Settings </div>

          <FormControlLabel
            control={
              <Switch
                checked={this.state.darkMode === 'true' ? true : false}
                onChange={this.handleModeChange}
                value="Dark Mode"
              />
            }
            label={'Dark Mode'}
            className="BoxDetailsRow"
          />
        </CardText>
      </Card>
    );
  }
}

export default DarkModeToggleButton;
