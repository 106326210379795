import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import './ShowAcceptTutorialComponent.css';

type ShowAcceptTutorialComponentProps = {
  updateFlag: (...args: any[]) => any;
};

class ShowAcceptTutorialComponent extends Component<
  ShowAcceptTutorialComponentProps
> {
  render() {
    return (
      <div>
        <div className="demoPageMaskBgSec" />
        <div className="blueBgPopUpSec" id="">
          <div className="popUpHeading">
            Important notes for video recording :
          </div>
          <ul>
            <li>Avoid background noise.</li>
            <li>Say the words on the screen exactly as they appear.</li>
            <li>
              Make sure the video shows your face clearly for lip-reading data.
            </li>
          </ul>
          <div className="buttonBgSec">
            <RaisedButton
              className="okBtnSec"
              onClick={() => {
                // eslint-disable-next-line camelcase
                // eslint-disable-next-line @typescript-eslint/naming-convention
                this.props.updateFlag({ is_sample_collection: true });
              }}
            >
              OK
            </RaisedButton>
          </div>
        </div>
      </div>
    );
  }
}

export default ShowAcceptTutorialComponent;
