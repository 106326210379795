import React, { Component } from 'react';
import './dashboard/SampleCalibrationComponent.css';
import { connect } from 'react-redux';
import Constants from '../utils/Constants';
import ScoreComponent from './ScoreComponent';
import { Card } from 'material-ui';

type AudioSamplesCompletedComponentProps = {
  history?: any;
};

class AudioSamplesCompletedComponent extends Component<
  AudioSamplesCompletedComponentProps
> {
  render() {
    return (
      <div className="SampCalibratBodySec">
        <div className="thankYouMsgBodySec">
          <Card
            style={{ margin: '20px 20px' }}
            className="thankuTextWithContrib"
          >
            <div className="ThankYouTextSec">
              {Constants.sample_collection_complete_screen}
            </div>
          </Card>
          <ScoreComponent path={this.props.history.location.pathname} />
        </div>
        <div className="clear" />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    score: state.score,
  };
}

export default connect(mapStateToProps)(AudioSamplesCompletedComponent);
