import React, { Component, Dispatch } from 'react';
import { updateScore } from '../../actions';
import Constants from '../../utils/Constants';
import NavigationDrawerComponent from '../NavigationDrawerComponent';
import DashboardRoutes from '../../routes/DashboardRoutes';
import AppBarComponent from '../AppBarComponent';
import { connect } from 'react-redux';
import './DashboardComponent.css';
import Api from '../../api/Api';
import log from '../../utils/logger';

let component: any;

type DashboardComponentProps = {
  history: any;
  updateScore: (...args: any[]) => any;
};

type DashboardComponentState = {
  isLeftDrawerEnabled: boolean;
  logout: boolean;
  // selectedMenuItem: any,
  // selectedMenuItemTitle: any,
  // isNeedToShowBackButton: boolean
};

class DashboardComponent extends Component<
  DashboardComponentProps,
  DashboardComponentState
> {
  constructor(props: DashboardComponentProps) {
    super(props);

    this.state = {
      isLeftDrawerEnabled: true,
      logout: false,
      // selectedMenuItemTitle: Constants.locations,
      // isNeedToShowBackButton: false
    };
    this.handleMenuTouchTap = this.handleMenuTouchTap.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.updateDrawerStatus = this.updateDrawerStatus.bind(this);

    component = this;
  }

  updateDrawerStatus(status: boolean) {
    //alert(status)
    this.setState({ isLeftDrawerEnabled: status });
  }

  async componentDidMount() {
    //    // for disable to login/signup
    // component.props.history.push({
    //     pathname: '/home'
    //   })
    //   return
    // window.onerror = (msg, url, lineNo, columnNo, error) => {
    //   //alert(""+msg+ url+ lineNo+ columnNo+ error)
    // };
    await Api.getGTScore()
      .then((response: any) => {
        log.debug('data', response);
        if (response && response.http_status && response.http_status === 200) {
          log.debug('updated score');
          component.props.updateScore(response);
        }
      })
      .catch(() => 0);
  }

  handleMenuTouchTap() {
    this.setState({
      isLeftDrawerEnabled: !this.state.isLeftDrawerEnabled,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMenuItemClick(title: string, pos: any) {
    //alert(menuItem)
    // this.setState({ selectedMenuItem: pos, selectedMenuItemTitle: title });
  }

  onLogout = async () => {
    await Api.logout();
    this.props.history.push('/login');
  };

  render() {
    return (
      <div>
        <NavigationDrawerComponent
          onMenuItemClick={this.onMenuItemClick.bind(this)}
          updateDrawerStatus={this.updateDrawerStatus}
          drawerEnabled={this.state.isLeftDrawerEnabled}
        />

        <div
          className={
            this.state.isLeftDrawerEnabled ? 'withLeftMenu' : 'withOutLeftMenu'
          }
        >
          <AppBarComponent
            history={this.props.history}
            handleMenuTouchTap={this.handleMenuTouchTap.bind(this)}
            // isNeedToShowBackButton={this.state.isNeedToShowBackButton}
            onLogout={this.onLogout}
            // selectedMenuItemTitle={this.state.selectedMenuItemTitle}
          />
          <div className="pageContSec">
            <div
              id="root"
              style={{
                width: 'auto',
                margin: '0 20px',
              }}
            >
              {<DashboardRoutes {...this.props} />}

              <div className="copyRight" style={{ position: 'relative' }}>
                {Constants.footer_text}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return { score: state.score };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    updateScore: (score: any) => {
      dispatch(updateScore(score));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
