/*
 A component to handle the other options pop-up with the predefined options in the pop-up and handle the clicks.
 This pop-up will appear when selected "Other" from dropdown.
*/
import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { confirmable } from 'react-confirm';
import Colors from '../../theme/Colors';
import Theme from '../../Theme';
import { List, ListItem } from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';
import DialogClose from 'material-ui/svg-icons/navigation/close';
import '../dashboard/SampleCalibrationComponent.css';
import './Dialogs.css';
import log from '../../utils/logger';

type AudioTranscribeOtherOptionsProps = {
  okLabel?: string;
  confirmation: string & ((...args: any[]) => any);
  cancel: (...args: any[]) => any;
  dismiss: (...args: any[]) => any;
  show: false & ((...args: any[]) => any);
  proceed: (...args: any[]) => any;
};

type AudioTranscribeOtherOptionsState = {
  show: boolean;
  selectedItems: undefined[];
  defaultOptions: undefined[];
};

class AudioTranscribeOtherOptions extends React.Component<
  AudioTranscribeOtherOptionsProps,
  AudioTranscribeOtherOptionsState
> {
  // rap: FlatButton | null;
  constructor(props: AudioTranscribeOtherOptionsProps) {
    super(props);
    this.state = {
      show: true,
      selectedItems: [],
      defaultOptions: [],
    };
    // const rap = null;
  }

  /*
  closes pop-up when "X" is clicked
  */
  handleClose = () => {
    this.props.cancel();
    this.setState({ show: false });
  };

  /*
  Submit button method which passes the selected options to the parent transcription class.
  */
  handleData = () => {
    log.debug(this.props);
    this.props.confirmation(this.state.defaultOptions);
    this.props.cancel();
    this.setState({ show: false });
  };

  /*
    Check box methods which removes or adds the selected option into array
    */
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleCheck = (event: any, isChecked: boolean) => {
    const target = event.target;
    const selectedName = target.id;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const checked = this.state.defaultOptions.slice();
    // const selectedItems = this.state.selectedItems.slice();
    if (value === true) {
      checked.push(selectedName);
      this.state.defaultOptions.push(selectedName);
    } else {
      const index = checked.indexOf(selectedName);
      checked.splice(index, 1);
      this.state.defaultOptions.splice(index, 1);
    }
  };

  render() {
    const { okLabel = 'Submit' } = this.props;
    const customContentStyle = {
      width: '450px',
      // maxWidth: 'none',
      maxWidth: '100%',
    };
    log.debug('Props are', this.props);
    const actions = [
      <FlatButton
        key={0}
        className="submitButton"
        label={okLabel}
        primary={true}
        hoverColor={Colors.ThemeDarkColor}
        onClick={this.handleData}
        // ref={element => {
        //   this.rap = element;
        // }}
      />,
    ];

    return (
      <Theme>
        <Dialog
          className="submitDialog"
          title="Could Not Transcribe Because"
          actions={actions}
          modal={false}
          open={this.state.show}
          bodyStyle={{ color: 'black' }}
          style={{ color: 'black' }}
          onRequestClose={this.handleClose}
          contentStyle={customContentStyle}
          autoScrollBodyContent={true}
          actionsContainerStyle={{
            textAlign: 'center',
            padding: '10px 0px 40px 0px',
          }}
        >
          <div className="closeDialogBtn">
            <DialogClose onClick={this.handleClose} />
          </div>
          <div>
            <List>
              <ListItem
                primaryText="Silent"
                leftCheckbox={
                  <Checkbox value="0" id="Silent" onCheck={this.handleCheck} />
                }
              />

              <ListItem
                primaryText="Unintelligable"
                leftCheckbox={
                  <Checkbox
                    value="1"
                    id="Unintelligable"
                    onCheck={this.handleCheck}
                  />
                }
              />

              <ListItem
                primaryText="Too much background noise"
                leftCheckbox={
                  <Checkbox
                    value="2"
                    id="Too_much_background_noise"
                    onCheck={this.handleCheck}
                  />
                }
              />
            </List>
          </div>
        </Dialog>
      </Theme>
    );
  }
}

export default confirmable(AudioTranscribeOtherOptions);
