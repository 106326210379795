/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import Dialog from 'material-ui/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomTextField from '../../CustomTextField';
import DatePicker from 'material-ui/DatePicker';
import Theme from '../../../Theme';
import log from '../../../utils/logger';
import { CSSProperties } from '@material-ui/styles';

const fromTextStyle: CSSProperties = {
  float: 'left',
  width: 'calc(50% - 10px)',
};

const toTextStyle: CSSProperties = {
  float: 'right',
  width: 'calc(50% - 10px)',
};

const filterHeaderWrapper: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const filterHeadingStyle: CSSProperties = {
  color: '#265067',
  margin: '25px 10px 0px 0px',
  lineHeight: '20px',
  fontSize: '15px',
  fontWeight: 'bold',
};

const btnMargin: CSSProperties = {
  marginBottom: '22px',
  marginRight: '22px',
  backgroundColor: '#488FAB',
  color: '#ffffff',
};

const btnTextStyles: CSSProperties = {
  color: '#488FAB',
  textTransform: 'capitalize',
};

const customContentStyle: CSSProperties = {
  minWidth: '350px',
  maxWidth: '600px',
};

type PaymentHistoryFilterProps = {
  options: Record<string, any>;
  confirmation: any;
  cancel: (...args: any[]) => any;
  dismiss: (...args: any[]) => any;
  proceed: (...args: any[]) => any;
  show: false & ((...args: any[]) => any);
};

type PaymentHistoryFilterState = {
  fromDateMilliseconds: any;
  toDateMilliseconds: any;
  show: boolean;
  error_text: string;
  timezoneOffset: number;
};

class PaymentHistoryFilter extends React.Component<
  PaymentHistoryFilterProps,
  PaymentHistoryFilterState
> {
  constructor(props: PaymentHistoryFilterProps) {
    super(props);
    this.state = {
      show: true,
      error_text: '',
      fromDateMilliseconds: undefined,
      toDateMilliseconds: undefined,
      timezoneOffset: 0,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.options.filter) {
      const offset = new Date().getTimezoneOffset() * 60 * 1000;
      let fromSeconds = undefined;
      let toSeconds = undefined;
      if (this.props.options.filter.last_payment_from) {
        fromSeconds = this.props.options.filter.last_payment_from + offset;
      }
      if (this.props.options.filter.last_payment_to) {
        toSeconds = this.props.options.filter.last_payment_to + offset;
      }
      this.setState({
        fromDateMilliseconds: fromSeconds,
        toDateMilliseconds: toSeconds,
      });
    }
  }

  formatDate = (date: Date) => {
    let locDate = date;
    if (locDate) {
      return (
        locDate.getMonth() +
        1 +
        '/' +
        locDate.getDate() +
        '/' +
        locDate.getFullYear()
      );
    }
    locDate = new Date();
    return (
      locDate.getMonth() +
      1 +
      '/' +
      locDate.getDate() +
      '/' +
      locDate.getFullYear()
    );
  };

  onFromDateChanged = (event: any, date: Date) => {
    log.debug(
      'The from date is',
      date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate(),
    );
    const utcFromDate = Date.parse(date.toLocaleDateString());
    log.debug('The UTC date is ', utcFromDate);
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const milliseconds = date.getTime() - offset;
    this.setState({
      fromDateMilliseconds: milliseconds,
      timezoneOffset: offset,
    });
    log.debug('The Offset is', offset);
  };

  onToDateChanged = (event: any, date: Date) => {
    log.debug(
      'The to date is',
      date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate(),
    );
    const utcToDate = Date.parse(date.toLocaleDateString());
    log.debug('The UTC date is ', utcToDate);
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    log.debug('The Offset is', offset);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    const milliseconds = date.getTime() - offset;
    this.setState({
      toDateMilliseconds: milliseconds,
      timezoneOffset: offset,
    });
  };

  onFilter = () => {
    const earnedFrom = $('#earned_from').val();
    const earnedTo = $('#earned_to').val();
    const filterData = {
      total_earned_from: earnedFrom,
      total_earned_to: earnedTo,
      last_payment_from: this.state.fromDateMilliseconds
        ? this.state.fromDateMilliseconds
        : 0,
      last_payment_to: this.state.toDateMilliseconds
        ? this.state.toDateMilliseconds
        : undefined,
    };
    this.props.proceed(filterData);
    this.setState({ show: false });
  };

  handleClearFilter = () => {
    this.setState({ show: false });
    this.props.proceed({}, true); // must be an empty array to "clear filter"
  };

  render() {
    const props = this.props;
    log.debug('state', this.state);
    return (
      <Theme>
        <Dialog
          className="submitDialog"
          modal={undefined}
          open={this.state.show}
          bodyStyle={{ color: 'black', padding: 0 }}
          style={{ color: 'black', padding: 0 }}
          contentStyle={customContentStyle}
          // fullWidth={true}
          // maxWidth={"md"}
          autoScrollBodyContent={true}
          actionsContainerStyle={{
            textAlign: 'center',
            padding: '10px 0px 40px 0px',
          }}
          paperClassName="dialogClass"
        >
          <div>
            <DialogContent>
              <div style={filterHeaderWrapper}>
                <h2 style={{ color: '#488FAB', margin: '0', fontSize: '24px' }}>
                  {' '}
                  Filter
                </h2>
                <Button
                  variant="text"
                  color="primary"
                  style={btnTextStyles}
                  onClick={this.handleClearFilter}
                >
                  Clear Filter
                </Button>
              </div>

              <DialogContentText>{props.options.message}</DialogContentText>
              <div style={filterHeadingStyle}>{'Payment Amount'}</div>
              <div className="fromClass" style={fromTextStyle}>
                <CustomTextField
                  id="earned_from"
                  {...(props.options.filter && {
                    value: props.options.filter.total_earned_from,
                  })}
                  type="text"
                  fullWidth={false}
                  hintText={'From'}
                  floatingLabelText={'From'}
                  className="fieldRows"
                />
              </div>
              <div className="toClass" style={toTextStyle}>
                <CustomTextField
                  id="earned_to"
                  type="text"
                  fullWidth={false}
                  floatingLabelText={'To'}
                  {...(props.options.filter && {
                    value: props.options.filter.total_earned_to,
                  })}
                  className="fieldRows"
                />
              </div>
              <div style={{ clear: 'both' }} />
              <div style={filterHeadingStyle}>{'Payment Date'}</div>
              <div className="fromClass" style={fromTextStyle}>
                {
                  <DatePicker
                    // color="primary"
                    style={{ color: '#ffffff' }}
                    className="fromDate"
                    id="paymentdate_from"
                    hintText="From"
                    textFieldStyle={{ width: '100%' }}
                    defaultDate={
                      props.options.filter &&
                      props.options.filter.last_payment_from > 0
                        ? new Date(this.state.fromDateMilliseconds)
                        : undefined
                    }
                    openToYearSelection={false}
                    formatDate={this.formatDate}
                    onChange={this.onFromDateChanged}
                  />
                }
              </div>
              <div className="toClass" style={toTextStyle}>
                <DatePicker
                  // color="primary"
                  className="toDate"
                  id="paymentdate_to"
                  hintText="To"
                  defaultDate={
                    props.options.filter &&
                    props.options.filter.last_payment_to > 0
                      ? new Date(this.state.toDateMilliseconds)
                      : undefined
                  }
                  textFieldStyle={{ width: '100%' }}
                  openToYearSelection={false}
                  formatDate={this.formatDate}
                  onChange={this.onToDateChanged}
                />
              </div>
              <div style={{ clear: 'both' }} />
              <div>{this.state.error_text}</div>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-end' }}>
              <Button
                style={btnMargin}
                onClick={() => {
                  this.setState({ show: false });
                  props.cancel();
                }}
                variant="contained"
              >
                {props.options.negative_text
                  ? props.options.negative_text
                  : 'Cancel'}
              </Button>
              <Button
                style={btnMargin}
                onClick={this.onFilter}
                variant="contained"
              >
                {props.options.positive_text
                  ? props.options.positive_text
                  : 'Filter'}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Theme>
    );
  }
}

const con = confirmable(PaymentHistoryFilter);
// const con = confirmable(dialog);
const confirm = createConfirmation(con);

export default (options: any) => {
  return confirm({ options });
};
