/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import './dashboard/SampleCalibrationComponent.css';
import RaisedButton from 'material-ui/RaisedButton';
import Constants from '../utils/Constants';
import log from '../utils/logger';
import { ButtonProps } from '@material-ui/core';

interface CustomFloatingActionButtonProps extends ButtonProps {
  id?: string;
  icon: any;
  label?: any;
  labelColor?: string;
  backgroundColor?: string;
  onClick: (...args: any[]) => any;
  updateTutorialFlag?: (...args: any[]) => any;
  tutorialText?: string;
  showMask?: any;
  mode?: any;
}

// eslint-disable-next-line func-style
const CustomFloatingActionButton: React.FC<CustomFloatingActionButtonProps> = (
  props: CustomFloatingActionButtonProps,
) => {
  //       id="password"   onChange={this.onChange}
  // errorText={this.state.passwordError}   floatingLabelText={Constants.password}
  //   type="password"   className="fieldRows"

  const floatingBtnStyle = {
    height: 40,
    lineHeight: '20px',
    // verticalAlign: 'middle',
    width: 40,
    // margin: '0px 16px 0px 30px ',
  };

  const color = props.labelColor ? props.labelColor : '#307192';

  const floatingBtnLabelStyle = {
    height: 40,
    verticalAlign: 'middle',
    // display: 'inline-block',
    margin: '0px ',
    color: color,
    fontStyle: 'italic',
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Icon: React.ComponentType<any> = props.icon;
  log.debug(props);

  return (
    <div className="playStyles">
      {props.showMask !== null && props.showMask !== undefined && (
        <div className="boxBlueBgSec">
          <div className="whiteLineSec" />
          {props.tutorialText}
          <div className="buttonBgSec">
            <RaisedButton
              className="okBtnSec"
              onClick={() => {
                try {
                  if (props.updateTutorialFlag) {
                    if (props.mode === Constants.AUDIO_NORMAL) {
                      // eslint-disable-next-line camelcase
                      props.updateTutorialFlag({ is_calibration: true });
                    } else {
                      // eslint-disable-next-line camelcase
                      props.updateTutorialFlag({
                        is_confirm_calibration: true,
                      });
                    }
                  }
                } catch (error) {
                  alert(error);
                }
              }}
            >
              OK
            </RaisedButton>
          </div>
        </div>
      )}
      <FloatingActionButton
        style={floatingBtnStyle}
        // primary={true}
        backgroundColor={props.backgroundColor}
        id={props.id}
        className="audioPlaybtn"
        onClick={props.onClick}
      >
        <Icon />
      </FloatingActionButton>
      {!!props.title && (
        <div className="btnTitleSec" style={floatingBtnLabelStyle}>
          {props.label}
        </div>
      )}
    </div>
  );
};

export default CustomFloatingActionButton;
