/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Constants from '../utils/Constants';
import log from '../utils/logger';
import { isLoggedIn } from '../utils/AppUtils';

interface PrivateRouteProps extends RouteProps {
  // location?: any,
  // context?: (...args: any[]) => any,
  // refs?: (...args: any[]) => any,
  // updater?: (...args: any[]) => any,
  history?: any;
  path?: any;
  component?: any;
  exact?: any;
}

// eslint-disable-next-line func-style
const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children: unwantedChildren,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props: PrivateRouteProps) => {
      if (props.location?.pathname !== undefined) {
        // eslint-disable-next-line no-undef
        gtag('config', Constants.GoogleTrackingID, {
          page_title: 'Tag That Data',
          page_path: props.location?.pathname,
        });
        log.debug('pathName', props.location?.pathname);
      }
      return isLoggedIn() ? (
        props.location?.pathname === '/dashboard' ||
        props.location?.pathname === '/dashboard/collections' ||
        // props.location.pathname === "/dashboard/tagcharacteristics" ||
        props.location?.pathname === '/dashboard/collections/calibration' ? (
          <Redirect
            to={{
              pathname: '/dashboard/transcriptions',
              // pathname: "/dashboard/tagcharacteristics",
              state: {
                from: props.location,
              },
            }}
          />
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location,
            },
          }}
        />
      );
    }}
  />
);

export default PrivateRoute;
