import {
  UPDATE_TEST_STATUS,
  GET_TEST_STATUS,
  UpdateTestStatus,
  GetTestStatus,
} from '../actions';
// import Apis from '../api/Api';
import log from '../utils/logger';

export interface TestStatusState {
  status: boolean;
}

const initialState: TestStatusState = {
  status: false,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function TestStatus(
  state = initialState,
  action: UpdateTestStatus & GetTestStatus,
) {
  if (action?.type === UPDATE_TEST_STATUS) {
    log.debug('ACTION_REDUX', { status: action.status });
    return Object.assign({}, { status: action.status });
  } else if (action?.type === GET_TEST_STATUS) {
    return state;
  }
  // } else if (action.type === 'GET_SCORE_FROM_API') {
  //   Apis.getGTScore()
  //     .then((response: any) => {
  //       log.debug('data', response);
  //       if (
  //         response !== undefined &&
  //         response.http_status !== undefined &&
  //         response.http_status === 200
  //       ) {
  //         log.debug('updated score');
  //         // component.props.updateScore(response);
  //       }
  //     })
  //     .catch((error: any) => log.debug(error));
  // }
  return state;
}

export default TestStatus;
