/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import { Card, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import logo from '../../theme/images/logo_white.png';
import { singleChoiceWithoutAutoCloseAlertDialogComponent } from '../../utils/DialogsUtils';
import Api from '../../api/Api';
import Constants from '../../utils/Constants';
import CircleProgressComponent from '../../components/CircleProgressComponent';
import './Agreement.css';
import log from '../../utils/logger';

let component: Component<AgreementComponentProps, AgreementComponentState>;

type AgreementComponentProps = {
  history?: any;
};

type AgreementComponentState = {
  isDoingSignUp: boolean;
  apiError: any;
  // error: any,
  open: boolean | null;
  data: any;
  stateData: any;
};

class AgreementComponent extends Component<
  AgreementComponentProps,
  AgreementComponentState
> {
  constructor(props: AgreementComponentProps) {
    super(props);
    component = this;
  }

  state = {
    // this.state.isDoingSignUp
    isDoingSignUp: false,
    apiError: '',
    error: '',
    open: null,
    data: null,
    stateData: null,
  };

  componentDidMount() {
    log.debug(this.props.history);
    if (
      this.props.history.location.state === undefined ||
      this.props.history.location.state.data === undefined
    ) {
      this.props.history.push('/signup');
    }
  }

  onDecline() {
    const data = component.props.history.location.state.stateData;
    log.debug('Decline', component.props.history.location.state.stateData);
    data.apiError = '';
    component.props.history.push({
      pathname: '/signup',
      state: {
        data: component.props.history.location.state.data,
        stateData: data,
      },
    });
  }

  doSignUp() {
    component.setState({ isDoingSignUp: true });
    const state = component.props.history.location.state.data;
    Api.signUp(state)
      .then((response: any) => {
        // component.setState({isDoingSignUp:false})
        if (response && response.http_status && response.http_status === 200) {
          singleChoiceWithoutAutoCloseAlertDialogComponent(
            response.message,
          ).then(
            // eslint-disable-next-line no-unused-vars
            (params: any) => {
              // eslint-disable-next-line camelcase
              state.user_id = response.user_id;
              component.props.history.push({
                pathname: '/phoneVerification',
                state: component.props.history.location.state.data,
              });
            },
            () => 0,
          );
          component.setState({ isDoingSignUp: false });
        } else {
          if (
            response &&
            response.http_status &&
            (response.http_status === 203 || response.http_status === 202)
          ) {
            //component.setState({ isDoingSignUp: false, apiError: response.message, open: true })
            const stateDataLoc =
              component.props.history.location.state.stateData;
            stateDataLoc.open = true;
            stateDataLoc.apiError = response.message;
            component.props.history.push({
              pathname: '/signup',
              state: {
                data: component.props.history.location.state.data,
                stateData: stateDataLoc,
              },
            });
          } else {
            // component.setState({ isDoingSignUp: false, apiError: Constants.generic_message, open: true })
            const stateDataLoc =
              component.props.history.location.state.stateData;
            stateDataLoc.open = true;
            stateDataLoc.apiError = Constants.generic_message;
            component.props.history.push({
              pathname: '/signup',
              state: {
                data: stateDataLoc,
                stateData: stateDataLoc,
              },
            });
          }
        }
      })
      .catch((error: Error) => {
        component.setState({
          isDoingSignUp: false,
          apiError: Constants.generic_message,
          open: true,
        });
      });
  }

  render() {
    return (
      <div className="loginHeadSec">
        <img src={logo} className="logoSec" />
        <div className="loginPageBgSec" />
        <div
          className="landingBgSec"
          style={{
            marginTop: '30px',
            width: '100%',
            height: '100%',
          }}
        >
          <div>
            <Card className="AgreementPageBox">
              <CardText>
                <div className="headingSec" style={{ fontWeight: 600 }}>
                  Agreement
                </div>
                <div className="clear" />
                <div className="cardContSec">
                  <div>
                    <p>
                      Welcome and thank you for joining Tag That Data. Our goal
                      is to be able to provide world-class computer human
                      interaction and we appreciate your support.
                    </p>
                    <br />
                    <p>
                      You will be paid for each audio transcription completed.
                    </p>
                  </div>

                  <div className="contHeadSec">Current Payouts</div>
                  <div className="PayoutBoxSec lightBg">
                    <div className="priceBox" style={{ color: '#FFFFFF' }}>
                      $0.05 Per Audio Transcription
                    </div>
                    <div className="priceBox" style={{ color: '#FFFFFF' }}>
                      $0.01 Per Tagged Characteristic
                    </div>

                    <div className="clear" />
                  </div>

                  <div className="clear" />
                  <div className="contHeadSec">
                    Before you begin, there are several important things to
                    know:
                  </div>
                  <div>
                    <ol>
                      <li>
                        {' '}
                        You must reach a minimum of $5.00 earned to receive a
                        payout.
                      </li>
                      <li>
                        {' '}
                        Payouts are sent through PayPal. You must have a PayPal
                        account to receive payment.{' '}
                      </li>
                      <li>
                        {' '}
                        If it is determined that you’ve submitted many
                        inaccurate transcriptions, your account may be suspended
                        or deactivated.
                      </li>
                      <li>
                        {' '}
                        If your account is deactivated, no payouts will be made.
                      </li>
                      <li>
                        {' '}
                        We retain the right to refuse service to anyone for any
                        reason.{' '}
                      </li>
                      <li>
                        {' '}
                        Payments are made to qualifying accounts once per week,
                        typically on Wednesday afternoon MT (PayPal typically
                        processes payments within 2-3 days).
                      </li>
                      <li>
                        {' '}
                        All audio recordings, transcriptions and characteristic
                        data become the property of Tag That Data upon
                        submission, including all intellectual property rights,
                        including but not limited to copyright, trademark,
                        patent rights, and all other global rights to use these
                        recordings and transcriptions.
                      </li>
                    </ol>
                  </div>
                  <div className="btnRow">
                    <RaisedButton
                      backgroundColor="#EC501E"
                      label="Decline"
                      className="DeclineBtn"
                      onClick={this.onDecline}
                    />
                    <div className="ContriSignUpBtnSec">
                      <RaisedButton
                        style={
                          this.state.isDoingSignUp
                            ? {
                                display: 'none',
                              }
                            : {
                                display: 'inline-block',
                              }
                        }
                        label="Accept"
                        className="AcceptBtn"
                        primary={true}
                        onClick={this.doSignUp}
                      />
                      <button
                        style={
                          this.state.isDoingSignUp
                            ? {
                                display: 'inline-block',
                                textAlign: 'center',
                              }
                            : {
                                display: 'none',
                              }
                        }
                        className="AcceptLoadBtn"
                      >
                        <CircleProgressComponent
                          color="#FFFFFF"
                          size={25}
                          thickness={2}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </CardText>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default AgreementComponent;
