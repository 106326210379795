// import AWS from "aws-sdk";
import { ContentState, EditorState } from 'draft-js';
import { Card } from 'material-ui/Card';
import htmlToDraft from 'html-to-draftjs';
import './FaqsComponent.css';
import { Component, default as React } from 'react';
import { Editor } from 'react-draft-wysiwyg';
// import Constants from "../../../utils/Constants";
import CircleProgressComponent from '../../../utils/CircularProgressComponent';
import Api from '../../../api/Api';
import log from '../../../utils/logger';
import Constants from '../../../utils/Constants';

type FaqsComponentProps = {
  history?: any;
  location?: any;
};

type FaqsComponentState = {
  editorState: any;
  data: any;
  contentLoading: boolean;
  errorMessage: string;
  file: string;
  loading: boolean;
};

class FaqsComponent extends Component<FaqsComponentProps, FaqsComponentState> {
  constructor(props: FaqsComponentProps) {
    super(props);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
    log.debug('this', this.props);
    this.state = {
      editorState: EditorState.createEmpty(),
      contentLoading: false,
      errorMessage: '',
      file: '',
      loading: true,
      data: null,
    };
  }

  componentDidMount() {
    this.contentManagement();
  }

  contentManagement() {
    Api.getContentManagement()
      .then((data: any) => {
        if (data !== undefined && data.http_status) {
          this.setState({
            loading: true,
          });
          if (data.http_status === 200) {
            log.debug('componentDidMount', data.data.faq);
            data.data.faq.map((item: any) => {
              if (item.title === Constants.FAQ) {
                log.debug('componentDidMount', item);
                const contentBlock = htmlToDraft(item.body_text);
                const contentState = ContentState.createFromBlockArray(
                  contentBlock.contentBlocks,
                );
                this.setState({
                  editorState: EditorState.createWithContent(contentState),
                  loading: false,
                });
              }
            });
            this.setState({
              data: data.data.faq,
              loading: false,
            });
          } else {
            this.setState({ loading: false, data: [] });
          }
        } else {
          this.setState({
            loading: false,
            errorMessage: data ? data.message : 'err',
          });
        }
      })
      .catch(reason => {
        log.debug('Exception', reason);
        this.setState({ loading: false, errorMessage: reason });
      });
  }

  onEditorStateChange = (editorState: any) => {
    log.debug(editorState);
    this.setState({
      editorState,
    });
  };

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async uploadImageCallBack(file: any) {
    // log.debug("file", file);
    // AWS.config.update({
    //   credentials: new AWS.CognitoIdentityCredentials({
    //     IdentityPoolId: Constants.AWS_COGNITO_POOL_ID
    //   }),
    //   region: Constants.AWS_BUCKET_REGION
    // });
    // AWS.config.setPromisesDependency(require("rsvp").Promise);
    // // const file = this.state.file;
    // const s3 = new AWS.S3({
    //   apiVersion: "2006-03-01",
    //   params: { Bucket: Constants.AWS_BUCKET_NAME }
    // });
    // log.debug("file", file);
    // const fileName = file !== undefined ? file.name : "";
    // const photoKey = Date.now() + "" + fileName;
    // await s3.upload(
    //   {
    //     Body: file,
    //     Bucket: Constants.AWS_BUCKET_NAME,
    //     Key: photoKey
    //   },
    //   (err, data) => {
    //     log.debug("DATA S#", err, data);
    //     if (!err) {
    //       return { data: { link: data.Location } };
    //     }
    //   }
    // );
  }

  render() {
    return (
      <div style={{ paddingTop: '15px' }}>
        {' '}
        {this.state.loading ? (
          <CircleProgressComponent />
        ) : (
          <Card className="faqCard">
            <Editor
              toolbarHidden={true}
              editorState={this.state.editorState}
              wrapperClassName="wrapper-class-custom"
              editorClassName="editor-class-custom"
              toolbarClassName="toolbar-class-custom"
              readOnly={true}
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                image: {
                  uploadCallback: this.uploadImageCallBack,
                  previewImage: true,
                  uploadEnabled: true,
                  alt: {
                    present: true,
                    mandatory: true,
                  },
                },
              }}
            />
          </Card>
        )}
      </div>
    );
  }
}

export default FaqsComponent;
