import React from 'react';
import { Card, CardText } from 'material-ui/Card';
import '../../dashboard/SampleCalibrationComponent.css';
import AudioPlayComponentGoodTimes from './AudioPlayComponentGoodTimes';
import ScoreComponent from '../../ScoreComponent';
import { isMobileDevice } from '../../../utils/AppUtils';

type AudioTranscriptionPlayComponentProps = {
  history?: any;
};

class AudioTranscriptionPlayComponent extends React.PureComponent<
  AudioTranscriptionPlayComponentProps
> {
  constructor(props: AudioTranscriptionPlayComponentProps) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="SampCalibratBodySec">
          {isMobileDevice() && (
            <ScoreComponent
              path={this.props.history.location.pathname}
              history={this.props.history}
            />
          )}
          <Card className="SampCalibratBoxSec">
            <CardText
              style={
                isMobileDevice() ? { padding: '12px' } : { padding: '32px' }
              }
            >
              <AudioPlayComponentGoodTimes history={this.props.history} />
            </CardText>
          </Card>
          {!isMobileDevice() && (
            <ScoreComponent
              path={this.props.history.location.pathname}
              history={this.props.history}
            />
          )}
          <div className="clear" />
        </div>
      </div>
    );
  }
}

export default AudioTranscriptionPlayComponent;
