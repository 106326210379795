import $ from 'jquery';
import Divider from 'material-ui/Divider';
import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  NavLink,
  // withRouter
} from 'react-router-dom';
import ReactSVG from 'react-svg';
import audioSampleImage from '../theme/images/cardiogram.svg';
// import characteristicsImage from "../theme/images/characteristics.svg";
import FAQsImage from '../theme/images/ic_faq.svg';
import ProfileImage from '../theme/images/ic_profile.svg';
import LeaderBoardIcon from '../theme/images/leader_board.svg';
// import logo from '../theme/images/tagthatdata.png';
import Constants from '../utils/Constants';
import './NavigationDrawer.css';
import log from '../utils/logger';

type NavigationDrawerComponentProps = {
  updateDrawerStatus?: (...args: any[]) => any;
  onMenuItemClick?: (...args: any[]) => any;
  drawerEnabled?: boolean;
};

type NavigationDrawerComponentState = {
  docked: boolean;
  drawerEnabled: boolean | undefined;
  selectedItem: string;
  width: number;
  height: number;
};

class NavigationDrawerComponent extends Component<
  NavigationDrawerComponentProps,
  NavigationDrawerComponentState
> {
  constructor(props: NavigationDrawerComponentProps) {
    super(props);
    this.state = {
      selectedItem: '',
      docked: true,
      width: 0,
      height: 0,
      drawerEnabled: this.props.drawerEnabled,
    };
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (window.innerWidth <= 768) {
      this.props.updateDrawerStatus
        ? this.props.updateDrawerStatus(false)
        : null;
      this.setState({ docked: false, drawerEnabled: false });
    }
    // mql.addListener(this.mediaQueryChanged); this.setState({mql: mql, docked:
    // mql.matches});
    window.addEventListener('resize', this.mediaQueryChanged);
  }

  componentWillUnmount() {
    // window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener('resize', this.mediaQueryChanged);
  }

  // eslint-disable-next-line camelcase
  // eslint-disable-next-line @typescript-eslint/naming-convention
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // nextContext) {
    // componentDidUpdate(nextProps, nextContext) {
    this.setState({ drawerEnabled: nextProps.drawerEnabled });
  }

  mediaQueryChanged() {
    //log.debug("screen changed")
    const width = $(window).width();
    if (width && width < 768) {
      this.setState({ docked: false });
    } else {
      this.setState({ docked: true });
    }
    // this.setState({}); this.setState({mql: mql, docked: this.state.mql.matches});
  }

  handleClose = () => {
    this.props.updateDrawerStatus ? this.props.updateDrawerStatus(false) : null;
    //this.setState({drawerEnabled: false});
  };

  onMenuClick() {
    log.debug('menu item clicked');
    if (window.innerWidth <= 768) {
      log.debug('screen height');
      this.props.updateDrawerStatus
        ? this.props.updateDrawerStatus(false)
        : null;
    }
  }

  render() {
    return (
      <div>
        <Drawer
          overlayStyle={{
            backgroundColor: '#000000',
            opacity: 0.3,
          }}
          className={
            this.props.drawerEnabled ? 'openLeftMenuSec' : 'closeLeftMenuSec'
          }
          containerClassName="drawerContClass"
          docked={this.state.docked}
          onRequestChange={this.handleClose}
          disableSwipeToOpen={false}
          open={this.state.drawerEnabled}
        >
          <div className="LeftMenuTopSec">
            <div className="appLogo" />
          </div>

          <Divider />

          <NavLink to="/dashboard/transcriptions">
            <MenuItem
              onClick={this.onMenuClick}
              leftIcon={<ReactSVG path={audioSampleImage}> </ReactSVG>}
            >
              {Constants.audio_transcription}
            </MenuItem>
          </NavLink>
          <Divider />

          <NavLink to="/dashboard/profile">
            <MenuItem
              onClick={this.onMenuClick}
              leftIcon={<ReactSVG path={ProfileImage}> </ReactSVG>}
            >
              {Constants.profile}
            </MenuItem>
          </NavLink>
          <Divider />
          <Divider />

          <NavLink to="/dashboard/faqs">
            <MenuItem
              onClick={this.onMenuClick}
              leftIcon={<ReactSVG path={FAQsImage}> </ReactSVG>}
            >
              {Constants.FAQ}
            </MenuItem>
          </NavLink>
          <Divider />
          <NavLink to="/dashboard/leaderboard">
            <MenuItem
              onClick={this.onMenuClick}
              leftIcon={<ReactSVG path={LeaderBoardIcon}> </ReactSVG>}
            >
              {Constants.LEADER_BOARD}
            </MenuItem>
          </NavLink>
          <Divider />
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    score: state.score,
    testStatus: state.testStatus,
    maskStatus: state.maskStatus,
  };
}

//contributor_score
//transcriber_score

// export default connect(mapStateToProps)(withRouter(NavigationDrawerComponent));
export default connect(mapStateToProps)(NavigationDrawerComponent);
