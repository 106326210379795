/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

// eslint-disable-next-line func-style
const Theme: React.FC = (props: any) => (
  <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme)}>
    {props.children}
  </MuiThemeProvider>
);

export default Theme;
