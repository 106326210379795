/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/**
 * Created by app it mac on 4/25/17.
 */
import {
  getBrowserName,
  getPreviousAudioStatus as AudioStatus,
} from '../utils/AppUtils';
import Constants from '../utils/Constants';
import log from '../utils/logger';
const Apis = {
  async doLogin(email: string, password: string) {
    const response = await fetch(Constants.endPoint + Constants.method_login, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({ email: email, password: password }),
    });
    if (response) {
      return await response.json();
    }
    return undefined;
  },
  async forgotPassword(email: string) {
    const response = await fetch(
      Constants.endPoint + Constants.method_forgot_password,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ email: email }),
      },
    );
    if (response) {
      return await response.json();
    }
    return undefined;
  },
  async activateAccountByVerificationCode(
    user_id: any,
    verification_code: string,
  ) {
    const response = await fetch(
      Constants.endPoint + Constants.method_activation_by_verification_code,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          verification_code: verification_code,
          user_id: user_id,
        }),
      },
    );
    if (response) {
      return await response.json();
    }
    return undefined;
  },
  async sendVerificationCode(user_id: any) {
    const response = await fetch(
      Constants.endPoint + Constants.method_resend_verification_code,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          user_id: user_id,
        }),
      },
    );
    if (response) {
      return await response.json();
    }
    return undefined;
  },
  async resetPassword(password: string, token: any) {
    const response = await fetch(
      Constants.endPoint + Constants.method_reset_password,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({ new_password: password }),
      },
    );
    if (response) {
      return await response.json();
    }
    return undefined;
  },
  async getStatements(requestBody: any) {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_statements,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(requestBody),
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getNextStatement() {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_statements,
      {
        method: 'GET',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async fileUpload(
    file: any,
    statement: any,
    customer_metadata: any,
    duration: any,
  ) {
    log.debug('File upload statement ', statement);
    const statementMetaData = JSON.parse(statement.meta_data);
    log.debug('File upload statement metadata json ', statementMetaData);
    log.debug('File upload customer metadata json ', customer_metadata);
    const customerMetadata = JSON.parse(customer_metadata.meta_data);
    const d = new Date();
    const n = d.getTime();
    log.debug('MILL', n);
    const user = JSON.parse(
      sessionStorage.getItem(Constants.LOGGED_USER) as string,
    );
    const audioId = statement.statement_text;
    let metadata;
    if (user.state && user.state.length > 0) {
      metadata = {
        audio: {
          type: 'video/webm',
          is_approved_for_training: false,
          duration: duration,
        },
        contributor: {
          age: parseInt(user.age),
          gender: user.gender,
          ethnicity: user.ethnicity,
          geographic_region: user.state,
          native_language: user.native_language,
        },
        content: {
          specific_to_customer: statement.customer_id,
          transcript_text: statement.statement_text,
          estimated_intent: statement.meta_data.estimated_intent,
          entities: statement.meta_data.entities,
          language_spoken: user.native_language,
          geographic_region: user.state,
          industries: customerMetadata.content.industries,
        },
      };
    } else {
      metadata = {
        audio: {
          type: 'video/webm',
          is_approved_for_training: false,
        },
        contributor: {
          age: parseInt(user.age),
          gender: user.gender,
          ethnicity: user.ethnicity,
          native_language: user.native_language,
        },
        content: {
          specific_to_customer: statement.customer_id,
          transcript_text: statement.statement_text,
          estimated_intent: statement.meta_data.estimated_intent,
          entities: statement.meta_data.entities,
          language_spoken: user.native_language,
          industries: customerMetadata.content.industries,
        },
      };
    }
    //audioId = '1 Cheeseburger 2 Fries 1 Drinks 1 Ice Cream 3 Cookies';
    log.debug('ID', audioId);
    log.debug('MetaData', JSON.stringify(metadata));
    const formData = new FormData();
    formData.append('metadata', JSON.stringify(metadata));
    formData.append('file', file, audioId + '.webm');
    log.debug(
      'HEADER_AUTH',
      'Bearer ' + sessionStorage.getItem(Constants.AUDIO_URL_SECRET_KEY),
    );
    // eslint-disable-next-line no-console
    console.time('upload_time');
    log.debug('file_size', file.size);
    const response = await fetch(Constants.audioEndPoint as string, {
      method: 'POST',
      headers: {
        authorization:
          'Bearer ' + sessionStorage.getItem(Constants.AUDIO_URL_SECRET_KEY),
      },
      body: formData,
    });
    log.debug('Response', response);
    // Examine the text in the response
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          // eslint-disable-next-line no-console
          console.timeEnd('upload_time');
          log.debug('Exception+DATA', data);
          return data;
        })
        .catch((error: any) => {
          log.debug('Exception', error);
          return error;
        });
    } else if (response && response.status === 401) {
      log.debug('401 error occurred');
      log.debug(response);
      log.debug('401 error occurred');
      return response;
    }
    log.debug('401 error undefined');
    log.debug(response);
    return undefined;
  },
  async postSampleDetails(requestBody: any) {
    const response = await fetch(
      Constants.endPoint + Constants.method_post_audio_details,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(requestBody),
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
    // method_get_GT_score
  },
  async getGTScore() {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_GT_score,
      {
        method: 'GET',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getScore() {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_score,
      {
        method: 'GET',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getCurrentAudioId(audioCountFlag: string, previousAudioId: string) {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_audio_id,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify({
          flag: audioCountFlag,
          browser_type: getBrowserName(),
          previous_audio_id: previousAudioId,
        }),
      },
    );
    log.debug('response_value', response);
    if (response) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getGoodTimesCurrentAudioId(
    audioCountFlag: string,
    previousAudioId: any,
  ) {
    const browserType = getBrowserName();
    const previousAudioStatus = AudioStatus();
    const response = await fetch(
      Constants.endPoint + Constants.method_good_times_get_audio_id,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify({
          flag: audioCountFlag,
          browser_type: browserType,
          previous_audio_id: previousAudioId,
          previous_audio_status: previousAudioStatus,
        }),
      },
    );
    log.debug('response_value', response);
    if (response) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getGoodTimesCharacteristicCurrentAudioId() {
    const response = await fetch(
      Constants.endPoint +
        Constants.method_get_good_times_characteristic_audio_id,
      {
        method: 'GET',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
      },
    );
    log.debug('response_value', response);
    if (response) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async updateUserTutorialFlag(tutorialFlag: any) {
    log.debug('tutorialFlag', tutorialFlag);
    tutorialFlag.user_id = JSON.parse(
      sessionStorage.getItem(Constants.LOGGED_USER) as string,
    ).user_id;
    log.debug(tutorialFlag);
    const response = await fetch(
      Constants.endPoint + Constants.method_updateUser_tutorial_flag,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(tutorialFlag),
      },
    );
    log.debug('response_value', response);
    if (response) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getUserIntents() {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_intents,
      {
        method: 'GET',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getAudioIssue() {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_audio_issues,
      {
        method: 'GET',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getCharacteristicAudioIssue() {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_characteristic_issues,
      {
        method: 'GET',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async storeTranscription(requestBody: any) {
    let ipRes = undefined;
    try {
      const ipResponseReq = await fetch('https://api.ipify.org/?format=json', {
        method: 'GET',
      });
      if (ipResponseReq) {
        ipRes = await ipResponseReq.json();
      }
    } catch (e) {
      log.debug(e);
    }
    requestBody['ip_address'] = ipRes === undefined ? '-1' : ipRes.ip;
    const response = await fetch(
      Constants.endPoint + Constants.method_store_transcription,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(requestBody),
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async storeGoodTimesTranscription(requestBody: any) {
    let ipRes = undefined;
    try {
      const ipResponseReq = await fetch('https://api.ipify.org/?format=json', {
        method: 'GET',
      });
      if (ipResponseReq) {
        ipRes = await ipResponseReq.json();
      }
    } catch (e) {
      log.debug(e);
    }
    requestBody['ip_address'] = ipRes === undefined ? '-1' : ipRes.ip;
    const response = await fetch(
      Constants.endPoint + Constants.method_good_times_store_transcription,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(requestBody),
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async storeGoodTimesCharacteristicTranscription(requestBody: any) {
    let ipRes = undefined;
    try {
      const ipResponseReq = await fetch('https://api.ipify.org/?format=json', {
        method: 'GET',
      });
      if (ipResponseReq) {
        ipRes = await ipResponseReq.json();
      }
    } catch (e) {
      log.debug(e);
    }
    requestBody['ip_address'] = ipRes === undefined ? '-1' : ipRes.ip;
    const response = await fetch(
      Constants.endPoint +
        Constants.method_good_times_characteristic_store_transcription,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(requestBody),
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getSignUpDropDownList() {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_list_of_drop_downs,
      {
        method: 'GET',
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async signUp(requestBody: any) {
    let ipRes = undefined;
    try {
      const ipResponseReq = await fetch('https://api.ipify.org/?format=json', {
        method: 'GET',
      });
      if (ipResponseReq) {
        ipRes = await ipResponseReq.json();
      }
    } catch (e) {
      log.debug(e);
    }
    const tempObj = Object.assign({}, requestBody);
    tempObj.phone_number = requestBody.country_code + requestBody.phone_number;
    //tempObj['ip_address'] = ip.address('public')
    tempObj['ip_address'] = ipRes === undefined ? '-1' : ipRes.ip;
    const response = await fetch(Constants.endPoint + Constants.method_signup, {
      method: 'POST',
      body: JSON.stringify(tempObj),
    });
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getUserProfile() {
    const response = await fetch(
      Constants.endPoint +
        Constants.method_get_user_profile +
        '/' +
        JSON.parse(sessionStorage.getItem(Constants.LOGGED_USER) as string)
          .user_id,
      {
        method: 'GET',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async logout() {
    const authorizationKey = JSON.parse(
      sessionStorage.getItem(Constants.LOGGED_USER) as string,
    ).authentication_token;
    const userID = JSON.parse(
      sessionStorage.getItem(Constants.LOGGED_USER) as string,
    ).user_id;
    sessionStorage.clear();
    // sessionStorage.clear();
    localStorage.clear();
    const response = await fetch(
      Constants.endPoint + Constants.method_logout + '/' + userID,
      {
        method: 'GET',
        headers: {
          Authorization: authorizationKey,
        },
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async verifyUser(token: any) {
    const response = await fetch(
      Constants.endPoint + Constants.method_verify_user,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async updateProfile(requestBody: any) {
    const response = await fetch(
      Constants.endPoint + Constants.method_update_user_profile,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(requestBody),
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async changePassword(requestBody: any) {
    const response = await fetch(
      Constants.endPoint + Constants.method_change_password,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(requestBody),
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getAudioAuthenticationToken() {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_token,
      {
        method: 'GET',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          log.debug('JSON DATA', data);
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async paymentHistory(requestBody: any) {
    const response = await fetch(
      Constants.endPoint + Constants.method_payment_history,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(requestBody),
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getContentManagement() {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_content,
      {
        method: 'GET',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
      },
    );
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getMenuByBrand(requestBody: any) {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_menu_by_brand,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(requestBody),
      },
    );
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async updateAvatarUserProfile(requestBody: any) {
    const response = await fetch(
      Constants.endPoint + Constants.method_update_profile_avatar,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(requestBody),
      },
    );
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
  async getLeaderBoardData(requestBody: any) {
    const response = await fetch(
      Constants.endPoint + Constants.method_get_leader_board_data,
      {
        method: 'POST',
        headers: {
          Authorization: JSON.parse(
            sessionStorage.getItem(Constants.LOGGED_USER) as string,
          ).authentication_token,
        },
        body: JSON.stringify(requestBody),
      },
    );
    log.debug('response_value', response);
    if (response && response.status === 200) {
      return await response
        .json()
        .then((data: any) => {
          return data;
        })
        .catch((error: any) => {
          return error;
        });
    }
    return undefined;
  },
};
export default Apis;
