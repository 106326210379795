/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import AppRating from 'react-rating';
import emptyStartImage from '../theme/images/ic_star_empty_48px.svg';
import fullStartImage from '../theme/images/ic_star_fill_48px.svg';
import ReactSVG from 'react-svg';
import log from '../utils/logger';

type CustomRatingBarProps = {
  onChange?: (...args: any[]) => any;
  appRating?: number;
  readonly?: boolean;
};

// eslint-disable-next-line func-style
const CustomRatingBar: React.FC<CustomRatingBarProps> = (
  props: CustomRatingBarProps,
) => {
  log.debug('CustomRatingBar', props);
  return (
    <AppRating
      placeholderSymbol={emptyStartImage}
      onHover={props.onChange}
      onChange={props.onChange}
      emptySymbol={<ReactSVG path={emptyStartImage} />}
      // fractions={true}
      fractions={1}
      readonly={props.readonly !== null ? props.readonly : true}
      initialRating={props.appRating}
      quiet={false}
      fullSymbol={<ReactSVG path={fullStartImage} />}
    />
  );
};

export default CustomRatingBar;
