import React from 'react';
import { Button, createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';

// eslint-disable-next-line func-style
const toolbarStyles = (theme: any) =>
  createStyles({
    actions: {
      color: theme.palette.text.secondary,
    },
    btnMargin: {
      // fontSize: '13px',
      // marginRight: '12px',
      // padding: '5px 18px',
      textTransform: 'capitalize',
      backgroundColor: '#488FAB',
      color: '#ffffff',
      height: '40px',
      '&:hover': {
        backgroundColor: '#488FAB',
      },
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            // backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            color: theme.palette.secondary.main,
          }
        : {
            // backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.text.primary,
          },
    root: {
      color: theme.palette.text.secondary,
      display: 'inline-block',
      paddingLeft: '0px',
      paddingRight: theme.spacing(1),
    },
    spacer: {
      flex: '1 1 100%',
    },
    title: {
      flex: '0 0 auto',
    },
  });

type PaginationFilterToolbarComponentProps = {
  classes: Record<string, any>;
  filter?: (...args: any[]) => any;
};

// interface IProps {
//   temp: any
//   history: any
//   // numSelected: number
//   classes: any,
//   filter: any
// }

class PaginationFilterToolbarComponent extends React.Component<
  PaginationFilterToolbarComponentProps
> {
  //  static propTypes: {
  //     classes: PropTypes.Validator<object>;
  //   };
  constructor(props: PaginationFilterToolbarComponentProps) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    // const isBrandComponent = this.props.history.location.pathname === Strings.DASHBOARD_BRAND_LOCATIONS ? true : false
    return (
      <React.Fragment>
        <Toolbar
          variant="dense"
          // style={{ backgroundColor: Colors.LIGHT_GRAY_BG_COLOR }}
          className={classNames(classes.root)}
        >
          <Button
            size="large"
            variant="contained"
            className={classes.btnMargin}
            onClick={this.props.filter}
            aria-label="Filter list"
          >
            Filter
          </Button>
        </Toolbar>
      </React.Fragment>
    );
  }
}

export default withStyles(toolbarStyles)(PaginationFilterToolbarComponent);
