/* eslint-disable @typescript-eslint/naming-convention */
import { Card, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { getScore } from '../../../actions';
import Api from '../../../api/Api';
import colorObject from '../../../reducers/ThemeReducer';
import VerifiedImage from '../../../theme/images/ic_done.svg';
import PayPalImage from '../../../theme/images/paypal.svg';
import Constants from '../../../utils/Constants';
import CircleProgressComponent from '../../CircleProgressComponent';
// import  { useDarkmodeContext } from 'react-use-dark-mode';
import DarkModeToggleButton from './DarkModeToggleButton';
import './ProfileComponent.css';
import EditProfileAvatarPopUp from './EditProfileAvatarPopUp';
import log from '../../../utils/logger';

let component: any;

type ProfileComponentProps = {
  history?: any;
};

type ProfileComponentState = {
  isLoading: boolean;
  checked: boolean;
  checkedToDark: boolean;
  // response: Record<string, unknown>,
  response: {
    data: {
      contact_email_id?: string;
      id?: string;
      email?: string;
      name?: string;
      year_of_birth?: number | string;
      native_language?: string;
      other_native_language?: string;
      paypal_id?: any[];
    };
  } | null;
  error: string;
  darkMode: string | null;
  userId: string;
} & ((error: any) => any);

class ProfileComponent extends Component<
  ProfileComponentProps,
  ProfileComponentState
> {
  constructor(props: ProfileComponentProps) {
    super(props);
    component = this;
    if (localStorage.getItem('DARK_MODE') === 'true') {
      document.body.classList.add('dark-mode');
    }
  }

  state = {
    isLoading: true,
    checked: false,
    checkedToDark: true,
    response: null,
    error: '',
    darkMode: localStorage.getItem('DARK_MODE'),
    userId: '',
  };

  componentDidMount() {
    // this.props
    //   .getScore()
    log.debug('colorObject', colorObject);
    this.updatedUserWithAvatar();
    log.debug('response', this.state.response);
  }

  updatedUserWithAvatar() {
    Api.getUserProfile()
      .then((response: any) => {
        if (response && response.http_status) {
          log.debug('Response', response);
          if (response.http_status === 200) {
            component.setState({
              response: response,
              userId: response.data.id,
              avatarURL: response.data.avatar_url,
              isLoading: false,
            });
          } else if (
            response.http_status === 202 ||
            response.http_status === 203
          ) {
            component.setState({ isLoading: false, error: response.message });
          }
        } else {
          component.setState({
            isLoading: false,
            error: Constants.generic_message,
          });
        }
      })
      .catch((error: any) => log.debug(error));
  }

  onBasicInfoEditClick() {
    component.props.history.push({
      pathname: '/dashboard/profile/basic/' + component.state.response.data.id,
      state: {
        email: component.state.response.data.email,
        name: component.state.response.data.name,
      },
    });
  }

  // const [checked, setChecked] = React.useState(false);
  toggleChecked() {
    // this.setState({
    //   checked: true
    // });
    this.setState({});
  }

  onCharacteristicEditClick() {
    component.props.history.push({
      pathname:
        '/dashboard/profile/characteristic/' + component.state.response.data.id,
    });
  }

  onChangePassword() {
    component.props.history.push({
      pathname:
        '/dashboard/profile/changepassword/' + component.state.response.data.id,
    });
  }

  onChangePaymentHistory() {
    log.debug('historyhistory', component.props.history);
    component.props.history.push({
      pathname:
        '/dashboard/profile/paymenthistory/' + component.state.response.data.id,
    });
  }

  // handleModeChange() {
  //   if(!component.state.darkMode) {
  //     document.body.classList.add('dark-mode');
  //   } else {
  //     document.body.classList.remove('dark-mode');
  //   }
  //   component.setState({
  //     darkMode: (!component.state.darkMode)
  //   });
  //   localStorage.setItem('DARK_MODE', !component.state.darkMode);
  // }

  addPaypalAddress() {
    component.props.history.push({
      pathname:
        '/dashboard/profile/addpaypal/' + component.state.response.data.id,
    });
  }

  onSignUp() {
    const win = window.open(
      'https://www.paypal.com/in/webapps/mpp/account-selection?&kw=ss98wozC2_dc;59055392496;slid-JSolVwQX;875c505876&gclid=Cj0KCQiA7dHSBRDEARIsAJhAHwg9Sia_y_g0qgwo9Ef4DKp3FxwwLsfuTqx6qt4VfOgGTVZ2mhja7fsaAk3AEALw_wcB&mpch=ads&mplx=27737-206509-42569-0&spid=412404218362794471',
      '_blank',
    );
    if (win !== null) {
      win.focus();
    }
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        {this.state.error.length > 0 && <div>{this.state.error}</div>}
        {this.state.isLoading && (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '100px auto',
              display: 'inline-block',
            }}
          >
            <CircleProgressComponent />
          </div>
        )}
        {this.state.response && (
          <div className="ProfilePageInfoBoxSec">
            <Card className="ProfilePageInfoBox">
              <CardText>
                <div className="headingSec">Basic Information</div>
                <RaisedButton
                  label="Edit"
                  className="editBtnSec"
                  primary={true}
                  onClick={this.onBasicInfoEditClick}
                />
                <div className="clear" />

                <EditProfileAvatarPopUp
                  userResponse={component.state.response?.data}
                />

                <div className="BoxDetailsSec">
                  <div className="BoxDetailsRow">
                    <div className="lableSec">Name</div>
                    <div className="textSec">
                      {component.state.response?.data?.name}
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="BoxDetailsRow">
                    <div className="lableSec">Email</div>
                    <div className="textSec">
                      <div className="EmailText">
                        {component.state.response?.data?.email}
                      </div>
                      <div className="VerifyedEmailText">
                        <img src={VerifiedImage} />
                        Verified!
                      </div>
                      <div className="clear" />
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="BoxDetailsRow">
                    <RaisedButton
                      onClick={this.onChangePassword}
                      label="Change Password"
                      className="TransparentBtn"
                      primary={true}
                      style={{ float: 'left' }}
                    />
                  </div>

                  <div className="clear" />
                </div>
              </CardText>
            </Card>

            <Card className="ProfilePageInfoBox">
              <CardText>
                <div className="headingSec">Characteristic Information</div>
                <RaisedButton
                  label="Edit"
                  className="editBtnSec"
                  primary={true}
                  onClick={this.onCharacteristicEditClick}
                />
                <div className="clear" />
                <div className="BoxDetailsSec">
                  <div className="BoxDetailsRow">
                    <div className="lableSec">Year of Birth</div>
                    <div className="textSec">
                      {component.state.response?.data?.year_of_birth}
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="BoxDetailsRow">
                    <div className="lableSec">Native Language</div>
                    <div className="textSec">
                      {component.state.response?.data?.native_language ===
                      'Other'
                        ? component.state.response?.data?.other_native_language
                        : component.state.response?.data?.native_language}
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="clear" />
                </div>
              </CardText>
            </Card>
            <Card className="ProfilePageInfoBox">
              <CardText>
                <div className="headingSec">Payment Setup</div>
                <div className="clear" />
                <div className="BoxDetailsSec">
                  <div className="BoxDetailsRow">
                    {
                      'In order to receive payment for your work on Tag That Data, please add your PayPal account:'
                    }
                    <div className="clear" />
                  </div>
                  {component.state.response?.data?.paypal_id &&
                  component.state.response?.data?.paypal_id.length > 0 ? (
                    <div>
                      <div className="PaypalAccountText">Paypal Account</div>
                      <div
                        className="PaypalEditTextSec"
                        onClick={this.addPaypalAddress}
                      >
                        Edit
                      </div>
                      <div className="clear" />
                      <div className="PaypalEmailTextSec">
                        {component.state.response?.data?.paypal_id}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="BoxDetailsRow">
                        <RaisedButton
                          onClick={this.addPaypalAddress}
                          label="Add PayPal Account"
                          className=""
                          primary={true}
                        />
                        <div className="clear" />
                      </div>
                      <div className="BoxDetailsRow">
                        {
                          "If you don't have a PayPal account, please register for one here:"
                        }
                        <div className="clear" />
                      </div>

                      <div className="BoxDetailsRow">
                        <img
                          className="paypallogo"
                          src={PayPalImage}
                          style={{ height: '38px', width: '146px' }}
                          onClick={this.onSignUp}
                        />
                        <RaisedButton
                          onClick={this.onSignUp}
                          label={Constants.signup}
                          className="TransparentBtn"
                          primary={true}
                        />
                      </div>
                    </div>
                  )}

                  <div className="clear" />
                </div>
              </CardText>
            </Card>

            <DarkModeToggleButton />

            <Card className="ProfilePageInfoBox">
              <CardText>
                <div className="headingSec">Contact Us</div>
                <div className="clear" />
                <div className="BoxDetailsSec">
                  <div className="BoxDetailsRow">
                    {
                      'If you have any questions about functionality, payment, or need support, please email the Tag That Data team at:'
                    }
                    <div className="clear" />
                  </div>
                  <div className="BoxDetailsRow concatUsEmailText">
                    <a
                      href={
                        'mailto:' +
                        component.state.response?.data?.contact_email_id
                      }
                    >
                      {component.state.response?.data?.contact_email_id}
                    </a>
                    <div className="clear" />
                  </div>

                  <div className="clear" />
                </div>
              </CardText>
            </Card>
            <Card className="ProfilePageInfoBox">
              <CardText>
                <div className="headingSec">Payment History</div>
                <div className="clear" />
                <div className="BoxDetailsSec">
                  <div className="BoxDetailsRow">
                    {
                      'Please click the button below to review your payment history.'
                    }
                    <div className="clear" />
                  </div>
                  <div className="BoxDetailsRow">
                    <RaisedButton
                      onClick={this.onChangePaymentHistory}
                      label="Payment History"
                      className="TransparentBtn"
                      primary={true}
                      style={{ float: 'left' }}
                    />
                  </div>

                  <div className="clear" />
                </div>
              </CardText>
            </Card>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return { score: state.score, testStatus: state.testStatus };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    getScore: () => {
      dispatch(getScore());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
