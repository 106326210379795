/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable func-style */
import { createBrowserHistory } from 'history';
import React from 'react';
import { Router, Redirect, Switch } from 'react-router-dom';
import DashboardComponent from '../components/dashboard/DashboardComponent';
import ForgotPasswordComponent from '../components/forgotpassword/ForgotPasswordComponent';
import LoginComponent from '../components/login/LoginComponent';
import PhoneNumberVerificationComponent from '../components/phoneNumberVerification/PhoneNumberVerificationComponent';
import ResetPasswordComponent from '../components/resetpassword/ResetPasswordComponent';
import AgreementComponent from '../components/signup/AgreementComponent';
import SignUpComponent from '../components/signup/SignUpComponent';
import VerifyUserComponent from '../components/verify/VerifyUserComponent';
import HomeComponent from './home/HomeComponent';
import NotAuthenticationRoute from './NotAuthenticationRoute';
import PrivateRoute from './PrivateRoute';
// import MenuComponent from "../components/dashboard/transcriptions/MenuDetailsComponent"

export const appHistory = createBrowserHistory();

// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RouterMain: React.FC<any> = (props: any) => (
  // <Router history={appHistory} render>
  <Router history={appHistory}>
    <Switch>
      <NotAuthenticationRoute exact path="/home" component={HomeComponent} />
      <NotAuthenticationRoute exact path="/" component={HomeComponent} />
      <PrivateRoute path="/dashboard" component={DashboardComponent} />
      <NotAuthenticationRoute exact path="/login" component={LoginComponent} />
      <NotAuthenticationRoute
        exact
        path="/signup"
        component={SignUpComponent}
      />
      <NotAuthenticationRoute
        exact
        path="/phoneVerification"
        component={PhoneNumberVerificationComponent}
      />
      <NotAuthenticationRoute
        exact
        path="/agreement"
        component={AgreementComponent}
      />
      <NotAuthenticationRoute
        exact
        path="/forgot"
        component={ForgotPasswordComponent}
      />
      <NotAuthenticationRoute
        exact
        path="/resetPassword/:token"
        component={ResetPasswordComponent}
      />

      <NotAuthenticationRoute
        path="/verify/:token"
        component={VerifyUserComponent}
      />
      <NotAuthenticationRoute
        component={LoginComponent}
        path="*"
        render={redirectToHome}
      />
    </Switch>
  </Router>
);

const redirectToHome = (props: any) => {
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: {
          from: props.location,
        },
      }}
    />
  );
};

// const style = {
//   padding: "20px",
//   textAlign: "center",
//   color: "red",
//   fontSize: "20px",
// };

let prevLocation: any = {};
appHistory.listen(location => {
  const pathChanged = prevLocation.pathname !== location.pathname;
  const hashChanged = prevLocation.hash !== location.hash;
  if (pathChanged || hashChanged) {
    window.scrollTo(0, 0);
  }
  prevLocation = location;
});

export default RouterMain;
