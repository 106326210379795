import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import React from 'react';
import { confirmable } from 'react-confirm';
import { Editor } from 'react-draft-wysiwyg';
import Theme from '../../Theme';
// import CloseIcon from '../../theme/images/ic_close_gray.png';
import './Dialogs.css';
import Api from '../../api/Api';
// import Slide from "@material-ui/core/Slide";
import CircleProgressComponent from '../../utils/CircularProgressComponent';
import log from '../../utils/logger';

// const transition = React.forwardRef(function transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

type MenuDetailsDialogComponentProps = {
  history?: any;
  confirmation: any;
  cancel: (...args: any[]) => any;
  dismiss: (...args: any[]) => any;
  proceed: (...args: any[]) => any;
  show: false & ((...args: any[]) => any);
};

type MenuDetailsDialogComponentState = { loading: boolean } & {
  editorState: any;
  contentLoading: boolean;
  errorMessage: string;
  file: string;
  loading: boolean;
  show: boolean;
  data: any;
};

class MenuDetailsDialogComponent extends React.Component<
  MenuDetailsDialogComponentProps,
  MenuDetailsDialogComponentState
> {
  constructor(props: MenuDetailsDialogComponentProps) {
    super(props);

    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);

    log.debug(
      'this',
      this.props,
      this.props.confirmation,
      this.props.confirmation.menu,
    );
    this.state = {
      editorState: EditorState.createEmpty(),
      contentLoading: false,
      errorMessage: '',
      file: '',
      loading: true,
      show: true,
      data: null,
    };
  }

  componentDidMount() {
    this.contentManagement();
    if (this.props.confirmation.menu === undefined) {
      this.props.history.push('/dashboard/transcriptions');
      return;
    }
  }

  contentManagement() {
    const request = {
      // eslint-disable-next-line camelcase
      // eslint-disable-next-line @typescript-eslint/naming-convention
      customer_id: this.props.confirmation.menu,
    };
    Api.getMenuByBrand(request)
      .then(data => {
        if (data !== undefined && data.http_status) {
          this.setState({
            loading: true,
          });
          if (data.http_status === 200) {
            log.debug('componentDidMount', data.data);
            data.data.map((item: any) => {
              log.debug('componentDidMount', item);
              const contentBlock = htmlToDraft(item.body_text);
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks,
              );
              this.setState({
                editorState: EditorState.createWithContent(contentState),
                loading: false,
              });
            });
            this.setState({
              data: data.data.faq,
              loading: false,
            });
          } else {
            this.setState({ loading: false, data: [] });
          }
        } else {
          this.setState({
            loading: false,
            errorMessage: data ? data.message : 'err',
          });
        }
      })
      .catch(reason => {
        log.debug('Exception', reason);
        this.setState({ loading: false, errorMessage: reason });
      });
  }

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async uploadImageCallBack(file: any) {
    //TODO
  }

  onEditorStateChange = (editorState: any) => {
    log.debug(editorState);
    this.setState({
      editorState,
    });
  };

  handleClose = () => {
    this.props.cancel();
    this.setState({ show: false });
  };

  handleData = () => {
    this.props.proceed();
    this.setState({ show: false });
  };

  render() {
    // const customContentStyle = {
    //   // width: "450px",
    //   // maxWidth: "none",
    //   // maxWidth: "100%",
    //   // marginLeft:"40px"
    // };
    log.debug('Props are', this.props);
    const actions = [
      <FlatButton
        key={0}
        style={{
          height: '45px',
          marginRight: '24px',
          lineHeight: '36px',
          padding: '0px 25px',
        }}
        className="OK"
        label={'OK'}
        onClick={this.handleClose}
      />,
    ];
    return (
      <Theme>
        <Dialog
          paperClassName="menuWrapper"
          // TransitionComponent={transition}
          actions={actions}
          // fullScreen
          modal={false}
          open={this.state.show}
          // bodyStyle={{ color: 'black' }}
          // style={{ color: 'black' }}
          onRequestClose={this.handleClose}
          // contentStyle={customContentStyle}
          autoScrollBodyContent={true}
          actionsContainerStyle={{
            // textAlign: "center",
            padding: '10px 0px 40px 0px',
          }}
        >
          <div className="menuWrapper">
            <h3>Menu</h3>
            <div style={{ color: '#4A4A4A' }}>
              <div className="popCloseBtn">
                {/* <img
                src={
                  this.state.darkMode === 'true'
                    ? CloseIconWhite
                    : CloseIconGray
                }
                onClick={this.handleClose}
              /> */}
              </div>

              {this.state.loading ? (
                <CircleProgressComponent />
              ) : (
                <div className="faqCard">
                  <Editor
                    toolbarHidden={true}
                    editorState={this.state.editorState}
                    wrapperClassName="wrapper-class-custom"
                    editorClassName="editor-class-custom"
                    toolbarClassName="toolbar-class-custom"
                    readOnly={true}
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      image: {
                        uploadCallback: this.uploadImageCallBack,
                        previewImage: true,
                        uploadEnabled: true,
                        alt: {
                          present: true,
                          mandatory: true,
                        },
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </Dialog>
      </Theme>
    );
  }
}

export default confirmable(MenuDetailsDialogComponent);
