/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable func-style */
import React from 'react';
import { RouteProps, Switch } from 'react-router-dom';
import AudioSamplesCompletedComponent from '../components/AudioSampleCompletedComponent';
import AudioRecorderComponentNew from '../components/dashboard/collections/AudioRecorderComponentNew';
import AudioRecorderSampleComponent from '../components/dashboard/collections/AudioRecorderSampleComponent';
import FAQsComponent from '../components/dashboard/faqs/FaqsComponent';
import TranscribeFaqsComponent from '../components/dashboard/faqs/TranscribeCharacteristicFaqsComponent';
import ChangePasswordComponent from '../components/dashboard/profile/ChangePasswordComponent';
import CharacteristicEditComponent from '../components/dashboard/profile/CharacteristicEditComponent';
import PaymentHistoryComponent from '../components/dashboard/profile/PaymentHistoryPaginationComponent';
import ProfileBasicEditComponent from '../components/dashboard/profile/ProfileBasicEditComponent';
import ProfileComponent from '../components/dashboard/profile/ProfileComponent';
import ProfilePayPalEditComponent from '../components/dashboard/profile/ProfilePayPalEditComponent';
import AudioTranscriptionPlay from '../components/dashboard/transcriptions/AudioTranscriptionPlayComponent';
// import Characteristics from "../components/dashboard/transcriptions/CharacteristicsComponent";
import MenuDetailsComponent from '../components/dashboard/transcriptions/menuDetailsComponent';
import LeaderBoardComponent from '../components/dashboard/leaderBoard/LeaderBoardComponent';
import PrivateRoute from './PrivateRoute';

// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DashboardRoutes = (props: RouteProps) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/dashboard/collections"
        component={AudioRecorderSampleComponent}
      />
      <PrivateRoute
        exact
        path="/dashboard/profile"
        component={ProfileComponent}
      />
      <PrivateRoute
        exact
        path="/dashboard/profile/characteristic/:id"
        component={CharacteristicEditComponent}
      />
      <PrivateRoute
        exact
        path="/dashboard/profile/paymenthistory/:id"
        component={PaymentHistoryComponent}
      />
      <PrivateRoute
        exact
        path="/dashboard/profile/changepassword/:id"
        component={ChangePasswordComponent}
      />
      <PrivateRoute
        exact
        path="/dashboard/profile/addpaypal/:id"
        component={ProfilePayPalEditComponent}
      />
      <PrivateRoute
        exact
        path="/dashboard/profile/basic/:id"
        component={ProfileBasicEditComponent}
      />
      <PrivateRoute exact path="/dashboard/faqs" component={FAQsComponent} />
      <PrivateRoute
        exact
        path="/dashboard/leaderboard"
        component={LeaderBoardComponent}
      />
      <PrivateRoute
        exact
        path="/dashboard/faqs/transcribefaqs"
        component={TranscribeFaqsComponent}
      />
      <PrivateRoute
        exact
        path="/dashboard/faqs/characteristicsfaqs"
        component={TranscribeFaqsComponent}
      />
      <PrivateRoute
        exact
        path="/dashboard/collections/calibration"
        component={AudioTranscriptionPlay}
      />
      <PrivateRoute
        exact
        path="/dashboard/collections/samplecollections"
        component={AudioRecorderComponentNew}
      />
      <PrivateRoute
        exact
        path="/dashboard/transcriptions"
        component={AudioTranscriptionPlay}
      />
      <PrivateRoute
        exact
        path="/dashboard/brand/menu"
        component={MenuDetailsComponent}
      />
      <PrivateRoute
        exact
        path="/dashboard/collections/thanku"
        component={AudioSamplesCompletedComponent}
      />
      <PrivateRoute exact path="*" component={NoMatchCase} />{' '}
    </Switch>
  );
};

const style = {
  padding: '20px',
  textAlign: 'center' as const,
  color: 'red',
  fontSize: '20px',
};

const NoMatchCase = () => <div style={style}>No Match Found</div>;

export default DashboardRoutes;
