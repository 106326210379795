/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/naming-convention */
import Checkbox from '@material-ui/core/Checkbox';
import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import infoIcon from '../../../theme/images/ic_info_black_18px.svg';
import Constants from '../../../utils/Constants';
import {
  // AudioQualityInfoAlertComponentConfirmDialogComponent,
  singleChoiceAlertDialogComponent,
} from '../../../utils/DialogsUtils';
// import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { createMuiTheme } from '@material-ui/core/styles';
import Theme from '../../../theme/CustomTheme';
import { MuiThemeProvider } from '@material-ui/core';
import log from '../../../utils/logger';

type AudioIssuesComponentProps = {
  audioIssues?: any[];
  audioDetails?: any;
  onCheck?: (...args: any[]) => any;
  onChange?: (...args: any[]) => any;
  userSelectedAudioIssues?: any;
  appRating?: any;
  metaData?: any;
  ageRange?: any;
  genders?: any;
  onAgeRangeSelection?: (...args: any[]) => any;
  onGenderSelection?: (...args: any[]) => any;
  onFrustrationLevelSelection?: (...args: any[]) => any;
  onNativeSpeakerSelection?: (...args: any[]) => any;
};

type AudioIssuesComponentState = {
  selected_age_range: string;
};

// let component: any;
class AudioIssuesComponent extends Component<
  AudioIssuesComponentProps,
  AudioIssuesComponentState
> {
  constructor(props: AudioIssuesComponentProps) {
    super(props);
    // component = this;
    log.debug('hashchange1', window.location.pathname);
    this.state = {
      // eslint-disable-next-line camelcase
      selected_age_range: '',
    };
  }

  // showRatingInfoAlert() {
  //   AudioQualityInfoAlertComponentConfirmDialogComponent(
  //     Constants.audio_issues_info_message
  //   ).then((params: any) => {}, () => 0);
  // }

  showAudioIssuesInfoAlert(audioIssue: any, context: any) {
    let dialogTitle = '';
    log.debug('context a', context, audioIssue);
    switch (audioIssue) {
      case 'Flag Offensive Audio':
        dialogTitle = Constants.audio_issue_offensive_audio;
        break;
      case "Can't Transcribe":
        dialogTitle = Constants.audio_issues_info_message;
        break;
      case 'Employee or Robot':
        dialogTitle = Constants.audio_issue_employee;
        break;
      case 'Spanish':
        dialogTitle = Constants.audio_issue_spanish;
        break;
      case 'Unknown Language':
        dialogTitle = Constants.audio_issue_unknown_language;
        break;
      default:
    }
    // dialogTitle = audioIssue === "Flag Offensive Audio"
    //   ? Constants.audio_issue_offensive_audio
    //   : Constants.audio_issues_info_message;

    singleChoiceAlertDialogComponent(dialogTitle).then(
      // eslint-disable-next-line no-unused-vars
      (params: any) => {},
      () => 0,
    );
  }

  showCharacteristicIssuesInfoAlert(audioIssue: any, context: any) {
    let dialogTitle = '';
    log.debug('context c', context, audioIssue);
    switch (audioIssue) {
      case 'Multiple Speakers':
        dialogTitle = Constants.audio_issue_characteristic_multiple;
        break;
      case 'Noise Only':
        dialogTitle = Constants.audio_issue_characteristic_noise_only;
        break;
      case 'Employee or Robot':
        dialogTitle = Constants.characteristic_issue_employee;
        break;
      case 'Inquiry':
        dialogTitle = Constants.audio_issue_Inquiry;
        break;
      default:
    }

    singleChoiceAlertDialogComponent(dialogTitle).then(
      // eslint-disable-next-line no-unused-vars
      (params: any) => {},
      () => 0,
    );
  }

  // handleChange = (event: any) => {
  //   this.setState({ [event.target.name]: event.target.value });
  // };

  filterAudioIssues = () => {
    if (
      this.props.audioDetails !== undefined &&
      this.props.audioDetails.question_id !== 4 &&
      this.props.audioIssues !== undefined &&
      this.props.audioIssues.length > 0
    ) {
      return this.props.audioIssues.filter(audioIssue => {
        return audioIssue.id !== 4;
      });
    }
    return this.props.audioIssues;
  };

  render() {
    const audioIssues = this.filterAudioIssues();
    return (
      <MuiThemeProvider theme={Theme}>
        <div className="SampleValidCheckBoxSec">
          <div className="checkBoxTitleSec">Audio Issues </div>
          <div className="clear" />
          {audioIssues &&
            audioIssues.map((audioIssue: any, index: number) => {
              return (
                <div key={index} className="checkBoxRowSec">
                  <FormControlLabel
                    style={{ margin: '0' }}
                    control={
                      <Checkbox
                        onChange={this.props.onCheck}
                        id={String(audioIssue.id)}
                        style={{ color: '#488FAB', padding: '4px' }}
                      />
                    }
                    label={audioIssue.audio_issue}
                    labelPlacement="end"
                  />

                  <div
                    onClick={
                      window.location.pathname === '/dashboard/transcriptions'
                        ? this.showAudioIssuesInfoAlert.bind(
                            this,
                            audioIssue.audio_issue,
                          )
                        : this.showCharacteristicIssuesInfoAlert.bind(
                            this,
                            audioIssue.audio_issue,
                          )
                    }
                    className="infoIconSec"
                  >
                    <ReactSVG path={infoIcon} />
                  </div>
                </div>
              );
            })}

          <div className="clear" />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default AudioIssuesComponent;
