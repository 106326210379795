/* eslint-disable func-style */
import React from 'react';
// import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import log from '../../utils/logger';

// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: any) =>
  createStyles({
    sortColor: {
      '-moz-transition': 'all $duration ease-out',
      '-ms-transition': 'all $duration ease-out',
      '-o-transition': 'all $duration ease-out',
      '-webkit-transition': 'all $duration ease-out',
      // tslint:disable-next-line:object-literal-sort-keys
      '&:active': {
        color: '#265067',
      },
      '&:focus': {
        color: '#265067',
      },
      '&:hover': {
        color: '#265067',
      },
      color: '#265067',
      fontSize: '14px',
      fontWeight: 'bold',
      transition: 'all 0.3s ease-in-out',
    },
    tableHeaderStyle: {
      backgroundColor: '#DEE8EF',
      // padding: '0px 0px 0px 18px ',
      borderRight: '1px solid rgba(0,0,0,0.1)',
    },
  });

type PaginationHeaderComponentProps = {
  order?: any;
  orderBy?: any;
  headers?: any;
  classes: Record<string, string | undefined>;
  rowCount?: number;
  onRequestSort: (...args: any[]) => any;
  onSelectAllClick?: (...args: any[]) => any;
};

type PaginationHeaderComponentState = {
  temp: boolean;
};

class PaginationHeaderComponent extends React.Component<
  PaginationHeaderComponentProps,
  PaginationHeaderComponentState
> {
  state = {
    temp: false,
  };

  createSortHandler = (property: any) => (event: any) => {
    log.debug(event);
    log.debug(property);
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, classes, headers } = this.props;
    return (
      <TableHead>
        <TableRow>
          {headers.map(
            (row: any) => (
              <TableCell
                className={classes.tableHeaderStyle}
                key={row.id}
                style={{ padding: ' 16px' }}
                align={row.numeric ? 'left' : 'left'}
                // padding={row.disablePadding ? 'checkbox' : 'checkbox'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === row.id && row.sort}
                  direction={order}
                  style={{ cursor: row.sort ? 'pointer' : 'auto' }}
                  onClick={
                    row.sort ? this.createSortHandler(row.id) : undefined
                  }
                  className={classes.sortColor}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default withStyles(styles)(PaginationHeaderComponent);
