/* eslint-disable @typescript-eslint/naming-convention */
// import MenuIcon from "material-ui/Menu";
import MenuIcon from 'material-ui/svg-icons/navigation/menu';
// import { createHashHistory } from "history";
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import NavigationBack from 'material-ui/svg-icons/navigation/arrow-back';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Constants from '../utils/Constants';
import { singleChoiceAlertDialogComponent } from '../utils/DialogsUtils';
import './AppBarComponent.css';
import log from '../utils/logger';
import { AppBar } from 'material-ui';

type AppBarComponentProps = {
  history: any;
  score?: Record<string, unknown>;
  handleMenuTouchTap: (...args: any[]) => any;
  onLogout: (...args: any[]) => any;
};

type AppBarComponentState = {
  title: string;
  isNeedToHideScore: boolean;
  isNeedToShowBackButton: boolean;
};

// const history = createHashHistory();
class AppBarComponent extends Component<
  AppBarComponentProps,
  AppBarComponentState
> {
  unlisten: any;
  constructor(props: AppBarComponentProps) {
    super(props);
    this.state = {
      title: '',
      isNeedToHideScore: false,
      isNeedToShowBackButton: false,
    };
    this.updateAppBarTitle = this.updateAppBarTitle.bind(this);
    this.onNavigationButtonClick = this.onNavigationButtonClick.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen((location: any) => {
      log.debug('APP_Location' + location.pathname);
      this.updateAppBarTitle(location.pathname);
    });
    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    window.addEventListener('load', (e: any) => {
      this.updateAppBarTitle(window.location.pathname);
      log.debug('hashchange1', window.location.pathname);
    });
  }

  componentDidMount() {
    this.updateAppBarTitle(window.location.pathname);
    log.debug('SCORE_DID', this.props.score);
    // if(this.props.score){
    //     if(this.props.score.this.props.score.is_disqualified){
    //         var data={is_disqualified:true}
    //         InvalidUserAlertComponent(data).then((params) => {
    //             component.props.onLogout
    //             history
    //                 .push('/dashboard/login')
    //         }, () => {
    //         });
    //     }else if(this.props.score.this.props.score.is_notification=="true"){
    //         var data={is_disqualified:false}
    //         InvalidUserAlertComponent(data).then((params) => {
    //         }, () => {
    //         });
    //     }
    // }
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    window.removeEventListener('load', (e: any) => {
      this.updateAppBarTitle(window.location.pathname);
    });
    this.unlisten();
  }

  updateAppBarTitle(pathname: string) {
    if (
      pathname.startsWith('/dashboard/collections') ||
      // pathname.startsWith('/dashboard/collections/thanku') ||
      pathname.startsWith('/dashboard/transcriptions')
    ) {
      this.setState({
        title: Constants.audio_transcription,
        isNeedToShowBackButton: false,
      });
      // } else if (
      //   pathname.startsWith('/dashboard/transcriptions') ||
      //   pathname.startsWith('/dashboard/transcriptions/thanku')
      // ) {
      //   this.setState({
      //     title: Constants.audio_transcription,
      //     isNeedToShowBackButton: false,
      //   });
    } else if (pathname.startsWith('/dashboard/profile')) {
      this.setState({
        title: Constants.profile,
        isNeedToShowBackButton: false,
      });
    } else if (pathname.startsWith('/dashboard/faqs/characteristicsfaqs')) {
      this.setState({
        title: Constants.CHARACTERISTICS_FAQ,
        isNeedToShowBackButton: true,
      });
    } else if (pathname.startsWith('/dashboard/tagcharacteristics')) {
      this.setState({
        title: Constants.demo_graphics,
        isNeedToShowBackButton: false,
      });
    } else if (pathname.startsWith('/dashboard/brand/menu')) {
      this.setState({
        title: 'Menu',
        isNeedToShowBackButton: true,
      });
    } else if (pathname === '/dashboard/faqs') {
      this.setState({ title: Constants.FAQ, isNeedToShowBackButton: false });
    } else if (pathname.startsWith('/dashboard/faqs/transcribefaqs')) {
      this.setState({
        title: Constants.TRANSCRIBE_FAQ,
        isNeedToShowBackButton: true,
      });
    } else if (pathname === '/dashboard/leaderboard') {
      this.setState({
        title: Constants.LEADER_BOARD,
        isNeedToShowBackButton: false,
      });
    }
  }

  onNavigationButtonClick() {
    const pathname = this.props.history.location.pathname;
    log.debug(pathname);
    if (this.state.isNeedToShowBackButton) {
      this.props.history.goBack();
    } else {
      this.props.handleMenuTouchTap();
    }
  }

  onInfoButtonClick() {
    singleChoiceAlertDialogComponent(Constants.paypalAlert).then(
      // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (params: any) => {
        this.props.history.push('/dashboard/profile');
      },
      () => 0,
    );
  }

  render() {
    return (
      <div>
        <AppBar
          className="AppBarSec"
          onLeftIconButtonTouchTap={this.onNavigationButtonClick}
          iconElementLeft={
            this.state.isNeedToShowBackButton ? (
              <IconButton>
                {' '}
                <NavigationBack />{' '}
              </IconButton>
            ) : (
              <IconButton>
                <MenuIcon />
              </IconButton>
            )
          }
          iconElementRight={
            <ul
              style={{
                float: 'right',
                listStyleType: 'none',
                display: 'inline-flex',
                margin: '3px 15px 5px 0px',
                padding: '0px',
              }}
            >
              <li>
                <FlatButton
                  style={{ float: 'right', margin: '0px 0px 0px 10px' }}
                  className="logoutBtnSec"
                  onClick={this.props.onLogout}
                  label={Constants.logout}
                  labelStyle={{ textTransform: 'capitalize' }}
                />
              </li>
            </ul>
          }
          title={this.state.title}
        >
          {' '}
        </AppBar>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    score: state.score,
    testStatus: state.testStatus,
    maskStatus: state.maskStatus,
  };
}

//contributor_score
//transcriber_score

export default connect(mapStateToProps)(AppBarComponent);
