/*
 A component to handle the other options pop-up with the predefined options in the pop-up and handle the clicks.
 This pop-up will appear when selected "Other" from dropdown.
*/
import React from 'react';
import Dialog from 'material-ui/Dialog';
// import FlatButton from 'material-ui/FlatButton';
import { confirmable } from 'react-confirm';
import Theme from '../../Theme';
import CloseIconGray from '../../theme/images/ic_close_gray.png';
import CloseIconWhite from '../../theme/images/ic_close_white.png';
import Constants from '../../utils/Constants';
import './Dialogs.css';
import log from '../../utils/logger';

type BrowserCompatibilityAlertComponentProps = {
  cancel: (...args: any[]) => any;
  proceed: (...args: any[]) => any;
  confirmation: string & ((...args: any[]) => any);
  dismiss: (...args: any[]) => any;
  show: false & ((...args: any[]) => any);
};

type BrowserCompatibilityAlertComponentState = {
  oldState: any;
  show: boolean;
  open: boolean;
  checked: boolean;
  defaultOptions: any[];
  darkMode: string | null;
};

// let component: any;

class BrowserCompatibilityAlertComponent extends React.Component<
  BrowserCompatibilityAlertComponentProps,
  BrowserCompatibilityAlertComponentState
> {
  constructor(props: BrowserCompatibilityAlertComponentProps) {
    super(props);
    this.state = {
      show: true,
      open: false,
      checked: false,
      oldState: null,
      defaultOptions: [],
      darkMode: localStorage.getItem('DARK_MODE'),
    };
    // component = this;
  }

  /*
    closes pop-up when "X" is clicked
    */
  handleClose = () => {
    this.props.cancel();
    this.setState({ show: false });
    // if (component.state.checked) {
    //   //this.props.proceed();
    //   this.setState({ show: false });
    // }else{
    //   this.setState({ open: true });
    // }
  };

  /*

      Submit button method which passes the selected options to the parent transcription class.
    */
  handleData = () => {
    this.props.proceed();
    this.setState({ show: false });
    // if (component.state.checked) {
    //   this.props.proceed();
    // }else{
    //   this.setState({ open: true });
    // }
  };

  updateCheck() {
    this.setState(oldState => {
      return {
        checked: !oldState.checked,
      };
    });
  }

  /*
  Check box methods which removes or adds the selected option into array
  */
  handleRequestClose = () => {
    this.props.proceed();
    this.setState({ open: false });
  };

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleCheck = (event: any, isChecked: boolean) => {
    const target = event.target;
    const selectedName = target.id;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const checked = this.state.defaultOptions.slice();
    // const selectedItems = this.state.selectedItems.slice();
    if (value === true) {
      checked.push(selectedName);
      this.state.defaultOptions.push(selectedName);
    } else {
      const index = checked.indexOf(selectedName);
      checked.splice(index, 1);
      this.state.defaultOptions.splice(index, 1);
    }
  };

  render() {
    // const {
    //   // okLabel = 'OK',
    //   // cancelLabel = 'Cancel',
    //   // title,
    //   // confirmation,
    //   // show,
    //   // proceed,
    //   // dismiss,
    //   // cancel,
    //   // modal,
    // } = this.props;
    // const customContentStyle = {
    //   width: '600px',
    //   // maxWidth: 'none',
    //   maxWidth: '100%',
    // };

    log.debug('Props are', this.props);

    // const actions = [
    //   <FlatButton
    //     key={0}
    //     className="OK"
    //     label={okLabel}
    //     style={{ height: '35px', lineHeight: '30px', width: '200px' }}
    //     onClick={this.handleData}
    //   />,
    // ];

    return (
      <Theme>
        <Dialog
          className="submitDialog"
          modal={true}
          open={this.state.show}
          contentClassName="customDialogBox"
          autoScrollBodyContent={true}
          onRequestClose={this.handleClose}
          actionsContainerStyle={{
            textAlign: 'center',
            padding: '10px 0px 40px 0px',
          }}
        >
          <div>
            <div className="popCloseBtn">
              <img
                src={
                  this.state.darkMode === 'true'
                    ? CloseIconWhite
                    : CloseIconGray
                }
                onClick={this.handleClose}
              />
            </div>
            <div className="popUpReportIconSec">
              <svg
                className="icon"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
              </svg>
            </div>
            <div
              style={{
                paddingBottom: '20px',
                fontWeight: 400,
                textAlign: 'center',
              }}
              className="innerTextSec"
            >
              {Constants.browser_compatibility_message}
            </div>
          </div>
        </Dialog>
      </Theme>
    );
  }
}

export default confirmable(BrowserCompatibilityAlertComponent);
