/* eslint-disable @typescript-eslint/naming-convention */
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import './index.css';
import todoApp from './reducers';
import RouterMain from './routes/RouterMain';
// import companyBaseTheme from "./theme/CompanyBaseTheme";
import { theme } from './theme/CompanyBaseTheme';
// import log from './utils/logger';

let store: any;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  store = createStore(todoApp, applyMiddleware(logger, thunk));
} else {
  // production code
  store = createStore(todoApp, applyMiddleware(thunk));
}

if (
  localStorage.getItem('DARK_MODE') &&
  localStorage.getItem('DARK_MODE') === 'true'
) {
  document.body.classList.add('dark-mode');
}

// log.info(`public url: ${process.env.PUBLIC_URL}`);

// const headers = [
//   { label: `First Name`, key: `firstname` },
//   { label: `Last Name`, key: `lastname` },
//   { label: `Email`, key: `email` },
// ];
// const data = [
//   ["Foo", "programmer", "99", "99"],
//   ["Bar", "bus driver", "99", "99"],
//   ["Moo", "Reindeer Hunter", "99", "99"],
// ];
// function downloadCsv() {
//   let csv = "Name,Title,Marks,Total\n";
//   data.forEach((row) => {
//     csv += row.join(",");
//     csv += "\n";
//   });
//   const hiddenElement = document.createElement("a");
//   hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
//   hiddenElement.target = "_blank";
//   hiddenElement.download = "people.csv";
//   hiddenElement.click();
// }

// eslint-disable-next-line func-style
const RouteApp: React.FC = () => (
  <HttpsRedirect>
    <MuiThemeProvider muiTheme={getMuiTheme(theme)}>
      <Provider store={store}>
        <RouterMain />
      </Provider>
    </MuiThemeProvider>
  </HttpsRedirect>
);

ReactDOM.render(<RouteApp />, document.getElementById('root'));
