/* eslint-disable @typescript-eslint/naming-convention */
import { combineReducers, Reducer } from 'redux';
import Score from './Score';
import TestStatus from './TestStatus';
import MaskStatus from './MaskStatus';
import PauseAudio from './PauseAudio';
import SwitchTheme from './ThemeReducer';
import { LogOut, LOG_OUT } from '../actions';
import { DARK_MODE } from '../theme/ThemeColors';

export type RootState = ReturnType<typeof rootReducer>;

const appReducer: Reducer = combineReducers({
  score: Score,
  testStatus: TestStatus,
  maskStatus: MaskStatus,
  pauseAudio: PauseAudio,
  switchTheme: SwitchTheme,
});

const initialState = appReducer(
  {},
  {
    type: LOG_OUT,
    status: false,
    paused: false,
    darkTheme: false,
    ...DARK_MODE,
  },
  // { status: false, isLoggedOut: true },
);

function rootReducer(state: any, action: LogOut) {
  if (action.type === LOG_OUT) {
    // eslint-disable-next-line no-param-reassign
    state = initialState;
  }
  return appReducer(state, action);
}

export default rootReducer;
