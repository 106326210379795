/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
// import AcceptAudio from 'material-ui/svg-icons/action/done';
import AudioPause from 'material-ui/svg-icons/av/pause';
import AudioPlay from 'material-ui/svg-icons/av/play-arrow';
import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  getScore,
  updateMaskStatus,
  updateTutorialFlag,
} from '../../../actions';
import Api from '../../../api/Api';
import {
  checkBrowserCompatibility,
  getAudioS3URL,
  getContext,
  isAudioApiTokenEmpty,
  isAutoPlayEnabled,
  isLoopEnabled,
  updateAudioLoop,
  updateAutoPlayAudio,
  replaceUnwantedWords,
} from '../../../utils/AppUtils';
import CircularProgressComponent from '../../../utils/CircularProgressComponent';
import Colors from '../../../theme/Colors';
import Constants from '../../../utils/Constants';
import CustomFloatingActionButton from '../../CustomFloatingActionButton';
import '../../dashboard/SampleCalibrationComponent.css';
import WaveformNew from '../../waveform/WaveformNew';
import AudioIssuesComponent from './AudioIssuesComponent';
import AudioTranscriptionTextField from './AudioTranscriptionTextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { updatePreviousAudioStatus } from '../../../utils/AppUtils';
import { Button } from '@material-ui/core';
// import purple from '@material-ui/core/colors/purple';
import Theme from '../../../theme/CustomTheme';
import { MuiThemeProvider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { singleChoiceWithoutAutoCloseAlertDialogComponent } from '../../../utils/DialogsUtils';
import Styles from './TranscriptionStyles';
import log from '../../../utils/logger';
import turtleIcon from '../../../theme/images/turtle.png';
import turtleIconGray from '../../../theme/images/turtle_gray.png';

let component: any;
const player: any = null;
let playAudio: any = null;
let pauseAudio: any = null;
let isFirstTime = 'true';
let handlePlayBackSpeed: any;
let enterKeyHit = false;

type AudioPlayComponentGoodtimesProps = {
  classes: Record<string, unknown | any>;
  history?: Record<string, unknown>;
  updateMaskStatus: (...args: any[]) => any;
  pauseAudio?: boolean;
  score?: Record<string, unknown>;
};

type AudioPlayComponentGoodtimesState = {
  blobUrl: string;
  playing: boolean;
  isAudioPlaying: boolean;
  played: boolean | null;
  pos: number;
  no_records: boolean;
  isResponseError: boolean;
  isLoading: boolean;
  audioFilesError: boolean;
  audioDecodeError: boolean;
  currentAudioDetails: any;
  intents: any[];
  markerStyle: Record<string, unknown>;
  isThisTutorial: boolean;
  position: number;
  buffer: any;
  responsive: boolean;
  showPosition: boolean;
  waveStyle: Record<string, unknown>;
  previousAudioStatus: boolean;
  metaData: Record<string, string>;
  isAudioAcceptClicked: boolean;
  dropDownValue: string;
  transcriptText: string;
  finalOtherOptions: string;
  dropdownsList: any;
  previousAudioId: any;
  errorText: string;
  appRating: number;
  userSelectedAudioIssues: Record<string, unknown>;
  context: AudioContext | null;
  audioIssues: any[];
  audioLoopChecked: boolean;
  autoPlayChecked: boolean;
  brandDetails: string;
  playbackRate: number;
  rulesAck: string | null;
} & ((error: any) => void);

class AudioPlayComponentGoodtimes extends Component<
  AudioPlayComponentGoodtimesProps,
  AudioPlayComponentGoodtimesState
> {
  constructor(props: AudioPlayComponentGoodtimesProps) {
    super(props);
    this.state = {
      blobUrl: '',
      brandDetails: '',
      playbackRate: 0.7,
      previousAudioId: '-1',
      finalOtherOptions: '',
      errorText: '',
      audioFilesError: false,
      audioDecodeError: false,
      isLoading: true,
      isAudioAcceptClicked: false,
      isResponseError: false,
      transcriptText: '',
      currentAudioDetails: null,
      dropDownValue: 'Select',
      playing: false,
      isAudioPlaying: false,
      played: null,
      pos: 0,
      intents: [],
      audioIssues: [],
      userSelectedAudioIssues: {},
      appRating: 0,
      context: null,
      markerStyle: {
        // waveform marker default style.
        color: 'rgba(0,0,0,.05)',
        width: 4,
      },
      isThisTutorial: true,
      position: 0,
      buffer: null,
      responsive: true,
      showPosition: true,
      waveStyle: {
        // to set the wavefom style.
        animate: true,
        color: '#488FAB',
        pointWidth: 1,
        plot: 'line',
      },
      dropdownsList: null,
      no_records: false,
      previousAudioStatus: false,
      autoPlayChecked: isAutoPlayEnabled() === 'true' ? true : false,
      audioLoopChecked: isLoopEnabled() === 'true' ? true : false,
      metaData: {
        age_range: 'none',
        frustration_level: 'none',
        native_speaker: 'none',
        gender: 'none',
        gender_error: '',
        age_range_error: '',
        native_speaker_error: '',
        frustration_error: '',
      },
      rulesAck: localStorage.getItem('RULES_ACK'),
    };
    component = this; // global component.
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handlePosChange = this.handlePosChange.bind(this);
  }

  handleTogglePlay() {
    this.setState({
      playing: !this.state.playing,
    });
  }

  handlePosChange(event: any) {
    this.setState({
      pos: event.originalArgs[0],
    });
  }

  componentWillUnmount() {
    component.pauseAudioPlay();
    window.removeEventListener('resize', this.onWindowSizeChanged);
    this.props.updateMaskStatus(false);
  }
  /*
      Get the current context to decode the audio and on componentdidmount get the current audio file to be played.
      */
  async UNSAFE_componentWillMount() {
    const context = getContext();
    log.debug('context', this.props);
    this.setState({
      context,
    });
    await context.close();
  }

  async componentDidMount() {
    // window.addEventListener("resize", this.onWindowSizeChanged);
    component.pauseAudioPlay();
    checkBrowserCompatibility();
    await Api.getSignUpDropDownList()
      .then(
        response => {
          if (
            response &&
            response.http_status &&
            response.http_status === 200
          ) {
            log.debug(response);
            // console.error("AGE_RANGES", response.age_range)
            component.setState({ dropdownsList: response });
            component.setState({ dropdownsList: response });
          } else {
            log.debug('AGE_RANGES', 'Nothing');
          }
        },
        (error: any) => {
          log.debug(error);
          // console.error("AGE_RANGES", error)
        },
      )
      .catch((error: any) => {
        log.debug(error);
        //console.error("AGE_RANGES", error)
      });
    // this.getCurrentAudioId("0", component.state.previousAudioId);
    this.getCurrentAudioId('0');
    // get the score
    component.props.getScore();
  }
  // makeWave() {
  //   const options = {
  //     waveColor: 'violet',
  //     progressColor: 'purple',
  //   };
  // }
  /*
      After getting current audio id to be played, get the file from that audio id to show the waveform only.
     */
  getCurrentAudioId = (audioCountFlag: string) => {
    Api.getGoodTimesCurrentAudioId(
      audioCountFlag,
      component.state.previousAudioId,
    )
      .then((response: any) => {
        log.debug('Audio id response is', response);
        if (response && response.http_status && response.http_status === 200) {
          const audioIdDetails = response.data[0];
          component.setState({
            no_records: false,
            brandDetails: audioIdDetails.customer_id,
            currentAudioDetails: audioIdDetails,
            isResponseError: false,
            isLoading: true,
            audioFilesError: false,
          });
          // eslint-disable-next-line no-console
          console.time(
            'audio_download_started',
            // component.state.currentAudioDetails
          );
          component.getFile(component.state.currentAudioDetails);
        } else if (response.http_status === 202) {
          const audioIdDetails =
            response.data && response.data.length ? response.data[0] : null;
          component.setState({
            currentAudioDetails: audioIdDetails,
            isResponseError: true,
            isLoading: false,
            audioFilesError: false,
            errorText: response.message,
          });
          //this.props.history.push("/dashboard/transcriptions/thanku")
          component.setState({
            errorText: Constants.no_transcriptions_message,
            no_records: true,
            isResponseError: true,
            isLoading: false,
            audioFilesError: true,
          });
        } else if (response.http_status === 203) {
          //this.props.history.push("/dashboard/transcriptions/thanku")
          component.setState({
            errorText: Constants.no_transcriptions_message,
            no_records: false,
            isResponseError: true,
            isLoading: false,
            audioFilesError: true,
          });
        } else {
          component.setState({
            errorText: response.message,
            no_records: false,
            isResponseError: true,
            isLoading: false,
            audioFilesError: true,
          });
        }
      })
      .catch((error: any) => {
        log.error(error);
        component.showApiError(error);
      });
  };
  /*
       After getting current audio file, get the buffer from that audio file to show the waveform only.
      */
  getFile = (audioDetails = component.state.currentAudioDetails) => {
    // const context = this.state.context;
    if (isAudioApiTokenEmpty()) {
      component.getAuthenticationToken();
    } else {
      // const videoBlob = getAudioS3URL(audioDetails, this.state.context);
      const videoBlob = getAudioS3URL(audioDetails);
      // log.debug("Video Blob", videoBlob, context);
      log.debug('Video Blob', videoBlob);
      videoBlob.then(
        response => {
          if (response.status === 401) {
            log.debug('Authentication not saved');
            component.getAuthenticationToken();
          } else if (response && response.status === 200) {
            // eslint-disable-next-line no-console
            // console.time('Getting duration');
            response.blob().then(
              (blob: any) => {
                const bloburl = URL.createObjectURL(blob);
                component.handleAudioIssues(bloburl);
                // eslint-disable-next-line no-console
                console.timeEnd('audio_download_started');
              },
              (error: any) => component.showApiError(error),
            );
          } else {
            log.debug(response);
            component.showApiError(response ? response : null);
          }
        },
        (error: any) => {
          component.showApiError(error);
        },
      );
    }
  };

  getAuthenticationToken() {
    Api.getAudioAuthenticationToken()
      .then(res => {
        if (res) {
          log.debug('Authentication token saved', res.access_token);
          localStorage.setItem(
            Constants.AUDIO_URL_SECRET_KEY,
            res.access_token,
          );
          component.getFile();
        }
      })
      .catch((error: any) => {
        component.showApiError(error);
      });
  }

  showApiError(error: any) {
    component.setState({
      errorText: error && error.length > 0 ? error : Constants.generic_message,
      no_records: true,
      isResponseError: true,
      isLoading: false,
      audioFilesError: true,
    });
  }

  handleAudioIssues(objectURL: string) {
    Api.getAudioIssue()
      .then((response: any) => {
        //log.debug('entered in handleUserIntents response', response);
        if (response && response.http_status && response.http_status === 200) {
          component.setState({
            audioIssues: response.data,
            isResponseError: false,
            isLoading: false,
            blobUrl: objectURL,
            played: null,
          });
        } else {
          component.setState({
            audioIssues: [],
            errorText: '',
            isResponseError: true,
            isLoading: false,
          });
        }
      })
      .catch((err: any) => {
        log.debug(err);
      });
  }

  submitAudioTranscriptions() {
    const userSelectedAudioIssues = component.state.userSelectedAudioIssues;

    if (component.checkAllMandatoryFields() === true) {
      log.debug('Started SetState');

      this.setState({
        playing: false,
        isAudioPlaying: false,
        isAudioAcceptClicked: true,
        playbackRate: 0.7,
      });

      handlePlayBackSpeed(0.7);

      setTimeout(() => {
        const transcriptionString = replaceUnwantedWords(
          this.state.transcriptText,
        ).trim();
        // const transcriptionString = this.state.transcriptText.trim();
        const metaData = component.state.metaData;
        const audioDetails = component.state.currentAudioDetails;
        const myParams = {
          audio_id: audioDetails.audio_id,
          customer_id: audioDetails.customer_id,
          campaign_id: audioDetails.campaign_id,
          // statement_id: audioDetails.statement_id,
          transcription_text: transcriptionString,
          is_audio_issue:
            Object.keys(userSelectedAudioIssues).length > 0 ? true : false,
          audio_issues: Array.from(Object.keys(userSelectedAudioIssues)).join(
            ',',
          ),
          native_speaker_flag:
            metaData['native_speaker'] === '1' ? true : false,
          contributor_gender: metaData['gender'],
          age_range: metaData['age_range'],
          frustration_level: metaData['frustration_level'],
        };

        log.debug('The params are', myParams);

        Api.storeGoodTimesTranscription(myParams)
          .then((response: any) => {
            //appResponse = response;
            if (
              response &&
              response.http_status &&
              response.http_status === 200
            ) {
              component.setState({
                isResponseError: false,
                isAudioAcceptClicked: false,
                dropDownValue: 'Select',
                transcriptText: '',
                isLoading: true,
                finalOtherOptions: '',
              });
              isFirstTime = 'false';
              // saved the status of previous transcription and use this status when getting new audio file.
              updatePreviousAudioStatus(myParams.is_audio_issue);
              component.setState({
                previousAudioId: audioDetails.audio_id,
                isResponseError: false,
                errorText: '',
                appRating: 0,
                userSelectedAudioIssues: {},
                metaData: component.resetMetaData(),
              });
              component.getCurrentAudioId('1');
              // component.props.getScore();
            } else {
              if (response.http_status === 203) {
                const errorMessage = response.message;
                component.setState({
                  isResponseError: true,
                  errorText: errorMessage,
                  isAudioAcceptClicked: false,
                  isLoading: false,
                  finalOtherOptions: '',
                });
              } else if (response.http_status === 204) {
                singleChoiceWithoutAutoCloseAlertDialogComponent(
                  response.message,
                ).then(
                  // eslint-disable-next-line no-unused-vars
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  (params: any) => {
                    component.setState({
                      isResponseError: false,
                      isAudioAcceptClicked: false,
                      dropDownValue: 'Select',
                      transcriptText: '',
                      isLoading: true,
                      finalOtherOptions: '',
                    });
                    isFirstTime = 'false';
                    // saved the status of previous transcription and use this status when getting new audio file.
                    // updatePreviousAudioStatus(params.is_audio_issue)
                    component.setState({
                      previousAudioId: audioDetails.audio_id,
                      isResponseError: false,
                      errorText: '',
                      appRating: 0,
                      userSelectedAudioIssues: {},
                      metaData: component.resetMetaData(),
                    });
                    // component.props.getScore();
                    component.getCurrentAudioId('1');
                  },
                  () => 0,
                );
              } else {
                component.setState({
                  isResponseError: true,
                  errorText: response.message,
                  isAudioAcceptClicked: false,
                  isLoading: false,
                });
              }
              this.getAutoPlayState();
              this.getAudioLoopState();
            }
            // get the score
            component.props.getScore();
          })
          .catch((error: any) => {
            component.setState({
              isResponseError: true,
              errorText: error.message,
              isAudioAcceptClicked: false,
              isLoading: false,
            });
            // get the score
            component.props.getScore();
          });
      }, 500);
    } else {
      component.setState({
        isResponseError: true,
        errorText: this.state.played
          ? 'Please enter all the details'
          : 'Please listen to the audio at least once.',
        isAudioAcceptClicked: false,
        isLoading: false,
      });
    }
  }

  getAudioLoopState() {
    throw new Error('Method not implemented.');
  }

  getAutoPlayState() {
    throw new Error('Method not implemented.');
  }

  resetMetaData() {
    // let metaData = component.state.metaData;
    const metaData = {
      age_range: 'none',
      frustration_level: 'none',
      native_speaker: 'none',
      gender: 'none',
      gender_error: '',
      age_range_error: '',
      native_speaker_error: '',
      frustration_error: '',
    };
    return metaData;
  }

  // handleMenuClick = menu => {
  //   log.debug("brandDetails", this.state.brandDetails, component, history);
  //   const requestBody = {
  //     customer_id: this.state.brandDetails.campaign_id
  //   };
  //   history.push({
  //     menu,
  //     pathname: "/dashboard/faqs/transcribefaqsComponent"
  //   });
  //   this.setState({ contentLoading: true, errorMessage: "" });
  //   Api.getMenuByBrand(requestBody).then(res => {
  //     if (res.http_status === 200) {
  //       this.props.history.push("/dashboard/contentmanagement");
  //       this.setState({ contentLoading: false });
  //     } else {
  //       log.debug("status 202");
  //       this.setState({ contentLoading: false, errorMessage: res.message });
  //     }
  //   });
  // };

  handleTextChange = (event: any) => {
    const newString = event.target.value;
    const letters = /^[a-z|A-Z|'| ]+$/.test(newString);
    log.debug('Pattern', letters);
    // this.setState({
    //   transcriptText: newString,
    // });
    if (letters) {
      log.debug('Pattern', 'matched');
      this.setState({
        transcriptText: newString,
      });
    } else {
      log.debug('Pattern', 'unmatched' + newString);
      if (newString.length > 0) {
        log.debug('Pattern', '1');
        this.setState({
          transcriptText: this.state.transcriptText,
        });
      } else {
        log.debug('Pattern', 'empty');
        this.setState({
          transcriptText: '',
        });
      }
    }
  };

  handleOnKeyUp = (event: any) => {
    if (event.key === 'Enter' && !enterKeyHit) {
      log.debug('enter key pressed');
      this.acceptAudioFile();
      enterKeyHit = true;
    }
  };

  checkAllMandatoryFields() {
    if (!this.state.played) {
      this.setState({ played: false });
      return false;
    }
    const userSelectedAudioIssues = component.state.userSelectedAudioIssues;
    const isAudioIssuesExist =
      Object.keys(userSelectedAudioIssues).length > 0 ? true : false;
    if (isAudioIssuesExist) {
      return true;
    }
    const isTranscriptionTextEntered =
      component.state.transcriptText &&
      component.state.transcriptText.trim().length > 0
        ? true
        : false;
    if (!isTranscriptionTextEntered) {
      return false;
    }
    log.debug('all are entered');
    return true;
  }

  startAudioPlay = () => {
    this.setState({ playing: true, isAudioPlaying: true });
    if (
      this.state.currentAudioDetails &&
      this.state.currentAudioDetails.audio_id !== this.state.previousAudioId
    ) {
      playAudio();
    }
  };

  onHandleSpeed = () => {
    if (this.state.playbackRate === 1) {
      this.setState({ playbackRate: 0.7 });
    } else {
      this.setState({ playbackRate: 1 });
    }
    handlePlayBackSpeed(this.state.playbackRate);
  };

  pauseAudioPlay = () => {
    this.setState({ playing: true, isAudioPlaying: false });
    if (pauseAudio) {
      pauseAudio();
    }
  };

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAudioEnded = (event: any) => {
    this.setState({ playing: false, isAudioPlaying: false });
    log.debug('onAudioEnded' + isLoopEnabled());
    if (isLoopEnabled() === 'true') {
      log.debug('onAudioEnded' + isLoopEnabled());
      component.startAudioPlay();
    }
    // set state that we have played file at least once
    this.setState({
      played: true,
    });
    enterKeyHit = false; // reset ability to hit 'Enter' key again.
  };

  acceptAudioFile = () => {
    if (this.state.audioFilesError === false) {
      try {
        component.pauseAudioPlay();
        this
          .submitAudioTranscriptions
          // this.state.dropDownValue,
          // this.state.currentAudioDetails,
          // this.state.finalOtherOptions
          ();
      } catch (err: any) {
        log.debug('error', err);
      }
    }
  };

  // setValue = (val: any, prop: string, sub: string) => {
  //   if (sub) {
  //     this.setState(state => ({
  //       ...state,
  //       [sub]: {
  //         ...state[sub],
  //         [prop]: val
  //       }
  //     }));
  //   } else {
  //     this.setState({ [prop]: val });
  //   }
  // };

  onEnded() {
    component.onAudioEnded();
  }

  // ref = player => {
  //   player = player;
  // };

  onProgress = (state: any) => {
    log.debug('onProgress', state, player.getDuration());
    // const total = player.getDuration();
    const pos = state.playedSeconds / player.getDuration();
    component.setState({ pos: pos });
  };

  updateMaskStatusValue() {
    if (this.props.score && !this.props.score.is_audio_transcription) {
      component.props.updateMaskStatus(true);
    } else {
      component.props.updateMaskStatus(false);
    }
  }

  onChange(value: string) {
    log.debug('Rating Changed', value);
    component.setState({ appRating: value });
  }

  onCheck(event: any, isInputChecked: boolean) {
    log.debug('KEY', event);
    log.debug('KEY', event.target.id + '  ' + event.target.value);
    log.debug('KEY', isInputChecked);
    const audioIssues = component.state.userSelectedAudioIssues;
    if (isInputChecked) {
      audioIssues[event.target.id] = true;
    } else {
      delete audioIssues[event.target.id];
    }
    log.debug(Object.keys(audioIssues).length.toString());
    component.setState({ userSelectedAudioIssues: audioIssues });
  }

  onReadyToPlay() {
    if (isAutoPlayEnabled() === 'true' && isFirstTime === 'false') {
      component.startAudioPlay();
    }
    component.updateMaskStatusValue();
  }

  // onAudioLoopChanged = (name: any) => (event: any) => {
  onAudioLoopChanged = (event: any) => {
    updateAudioLoop(event.target.checked);
    this.setState({
      audioLoopChecked: !this.state.audioLoopChecked,
    });
  };

  // onAutoPlayChanged = (name: any) => (event: any) => {
  onAutoPlayChanged = (event: any) => {
    updateAutoPlayAudio(event.target.checked);
    this.setState({
      autoPlayChecked: !this.state.autoPlayChecked,
    });
  };

  // eslint-disable-next-line no-unused-vars
  // onAudioIssueSelection = (key: any, event: any) => {};

  onAgeRangeSelection = (event: any) => {
    const metadata = component.state.metaData;
    metadata['age_range'] = event.target.value;
    log.debug('META_DATA', metadata);
    component.setState({ metaData: metadata });
  };

  onNativeSpeakerSelection = (event: any, index: number, value: string) => {
    const metadata = component.state.metaData;
    metadata['native_speaker'] = value;
    log.debug('META_DATA', metadata);
    component.setState({ metaData: metadata });
  };

  onGenderSelection = (event: any, index: number, value: string) => {
    const metadata = component.state.metaData;
    metadata['gender'] = value;
    log.debug('META_DATA', metadata);
    component.setState({ metaData: metadata });
  };

  onFrustrationLevelSelection = (event: any, index: number, value: string) => {
    const metadata = component.state.metaData;
    metadata['frustration_level'] = value;
    log.debug('META_DATA', metadata);
    component.setState({ metaData: metadata });
  };

  onWindowSizeChanged() {
    // component.pauseAudioPlay()
  }

  render() {
    log.debug('IS_AUTO_PLAY_ENABLED', isAutoPlayEnabled());

    const { classes } = this.props;
    if (this.props.pauseAudio) {
      component.pauseAudioPlay();
    }

    return (
      <MuiThemeProvider theme={Theme}>
        {this.state.isLoading ? (
          <div
            style={{
              position: 'absolute',
              width: '75px',
              textAlign: 'center',
              top: '50%',
              margin: '-20px 0px 0px -57px',
              left: '50%',
            }}
          >
            <CircularProgressComponent />
          </div>
        ) : (
          <div>
            {this.state.audioFilesError ? (
              <div>
                <div className="noAudioFileText" style={{ color: '#265067' }}>
                  {this.state.errorText}
                </div>
              </div>
            ) : (
              <div>
                <div className="audioWaveform">
                  <WaveformNew
                    src={this.state.blobUrl}
                    finish={this.onAudioEnded}
                    onReady={this.onReadyToPlay}
                    render={({ play, pause, slowerPlayBackRate }) => {
                      playAudio = play;
                      handlePlayBackSpeed = slowerPlayBackRate;
                      pauseAudio = pause;
                    }}
                  />
                </div>

                <div className="clear" />

                <div className="audioSettingsContainer">
                  <FormControlLabel
                    className="playStyles"
                    control={
                      <Switch
                        checked={this.state.autoPlayChecked}
                        onChange={(e: any) => this.onAutoPlayChanged(e)}
                        value="Auto Play"
                        color="primary"
                        classes={{
                          switchBase: classes.colorSwitchBase,
                          checked: classes.colorChecked,
                          // bar: classes.colorBar
                        }}
                      />
                    }
                    label="Auto Play"
                    style={{ margin: 0 }}
                  />

                  {this.state.isAudioPlaying ? (
                    <CustomFloatingActionButton
                      id="pausePlaying"
                      icon={AudioPause}
                      backgroundColor="#488FAB"
                      onClick={this.pauseAudioPlay}
                      labelColor={'#5299b4'}
                      // label={'Pause'}
                    />
                  ) : (
                    <CustomFloatingActionButton
                      id="startPlaying"
                      icon={AudioPlay}
                      backgroundColor="#9F9F9F" // "#488FAB"
                      onClick={this.startAudioPlay}
                      labelColor={'#5299b4'}
                      // label={'Listen'}
                    />
                  )}

                  <img
                    className={classes.turtleIcon}
                    src={
                      this.state.playbackRate === 0.7
                        ? turtleIconGray
                        : turtleIcon
                    }
                    onClick={this.onHandleSpeed}
                  />

                  <FormControlLabel
                    className="loopPlayStyle"
                    control={
                      <Switch
                        checked={this.state.audioLoopChecked}
                        onChange={(e: any) => this.onAudioLoopChanged(e)}
                        value="Loop Audio"
                        color="primary"
                        classes={{
                          switchBase: classes.colorSwitchBase,
                          checked: classes.colorChecked,
                          // bar: classes.colorBar
                        }}
                      />
                    }
                    label="Loop Audio"
                    style={{ margin: 0 }}
                  />
                </div>
                <div className="audioWaveformSubFields">
                  <AudioTranscriptionTextField
                    id="text-field-controlled"
                    history={this.props.history}
                    value={this.state.transcriptText}
                    onChange={this.handleTextChange}
                    onKeyUp={this.handleOnKeyUp}
                    brandDetails={this.state.brandDetails}
                  />

                  {component.state.isResponseError === true && (
                    <div style={{ color: 'red', fontWeight: 'bold' }}>
                      {this.state.errorText}
                    </div>
                  )}
                  {component.state.rulesAck !== 'true' && (
                    <div style={{ color: 'red', fontWeight: 'bold' }}>
                      {'Please review the rules once per login.'}
                    </div>
                  )}

                  <div className={classes.audioWrapper}>
                    {component.state.dropdownsList && (
                      <AudioIssuesComponent
                        metaData={component.state.metaData}
                        onCheck={this.onCheck}
                        audioIssues={this.state.audioIssues}
                        ageRange={component.state.dropdownsList.age_range}
                        genders={
                          component.state.dropdownsList.contributor_gender
                        }
                        onAgeRangeSelection={this.onAgeRangeSelection}
                        onGenderSelection={this.onGenderSelection}
                        onFrustrationLevelSelection={
                          this.onFrustrationLevelSelection
                        }
                        onNativeSpeakerSelection={this.onNativeSpeakerSelection}
                      />
                    )}
                    <Button
                      variant="contained"
                      size="medium"
                      id="acceptAudio"
                      // icon={AcceptAudio}
                      className={
                        this.state.isAudioAcceptClicked ||
                        this.state.rulesAck !== 'true'
                          ? 'audioSubmitButtonDisabled'
                          : 'audioSubmitButton'
                      }
                      disabled={
                        this.state.isAudioAcceptClicked ||
                        this.state.rulesAck !== 'true'
                          ? true
                          : false
                      }
                      onClick={
                        this.state.isAudioAcceptClicked
                          ? () => 0
                          : this.acceptAudioFile
                      }
                    >
                      {this.state.isAudioAcceptClicked ? (
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 999,
                          }}
                        >
                          <CircularProgressComponent
                            thickness={2}
                            size={30}
                            color={Colors.DRAWER_TEXT_COLOR}
                          />
                        </div>
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

// function mapStateToProps(state: any) {
//   return { score: state.score, pauseAudio: state.pauseAudio };
// }

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    getScore: () => {
      dispatch(getScore());
    },
    updateTutorialFlag: (tutorialFlag: boolean) => {
      dispatch(updateTutorialFlag(tutorialFlag));
    },
    updateMaskStatus: (status: any) => {
      dispatch(updateMaskStatus(status));
    },
  };
}

const addedStyle = withStyles(Styles)(AudioPlayComponentGoodtimes);

export default connect(null, mapDispatchToProps)(addedStyle);
