// import TOGGLE_SWITCHER from '../actions/index'
import { DARK_THEME, ToggleSwitcher } from '../actions';
import { LIGHT_MODE, DARK_MODE } from '../theme/ThemeColors';

// export default (
//     state = {
//         isDarkMode:true,
//         ...DARK_MODE
//     },action
// ) => {
// switch(action.type) {
//     case TOGGLE_SWITCHER :
//     const isDarkMode = !state.isDarkMode;
//     const colorObject = isDarkMode? DARK_MODE : LIGHT_MODE;
// return {isDarkMode, ...colorObject, ...state }
// default: return state
// }
// }

export interface ThemeReducerState {
  darkTheme: boolean;
  textColor: string;
  backgroundColor: string;
}

const initialState: ThemeReducerState = {
  darkTheme: true,
  ...DARK_MODE,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function SwitchTheme(state = initialState, action: ToggleSwitcher) {
  const isDarkMode = !state.darkTheme;
  const colorObject = isDarkMode ? DARK_MODE : LIGHT_MODE;
  if (action.type === DARK_THEME) {
    return Object.assign({}, { darkTheme: action.darkTheme, ...colorObject });
  }
  return { ...state, ...colorObject };
}

export default SwitchTheme;
